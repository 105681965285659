import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MasterService } from '../../services/master.service';
import { LoadingController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-choose-storie',
  templateUrl: './choose-storie.component.html',
  styleUrls: ['./choose-storie.component.scss'],
})
export class ChooseStorieComponent implements OnInit {

  constructor(
  	public modalController: ModalController,
    private master: MasterService,
    public loadingController: LoadingController,
    public toastController: ToastController
  	) { }

  ngOnInit() {}

  
    closeModal() {
      this.modalController.dismiss();
  }

}
