import { Component, OnInit, Input , NgZone } from '@angular/core'; 
import { Router } from '@angular/router';

import { ActivatedRoute } from '@angular/router';
import { MasterService } from '../../../services/master.service'


import { ModalController } from '@ionic/angular';


import  { RazorPayment } from '../../../interfaces';



import { WindowRefService } from '../../../services/window-ref.service'

import { ToastController } from '@ionic/angular';



import { Facebook, FacebookLoginResponse } from '@awesome-cordova-plugins/facebook/ngx';





@Component({
  selector: 'app-enroll-modal',
  templateUrl: './enroll.component.html',
  styleUrls: ['./enroll.component.scss'],
})
export class EnrollModal implements OnInit {



  minDate: any;
  maxDate: any;


  constructor(
    private route:ActivatedRoute,
    private router:Router,
	  private master: MasterService,
    private zone: NgZone,
    private winRef: WindowRefService,
    public modalController: ModalController,
    public toastController: ToastController,
    private fb: Facebook
  	) { 

    this.minDate = new Date();
    this.minDate.setDate(new Date().getDate() + 7);
    this.maxDate = new Date();
    this.maxDate.setDate(new Date().getDate() + 90);
    }

  @Input() batch_id: any;
  @Input() profile: any;



  batch: any;
  school: any;
  course: any;

  badges: any;
  skills:any;

  badge_imgs: any;
  skill_imgs: any;

  badge_dict : any;
  skill_dict : any;

  startDate1: any;
  endDate1: any;
  
  startDate2: any;
  endDate2: any;
  
  startDate3: any;
  endDate3: any;
  



   Pay_options = {
    "key": this.master.pay_key(), // Enter the Key ID generated from the Dashboard
    "amount": "", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    "currency": "INR",
    "name": "",
    "description": "",
    "image": "/assets/logo.png",
    "order_id": "", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    "handler": async (response) => {
      var payment_info = <RazorPayment> {};
      payment_info.razorpay_order_id = response.razorpay_order_id;
      payment_info.razorpay_payment_id = response.razorpay_payment_id;
      payment_info.razorpay_signature = response.razorpay_signature;
      var res = await this.master.submitPaymentInfo(payment_info);
      console.log(res);
      if(res['result']=='true'){
        this.zone.run(()=>{
        this.presentToast('order made!');
      this.dismiss();
        });
      }
      else{
        this.zone.run(()=>{
        this.presentToast('error! please try in some time!');
        })
      }
    } ,
    "redirect": false,
    "prefill": {
        "name": "",
        "email": "",
        "contact": ""
    },
    "notes": {
        "none": "none"
    },
    "theme": {
        "color": "#073060"
    }
  };



  ngOnInit() {
    this.fb.logEvent(this.fb.EVENTS.EVENT_NAME_ADDED_TO_CART);
    this.loadBatchDetails();
  	this.loadBatches();
  	this.loadSkills();
   }

  async loadBatches(){

  	var res = await this.master.readPilotBadges();
  	console.log(res);
  	this.badges = JSON.parse(res['payload'])
  	console.log(this.badges)

  	this.badge_dict = {};
  	this.badge_imgs = this.master.mapURLs(this.badges.map((x:any ) =>{
  		return x.fields.imagefile
  	}))

  	console.log(this.badge_imgs)

  	for (var i = this.badges.length - 1; i >= 0; i--) {
  		this.badge_dict[this.badges[i].pk] = this.badge_imgs[i]
  	}

  	console.log(this.badge_dict)
  }
  async loadSkills(){
  	var res = await this.master.readPilotSkills();
  	console.log(res);
  	this.skills = JSON.parse(res['payload'])
  	console.log(this.skills)

  	this.skill_dict = {};
  	this.skill_imgs = this.master.mapURLs(this.skills.map((x:any ) =>{
  		return x.fields.imagefile
  	}))

  	console.log(this.skill_imgs)
  	for (var i = this.skills.length - 1; i >= 0; i--) {
  		this.skill_dict[this.skills[i].pk] = this.skill_imgs[i]
  	}
  	console.log(this.skill_dict)


  }

  async loadBatchDetails(){
  	var res = await this.master.readOneBatch(this.batch_id);

  	console.log(res);

  	this.batch = JSON.parse(res['payload']['batch'])[0];
  	this.school = JSON.parse(res['payload']['school'])[0];
  	this.course = JSON.parse(res['payload']['course'])[0];

  	console.log(this.batch);
  	console.log(this.school);
  	console.log(this.course);

  }
  
  backClicked(){
    this.dismiss();
    // this.router.navigate(['/pilot-trainings']);
  }

    dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true,
    });
  }

  async makeEnrollment(){

    var context_string = JSON.stringify([]);

    if(this.batch.fields.is_open){
    var context_dates = [
      new Date( this.startDate1 ).toISOString(),
      new Date( this.endDate1 ).toISOString(),
      new Date( this.startDate2 ).toISOString(),
      new Date( this.endDate2 ).toISOString(),
      new Date( this.startDate3 ).toISOString(),
      new Date( this.endDate3 ).toISOString(),
    ]  

    context_string = JSON.stringify(context_dates);

      // context_string = JSON.stringify([]);
    }

    var res = await this.master.pilotPokeTraining(this.batch.fields.unique_id,'batch','apply',context_string);
    console.log(res); 
    if(res['result']=='true'){
      // this.dismiss();
        this.fb.logEvent(this.fb.EVENTS.EVENT_NAME_PURCHASED);
        this.payWithRazor(res['payment_info']);
    }
    else{
      this.presentToast(res['error']);
      this.router.navigate(['/pilot-trainings']);
    }
  }


  payWithRazor(order)
  {
        this.Pay_options.amount = order.amount_due;
        this.Pay_options.name = "VIMANA AeroTech Pvt. Ltd.";
        this.Pay_options.description = "Pay for your training";
        this.Pay_options.order_id = order.id;
        this.Pay_options.prefill.name = this.profile.userName;
        this.Pay_options.prefill.contact = this.profile.userPhone;
        console.log(this.Pay_options);
        const rzp = new this.winRef.nativeWindow.Razorpay(this.Pay_options);
        rzp.open();
  }

 


  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }  





}
