import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { FormControl,FormGroup,Validators } from '@angular/forms';
import { MasterService } from '../../services/master.service'
import { ToastController } from '@ionic/angular';
import {Observable,Subject} from 'rxjs';
import {map, startWith,debounceTime} from 'rxjs/operators';



@Component({
  selector: 'app-pilot-add-drone',
  templateUrl: './pilot-add-drone.component.html',
  styleUrls: ['./pilot-add-drone.component.scss'],
})
export class PilotAddDroneComponent implements OnInit {

  constructor(
    private router: Router,
		private master: MasterService,
    public toastController: ToastController,
		public modalController: ModalController,
    	) { }

  myControl = new FormControl('');

  filteredOptions: any;

  uavModels: any = [];
  search_drone: any;

  selected_model: any;

  droneForm: any;

  registrarion_type: any;

  private query_subject: Subject<string> = new Subject();
  queryChange(){
    console.log(this.search_drone)
    this.query_subject.next(this.search_drone);
  }

  todayDate: any;

  ngOnInit() {

    this.todayDate = new Date();

    this.query_subject.pipe(
      debounceTime(500)
    ).subscribe((searchTextValue: any) => {
      this.handleSearch(searchTextValue);
    });

    this.loadModels();


    this.droneForm = new FormGroup(
    {
      'purchaseDate': new FormControl('',[Validators.required]),
      'estimate_amount': new FormControl('',[Validators.required]),
      'code_name': new FormControl('',[Validators.required]),
      'description': new FormControl('',[]),
      'DAN': new FormControl('0',[]),
      'OAN': new FormControl('0',[]),
      'UIN': new FormControl('0',[]),
      'serial_number': new FormControl('',[Validators.required]),
    })




  }
  async handleSearch(x: any){
    console.log(x);
     const filterValue = x.toLowerCase();

    this.filteredOptions = this.uavModels.filter((drone: any) => {
    var name = drone.fields.uasModelName;
    var desc = drone.fields.manufacturerName;
    return name.toLowerCase().includes(filterValue)||desc.toLowerCase().includes(filterValue)
    } );

    console.log(this.filteredOptions)


  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.uavModels.filter(option => option.fields.uasModelName.toLowerCase().includes(filterValue));
  }
  async loadModels(){
    var res = await this.master.readUAVModels();

    console.log(res);

    this.uavModels = JSON.parse(res['payload'])

    console.log(this.uavModels);
  }
  filterOptions(events){
    console.log(events);
  }
  backClicked(){
  	this.router.navigate(['/pilot-drones']);

  }
  selectDrone(option){
    console.log(option);
    this.selected_model = option;
  }



  front_img_urls: any
  front_files_selected: any
  front_files: any
  front_file_list: any = [];

  back_img_urls: any
  back_files_selected: any
  back_files: any
  back_file_list: any = [];

  serial_img_urls: any
  serial_files_selected: any
  serial_files: any
  serial_file_list: any = [];


  purchase_bill_img_urls: any
  purchase_bill_files_selected: any
  purchase_bill_files: any
  purchase_bill_file_list: any = [];

  onFrontFileSelected(event){
    let fileList: FileList = event.target.files;
    let fileListLength = fileList.length;
    this.front_files = fileList;
    this.front_file_list = fileList;
    this.front_img_urls = [];


    for (var i = 0; i < fileListLength; i++) {
       if(fileList[i].size > 1048576*50){

         this.front_img_urls = undefined;

            this.presentToast('FILE TOO BIG');
         return ;
       }
    }

    for (var i = 0; i < fileListLength; i++) {
      var a = fileList[i];
      var reader = new FileReader();
      reader.onload = (event:any) => {
        var url = event.target.result;
        this.front_img_urls.push(url); 
      }
      reader.readAsDataURL(a);
    }
    this.front_files_selected = true;
  }

  onBackFileSelected(event){
    let fileList: FileList = event.target.files;
    let fileListLength = fileList.length;
    this.back_files = fileList;
    this.back_file_list = fileList;
    this.back_img_urls = [];


    for (var i = 0; i < fileListLength; i++) {
       if(fileList[i].size > 1048576*50){

         this.back_img_urls = undefined;

            this.presentToast('FILE TOO BIG');
         return ;
       }
    }

    for (var i = 0; i < fileListLength; i++) {
      var a = fileList[i];
      var reader = new FileReader();
      reader.onload = (event:any) => {
        var url = event.target.result;
        this.back_img_urls.push(url); 
      }
      reader.readAsDataURL(a);
    }
    this.back_files_selected = true;
  }

  onSerialFileSelected(event){
    let fileList: FileList = event.target.files;
    let fileListLength = fileList.length;
    this.serial_files = fileList;
    this.serial_file_list = fileList;
    this.serial_img_urls = [];


    for (var i = 0; i < fileListLength; i++) {
       if(fileList[i].size > 1048576*50){

         this.serial_img_urls = undefined;

            this.presentToast('FILE TOO BIG');
         return ;
       }
    }

    for (var i = 0; i < fileListLength; i++) {
      var a = fileList[i];
      var reader = new FileReader();
      reader.onload = (event:any) => {
        var url = event.target.result;
        this.serial_img_urls.push(url); 
      }
      reader.readAsDataURL(a);
    }
    this.serial_files_selected = true;
  }
  

  onPurchaseFileSelected(event){
    let fileList: FileList = event.target.files;
    let fileListLength = fileList.length;
    this.purchase_bill_files = fileList;
    this.purchase_bill_file_list = fileList;
    this.purchase_bill_img_urls = [];


    for (var i = 0; i < fileListLength; i++) {
       if(fileList[i].size > 1048576*50){

         this.purchase_bill_img_urls = undefined;

            this.presentToast('FILE TOO BIG');
         return ;
       }
    }

    for (var i = 0; i < fileListLength; i++) {
      var a = fileList[i];
      var reader = new FileReader();
      reader.onload = (event:any) => {
        var url = event.target.result;
        this.purchase_bill_img_urls.push(url); 
      }
      reader.readAsDataURL(a);
    }
    this.purchase_bill_files_selected = true;
  }
  

  async sendDrone(){



    var drone_data: any = {
      model_uuid : this.selected_model.fields.unique_id,
      purchaseDate : new Date(this.droneForm.value.purchaseDate).toISOString(),
      estimate_amount : this.droneForm.value.estimate_amount,
      code_name : this.droneForm.value.code_name,
      description : this.droneForm.value.description,
      DAN : this.droneForm.value.DAN,
      OAN : this.droneForm.value.OAN,
      UIN : this.droneForm.value.UIN,
      serial_number : this.droneForm.value.serial_number,      
    }
    var res = await this.master.pilotCreateUAV(drone_data);

    console.log(res);


    if(res['result']=='true'){
      this.presentToast("added")


      var drone_uuid = res['uuid'];


      if(this.front_files_selected)
      {
        var formData = new FormData();
        formData.append('imagefile', this.front_file_list[0], this.front_file_list[0].name);
        formData.append('description', 'front_image');
        formData.append('uuid',drone_uuid)
        var res2 = await this.master.pilotUploadDroneImage(formData)
        if(res2['result']=='true'){
          this.presentToast("front image added")
        }
      }
      if(this.back_files_selected)
      {
        var formData = new FormData();
        formData.append('imagefile', this.back_file_list[0], this.back_file_list[0].name);
        formData.append('description', 'back_image');
        formData.append('uuid',drone_uuid)
        var res2 = await this.master.pilotUploadDroneImage(formData)
        if(res2['result']=='true'){
          this.presentToast("back image added")
        }
      }
      if(this.serial_files_selected)
      {
        var formData = new FormData();
        formData.append('imagefile', this.serial_file_list[0], this.serial_file_list[0].name); 
        formData.append('description', 'serial_image');
        formData.append('uuid',drone_uuid)
        var res2 = await this.master.pilotUploadDroneImage(formData)
        if(res2['result']=='true'){
          this.presentToast("serial image added")
        }
      }
      if(this.purchase_bill_files_selected)
      {
        var formData = new FormData();
        formData.append('imagefile', this.purchase_bill_file_list[0], this.purchase_bill_file_list[0].name); 
        formData.append('description', 'serial_image');
        formData.append('uuid',drone_uuid)
        var res2 = await this.master.pilotUploadDroneImage(formData)
        if(res2['result']=='true'){
          this.presentToast("purchase bill image added")
        }
      }

      this.backClicked()
    }
    else{
        this.presentToast(res['error']);
    }

  }


  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }

}
