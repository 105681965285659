import * as THREE from 'three';
import { Water } from 'three/examples/jsm/objects/Water.js';
import { Sky } from 'three/examples/jsm/objects/Sky.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';

import { fragmentShaderSurvey,vertexShaderSurvey } from '../shaders';


const {SimplexNoise} = require('simplex-noise');

let simplex = new SimplexNoise(4);
function map(val, smin, smax, emin, emax) {
    const t =  (val-smin)/(smax-smin)
    return (emax-emin)*t + emin
}
function noise(nx, ny) {
    // Re-map from -1.0:+1.0 to 0.0:1.0
    return map(simplex.noise2D(nx,ny),-1,1,0,1)
}
//stack some noisefields together
function octave(nx,ny,octaves) {
    let val = 0;
    let freq = 1;
    let max = 0;
    let amp = 1;
    for(let i=0; i<octaves; i++) {
        val += noise(nx*freq,ny*freq)*amp;
        max += amp;
        amp /= 2;
        freq  *= 2;
    }
    return val/max;
}


function doneLoadManager(){

}
function onProgressLoadManager()
{

}
function onErrorLoadManager()
{

}
function onProgressLoader()
{

}
function onErrorLoader()
{

}
function generateTexture() {
	const canvas = <HTMLCanvasElement> document.createElement('canvas');
	const ctx = canvas.getContext('2d');
    ctx.fillStyle = 'black'
    ctx.fillRect(0,0,canvas.width, canvas.height)

    for(let i=0; i<canvas.width; i++) {
        for(let j=0; j<canvas.height; j++) {
            let v =  octave(i/canvas.width,j/canvas.height,16)
            const per = (100*v).toFixed(2)+'%'
            ctx.fillStyle = `rgb(${per},${per},${per})`
            ctx.fillRect(i,j,1,1)
        }
    }
    return ctx.getImageData(0,0,canvas.width,canvas.height)
}
export function get_survey_scene(renderer){
	   console.log("<<SURVEY SCENE>>");

	var obj_material = new THREE.MeshLambertMaterial({
		color  : 0xffffff,
		side : THREE.DoubleSide,
	});


	var drone_material = new THREE.MeshLambertMaterial({
		color  : 0xe8b5ca,
		side : THREE.DoubleSide,
	});
	var bluewireframe_material =  new THREE.MeshBasicMaterial({
									    color: 0x0095DD,
									    wireframe: true,
									    wireframeLinewidth: 1
									});



	// var terrain_materials = [];
	// terrain_materials.push( new THREE.MeshBasicMaterial( { color: 0xff0000 }) );
	// terrain_materials.push( new THREE.MeshBasicMaterial( { color: 0x00ff00 }) );
	// terrain_materials.push( new THREE.MeshBasicMaterial( { color: 0x0000ff }) );


	var xScale = 1.0;
	var yScale = 1.0;
	var zScale = 1.0;

    // ground planes


	var geo = new THREE.PlaneGeometry(1000,1000,100,100);
	geo.rotateX(-Math.PI/2);


	geo.clearGroups(); // just in case
	geo.addGroup( 0, Infinity, 0 ); // remaining vertices use material 2


	const positionAttribute = geo.getAttribute('position');
	var width = 512;
	var height = 512;

	const size = width * height;
	// const data = new Uint8Array( 4 * size );

	// var max_height = 0.
	// for(let j=0; j< 100; j++) {
	// 	// var av_height
	//     for (let i = 0; i < 100; i++) {
	//         const n =  (j*(100)  +i)
	//         const nn = (j*(100)+i)
	// 		var v =  octave(i/100,j/100,16)
	// 		v *= 100.;
	//         if(v > 50) v = v*1.9 //exaggerate the peaks
	//         // v1.x += map(Math.random(),0,1,-0.5,0.5) //jitter x
	//         // v1.y += map(Math.random(),0,1,-0.5,0.5) //jitter y
 //    		positionAttribute.setY(nn, v);
	//     	// console.log(nn,v);
	//     	if(max_height < v){
	//     		max_height = v;
	//     	}
	//     	const stride = 100*j + i;
	//     	data[stride] = 1;
	//     	data[stride + 1] = 1;
	//     	data[stride + 2] = 1;
	//     	data[stride + 3] = 1;
	//     }
	// }
	// positionAttribute.needsUpdate = true;

	console.log("TERRAIN geometry")
	console.log(geo)
	// var l = geometry.faces.length / 2;
	// for( var i = 0; i < l; i ++ ) {
	//     var j = 2 * i;
	//     geometry.faces[ j ].materialIndex = i % 3;
	//     geometry.faces[ j + 1 ].materialIndex = i % 3;
	// }

	// var geometry = new THREE.BoxGeometry( 1000, 2, 1000 );
	var geometry = geo;
	// var material = new THREE.MeshNormalMaterial();

	// var texture = THREE.ImageUtils.loadTexture('/assets/eco.png');
	// texture.anisotropy = this.renderer.getMaxAnisotropy();
	// texture.offset.x = 0.5; // 0.0 - 1.0
	// texture.offset.y = 0.5; // 0.0 - 1.0
	// texture.repeat.set(1, 1);


	// var imageMaterial = 	    new THREE.MeshBasicMaterial({
	//         map: texture //left
	//     });



	// const heightTexture = new THREE.DataTexture( data, 100, 100 );
	// heightTexture.needsUpdate = true;
	
	console.log('|||||||||||||||||HTEXTURE|||||||||||||||||||||');
	// console.log(heightTexture);


    var uniforms = {
        time: { type: "f", value: 1.0 },
        resolution: { type: "v2", value: new THREE.Vector2(100.,100.) },
        flight_x :{ type: "f", value: 1.0 },
        flight_y :{ type: "f", value: 1.0 },
    };
	var shaderMaterial = new THREE.ShaderMaterial({
                uniforms: uniforms,
                vertexShader: vertexShaderSurvey,
                fragmentShader: fragmentShaderSurvey,
           		side: THREE.DoubleSide
            });


    var terrain_materials = [
    shaderMaterial,
    new THREE.MeshBasicMaterial( { color: 0x00ff00 } ),
    new THREE.MeshBasicMaterial( { color: 0x0000ff } ),    	
    new THREE.MeshBasicMaterial( { color: 0xffffff } ),    	
    ]

	var mesh = new THREE.Mesh( geometry, terrain_materials );

    var middle = new THREE.Vector3();

    geometry.computeBoundingBox();

    middle.x = (geometry.boundingBox.max.x + geometry.boundingBox.min.x) / 2;
    middle.y = (geometry.boundingBox.max.y + geometry.boundingBox.min.y) / 2;
    middle.z = (geometry.boundingBox.max.z + geometry.boundingBox.min.z) / 2;
    // console.log(middle);
    mesh.translateX(-1*middle.x*xScale);
    mesh.translateY(-1*middle.y*yScale);
    mesh.translateZ(-1*middle.z*zScale);


    var myScene = new THREE.Scene();


	var pointLightC = new THREE.PointLight( 0xaaaaaa,5.0, 1000 );
	var pointLightD = new THREE.PointLight( 0xffffff,5.0, 1000 );
	pointLightC.position.set( 500, 500, 500 );
	pointLightD.position.set( -500, 500, -500 );

	myScene.add( pointLightC );
	myScene.add( pointLightD );
    var ambientLight = new THREE.AmbientLight( 0xcccccc, 0.4 );

    myScene.add( ambientLight );



	// myScene.add(geo);


	const sky = new Sky();
	sky.scale.setScalar(10000);

	// myScene.add(sky);


	// const pmremGenerator = new THREE.PMREMGenerator(renderer);
	const sun = new THREE.Vector3();

	// Defining the x, y and z value for our 3D Vector
	// const theta = THREE.MathUtils.degToRad(0);
	// const phi = THREE.MathUtils.degToRad( -90);

	// sun.setFromSphericalCoords( 1, theta, phi );

	  const theta = Math.PI * (0.49 - 0.5);
	  const phi = 2 * Math.PI * (0.105 - 0.5);
	  sun.x = Math.cos(phi);
	  sun.y = Math.sin(phi) * Math.sin(theta);
	  sun.z = Math.sin(phi) * Math.cos(theta);



	sky.material.uniforms['sunPosition'].value.copy(sun);

	// myScene.environment = pmremGenerator.fromScene(myScene).texture;


    myScene.add( mesh );

	var pointToRotateAround = new THREE.Vector3( 0, 0, 0);
	var pivot = new THREE.Object3D();

	var myDrone;

	function doneDroneLoader(){		
    myDrone.rotation.set(0,0,0);
    myDrone.scale.set(3,3,3);
    var middle = new THREE.Vector3();
    var geometry = myDrone.children[0].geometry;
    geometry.computeBoundingBox();
    middle.x = (geometry.boundingBox.max.x + geometry.boundingBox.min.x) / 2;
    middle.y = (geometry.boundingBox.max.y + geometry.boundingBox.min.y) / 2;
    middle.z = (geometry.boundingBox.max.z + geometry.boundingBox.min.z) / 2;
    // console.log(middle);
    myDrone.translateX(-1*middle.x*xScale);
    myDrone.translateY(-1*middle.y*yScale);
    myDrone.translateZ(-1*middle.z*zScale);

    myDrone.translateY(500);

    console.log(myDrone);
    myScene.add( myDrone );
	}

	var droneModel = 'https://vimanastatic.s3.ap-south-1.amazonaws.com/drone_rotorless.obj';


	var manager = new THREE.LoadingManager( doneLoadManager,onProgressLoadManager,doneLoadManager);
	var loader = new OBJLoader( manager );



	loader.load( droneModel, ( obj ) => {
	myDrone = obj;
	myDrone.traverse( function( child ) {
	if ( child instanceof THREE.Mesh ) {
	child.material = drone_material;
	}
	} );

	doneDroneLoader();

	}, onProgressLoader, onErrorLoader );


	var axis = new THREE.Vector3(0,0,0);//tilted a bit on x and y - feel free to plug your different axis here
	var rad = 0;
	var radIncrement = 0.007;
	var rot = 0;
	var rotIncrement = 0.0005;


	var FUNCTIONAL_K = 5/3;

	function droneNormalXYZ(val){
		var r_polar = Math.sin(FUNCTIONAL_K * val);

		var dx = Math.sin(val)*r_polar;
		var dy = 0;
		var dz = Math.cos(val)*r_polar;
		var r_vex = new THREE.Vector3(dx,dy,dz);
		return r_vex;

	}

	function droneXYZ(frame){

		frame = frame % 100000;

		var theta = frame;

		var span = 500;
		var fly_height = 100;

		var norm_xyz = droneNormalXYZ(theta)
		// console.log(norm_xyz);

		var dx = norm_xyz.x*span;
		var dy = fly_height;
		var dz = norm_xyz.z*span;
		var r_vex = new THREE.Vector3(dx,dy,dz);
		return r_vex;
	}

	function droneROT(frame){

		frame = frame % 100000;

		var omega_frame = frame/100000 * 2 * Math.PI;

		var theta = frame;
		var del = 0.0001;
		var r_polar = 500*Math.sin(FUNCTIONAL_K * theta);

		function fx(x){
			return 500*Math.sin(FUNCTIONAL_K * x)* Math.cos(x)
		}
		function fy(x){
			return 500*Math.sin(FUNCTIONAL_K * x)* Math.sin(x)
		}

		var dx = fx(theta + del) - fx(theta); 
		var dy = fy(theta + del) - fy(theta); 

		var ret_theta = Math.atan2(dy,dx);
		return ret_theta - Math.PI/2;
	}


    var myAnimation = function(self: any) {
  		// console.log("inspection update");
	  // Animates our water
	  try{
	  	var normal_pos = new THREE.Vector3(0,0,0);
		if(myDrone){
			rot += rotIncrement;
			var normal_pos = droneNormalXYZ(rot);
			var new_pos = droneXYZ(rot);
			var new_rot = droneROT(rot);
			myDrone.position.set(new_pos.x,new_pos.y,new_pos.z);
			// myDrone.rotation.set(new_rot,0,0);
			myDrone.rotation.y = new_rot ;
		}
		// console.log(myDrone.position);
		uniforms.time.value += 0.01
		uniforms.flight_x.value = (normal_pos.x + 1)/2;
		uniforms.flight_y.value = (1 - normal_pos.z)/2;
		
		// console.log(water.material.uniforms[ 'time' ].value);
		self.orbitControls.update();
		self.camera.lookAt( self.scene.position );
		self.renderer.render( self.scene, self.camera );
	  }
	  catch{
	  	;
	  }
	  

	}


    return [ myScene , myAnimation  ]
}