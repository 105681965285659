import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MasterService } from '../../services/master.service';
import { ActivatedRoute } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-pilot-payments',
  templateUrl: './pilot-payments.component.html',
  styleUrls: ['./pilot-payments.component.scss'],
})
export class PilotPaymentsComponent implements OnInit {

    constructor(
    private master: MasterService,
    private router: Router,
    public toastController: ToastController,
    private route:ActivatedRoute,
    public modalController: ModalController,
  	) { }


  	  profile: any;
	  profile_stats: any;
	  statistics: any;
	  status: any;
	  verification: any;
	  ranks: any;
	  my_rank :any;
	  my_rank_insignia :any;

	  star_array = [];

	  modal: any;

    trans_ingress: any;
    trans_egress: any;
    trans_ingress_pta: any;
    trans_egress_pta: any;

  ngOnInit() {
    this.loadTransactions();
  }

  async loadTransactions(){
    var res = await this.master.pilotMyTransactions();

    console.log(res);
    this.trans_ingress = JSON.parse(res['payload']['vm_egress']);
    this.trans_egress = JSON.parse(res['payload']['vm_ingress']);
    this.trans_ingress_pta = res['payload']['action_egress'];
    this.trans_egress_pta = res['payload']['action_ingress'];


    this.trans_egress_pta = this.trans_egress_pta.map((x: any) => {
     return  JSON.parse(x)[0];
    })

    this.trans_ingress_pta = this.trans_ingress_pta.map((x: any) => {
     return  JSON.parse(x)[0];
    })


    console.log(this.trans_ingress);
    console.log(this.trans_egress);

    console.log(this.trans_ingress_pta);
    console.log(this.trans_egress_pta);

  }  

  // goToCertificate(){
  //     // this.closeModal()
  //     this.router.navigate(['/certificate']);

  // }

  // backClicked(){
  // 	  this.router.navigate(['/pilot-tablinks/pilot-tools']);
  // }


    dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true,
    });
  }  

}
