import { Component, OnInit,Input,Output,EventEmitter, NgZone, Renderer2,ViewChild,ViewContainerRef,AfterViewInit,ComponentFactoryResolver } from '@angular/core';
import { Location } from '@angular/common';
import { VimanaMission } from '../../interfaces';
import { MasterService } from '../../services/master.service';
import { FormControl,FormGroup,Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { GoogleMapsAPIWrapper } from '@agm/core';
import { MapsAPILoader, AgmMap } from '@agm/core';
import { LocationInterface,Marker } from '../../interfaces';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from "@angular/animations";

export interface CardData {
  imageId: string;
  state: "default" | "flipped" | "matched";
}

@Component({
  selector: 'app-locator',
  templateUrl: './locator.component.html',
  styleUrls: ['./locator.component.scss'],
  animations: [
    trigger("cardFlip", [
      state(
        "default",
        style({
          transform: "none"
        })
      ),
      state(
        "flipped",
        style({
          transform: "rotateY(180deg)"
        })
      ),
      state(
        "matched",
        style({
          visibility: "false",
          transform: "scale(0.05)",
          opacity: 0
        })
      ),
      transition("default => flipped", [animate("400ms")]),
      transition("flipped => default", [animate("400ms")]),
      transition("* => matched", [animate("400ms")])
    ])
  ]
})
export class LocatorComponent implements OnInit {

  data: CardData = {
    imageId: "dasAasf12",
    state: "default"
  }; 

  @Output() location_emitted = new EventEmitter<LocationInterface>();;
  location_saved = false;

  is_dialog = false;

  coords_lat: any;
  coords_long: any;


  lat =  23.152790;
  lng = 79.947327;
  circleRadius:number = 5000;



  @Input() location:LocationInterface = {
    lat: 23.152790,
    lng: 79.947327,
    marker: {
      lat: 23.152790,
      lng: 79.947327,
      draggable: true
    },
    zoom: 5
  };

  @ViewChild(AgmMap) map: AgmMap;


  to_send_address : any;


  geocoder:any;
  to_send_address : any;
  locationSelected = false;


  constructor(
    public mapsApiLoader: MapsAPILoader,
    private zone: NgZone,
    private wrapper: GoogleMapsAPIWrapper,
    private _location: Location,
    private router: Router) {
    this.mapsApiLoader = mapsApiLoader;
    this.zone = zone;
    this.wrapper = wrapper;
    this.mapsApiLoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
    });
  }

  ngOnInit() {}

updateOnMap() {
    let full_address:string = this.location.address_level_1 || ""
    // if (this.location.address_level_2) full_address = full_address + " " + this.location.address_level_2
    // if (this.location.address_state) full_address = full_address + " " + this.location.address_state
    // if (this.location.address_country) full_address = full_address + " " + this.location.address_country

    this.findLocation(full_address);
  }

  findLocation(address) {


    if (!this.geocoder) this.geocoder = new google.maps.Geocoder()
    this.geocoder.geocode({
      'address': address
    }, (results, status) => {
      console.log(results);
      if (status == google.maps.GeocoderStatus.OK) {
        this.to_send_address = results[0].formatted_address;
        for (var i = 0; i < results[0].address_components.length; i++) {
          let types = results[0].address_components[i].types

          if (types.indexOf('locality') != -1) {
            this.location.address_level_2 = results[0].address_components[i].long_name
          }
          if (types.indexOf('country') != -1) {
            this.location.address_country = results[0].address_components[i].long_name
          }
          if (types.indexOf('postal_code') != -1) {
            this.location.address_zip = results[0].address_components[i].long_name
          }
          if (types.indexOf('administrative_area_level_1') != -1) {
            this.location.address_state = results[0].address_components[i].long_name
          }
        }

        if (results[0].geometry.location) {
          this.location.lat = results[0].geometry.location.lat();
          this.location.lng = results[0].geometry.location.lng();
          this.location.marker.lat = results[0].geometry.location.lat();
          this.location.marker.lng = results[0].geometry.location.lng();
          this.location.marker.draggable = true;
          this.location.viewport = results[0].geometry.viewport;
        }
        
        this.map.triggerResize()
        this.saveLocation();
      } else {
        console.log("Sorry, this search produced no results.");
      }
    })
  }
 markerDragEnd(m: any, $event: any) {
   console.log(m);
   console.log(event);
   this.location.marker.lat = m.latLng.lat();
   this.location.marker.lng = m.latLng.lng();
   this.findAddressByCoordinates();
  }
 findAddressByCoordinates() {
    this.geocoder.geocode({
      'location': {
        lat: this.location.marker.lat,
        lng: this.location.marker.lng
      }
    }, (results, status) => {
      this.decomposeAddressComponents(results);
    })
  }
 decomposeAddressComponents(addressArray) {
   console.log(addressArray)

   this.location['address_level_1'] = '';
   this.location['address_level_2'] = '';
   this.location['address_country'] = '';
   this.location['address_zip'] = '';
   this.location['address_state'] = '';

   this.to_send_address = addressArray[0].formatted_address;

    if (addressArray.length == 0) return false;
    let address = addressArray[0].address_components;

    for(let element of address) {
      if (element.length == 0 && !element['types']) continue

      if (element['types'].indexOf('premise') > -1) {
        this.location.address_level_1 = element['long_name'];
        continue;
      }
      if (element['types'].indexOf('street_number') > -1) {
        this.location.address_level_1 += ' ' + element['long_name'];
        continue;
      }
      if (element['types'].indexOf('sublocality_level_1') > -1) {
        this.location.address_level_1 += ' ' + element['long_name'];
        continue;
      }
      if (element['types'].indexOf('sublocality') > -1 || element['types'].indexOf('sublocality_level_3') > -1) {
        this.location.address_level_2 = element['long_name'];
        continue;
      }
      if (element['types'].indexOf('sublocality') > -1 || element['types'].indexOf('sublocality_level_2') > -1) {
        this.location.address_level_2 += ' ' + element['long_name'];
        continue;
      }
      if (element['types'].indexOf('sublocality') > -1 || element['types'].indexOf('sublocality_level_1') > -1) {
        this.location.address_level_2 += ' ' + element['long_name'];
        continue;
      }
      if (element['types'].indexOf('locality') > -1 || element['types'].indexOf('locality') > -1) {
        this.location.address_level_2 += ' ' + element['long_name'];
        continue;
      }
      if (element['types'].indexOf('administrative_area_level_1') > -1) {
        this.location.address_state = element['long_name'];
        continue;
      }
      if (element['types'].indexOf('country') > -1) {
        this.location.address_country = element['long_name'];
        continue;
      }
      if (element['types'].indexOf('postal_code') > -1) {
        this.location.address_zip = element['long_name'];
        continue;
      }
    }
  this.saveLocation();
  this.map.triggerResize()
  }    
  milesToRadius(value) {
     this.circleRadius = value / 0.00062137;
  }

  circleRadiusInMiles() {
    return this.circleRadius * 0.00062137;
  }
  saveLocation(){
    this.location_saved = true;
    this.location_emitted.emit(this.location);
  }
  cardClicked() {
    if (this.data.state === "default") {
      this.data.state = "flipped";
    } else {
      this.data.state = "default";
    }
  }
  is_enter(e: any){
    if (e.key === 'Enter' || e.keyCode === 13) {
      this.updateOnMap();
    }
  }
}
