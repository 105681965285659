import { Component, OnInit,  Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MasterService } from '../../services/master.service';

import { LoadingController } from '@ionic/angular';

import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-pilot-create-post',
  templateUrl: './pilot-create-post.component.html',
  styleUrls: ['./pilot-create-post.component.scss'],
})
export class PilotCreatePostComponent implements OnInit {


  @Input() group: any;

  caption = '';
  img_urls: any
  files_selected: any
  files: any
  file_list: any = [];


  constructor(
  	public modalController: ModalController,
  	private master: MasterService,
    public loadingController: LoadingController,
    public toastController: ToastController
    ) { }



  ngOnInit() {}


     closeModal() {
      this.modalController.dismiss(
		{
		'dismissed': true
		});
  	}


  onFileSelected(event){
    let fileList: FileList = event.target.files;
    let fileListLength = fileList.length;
    this.files = fileList;
    this.file_list = fileList;
    this.img_urls = [];


    for (var i = 0; i < fileListLength; i++) {
       if(fileList[i].size > 1048576*50){

         this.img_urls = undefined;

        this.presentToast('FILE TOO BIG');
         return ;
       }
    }

    for (var i = 0; i < fileListLength; i++) {
      var a = fileList[i];
      var reader = new FileReader();
      reader.onload = (event:any) => {
        var url = event.target.result;
        this.img_urls.push(url); 
      }
      reader.readAsDataURL(a);
    }
    this.files_selected = true;

  }

  send(){
    if(this.group == undefined){
      this.sendSolo();
    }
    else{
      this.sendGroup();
    }
  }
  async sendSolo(){
    console.log("posting");
    var fileData = this.getFileFormData();
    fileData.append('postBody',this.caption);
    

    // this.present();
    var res = this.master.makePost(fileData);
    // this.dismiss();
    this.closeModal();

    // console.log(res);
    // if(res=='true')
    // {
    //   this.closeModal();
    // }
    // else{
    //   this.presentToast("ERROR! TRY AGAIN LATER!")
    //   this.closeModal();
    // }
  }
  async sendGroup(){
    console.log("posting");
    var fileData = this.getFileFormData();
    fileData.append('postBody',this.caption);
    this.present();



    var res = await this.master.makeGroupPost(fileData,this.group.fields.unique_id);
    this.dismiss();

    // this.my_loading.dismiss();
    console.log('Loading dismissed!');
    // const { role, data } = await this.my_loading.onDidDismiss();

    console.log(res);
    if(res=='true')
    {
      this.closeModal();
    }
    else{
      this.presentToast("ERROR! TRY AGAIN LATER!")
      this.closeModal();
    }
  }
  getFileFormData()
  {
	var formData = new FormData();
	if( this.file_list.length <= 0)
		return formData
    formData.append('file_count', this.file_list.length);

    for (var i = 0; i < this.file_list.length; i++) {
    	var upload_image = this.file_list[i]
  		formData.append('file#' + i.toString() , upload_image, upload_image.name);
    }

	return formData;
  }

  my_loading: any;

  // async presentLoading() {
  //   const loading = await this.loadingController.create({
  //     cssClass: 'my-custom-class',
  //     message: 'Please wait...',
  //     duration: 2000
  //   });
  //   await loading.present();

  //   const { role, data } = await loading.onDidDismiss();
  //   console.log('Loading dismissed!');
  // }
  isLoading = false;
  async present() {
    this.isLoading = true;
    return await this.loadingController.create({
      // duration: 5000,
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }

  async dismiss() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => console.log('dismissed'));
  }
  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }

    isFileImage(file_index) {
      // console.log(file_index);
      // console.log();
      var img_type = this.file_list[file_index].type;
      // return true;
      return(img_type.match(/(jpeg|jpg|JPG|JPEG|gif|png)$/) != null);
  }
}
