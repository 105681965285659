import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { MasterService } from '../../services/master.service';
import { ActivatedRoute } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { SkillsFormComponent } from '../skills-form/skills-form.component';
import { SinglePortfolioProjectComponent } from '../single-portfolio-project/single-portfolio-project.component';
import { ModalController } from '@ionic/angular';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-pilot-drones',
  templateUrl: './pilot-drones.component.html',
  styleUrls: ['./pilot-drones.component.scss'],
})
export class PilotDronesComponent implements OnInit {



  models: any;
  drones: any;
  drone_images: any;
  drone_disp_images: any;


 	constructor(        
  	 private cdr: ChangeDetectorRef,
  	 private route: Router,
  	 private master: MasterService,
     public modalController: ModalController,
     ) { }

  ngOnInit() {


    this.loadDrones();

  }
  ionViewWillEnter(){
    this.ngOnInit();
  }

  async loadDrones(){
    var res = await this.master.readMyDrones();



    this.models = JSON.parse(res['payload']['models']);
    this.drones = JSON.parse(res['payload']['drones']);
    this.drone_images = JSON.parse(res['payload']['images']);
    this.drone_images = this.drone_images.map((x: any) => {
      return JSON.parse(x);
    })

    this.drone_disp_images = this.drone_images.map((x: any) => {
      if(x.length < 1){
        return x;
      }
      return this.master.mapURLs(x.map((y: any) => {
        return y.fields.imagefile
      }));
    })
    console.log(this.models);
    console.log(this.drones);
    console.log(this.drone_images);
    console.log(this.drone_disp_images);

  }  

    backClicked(){
  	this.route.navigate(['/pilot-tablinks/pilot-profile']);

  }
    addDrone(){
    this.route.navigate(['/add-drone']);

  }
  
}
