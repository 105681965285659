import { Component, OnInit,Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-xp-claim-card',
  templateUrl: './xp-claim-card.component.html',
  styleUrls: ['./xp-claim-card.component.scss'],
})
export class XpClaimCardComponent implements OnInit {

	@Input() event: any;
	@Input() name: any;
	@Input() val: any;

	@Output() claimed = new EventEmitter<any>();
  
  	constructor() { }

  val_array : any;
	ngOnInit() {
		this.val_array = []
		if(this.event.fields.intent == 'review'){	
			for(var i = 0; i < this.val; i ++)
			{
				this.val_array.push(1)	
			}
		}
	}

	claim(){
		this.claimed.emit(this.event);
	}
}
