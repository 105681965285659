import * as THREE from 'three';
import { Water } from 'three/examples/jsm/objects/Water.js';
import { Sky } from 'three/examples/jsm/objects/Sky.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { fragmentShaderMountains,vertexShaderMountains } from '../shaders';
import { fragmentShaderPlains,vertexShaderPlains } from '../shaders';
import { fragmentShaderNoop,vertexShaderNoop } from '../shaders';

const {SimplexNoise} = require('simplex-noise');

let simplex = new SimplexNoise(4);
function map(val, smin, smax, emin, emax) {
    const t =  (val-smin)/(smax-smin)
    return (emax-emin)*t + emin
}
function noise(nx, ny) {
    // Re-map from -1.0:+1.0 to 0.0:1.0
    return map(simplex.noise2D(nx,ny),-1,1,0,1)
}
//stack some noisefields together
function octave(nx,ny,octaves) {
    let val = 0;
    let freq = 1;
    let max = 0;
    let amp = 1;
    for(let i=0; i<octaves; i++) {
        val += noise(nx*freq,ny*freq)*amp;
        max += amp;
        amp /= 2;
        freq  *= 2;
    }
    return val/max;
}


function doneLoadManager(){

}
function onProgressLoadManager()
{

}
function onErrorLoadManager()
{

}
function onProgressLoader()
{

}
function onErrorLoader()
{

}
export function get_inspection_scene(renderer,n_turbines = 4,terrain = 'water'){
	   console.log("<>INSPECTION SCENE<>");

	var obj_material = new THREE.MeshLambertMaterial({
		color  : 0xffffff,
		side : THREE.DoubleSide,
	});


	var drone_material = new THREE.MeshLambertMaterial({
		color  : 0xe8b5ca,
		side : THREE.DoubleSide,
	});




	var xScale = 1.0;
	var yScale = 1.0;
	var zScale = 1.0;

    // ground planes

	var geometry = new THREE.BoxGeometry( 10000, 2, 10000 );
	var material = new THREE.MeshNormalMaterial();

	// var texture = THREE.ImageUtils.loadTexture('/assets/eco.png');
	// texture.anisotropy = this.renderer.getMaxAnisotropy();
	// texture.offset.x = 0.5; // 0.0 - 1.0
	// texture.offset.y = 0.5; // 0.0 - 1.0
	// texture.repeat.set(1, 1);


	// var imageMaterial = 	    new THREE.MeshBasicMaterial({
	//         map: texture //left
	//     });

	// LRTBFB
	var groundMaterial = [
	    new THREE.MeshBasicMaterial({
	    	color: 0x001e0f,
	    }),
	    new THREE.MeshBasicMaterial({
	    	color: 0x001e0f,
	    }),
	    new THREE.MeshBasicMaterial({
	    	color: 0x001e0f,
	    }),
	    new THREE.MeshBasicMaterial({
	    	color: 0x001e0f,
	    }),
	    new THREE.MeshBasicMaterial({
	    	color: 0x001e0f,
	    }),
	    new THREE.MeshBasicMaterial({
	    	color: 0x001e0f,
	    }),

	];



	var mesh = new THREE.Mesh( geometry, groundMaterial );

    // this.object = this.mesh;

    // mesh.rotation.set(this.xRot,this.yRot,this.zRot);
    // mesh.scale.set(this.xScale,this.yScale,this.zScale);

    var middle = new THREE.Vector3();
    // var geometry = this.geometry;

    geometry.computeBoundingBox();

    middle.x = (geometry.boundingBox.max.x + geometry.boundingBox.min.x) / 2;
    middle.y = (geometry.boundingBox.max.y + geometry.boundingBox.min.y) / 2;
    middle.z = (geometry.boundingBox.max.z + geometry.boundingBox.min.z) / 2;
    // console.log(middle);
    mesh.translateX(-1*middle.x*xScale);
    mesh.translateY(-1*middle.y*yScale);
    mesh.translateZ(-1*middle.z*zScale);


    var myScene = new THREE.Scene();

	const waterGeometry = new THREE.PlaneGeometry(10000, 10000);
	const water = new Water(
	waterGeometry,
	{
		textureWidth: 512,
		textureHeight: 512,
		waterNormals: new THREE.TextureLoader().load('assets/waternormals.jpg', function ( texture ) {
		texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
		}),
		alpha: 1.0,
		sunDirection: new THREE.Vector3(),
		sunColor: 0xffffff,
		waterColor: 0x001e0f,
		distortionScale: 3.7,
		fog: myScene.fog !== undefined
	}
	);
  water.translateY(10);
  water.rotation.x =- Math.PI / 2;
    var uniforms = {
        time: { type: "f", value: 1.0 },
        resolution: { type: "v2", value: new THREE.Vector2(100.,100.) },
        flight_x :{ type: "f", value: 3.0 },
        flight_y :{ type: "f", value: 3.0 },
    };

	var shaderMaterialMountain = new THREE.ShaderMaterial({
	            uniforms: uniforms,
	            vertexShader: vertexShaderMountains,
	            fragmentShader: fragmentShaderMountains,
	       		side: THREE.DoubleSide
	        });

	var mountain_geo = new THREE.PlaneGeometry(1000,1000,100,100);
	mountain_geo.rotateX(-Math.PI/2);


	var mountain_mesh = new THREE.Mesh( mountain_geo, shaderMaterialMountain );

    var middle = new THREE.Vector3();

    mountain_geo.computeBoundingBox();

    middle.x = (mountain_geo.boundingBox.max.x + mountain_geo.boundingBox.min.x) / 2;
    middle.y = (mountain_geo.boundingBox.max.y + mountain_geo.boundingBox.min.y) / 2;
    middle.z = (mountain_geo.boundingBox.max.z + mountain_geo.boundingBox.min.z) / 2;
    // console.log(middle);
    mountain_mesh.translateX(-1*middle.x*xScale);
    mountain_mesh.translateY(-1*middle.y*yScale);
    mountain_mesh.translateZ(-1*middle.z*zScale);


	var shaderMaterialPlains = new THREE.ShaderMaterial({
	            uniforms: uniforms,
	            vertexShader: vertexShaderPlains,
	            fragmentShader: fragmentShaderPlains,
	       		side: THREE.DoubleSide
	        });


	var plain_geo = new THREE.PlaneGeometry(1000,1000,100,100);
	plain_geo.rotateX(-Math.PI/2);


	var plains_mesh = new THREE.Mesh( plain_geo, shaderMaterialPlains );

    var middle = new THREE.Vector3();

    plain_geo.computeBoundingBox();

    middle.x = (plain_geo.boundingBox.max.x + plain_geo.boundingBox.min.x) / 2;
    middle.y = (plain_geo.boundingBox.max.y + plain_geo.boundingBox.min.y) / 2;
    middle.z = (plain_geo.boundingBox.max.z + plain_geo.boundingBox.min.z) / 2;
    // console.log(middle);
    plains_mesh.translateX(-1*middle.x*xScale);
    plains_mesh.translateY(-1*middle.y*yScale);
    plains_mesh.translateZ(-1*middle.z*zScale);

    // mountain_mesh.translateY(-110);

  if(terrain == 'plains'){
  	myScene.add(plains_mesh);
	var shaderMaterialTurbine = new THREE.ShaderMaterial({
	            uniforms: uniforms,
	            vertexShader: vertexShaderPlains,
	            fragmentShader: fragmentShaderNoop,
	       		side: THREE.DoubleSide
	        });
  }
  if(terrain == 'water'){
  	myScene.add(water);
	var shaderMaterialTurbine = new THREE.ShaderMaterial({
	            uniforms: uniforms,
	            vertexShader: vertexShaderNoop,
	            fragmentShader: fragmentShaderNoop,
	       		side: THREE.DoubleSide
	        });
  }
  if(terrain == 'mountains'){
  	myScene.add(mountain_mesh);
	var shaderMaterialTurbine = new THREE.ShaderMaterial({
	            uniforms: uniforms,
	            vertexShader: vertexShaderPlains,
	            fragmentShader: fragmentShaderNoop,
	       		side: THREE.DoubleSide
	        });
  }


  var turbMaterials = [
  obj_material,
  shaderMaterialTurbine
  ]



  const sky = new Sky();
  sky.scale.setScalar(10000);

  myScene.add(sky);


	var pointLightC = new THREE.PointLight( 0xaaaaaa,5.0, 1000 );
	var pointLightD = new THREE.PointLight( 0xffffff,5.0, 1000 );
	pointLightC.position.set( 500, 500, 500 );
	pointLightD.position.set( -500, 500, -500 );

	myScene.add( pointLightC );
	myScene.add( pointLightD );
    var ambientLight = new THREE.AmbientLight( 0xcccccc, 0.4 );

    myScene.add( ambientLight );



  const pmremGenerator = new THREE.PMREMGenerator(renderer);
  const sun = new THREE.Vector3();

	// Defining the x, y and z value for our 3D Vector
	// const theta = THREE.MathUtils.degToRad(0);
	// const phi = THREE.MathUtils.degToRad( -90);

	// sun.setFromSphericalCoords( 1, theta, phi );

	  const theta = Math.PI * (0.49 - 0.5);
	  const phi = 2 * Math.PI * (0.105 - 0.5);
	  sun.x = Math.cos(phi);
	  sun.y = Math.sin(phi) * Math.sin(theta);
	  sun.z = Math.sin(phi) * Math.cos(theta);



	sky.material.uniforms['sunPosition'].value.copy(sun);
	water.material.uniforms[ 'sunDirection' ].value.copy( sun ).normalize();

	myScene.environment = pmremGenerator.fromScene(myScene).texture;


    // myScene.add( mesh );



    var turbines = [];



	var pointToRotateAround = new THREE.Vector3( 0, 0, 0);
	var pivot = new THREE.Object3D();

	var object,new_object,new_object2;
	var myDrone;


	var mill_array = [];

	function doneTurbineLoader(){		
    object.rotation.set(0,0,0);
    object.scale.set(10,10,10);
    var middle = new THREE.Vector3();
    var geometry = object.children[0].geometry;
    geometry.computeBoundingBox();
    middle.x = (geometry.boundingBox.max.x + geometry.boundingBox.min.x) / 2;
    middle.y = (geometry.boundingBox.max.y + geometry.boundingBox.min.y) / 2;
    middle.z = (geometry.boundingBox.max.z + geometry.boundingBox.min.z) / 2;
    // console.log(middle);
    object.translateX(-1*middle.x*xScale);
    object.translateY(-1*middle.y*yScale);
    object.translateZ(-1*middle.z*zScale);

    object.translateY(30);
    // object.translateX(-30);


	// pivot.position.set(0,10,0);
	// pivot.add(object.children[2]);
	// myScene.add(pivot);

	var x_stride = 50;
	var z_stride = 50;
	var sm_n = Math.floor(Math.sqrt(n_turbines));

	for (var i = 0; i < sm_n; i++) {
		    object.position.z = 0;
		    object.position.x = x_stride*i;
		for (var j = 0; j < sm_n; j++) {
	    	object.position.z = z_stride*j;
	    	new_object = object.clone();
			turbines.push( new_object );
		}
	}

	for (var i = 0; i < turbines.length; i++) {
		myScene.add(turbines[i]);
	}
	}


	// var pivotA = new THREE.Object3D();
	// var pivotB = new THREE.Object3D();
	// var pivotC = new THREE.Object3D();
	// var pivotD = new THREE.Object3D();



	function doneDroneLoader(){		
    myDrone.rotation.set(0,0,0);
    myDrone.scale.set(3,3,3);
    var middle = new THREE.Vector3();
    var geometry = myDrone.children[0].geometry;
    geometry.computeBoundingBox();
    middle.x = (geometry.boundingBox.max.x + geometry.boundingBox.min.x) / 2;
    middle.y = (geometry.boundingBox.max.y + geometry.boundingBox.min.y) / 2;
    middle.z = (geometry.boundingBox.max.z + geometry.boundingBox.min.z) / 2;
    // console.log(middle);
    myDrone.translateX(-1*middle.x*xScale);
    myDrone.translateY(-1*middle.y*yScale);
    myDrone.translateZ(-1*middle.z*zScale);

    myDrone.translateY(40);
    // myDrone.translateX(-30);

	// pivotA.position.set(0,10,0);
	// pivotA.add(myDrone.children[3]);
	// myScene.add(pivotA);
	// pivotB.position.set(0,10,0);
	// pivotB.add(myDrone.children[4]);
	// myScene.add(pivotB);
	// pivotC.position.set(0,10,0);
	// pivotC.add(myDrone.children[7]);
	// myScene.add(pivotC);
	// pivotD.position.set(0,10,0);
	// pivotD.add(myDrone.children[10]);
	// myScene.add(pivotD);

	// pivot.position.set(0,10,0);
	// pivot.add(object.children[2]);
	// myScene.add(pivot);

    console.log(myDrone);
    myScene.add( myDrone );
	}

	var turbineModel = 'https://vimanastatic.s3.ap-south-1.amazonaws.com/Turbine5.obj';
	var droneModel = 'https://vimanastatic.s3.ap-south-1.amazonaws.com/drone_rotorless.obj';


	var manager = new THREE.LoadingManager( doneLoadManager,onProgressLoadManager,doneLoadManager);
	var loader = new OBJLoader( manager );


	loader.load( turbineModel, ( obj ) => {
	object = obj;
	object.traverse( function( child ) {
	if ( child instanceof THREE.Mesh ) {
	child.material = shaderMaterialTurbine;
	}
	} );

	doneTurbineLoader();

	}, onProgressLoader, onErrorLoader );


	loader.load( droneModel, ( obj ) => {
	myDrone = obj;
	myDrone.traverse( function( child ) {
	if ( child instanceof THREE.Mesh ) {
	child.material = drone_material;
	}
	} );

	doneDroneLoader();

	}, onProgressLoader, onErrorLoader );


	var axis = new THREE.Vector3(0,0,0);//tilted a bit on x and y - feel free to plug your different axis here
	var rad = 0;
	var radIncrement = 0.007;
	var rot = 0;
	var rotIncrement = 0.01;

	function droneXYZ(frame){

		frame = frame % 100000;

		var omega_frame = frame/100000 * 2 * Math.PI;

		var r_polar = 90;

		var dx = Math.sin(frame)*r_polar;
		var dy = 40;
		var dz = Math.cos(frame)*r_polar;
		var r_vex = new THREE.Vector3(dx,dy,dz);
		return r_vex;
	}

	function droneROT(frame){

		frame = frame % 100000;

		var omega_frame = frame/100000 * 2 * Math.PI;
		return omega_frame;
	}


    var myAnimation = function(self: any) {
  		// console.log("inspection update");
	  // Animates our water
	  try{

		water.material.uniforms[ 'time' ].value += 1.0 / 60.0;
		if(object){
			rad += radIncrement;
			for (var i = 0; i < turbines.length; i++) {
				turbines[i].children[2].rotation.x = rad;
			}

			// object.children[2].rotateOnAxis(axis,rad);
		}
		if(myDrone){
			rot += rotIncrement;
			var new_pos = droneXYZ(rot);
			var new_rot = droneROT(rot);
			myDrone.position.set(new_pos.x,new_pos.y,new_pos.z);
			// myDrone.rotation.set(new_rot,0,0);
			myDrone.rotation.y = rot;
		}
		// console.log(water.material.uniforms[ 'time' ].value);
		self.orbitControls.update();
		self.camera.lookAt( self.scene.position );
		self.renderer.render( self.scene, self.camera );
	  }
	  catch{
	  	;
	  }
	  

	}


    return [ myScene , myAnimation  ]
}