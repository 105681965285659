import { Component, OnInit, Input } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MasterService } from '../../services/master.service'
import { GoogleMapsAPIWrapper } from '@agm/core';
import { MapsAPILoader, AgmMap } from '@agm/core';
import { PopoverController } from '@ionic/angular';


import { LocationInterface,Marker,VimanaProject } from '../../interfaces';
import { ToastController } from '@ionic/angular';
import { FormControl,FormGroup,Validators } from '@angular/forms';

import { LoadingController } from '@ionic/angular';

import { ModalController } from '@ionic/angular';


import { Options, LabelType } from "@angular-slider/ngx-slider";

import { SkillatorComponent } from '../skillator/skillator.component';
import * as moment from 'moment';

import { UploadJob } from '../../interfaces'
import { defer, from } from 'rxjs';


// import momentDurationFormatSetup from "moment-duration-format";
// momentDurationFormatSetup(moment);


@Component({
  selector: 'app-bag-project',
  templateUrl: './bag-project.component.html',
  styleUrls: ['./bag-project.component.scss'],
})
export class BagProjectComponent implements OnInit {



 @Input() bag_mode = 'create';
 @Input() bag_data: any;

  my_loading: any;


  projectForm : FormGroup;
  toSendProject: any;
  location: any;
  to_send_address : any;

  flight_hours: any;

  minValueProject: number = 1000;
  maxValueProject: number = 5000;
  sliderOptions: Options = {
    floor: 1000,
    ceil: 50000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return "₹" + value;
        case LabelType.High:
          return "₹" + value;
        default:
          return "₹" + value;
      }
    }
  };

  constructor(
    private route: Router,
    private master: MasterService,
    public toastController: ToastController,
     public modalController: ModalController,
    public loadingController: LoadingController
    ) { 




      this.projectForm = new FormGroup( 
      {
      'projectName': new FormControl('',[Validators.required]),
      'projectDesciption': new FormControl('',[Validators.required]),
      'projectLocation': new FormControl('',[Validators.required]),
      'projectDate': new FormControl('',[Validators.required]),
      'projectEstimateLow': new FormControl('',[Validators.required]),
      'projectEstimateHigh': new FormControl('',[Validators.required]),
      'projectDeliveryTime': new FormControl('',[Validators.required]),
      })


  }

  originalPrice: any;
  originalPriceH: any;
  originalDelivery: any;

  originalIsPublic: any;

  originalName: any;
  originalDescription: any;
  originalLocation: any;
  originalDate: any;

  // originalLocation:LocationInterface = {
  //   lat: 23.152790,
  //   lng: 79.947327,
  //   marker: {
  //     lat: 23.152790,
  //     lng: 79.947327,
  //     draggable: true
  //   },
  //   zoom: 8
  // };
  ngOnInit() {

    if(this.bag_mode == 'maintain'){
      this.load();
      this.initProject();
    }
  }

  initProject(){
    this.originalPrice = this.bag_data.fields.projectEstimateLow;
    this.originalPriceH = this.bag_data.fields.projectEstimateHigh;
    this.originalDelivery = this.bag_data.fields.projectDeliveryTime;
    this.originalIsPublic = this.bag_data.fields.is_public;
    this.originalName = this.bag_data.fields.projectName;
    this.originalDescription = this.bag_data.fields.projectDesciption;
    this.originalDate= this.bag_data.fields.projectDate;
    this.originalLocation = this.bag_data.fields.projectLocation;
    // this.originalLocation.marker.lat = this.bag_data.fields.projectName;
    // this.originalLocation.marker.lat = this.bag_data.fields.projectName;
  }
  async load(){
     await this.loadAllLanguages();      
     this.loadCrumbs();
     this.loadProfile();
     this.loadAllSkills();    
     this.loadJobs();   
  }

  location_capture = false;

  locationEmitted(location){
    console.log(location);
    this.location = location;
    this.location_capture = true;
    this.to_send_address = location.address_level_1 + ' ' + location.address_level_2 + ' ' + location.address_state;

  }



  async submit(){
   
   this.toSendProject = <VimanaProject>{

   }
    this.toSendProject.projectName = this.projectForm.value.projectName;
    this.toSendProject.projectDesciption = this.projectForm.value.projectDesciption;
    this.toSendProject.projectLocation = this.to_send_address;
    this.toSendProject.projectDate = this.projectForm.value.projectDate;
    this.toSendProject.projectEstimateLow = this.minValueProject;
    this.toSendProject.projectEstimateHigh = this.maxValueProject;
    this.toSendProject.projectDeliveryTime = this.projectForm.value.projectDeliveryTime;



    console.log(this.projectForm);
  

    var res = await this.master.pilotAddProject(this.toSendProject);

    console.log(res);
    if(res['result'] == 'true'){
      this.uploadCrumb(res['uuid'])
    }
  }


  async submitEdit(){
   
   this.toSendProject = <VimanaProject>{

   }
    this.toSendProject.projectName = this.bag_data.fields.projectName;
    this.toSendProject.projectDesciption = this.bag_data.fields.projectDesciption;
    this.toSendProject.projectLocation = this.bag_data.fields.projectLocation;
    this.toSendProject.projectDate = this.bag_data.fields.projectDate;
    this.toSendProject.projectEstimateLow = this.bag_data.fields.projectEstimateLow;
    this.toSendProject.projectEstimateHigh = this.bag_data.fields.projectEstimateHigh;
    this.toSendProject.projectDeliveryTime = this.bag_data.fields.projectDeliveryTime;
    this.toSendProject.is_public = this.bag_data.fields.is_public;



    console.log(this.toSendProject);
  

    var res = await this.master.pilotEditProject(this.bag_data.fields.unique_id,this.toSendProject);

    console.log(res);
    if(res['result'] == 'true'){
      this.loadProject()
    }
  }


  caption = '';
  img_urls: any
  files_selected: any
  files: any
  file_list: any = [];

  onFileSelected(event){
    console.log("FILE SELECTED")
    let fileList: FileList = event.target.files;
    let fileListLength = fileList.length;
    this.files = fileList;
    this.file_list = fileList;
    console.log("FILELIST",fileList)
    this.img_urls = [];
    for (var i = 0; i < fileListLength; i++) {
      console.log("FILE DATA",i)
      var a = fileList[i];
      var reader = new FileReader();
      reader.onload = (event:any) => {
        var url = event.target.result;
        this.img_urls.push(url); 
      }
      reader.readAsDataURL(a);
    }
    this.files_selected = true;

  }

  async uploadCrumb(proj_id,reload = false){

      this.presentLoading();
      var fileData = this.getFileFormData();
      fileData.append('project_uuid',proj_id);


      var res_upload = defer(() => from(this.master.pilotCreateProjectCrumb(fileData)));

      res_upload.subscribe(
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      )



      this.pending_upload = true;
      this.loadJobs();
      var res = this.presentLoading();

      // console.log(res);

      // res.dismiss();
      // console.log("FILE UPLOAD SUCCESS")
      if(reload){
        this.loadProject();
        this.loadCrumbs();
      }
      else{
        this.goBack();
      }
      // this.ngOnInit();   
  }
  async uploadMore(proj_id){
    this.uploadCrumb(proj_id,true);

    this.file_list = [];
  }
  getFileFormData()
  {
  var formData = new FormData();
  if( this.file_list.length <= 0)
    return formData
    formData.append('file_count', this.file_list.length);

    for (var i = 0; i < this.file_list.length; i++) {
      var upload_image = this.file_list[i]
      formData.append('file#' + i.toString() , upload_image, upload_image.name);
    }

  return formData;
  }


  // OTRA VIEW


  crumbs: any;

  profile: any;
  profile_stats: any;
  statistics: any;
  status: any;
  verification: any;
  ranks: any;
  my_rank :any;
  my_rank_insignia :any;
  display_picture:any;
  my_skills: any;
  star_array = [];

  skills: any;
  skills_dict: any;

  my_languages: any;
  all_languages: any;
  lang_dict: any;


  media = [];

  async loadProject(){
    var res = await this.master.loadPilotOneProject(this.bag_data.fields.unique_id);

    console.log(res)

    var project = JSON.parse(res['payload']['project'])[0];

    this.bag_data = project;

    console.log(this.bag_data);

    this.initProject();
  }
  async loadAllSkills(){

    var res = await this.master.readPilotSkills();

    console.log(res);
  
    this.skills = JSON.parse(res['payload']);

    this.skills_dict = {};

    for(var i = 0; i < this.skills.length; i ++) {
      var siq = this.skills[i];
      this.skills_dict[siq.pk] = siq;
    }
    console.log(this.skills);
  }

  async loadAllLanguages(){

    var res = await this.master.readLanguages();

    console.log(res);
  
    this.all_languages = JSON.parse(res['payload']);

    this.lang_dict = {};

    for(var i = 0; i < this.all_languages.length; i ++) {
      var liq = this.all_languages[i];
      this.lang_dict[liq.pk] = liq;
    }
    console.log(this.all_languages);
  }

  async loadCrumbs(){

    var res = await this.master.loadPilotProjectsCrumbs(this.bag_data.fields.unique_id);


    console.log(res);


    this.crumbs = JSON.parse(res['payload']['project_crumbs']);

    this.media = [];

    for(var i = 0; i < this.crumbs.length; i++){
      var ciq = this.crumbs[i];
      if(this.isFileImage(ciq.fields.crumb_file) || this.isFileVideo(ciq.fields.crumb_file)){
        var media_url = this.master.mapURL(ciq.fields.crumb_file)
        this.media.push(media_url)
      }

    }
    console.log(this.crumbs);
  }
  isFileImage(file_url) {
      return(file_url.match(/\.(jpeg|jpg|JPG|JPEG|gif|png)$/) != null);
  }
  isFileVideo(file_url) {
      return(file_url.match(/\.(mp4|avi|webm)$/) != null);
  }
  mapUrl(url){
    return this.master.mapURL(url);
  }
  async loadProfile(){
    var res = await this.master.getPilotProfile();


    console.log(res);

    this.profile = JSON.parse(res['payload']['profile'])[0];


    this.profile_stats = JSON.parse(res['payload']['profile_stats'])[0];
    this.statistics = JSON.parse(res['payload']['statistics']);
    this.status = res['payload']['status'];
    this.verification = JSON.parse(res['payload']['verification'])[0];
    this.ranks = JSON.parse(res['payload']['ranks']);
    this.my_skills = JSON.parse(res['payload']['skills']);

    this.my_rank = this.ranks[this.ranks.length -1]

    // this.flight_hours = moment.duration(this.statistics.flight_hours, "hours").format("h [hrs], m [min]");
    for (var i = this.ranks.length - 1; i >= 0; i--) {
      var this_rank = parseInt(this.ranks[i].fields.xp_cutoff);
      var my_rank = parseInt(this.statistics.xp)
      if (this_rank <= my_rank || i == 0){
        this.my_rank = this.ranks[i];
        break;
      }
      else{
        console.log(this_rank);
        console.log(my_rank);

      }
    }


    this.my_languages = this.profile_stats.fields.language.map((x: any) => { return this.lang_dict[x]});




    this.my_rank_insignia = this.master.mapURL(this.my_rank.fields.imagefile);

    this.display_picture = this.master.mapURL(this.profile.fields.userPhoto);

    console.log(res);
    console.log(this.my_rank);
    console.log(this.my_rank_insignia);
    console.log(this.display_picture)
    console.log(this.ranks);
    console.log(this.profile);
    // console.log(this.profile_stats);
    console.log(this.statistics);
    console.log(this.status);
    // console.log(this.verification);
    // console.log(this.statistics["rating"]);

    this.star_array.length = this.statistics["rating"];
    this.star_array.fill(1);

    console.log(this.star_array);

    // this.cdr.markForCheck();
   }




  job_count = 0;
  upload_jobs: UploadJob[];

  prev_done = 0;

  pending_upload = false;

  async loadJobs(){

    this.upload_jobs = [];
    // this.job_count = 0;

    // this.done_refresh = true;

    var res = this.master.getCurrentJobs();
    this.upload_jobs = res[0];

    this.job_count = this.upload_jobs.length;


    

    var jobs_done = this.upload_jobs.filter((x: any) => {
      return x.jobDone;
    }).length

    console.log(jobs_done,this.prev_done);
    // if(jobs_done != this.prev_done){
    //     this.scroller.refresh();
    // }
    // else{
    //   this.prev_done = jobs_done;
    // }


    // setTimeout( this.loadJobs , 1000);
  }
  finishedJobs(){
    var jobs_done = this.upload_jobs.filter((x: any) => {
      return x.jobDone;
    }).length


    if(this.job_count == jobs_done && this.pending_upload){
      console.log("DONE")
      this.pending_upload = false;
      this.loadJobs();
      this.loadCrumbs();
    }
    return jobs_done    
  }

  async presentSkillsModal(){
  const modal = await this.modalController.create({
    component: SkillatorComponent,
    cssClass: 'my-custom-class',
  });

  modal.onDidDismiss().then((data) => {
    console.log(data);
    this.updateProjectSkills(data.data.sk_array)
    // this.ngOnInit();
  })


  return await modal.present();
  }

  async deleteCrumb(crumb: any){
    var res = await this.master.pilotDeleteProjectCrumbFile(crumb.fields.unique_id);
    if(res['result']=='true'){
      this.ngOnInit();
    }

  }
  async presentLoading() {
    this.my_loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...',
      duration: 2000
    });
    return await this.my_loading.present();

    // const { role, data } = await this.my_loading.onDidDismiss();
    // console.log('Loading dismissed!');

  }
  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }  
  goBack(){
    this.route.navigate(['/pilot-portfolio']);
  }
  async updateProjectSkills(sk_array: any){
    var res = await this.master.pilotEditProjectSkills(this.bag_data.fields.unique_id,sk_array);

    console.log(res);
    if(res['result'] == 'true'){
      this.restart();
    }    
  }
  restart(){
    this.modalController.dismiss({
      'restart': true,
    });    
  }
}
