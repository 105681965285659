import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PilotTablinksPageRoutingModule } from './pilot-tablinks-routing.module';
import { PilotTablinksPage } from './pilot-tablinks.page';
import { PilotSingleMissionComponent } from '../pilot-single-mission/pilot-single-mission.component';
import { MyActionsPopover } from '../pilot-single-mission/my-actions.component';
import { EditProfileComponent } from '../edit-profile/edit-profile.component';
import { PilotProfilePageModule } from '../pilot-profile/pilot-profile.module';
import { StartFlightLogComponent } from '../start-flight-log/start-flight-log.component';
import { PilotMissionControlDashboardComponent } from '../pilot-mission-control-dashboard/pilot-mission-control-dashboard.component';
import { LeaderboardComponent } from '../leaderboard/leaderboard.component';
import { PilotReferComponent } from '../pilot-refer/pilot-refer.component';
import { PilotFlightRegulationComponent } from '../pilot-flight-regulation/pilot-flight-regulation.component';
import { PilotPaymentsComponent } from '../pilot-payments/pilot-payments.component';
import { AccountComponent } from '../account/account.component';
import { PilotFlightlogsComponent } from '../pilot-flightlogs/pilot-flightlogs.component';
import { VidiaMaterialModule } from '../../common/material.module';
import { TimeagoModule,TimeagoPipe } from 'ngx-timeago';
import { AgmCoreModule } from '@agm/core';
import { GoogleMapsAPIWrapper } from '@agm/core';
import { PilotPortfolioComponent } from '../pilot-portfolio/pilot-portfolio.component';
import { SkillsFormComponent } from '../skills-form/skills-form.component';
import { CreatePortfolioProjectComponent } from '../create-portfolio-project/create-portfolio-project.component';
import { PilotAddDroneComponent } from '../pilot-add-drone/pilot-add-drone.component';
import { PilotBadgesComponent } from '../pilot-badges/pilot-badges.component';
import { PilotTrainingsComponent } from '../pilot-trainings/pilot-trainings.component';
import { TrainingCardComponent } from '../training-card/training-card.component';
import { SinglePortfolioProjectComponent } from '../single-portfolio-project/single-portfolio-project.component';
import { MatCardModule } from '@angular/material/card';
import { TrainingSchoolComponent  } from '../training-school/training-school.component';
import { TrainingCourseComponent } from '../training-course/training-course.component';
import { EnrollModal } from '../training-course/enroll/enroll.component';
import { CourseCardMiniComponent } from '../course-card-mini/course-card-mini.component';
import { SingleCourseComponent } from '../single-course/single-course.component';
import { XpClaimCardComponent } from '../../common/xp-claim-card/xp-claim-card.component';
import { XpContestMiniComponent } from '../../common/xp-contest-mini/xp-contest-mini.component';
import { PilotPortfolioMiniComponent } from '../pilot-portfolio-mini/pilot-portfolio-mini.component';
import { PilotPortfolioHelpComponent } from '../pilot-portfolio-help/pilot-portfolio-help.component';
import { BagProjectComponent } from '../../common/bag-project/bag-project.component'; 
// import { NgFireworksModule } from '@fireworks-js/angular'

import { AboutPilotComponent } from '../about-pilot/about-pilot.component';
import { SkillatorComponent } from '../../common/skillator/skillator.component'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { LocatorComponent } from '../../common/locator/locator.component'; 
import { VmnCommonModule,LocatorComponent } from '../../common/vmncommon.module';
import { PilotMissionShare } from '../pilot-single-mission/share-mission.component';
import { MarkdownModule } from 'ngx-markdown';


@NgModule({
  imports: [
    VmnCommonModule,
    CommonModule,
    FormsModule,
    MatCardModule,
    IonicModule,
    PilotTablinksPageRoutingModule,
    PilotProfilePageModule,
    ReactiveFormsModule,
    NgbModule,
    VidiaMaterialModule ,
    // NgFireworksModule,
    TimeagoModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAChTFNbiwIMNd9s0Fjv3odZJRkTRexgS4'
    }),
    MarkdownModule.forRoot()
   ],
  declarations: [
    PilotTablinksPage,
    EditProfileComponent,
    // LocatorComponent,
    PilotSingleMissionComponent,
    PilotMissionControlDashboardComponent,
    MyActionsPopover,
    PilotMissionShare,
    LeaderboardComponent,
    StartFlightLogComponent,
    PilotFlightRegulationComponent,
    PilotPaymentsComponent,
    AccountComponent,
    PilotReferComponent,
    PilotFlightlogsComponent,
    PilotPortfolioComponent,
    SkillsFormComponent,
    CreatePortfolioProjectComponent,
    PilotAddDroneComponent,
    PilotBadgesComponent,
    PilotTrainingsComponent,
    TrainingCardComponent,
    SinglePortfolioProjectComponent,
    TrainingSchoolComponent,
    TrainingCourseComponent,
    EnrollModal,
    CourseCardMiniComponent,
    SingleCourseComponent,
    XpClaimCardComponent,
    XpContestMiniComponent,
    PilotPortfolioMiniComponent,
    PilotPortfolioHelpComponent,
    BagProjectComponent,
    SkillatorComponent,
    AboutPilotComponent
  ],
   providers : [
    GoogleMapsAPIWrapper,    
    TimeagoPipe
  ]  
})
export class PilotTablinksPageModule {}
