import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MasterService } from '../../services/master.service'

@Component({
  selector: 'app-pilot-flight-regulation',
  templateUrl: './pilot-flight-regulation.component.html',
  styleUrls: ['./pilot-flight-regulation.component.scss'],
})
export class PilotFlightRegulationComponent implements OnInit {

  constructor(    private route: Router,private master: MasterService,) { }

  ngOnInit() {}


  backClicked(){
        this.route.navigate(['/pilot-tablinks/pilot-tools']);

  }

}
