import { Component, OnInit, Input,Output,ChangeDetectorRef,EventEmitter,NgZone } from '@angular/core';
import { VimanaMission } from '../../interfaces';
import { MasterService } from '../../services/master.service';
import { FormControl,FormGroup,Validators } from '@angular/forms';
import { trigger, style, animate, transition } from '@angular/animations';
import { Router , ActivatedRoute} from '@angular/router';

import  { RazorPayment } from '../../interfaces';


import { ToastController } from '@ionic/angular';
import { WindowRefService } from '../../services/window-ref.service'
import { LoadingController } from '@ionic/angular';

import * as moment from 'moment';

const arr = x => Array.from(x);
const num = x => Number(x) || 0;
const str = x => String(x);
const isEmpty = xs => xs.length === 0;
const take = n => xs => xs.slice(0,n);
const drop = n => xs => xs.slice(n);
const reverse = xs => xs.slice(0).reverse();
const comp = f => g => x => f (g (x));
const not = x => !x;
const chunk = n => xs =>
  isEmpty(xs) ? [] : [take(n)(xs), ...chunk (n) (drop (n) (xs))];

let numToWords = n => {
  let a = [
    '', 'one', 'two', 'three', 'four',
    'five', 'six', 'seven', 'eight', 'nine',
    'ten', 'eleven', 'twelve', 'thirteen', 'fourteen',
    'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'
  ];
  let b = [
    '', '', 'twenty', 'thirty', 'forty',
    'fifty', 'sixty', 'seventy', 'eighty', 'ninety'
  ];
  let g = [
    '', 'thousand', 'million', 'billion', 'trillion', 'quadrillion',
    'quintillion', 'sextillion', 'septillion', 'octillion', 'nonillion'
  ];
  // this part is really nasty still
  // it might edit this again later to show how Monoids could fix this up
  let makeGroup = ([ones,tens,huns]) => {
    return [
      num(huns) === 0 ? '' : a[huns] + ' hundred ',
      num(ones) === 0 ? b[tens] : b[tens] && b[tens] + '-' || '',
      a[tens+ones] || a[ones]
    ].join('');
  };
  // "thousands" constructor; no real good names for this, i guess
  let thousand = (group,i) => group === '' ? group : `${group} ${g[i]}`;
  // execute !
  if (typeof n === 'number') return numToWords(String(n));
  if (n === '0')             return 'zero';
  return comp (chunk(3)) (reverse) (arr(n))
    .map(makeGroup)
    .map(thousand)
    .filter(comp(not)(isEmpty))
    .reverse()
    .join(' ');
};

@Component({
  selector: 'app-create-sanction-mission',
  templateUrl: './create-sanction-mission.component.html',
  styleUrls: ['./create-sanction-mission.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateY(100%)', opacity: 0}),
          animate('500ms', style({transform: 'translateY(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateY(0)', opacity: 1}),
          animate('200ms', style({transform: 'translateY(100%)', opacity: 0}))
        ])
      
  ]
  )],
})
export class CreateSanctionMissionComponent implements OnInit {





   Pay_options = {
    "key": this.master.pay_key(), // Enter the Key ID generated from the Dashboard
    "amount": "", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    "currency": "INR",
    "name": "",
    "description": "",
    "image": "/assets/logo.png",
    "order_id": "", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    "handler": async (response) => {
      var payment_info = <RazorPayment> {};
      payment_info.razorpay_order_id = response.razorpay_order_id;
      payment_info.razorpay_payment_id = response.razorpay_payment_id;
      payment_info.razorpay_signature = response.razorpay_signature;
      var res = await this.master.submitPaymentInfo(payment_info);
      console.log(res);
      if(res['result']=='true'){
        this.zone.run(()=>{
        this.presentToast('order made!');
        this.sendMission();
        });
      }
      else{
        this.zone.run(()=>{
        this.presentToast('error! please try in some time!');
        })
      }
    } ,
    "redirect": false,
    "prefill": {
        "name": "",
        "email": "",
        "contact": ""
    },
    "notes": {
        "none": "none"
    },
    "theme": {
        "color": "#073060"
    }
  };

  @Output() doDisplay = new EventEmitter<boolean>();


  project_id: any;
  portfolio_data: any;
  portfolio_uuid: any;

  toSendMission: VimanaMission = {};
  missionForm : FormGroup;

  categories: any;

  startDate: any;
  endDate: any;

  cat_selected: any = [];


  to_send_address: any;
  location: any;
  
  profile: any;
  profile_stats: any;
  statistics: any;
  status: any;
  verification: any;
  ranks: any;
  my_rank :any;
  my_rank_insignia :any;
  display_picture:any;
  skills: any;
  star_array = [];

  crumbs: any;
  media = [];

  customer_budget: any = 0;
  total_budget: any = 0;

  async loadProject(){
      var res = await this.master.getPilotProject(this.project_id);
    this.profile = JSON.parse(res['payload']['profile'])[0];
    this.portfolio_data = JSON.parse(res['payload']['project'])[0];
    this.crumbs = JSON.parse(res['payload']['project_crumbs']);
    this.statistics = JSON.parse(res['payload']['statistics']);

    console.log(this.profile);
    console.log(this.portfolio_data);

    this.media = [];
    this.display_picture = this.master.mapURL(this.profile.fields.userPhoto);

    if(this.portfolio_data.fields.is_public){
      this.doDisplay.emit(true);
    }else{
      this.doDisplay.emit(false);
    }

    for(var i = 0; i < this.crumbs.length; i++){
      var ciq = this.crumbs[i];
      if(this.isFileImage(ciq.fields.crumb_file)||this.isFileVideo(ciq.fields.crumb_file)){
        var media_url = this.master.mapURL(ciq.fields.crumb_file)
        this.media.push(media_url)
      }
    }


    this.star_array.length = this.statistics["rating"];
    this.star_array.fill(1);


    this.missionForm.controls['missionTitle'].setValue(this.portfolio_data.fields.projectName);
    this.missionForm.controls['missionDescription'].setValue(this.portfolio_data.fields.projectDesciption);

    this.customer_budget = this.portfolio_data.fields.projectEstimateLow;

    this.total_budget = Number(this.customer_budget) + Number(this.customer_budget)*0.18;

    console.log(this.display_picture);
    console.log(this.crumbs);
  }
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private master: MasterService,
    private zone: NgZone,
    public loadingController: LoadingController,
    public toastController: ToastController,
    private winRef: WindowRefService,
    ) { }

  ngOnInit() {
    this.project_id = this.route.snapshot.paramMap.get('id');
    this.missionForm = new FormGroup(
    {
      'missionTitle': new FormControl('',[Validators.required]),
      'missionDescription': new FormControl('',[Validators.required]),
      'misisonLocation': new FormControl('',[Validators.required]),
      'custom_id': new FormControl('',[Validators.required]),
      'missionPriceHigh': new FormControl('',[Validators.required]),
      'missionStartDate': new FormControl('',[Validators.required]),
      'missionEndDate': new FormControl('',[Validators.required]),
    })

    this.loadProfile()

    this.loadCategories();
    this.loadProject();
  }
  async loadCategories(){
    var res: any = await this.master.readMissionCategories();
    this.categories = JSON.parse(res.payload);
    console.log(this.categories);
  }
  //new functions


  stepIndex = 0;


   nextClicked(){
    this.stepIndex += 1;
    console.log(this.stepIndex);
  }


    backClicked(){

    if(this.stepIndex == 0 ){
       this.router.navigate(['/mission-tablinks/mission-dashboard']);
    }
    else{
      this.stepIndex -=1;
    }

  }

  currDiv :any;

  ShowDiv(divVal: string) {
    this.currDiv = divVal;
  }


  currBud :any;

    ShowBud(budVal: string) {
      this.currBud = budVal;
  }
  
  location_capture = false;

  locationEmitted(location){
    console.log(location);
    this.location = location;
    this.location_capture = true;
    this.to_send_address = location.address_level_1 + ' ' + location.address_level_2 + ' ' + location.address_state;
    // this.nextClicked
  }
  shorty(str: any){
    var res = str.split('/');
    return res[res.length - 1];
  }
  num_in_words: any = '';

  keuUpBudget(event: any){
    console.log(event);
    this.num_in_words = numToWords(event.target.value);

  }
  isFileImage(file_url) {
      return(file_url.match(/\.(jpeg|jpg|JPG|JPEG|gif|png)$/) != null);
  }  
  isFileVideo(file_url) {
      return(file_url.match(/\.(mp4|avi|webm)$/) != null);
  }

  customer_gst: any = 'NA';


  order_created: any = false;

  async makeOrder(){
    var order: any = {
      'customerContact': this.profile.fields.userPhone,
      'customerName': this.profile.fields.userName,
      'customerAddress': this.profile.fields.userAddress,
      'projectEstimateLow': this.customer_budget,
      'invoiceTotal': this.total_budget,
      'customerGST': this.customer_gst,
    }
    var res = await this.master.clientCreateOrder(order);
    console.log(res);

    if(res['result']=='true'){
      this.payWithRazor(res['payment_info']);
    }
  } 

  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  } 

  payWithRazor(order)
  {
        this.Pay_options.amount = order.amount_due;
        this.Pay_options.name = "VIMANA AeroTech Pvt. Ltd.";
        this.Pay_options.description = "Pay for mission Advance";
        this.Pay_options.order_id = order.id;
        this.Pay_options.prefill.name = this.profile.userName;
        this.Pay_options.prefill.contact = this.profile.userPhone;
        console.log(this.Pay_options);
        const rzp = new this.winRef.nativeWindow.Razorpay(this.Pay_options);
        rzp.open();
  }
  async sendMission(){

    if(!this.missionForm.value.misisonStartDate){
      this.missionForm.value.misisonStartDate = moment().add(30,'d').toDate();
      this.missionForm.value.misisonEndDate = moment().add(37,'d').toDate();
    }
    this.toSendMission.missionTitle = this.missionForm.value.missionTitle;
    this.toSendMission.missionDescription =  'NEW MISSION';
    this.toSendMission.missionPriceLow =  this.customer_budget;
    this.toSendMission.missionPriceHigh =  this.customer_budget;
    this.toSendMission.missionStartDate = this.missionForm.value.misisonStartDate ;
    this.toSendMission.missionEndDate =  this.missionForm.value.misisonEndDate;
    this.toSendMission.missionLocation =  this.to_send_address;
    this.toSendMission.missionLat =  this.location.marker.lat;
    this.toSendMission.missionLon =  this.location.marker.lng;
    this.toSendMission.custom_id =  this.missionForm.value.custom_id;
    this.toSendMission.category = JSON.stringify(this.cat_selected);

    console.log(this.startDate);
    console.log(this.endDate);
    console.log(this.missionForm);
    console.log(this.toSendMission);

    this.presentLoading();


    var res = await this.master.createMisison(this.toSendMission);

    if(res['result']=='true'){
      this.my_loading.dismiss();
      this.router.navigate(['/mission-tablinks/mission-activities']);      
    }else{
      this.presentToast('try again later');
    }
  }
 
  async loadProfile(){
    var res = await this.master.getProfile();
    this.profile = res;
    console.log(res);

    this.display_picture = this.master.mapURL(this.profile.fields.userPhoto);
    console.log(this.display_picture)

   }
  my_loading: any;

  async presentLoading() {
    this.my_loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...',
    });
    await this.my_loading.present();

    const { role, data } = await this.my_loading.onDidDismiss();
    console.log('Loading dismissed!');
  }

}
