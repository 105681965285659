import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ActivatedRoute } from '@angular/router';
import { MasterService } from '../../services/master.service'



import { ModalController } from '@ionic/angular';



@Component({
  selector: 'app-single-course',
  templateUrl: './single-course.component.html',
  styleUrls: ['./single-course.component.scss'],
})
export class SingleCourseComponent implements OnInit {
  training_id: any;




  school_data: any;
  batch_data: any;
  course_data: any;

  training_data: any;


  badges: any;
  skills:any;

  badge_imgs: any;
  skill_imgs: any;

  badge_dict : any;
  skill_dict : any;


 
   constructor(
    private route:ActivatedRoute,
    private router:Router,
     public modalController: ModalController,
  private master: MasterService,
  ) { }

  ngOnInit() {

      this.training_id = this.route.snapshot.paramMap.get('id');
      this.refresh();
  }  

  refresh()
  {
    this.loadTraining()
    this.loadBatches();
    this.loadSkills();
  }
  async loadTraining(){
    var res = await this.master.readOneTraining(this.training_id);

    console.log(res);
    this.school_data = JSON.parse(res['payload']['school'])[0];
    this.batch_data = JSON.parse(res['payload']['batch'])[0];
    this.course_data = JSON.parse(res['payload']['course'])[0];
    this.training_data = JSON.parse(res['payload']['training'])[0];


  }


  async loadBatches(){

    var res = await this.master.readPilotBadges();
    console.log(res);
    this.badges = JSON.parse(res['payload'])
    console.log(this.badges)

    this.badge_dict = {};
    this.badge_imgs = this.master.mapURLs(this.badges.map((x:any ) =>{
      return x.fields.imagefile
    }))

    console.log(this.badge_imgs)

    for (var i = this.badges.length - 1; i >= 0; i--) {
      this.badge_dict[this.badges[i].pk] = this.badge_imgs[i]
    }

    console.log(this.badge_dict)
  }
  async loadSkills(){
    var res = await this.master.readPilotSkills();
    console.log(res);
    this.skills = JSON.parse(res['payload'])
    console.log(this.skills)

    this.skill_dict = {};
    this.skill_imgs = this.master.mapURLs(this.skills.map((x:any ) =>{
      return x.fields.imagefile
    }))

    console.log(this.skill_imgs)
    for (var i = this.skills.length - 1; i >= 0; i--) {
      this.skill_dict[this.skills[i].pk] = this.skill_imgs[i]
    }
    console.log(this.skill_dict)


  }


    backClicked(){
    this.router.navigate(['/pilot-trainings',]);

  }
}
