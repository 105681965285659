import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';

import { fragmentShaderTrack,vertexShaderTrack } from '../shaders';
import { fragmentShaderVideography,vertexShaderVideography } from '../shaders';
import { fragmentShaderSurvey,vertexShaderSurvey } from '../shaders';

function doneLoadManager(){

}
function onProgressLoadManager()
{

}
function onErrorLoadManager()
{

}
function onProgressLoader()
{

}
function onErrorLoader()
{

}

export function get_videography_scene(renderer){
	   console.log(">>INSPECTION SCENE<<");
    var myScene = new THREE.Scene();


	var cameraRTT = new THREE.PerspectiveCamera( 25, 1 , 1, 5000 );
	cameraRTT.position.x = 300;
	cameraRTT.position.y = 100;
	cameraRTT.position.z = 300;

    var pointLightC = new THREE.PointLight( 0xaaaaaa, 0.1, 1000 );
    var pointLightD = new THREE.PointLight( 0xffffff, 0.1, 1000 );
    pointLightC.position.set( 0, 100, 0 );
    pointLightD.position.set( 10, 100, -10 );

    var ambientLight = new THREE.AmbientLight( 0xcccccc, 0.4 );

	var sceneRTT = new THREE.Scene();

	let light = new THREE.DirectionalLight( 0xffffff );
	light.position.set( 0, 0, 1 ).normalize();


	sceneRTT.add( light );
    sceneRTT.add( ambientLight );
    sceneRTT.add( pointLightC );
    sceneRTT.add( pointLightD );


	var rtTexture = new THREE.WebGLRenderTarget( 512, 512 );

	var rtMat =	    new THREE.MeshBasicMaterial({
	    	color: 0x001e0f,
	    })

	const materialScreen = new THREE.ShaderMaterial( {

		uniforms: { tDiffuse: { value: rtTexture.texture } },
		vertexShader: vertexShaderVideography,
		fragmentShader: fragmentShaderVideography,

		depthWrite: false

	} );


    var rendererRTT = new THREE.WebGLRenderer();
	rendererRTT.setSize( 512, 512 );
	rendererRTT.autoClear = false;
	rendererRTT.setRenderTarget( rtTexture );



	var geometry = new THREE.BoxGeometry( 500, 2,500 );


	const material1 = new THREE.MeshBasicMaterial( { color: 0x333333 } );
	const material2 = new THREE.MeshBasicMaterial( { color: 0xffffff, map: rtTexture.texture } );

	const box_mats = [
		material1,
		material1,
		material2,
		material1,
		material1,
		material1,
	]

	var screen = new THREE.Mesh( geometry, box_mats );

	// screen.rotate(Math.PI * 0.5);
	// screen.rotateY(Math.PI * 0.5);

	screen.translateX(-100);
	screen.translateZ(-500);
	screen.translateY(300);
	screen.rotation.x = Math.PI * 0.5;
	// screen.rotation.y = Math.PI * 0.5 * 0.5;
	// screen.rotateY(Math.PI * 0.5 * 0.5);



	var xScale = 1.0;
	var yScale = 1.0;
	var zScale = 1.0;

    // ground planes

	var geometry = new THREE.BoxGeometry( 1000, 2, 1000 );
	var material = new THREE.MeshNormalMaterial();

	var texture = THREE.ImageUtils.loadTexture('/assets/eco.png');
	// texture.anisotropy = this.renderer.getMaxAnisotropy();
	// texture.offset.x = 0.5; // 0.0 - 1.0
	// texture.offset.y = 0.5; // 0.0 - 1.0
	texture.repeat.set(1, 1);

	var myCar1,myCar2,myTyre,myDrone;

	// var carModel = '/assets/objs/f1nubody.obj';
	// var tyreModel = '/assets/objs/f1nutya.obj';

	var carModel = 'https://vimanastatic.s3.ap-south-1.amazonaws.com/f1nubody.obj';
	var tyreModel = 'https://vimanastatic.s3.ap-south-1.amazonaws.com/f1nutya.obj';
	var droneModel = 'https://vimanastatic.s3.ap-south-1.amazonaws.com/drone_rotorless.obj';

	var car1_material = new THREE.MeshLambertMaterial({
		color  : 0xe8b5ca,
		side : THREE.DoubleSide,
	});

	var car2_material = new THREE.MeshLambertMaterial({
		color  : 0xffb59c,
		side : THREE.DoubleSide,
	});

	var tyre_material = new THREE.MeshLambertMaterial({
		color  : 0x222222,
		side : THREE.DoubleSide,
	});

	var drone_material = new THREE.MeshLambertMaterial({
		color  : 0xe8b5ca,
		side : THREE.DoubleSide,
	});

    var racingScene = new THREE.Scene();
    var car1Scene = new THREE.Scene();
    var car2Scene = new THREE.Scene();
    var droneScene = new THREE.Scene();
    var overall_scale = 3;

	function doneTyreLoader(){		
    myTyre.rotation.set(0,0,0);
    myTyre.scale.set(overall_scale,overall_scale,overall_scale);
    var middle = new THREE.Vector3();
    var geometry = myTyre.children[0].geometry;
    geometry.computeBoundingBox();
    middle.x = (geometry.boundingBox.max.x + geometry.boundingBox.min.x) / 2;
    middle.y = (geometry.boundingBox.max.y + geometry.boundingBox.min.y) / 2;
    middle.z = (geometry.boundingBox.max.z + geometry.boundingBox.min.z) / 2;
    // console.log(middle);



    myTyre.traverse( function( child ) {
	if ( child instanceof THREE.Mesh ) {
	child.material = tyre_material ;
	}
	} );

    myTyre.translateX(-1*middle.x*xScale);
    myTyre.translateY(-1*middle.y*yScale);
    myTyre.translateZ(-1*middle.z*zScale);
    console.log("myTyre");
    console.log(myTyre);


    var car_w = 1*overall_scale;
    var car_l = 4*overall_scale;


	myTyre.position.x = 0;
	myTyre.position.y = 0;
	myTyre.position.z = 0;
    myTyre.translateX(car_l*-0.5);
    myTyre.translateZ(car_w);
    car1Scene.add( myTyre.clone() );

    myTyre.translateZ(-2*car_w);
    car1Scene.add( myTyre.clone() );

    myTyre.translateX(car_l);
    car1Scene.add( myTyre.clone() );

    myTyre.translateZ(2*car_w);
    car1Scene.add( myTyre.clone() );

	myTyre.position.x = 0;
	myTyre.position.y = 0;
	myTyre.position.z = 0;
    myTyre.translateX(car_l*-0.5);
    myTyre.translateZ(car_w);
    car2Scene.add( myTyre.clone() );

    myTyre.translateZ(-2*car_w);
    car2Scene.add( myTyre.clone() );

    myTyre.translateX(car_l);
    car2Scene.add( myTyre.clone() );

    myTyre.translateZ(2*car_w);
    car2Scene.add( myTyre.clone() );

	}

	function doneCar1Loader(){		
    myCar1.rotation.set(0,0,0);
    myCar1.scale.set(overall_scale,overall_scale,overall_scale);
    var middle = new THREE.Vector3();
    var geometry = myCar1.children[0].geometry;
    geometry.computeBoundingBox();
    middle.x = (geometry.boundingBox.max.x + geometry.boundingBox.min.x) / 2;
    middle.y = (geometry.boundingBox.max.y + geometry.boundingBox.min.y) / 2;
    middle.z = (geometry.boundingBox.max.z + geometry.boundingBox.min.z) / 2;
    // console.log(middle);


    myCar1.traverse( function( child ) {
	if ( child instanceof THREE.Mesh ) {
	child.material = car1_material;
	}
	} );

    myCar1.translateX(-1*middle.x*xScale);
    myCar1.translateY(-1*middle.y*yScale);
    myCar1.translateZ(-1*middle.z*zScale);

    console.log("myCar1");
    console.log(myCar1);
    car1Scene.add( myCar1 );
	}
	function doneCar2Loader(){		
    myCar2.rotation.set(0,0,0);
    myCar2.scale.set(overall_scale,overall_scale,overall_scale);
    var middle = new THREE.Vector3();
    var geometry = myCar2.children[0].geometry;
    geometry.computeBoundingBox();
    middle.x = (geometry.boundingBox.max.x + geometry.boundingBox.min.x) / 2;
    middle.y = (geometry.boundingBox.max.y + geometry.boundingBox.min.y) / 2;
    middle.z = (geometry.boundingBox.max.z + geometry.boundingBox.min.z) / 2;
    // console.log(middle);


    myCar2.traverse( function( child ) {
	if ( child instanceof THREE.Mesh ) {
	child.material = car2_material;
	}
	} );

    myCar2.translateX(-1*middle.x*xScale);
    myCar2.translateY(-1*middle.y*yScale);
    myCar2.translateZ(-1*middle.z*zScale);

    console.log("myCar2");
    console.log(myCar2);
    car2Scene.add( myCar2 );
	}
	function doneDroneLoader(){		
    myDrone.rotation.set(0,0,0);
    myDrone.scale.set(3,3,3);
    var middle = new THREE.Vector3();
    var geometry = myDrone.children[0].geometry;
    geometry.computeBoundingBox();
    middle.x = (geometry.boundingBox.max.x + geometry.boundingBox.min.x) / 2;
    middle.y = (geometry.boundingBox.max.y + geometry.boundingBox.min.y) / 2;
    middle.z = (geometry.boundingBox.max.z + geometry.boundingBox.min.z) / 2;
    // console.log(middle);


    myDrone.traverse( function( child ) {
	if ( child instanceof THREE.Mesh ) {
	child.material = drone_material;
	}
	} );

    myDrone.translateX(-1*middle.x*xScale);
    myDrone.translateY(-1*middle.y*yScale);
    myDrone.translateZ(-1*middle.z*zScale);

    console.log("myDrone");
    console.log(myDrone);
    droneScene.add( myDrone );
	}

	car1Scene.position.x = 5*overall_scale;
	car1Scene.position.y = 0;
	car1Scene.position.z = 0;
	car2Scene.position.x = -5*overall_scale;
	car2Scene.position.y = 0;
	car2Scene.position.z = 0;
	racingScene.add(car1Scene);
	racingScene.add(car2Scene);


	myScene.add(racingScene);
	myScene.add(droneScene);


	var manager = new THREE.LoadingManager( doneLoadManager,onProgressLoadManager,doneLoadManager);
	var loader = new OBJLoader( manager );
	loader.load( carModel, ( obj ) => {
	myCar1 = obj;


	doneCar1Loader();

	}, onProgressLoader, onErrorLoader );
	var loader = new OBJLoader( manager );
	loader.load( carModel, ( obj ) => {
	myCar2 = obj;


	doneCar2Loader();

	}, onProgressLoader, onErrorLoader );

	var loader = new OBJLoader( manager );
	loader.load( tyreModel, ( obj ) => {
	myTyre = obj;


	doneTyreLoader();

	}, onProgressLoader, onErrorLoader );


	var loader = new OBJLoader( manager );
	loader.load( droneModel, ( obj ) => {
	myDrone = obj;


	doneDroneLoader();

	}, onProgressLoader, onErrorLoader );


	var imageMaterial = 	    new THREE.MeshBasicMaterial({
	        map: texture //left
	    });



    var uniforms = {
        time: { type: "f", value: 1.0 },
        resolution: { type: "v2", value: new THREE.Vector2(100.,100.) },
        flight_x :{ type: "f", value: 1.0 },
        flight_y :{ type: "f", value: 1.0 },
    };
	var trackMaterial = new THREE.ShaderMaterial({
        uniforms: uniforms,
        vertexShader: vertexShaderTrack,
        fragmentShader: fragmentShaderTrack,
   		side: THREE.DoubleSide
    });

	// LRTBFB
	var groundMaterial = [
	    new THREE.MeshNormalMaterial({
	    }),
	    new THREE.MeshNormalMaterial({
	    }),
	    trackMaterial,
	    imageMaterial,
	    new THREE.MeshNormalMaterial({
	    }),
	    new THREE.MeshNormalMaterial({
	    }),
	    new THREE.MeshNormalMaterial({
	    })
	];


	var geo = new THREE.PlaneGeometry(1500,1500,100,100);
	geo.rotateX(-Math.PI/2);


	var mesh = new THREE.Mesh( geo, trackMaterial );

    // this.object = this.mesh;

    // mesh.rotation.set(this.xRot,this.yRot,this.zRot);
    // mesh.scale.set(this.xScale,this.yScale,this.zScale);

    var middle = new THREE.Vector3();
    // var geometry = this.geometry;

    geometry.computeBoundingBox();

    middle.x = (geometry.boundingBox.max.x + geometry.boundingBox.min.x) / 2;
    middle.y = (geometry.boundingBox.max.y + geometry.boundingBox.min.y) / 2;
    middle.z = (geometry.boundingBox.max.z + geometry.boundingBox.min.z) / 2;
    // console.log(middle);
    mesh.translateX(-1*middle.x*xScale);
    mesh.translateY(-1*middle.y*yScale);
    mesh.translateZ(-1*middle.z*zScale);


	var pointLightC = new THREE.PointLight( 0xaaaaaa,5.0, 1000 );
	var pointLightD = new THREE.PointLight( 0xffffff,5.0, 1000 );
	pointLightC.position.set( 500, 500, 500 );
	pointLightD.position.set( -500, 500, -500 );

	myScene.add( pointLightC );
	myScene.add( pointLightD );
    var ambientLight = new THREE.AmbientLight( 0xcccccc, 0.4 );

    myScene.add( ambientLight );


    myScene.add(screen);
    myScene.add(mesh);
    // sceneRTT.add(screen);

	var FUNCTIONAL_K = 1/5;

	function droneNormalXYZ(val){
		var r_polar = Math.sin(FUNCTIONAL_K * val);

		var dx = Math.sin(val)*r_polar;
		var dy = 0;
		var dz = Math.cos(val)*r_polar;
		var r_vex = new THREE.Vector3(dx,dy,dz);
		return r_vex;

	}

	function droneXYZ(frame){

		frame = frame % 100000;

		var theta = frame;

		var span = 500;
		var fly_height = 10;

		var norm_xyz = droneNormalXYZ(theta)
		// console.log(norm_xyz);

		var dx = norm_xyz.x*span;
		var dy = fly_height;
		var dz = norm_xyz.z*span;
		var r_vex = new THREE.Vector3(dx,dy,dz);
		return r_vex;
	}

	function droneROT(frame){

		frame = frame % 100000;

		var omega_frame = frame/100000 * 2 * Math.PI;

		var theta = frame;
		var del = 0.0001;
		var r_polar = 500*Math.sin(FUNCTIONAL_K * theta);

		function fx(x){
			return 500*Math.sin(FUNCTIONAL_K * x)* Math.cos(x)
		}
		function fy(x){
			return 500*Math.sin(FUNCTIONAL_K * x)* Math.sin(x)
		}

		var dx = fx(theta + del) - fx(theta); 
		var dy = fy(theta + del) - fy(theta); 

		var ret_theta = Math.atan2(dy,dx);
		return ret_theta - Math.PI/2;
	}


	var rad = 0;
	var radIncrement = 0.007;
	var rot = 0;
	var rotIncrement = 0.005;

	var car1_velo = 0;
	var car1_acc = 1;

	var p_gain = 0.003;
	var i_gain = 0.1;
	var d_gain = 0.1;

	var err_X,err_Z;
	var sigma_err_X,sigma_err_Z;
	var delta_err_X,delta_err_Z;


    var myAnimation = function(self: any) {
  		// console.log("inspection update");
	  // Animates our water
	  try{
		rot += rotIncrement;
		if(racingScene)
		{

		var normal_pos = droneNormalXYZ(rot);
		var new_pos = droneXYZ(rot);
		var new_rot = droneROT(rot);
		racingScene.position.set(new_pos.x,new_pos.y,new_pos.z);
		// car1Scene.translateZ(car1_velo);
		car1_velo = Math.cos(rot);
		car1Scene.rotation.y = new_rot + Math.PI;
		car2Scene.rotation.y = new_rot + Math.PI;
		
		}

		err_X = racingScene.position.x -   cameraRTT.position.x ;
		err_Z = racingScene.position.z -   cameraRTT.position.z ;

		cameraRTT.position.x += err_X*p_gain;
		cameraRTT.position.z += err_Z*p_gain;

		if(myDrone){
			myDrone.position.set(cameraRTT.position.x,cameraRTT.position.y + 10,cameraRTT.position.z);
			myDrone.rotation.y = cameraRTT.rotation.y + Math.PI*0.5;
			// console.log(cameraRTT.position);
		}

		uniforms.time.value += 0.01
		uniforms.flight_x.value = (normal_pos.x + 1)/2;
		uniforms.flight_y.value = (1 - normal_pos.z)/2;
	
	  	var normal_pos = new THREE.Vector3(0,0,0);
		self.orbitControls.update();
		self.camera.lookAt( self.scene.position );
		cameraRTT.lookAt( racingScene.position);

		self.renderer.setRenderTarget( rtTexture );
		self.renderer.render( self.scene,cameraRTT );
		self.renderer.setRenderTarget( null );
		self.renderer.render( self.scene, self.camera );

	  }
	  catch(e){
	  	console.log(e);
	  }
	  

	}


    return [ myScene , myAnimation  ]
}