import { Component, OnInit ,Input,Output,ChangeDetectorRef,EventEmitter } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { MasterService } from '../../services/master.service';
import { ActivatedRoute } from '@angular/router';
import { SinglePortfolioProjectComponent } from '../single-portfolio-project/single-portfolio-project.component';
import { ModalController } from '@ionic/angular';
import { Facebook, FacebookLoginResponse } from '@awesome-cordova-plugins/facebook/ngx';
import { PilotOnePortfolioComponent } from '../../social/pilot-one-portfolio/pilot-one-portfolio.component'


@Component({
  selector: 'app-pilot-portfolio-mini',
  templateUrl: './pilot-portfolio-mini.component.html',
  styleUrls: ['./pilot-portfolio-mini.component.scss'],
})
export class PilotPortfolioMiniComponent implements OnInit {

  @Input() portfolio_data: any;
  @Output() reopen_emitted = new EventEmitter<any>();
	profile: any;
	profile_stats: any;
	statistics: any;
	status: any;
	verification: any;
	ranks: any;
	my_rank :any;
	my_rank_insignia :any;
	display_picture:any;
	skills: any;
	star_array = [];


  crumbs: any;
  media = [];

  constructor(
  	private route: Router,
  	private master: MasterService,
  	private cdr: ChangeDetectorRef,
  	public modalController: ModalController,
  	private fb: Facebook
	) { }

  ngOnInit() {
  	console.log(this.portfolio_data)
  	 this.loadProfile();
     this.loadCrumbs()

  }


      async loadProfile(){
  	var res = await this.master.getPilotProfile();


    console.log(res);

    this.profile = JSON.parse(res['payload']['profile'])[0];


    this.profile_stats = JSON.parse(res['payload']['profile_stats'])[0];
    this.statistics = JSON.parse(res['payload']['statistics']);
    this.status = res['payload']['status'];
    this.verification = JSON.parse(res['payload']['verification'])[0];
    this.ranks = JSON.parse(res['payload']['ranks']);
    this.skills = JSON.parse(res['payload']['skills']);

    this.my_rank = this.ranks[this.ranks.length -1]

    for (var i = this.ranks.length - 1; i >= 0; i--) {
      var this_rank = parseInt(this.ranks[i].fields.xp_cutoff);
      var my_rank = parseInt(this.statistics.xp)
      if (this_rank <= my_rank || i == 0){
        this.my_rank = this.ranks[i];
        break;
      }
      else{
        console.log(this_rank);
        console.log(my_rank);

      }
    }




    this.my_rank_insignia = this.master.mapURL(this.my_rank.fields.imagefile);

    this.display_picture = this.master.mapURL(this.profile.fields.userPhoto);

    console.log(res);
    console.log(this.my_rank);
    console.log(this.my_rank_insignia);
    console.log(this.display_picture)
    console.log(this.ranks);
    console.log(this.profile);
    // console.log(this.profile_stats);
    // console.log(this.statistics);
    console.log(this.status);
    // console.log(this.verification);
    // console.log(this.statistics["rating"]);

    this.star_array.length = this.statistics["rating"];
    this.star_array.fill(1);

    console.log(this.star_array);

    this.cdr.markForCheck();
   }

    async presentProjectModal(data){
      console.log(data);
      const modal = await this.modalController.create({
        component: SinglePortfolioProjectComponent,
        cssClass: 'image-popup-modal-css',
        componentProps: { data:  data}
      });

      modal.onDidDismiss().then((data) => {
        console.log(data);
        // this.ref.detectChanges();
        if(data.data.restart){
            this.reopen_emitted.emit(this.portfolio_data.fields.unique_id);
        }

        // this.zone.run(() => {
        //   console.log('force update the screen');
        // });
        this.fb.logEvent(this.fb.EVENTS.EVENT_NAME_ADDED_TO_WISHLIST);

        this.ngOnInit();

      })


      return await modal.present();
    }
    async presentCustomerProjectModal(data){
      console.log(data);
      const modal = await this.modalController.create({
        component: PilotOnePortfolioComponent,
        cssClass: 'image-popup-modal-css',
        componentProps: { portfolio_uuid:  data.fields.unique_id}
      });

      modal.onDidDismiss().then((data) => {
        console.log(data);
        // this.ref.detectChanges();
        if(data.data.restart){
            this.reopen_emitted.emit(this.portfolio_data.fields.unique_id);
        }

        // this.zone.run(() => {
        //   console.log('force update the screen');
        // });
        this.fb.logEvent(this.fb.EVENTS.EVENT_NAME_ADDED_TO_WISHLIST);

        this.ngOnInit();

      })


      return await modal.present();
    }

  async loadCrumbs(){

    var res = await this.master.loadPilotProjectsCrumbs(this.portfolio_data.fields.unique_id);


    console.log(res);


    this.crumbs = JSON.parse(res['payload']['project_crumbs']);

    this.media = [];

    for(var i = 0; i < this.crumbs.length; i++){
      var ciq = this.crumbs[i];
      if(this.isFileImage(ciq.fields.crumb_file)||this.isFileVideo(ciq.fields.crumb_file)){
        var media_url = this.master.mapURL(ciq.fields.crumb_file)
        this.media.push(media_url)
      }

    }
    console.log(this.crumbs);
  }
  isFileImage(file_url) {
      return(file_url.match(/\.(jpeg|jpg|JPG|JPEG|gif|png)$/) != null);
  }  
  isFileVideo(file_url) {
      return(file_url.match(/\.(mp4|avi|webm)$/) != null);
  }  
}
