import { Injectable } from '@angular/core';
import { BehaviorSubject,Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class HeartbeatService {

  new_discovery_feed_subj = new BehaviorSubject(false);


  constructor(){}


  newDiscoveryPost(){
  	this.new_discovery_feed_subj.next(true);
  }


}