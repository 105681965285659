import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MasterService } from '../../services/master.service';


@Component({
  selector: 'app-pilot-create-group',
  templateUrl: './pilot-create-group.component.html',
  styleUrls: ['./pilot-create-group.component.scss'],
})
export class PilotCreateGroupComponent implements OnInit {

	
  constructor(
  	public modalController: ModalController,
    private master: MasterService,
  	) { }

  ngOnInit() {}


  async sendInfo(){

  }
  closeModal() {
      this.modalController.dismiss();
  }

}
