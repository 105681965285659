import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MasterService } from '../../services/master.service'
import { FormControl,FormGroup,Validators,AbstractControl,ValidationErrors } from '@angular/forms';
import { take,first } from 'rxjs/operators';
import { ToastController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { Facebook, FacebookLoginResponse } from '@awesome-cordova-plugins/facebook/ngx';



@Component({
  selector: 'app-mission-signup',
  templateUrl: './mission-signup.component.html',
  styleUrls: ['./mission-signup.component.scss'],
})
export class MissionSignupComponent implements OnInit {


  redirect: any;

  name:any;
  pass:any;

  newUser: any;
  newUserForm:any;
  otpUserForm:any;


  otp_sent_control:any = false;
  otp_verified_control:any = false;
  role_verified_control: any = false;


  timeLeft: number = 60;
  interval;
  verified_mobile: any;

  profile_type: any = undefined;


  is_referral = false;
  // referral_verified_control = false;
  referral_verified_control = false;
  referralForm:any;

  constructor(
  	private router: Router,
    private master: MasterService,
    public toastController: ToastController,        
    public modalController: ModalController,
    private fb: Facebook
  	) { }

 
  ngOnInit() {
    this.referralForm = new FormGroup({
    'refer_code': new FormControl('',[Validators.required]),

    });



    this.otpUserForm = new FormGroup(
    {
    'mobile': new FormControl('',[Validators.required,Validators.pattern("[0-9]{10}")]),
    'otp': new FormControl('',[Validators.required,Validators.pattern("[0-9]*")]),
	  'title': new FormControl('',[Validators.required]),
    'address': new FormControl('',[Validators.required]),
	  'email': new FormControl('',[Validators.required,Validators.email]),
    'city': new FormControl('', [Validators.required]),
    'disctrict': new FormControl('', [Validators.required]),
    'pincode': new FormControl('', [Validators.required]),
	  'phone': new FormControl('',[Validators.required]),
	  'password': new FormControl('', [Validators.required]),
    'repeat': new FormControl('',[Validators.required,this.matchValues('password'),]),
    'refer_code': new FormControl('',[]),
    }
    )
	}


 segmentChanged(ev){
   console.log(ev);
   this.profile_type = ev.detail.value;
 }
 confirmRole(val: any = ''){
  // this.profile_type = val;
   this.role_verified_control = true;

 }
  matchValues(
    matchTo: string // name of the control to match to
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.controls[matchTo].value
        ? null
        : { isMatching: false };
    };
  }



  async attemptLogin() {    
    this.master.auth_ping.pipe(first()).subscribe((d)=>{
      console.log(d);
      this.authProceed();
    });

  	var username = this.newUser.gusername;
  	var password = this.newUser.gpassword;

  	var res = await this.master.login(username,password,true);
    console.log(res);
    if(res==true)
    {
      this.fb.logEvent(this.fb.EVENTS.EVENT_NAME_COMPLETED_REGISTRATION);
      this.dismiss();
    }
    else{
      this.presentToast('invalid details');
    }
  }



  authProceed()
  {
    console.log(this.master.auth_status);
    if(this.master.auth_status)
    {
      this.fb.logEvent(this.fb.EVENTS.EVENT_NAME_COMPLETED_REGISTRATION);

      this.dismiss();
    }
    else{
      ;
    }
  }



  async makeNewOTP(){
    var mobile = this.otpUserForm.value.mobile;

    var res = await this.master.makeOTP(mobile);
    console.log(res);
    if(res['result']=='true'){
    this.otp_sent_control = true;
    this.restartTimer();
    }
    else{
    	this.presentToast("Please check mobile number!");
    }

  }

  async verifyOTP(){
    var otp = this.otpUserForm.value.otp;
    var res = await this.master.verifyOTP(otp);
    if(res['result']=='true'){
    	this.otp_verified_control = true;
    	this.verified_mobile = this.otpUserForm.value.mobile;
    }
    else{
      	this.presentToast("Please check otp!");
	
    }
    }
    refer_code = '';
  async makeNewUser(){
  	this.newUser = {};

    this.newUser.userName = this.otpUserForm.value.title;
    this.newUser.userPhone = this.otpUserForm.value.mobile;
    this.newUser.userEmail = this.otpUserForm.value.email;
    this.newUser.userAddress = this.otpUserForm.value.address;
    this.newUser.gusername = this.otpUserForm.value.mobile;
    this.newUser.gpassword = this.otpUserForm.value.repeat;
    this.newUser.profile_type = this.profile_type;
    this.newUser.refer_code = this.refer_code;

    var res = await this.master.signUp(this.newUser);
    console.log(res);
    if(res['result']=='true'){
      this.name = this.otpUserForm.value.mobile;
      this.pass = this.newUser.gpassword;
      this.attemptLogin();
    }else{
      if(res['result']=='false'){
        this.presentToast(res['error']);      
      }
      else{
        this.presentToast('Alredy Registered!');      
      }
    }
  }

  restartTimer() {
    this.timeLeft = 60;
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 0;
      }
    },1000)
  }

  referer_profile : any;

  toggleReferral(){
    this.is_referral = !this.is_referral;
  }
  async makeNewReferral(){
    var ref_code = this.referralForm.value.refer_code;

    var res = await this.master.verifyREFCODE(ref_code);
    console.log(res);
    if(res['result'] == 'true'){
      this.referral_verified_control = true;
      this.referer_profile = JSON.parse(res['referer'])[0];
      this.refer_code = this.referer_profile.fields.unique_id;
      // this.otpUserForm.setValue({
      //   'refer_code' : this.referer_profile.fields.unique_id,
      // });
      this.presentToast('Verified!');
    }
    else{
      this.presentToast('Sorry! Not Verified!');      
    }
  }

  back(){
  	    this.router.navigate(['/login']);

  }
  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  } 

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true,
    });
  }

}
