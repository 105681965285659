import * as THREE from 'three';

export function get_photography_scene(renderer){
	   console.log("starting dunes");

	var xScale = 1.0;
	var yScale = 1.0;
	var zScale = 1.0;

    // ground planes

	var geometry = new THREE.BoxGeometry( 400, 400, 400 );
	var material = new THREE.MeshNormalMaterial();

	var texture1 = THREE.ImageUtils.loadTexture('/assets/photography/phtography1.png');
	var texture2 = THREE.ImageUtils.loadTexture('/assets/photography/phtography2.png');
	var texture3 = THREE.ImageUtils.loadTexture('/assets/photography/phtography3.png');
	var texture4 = THREE.ImageUtils.loadTexture('/assets/photography/phtography4.png');
	var texture5 = THREE.ImageUtils.loadTexture('/assets/photography/phtography5.png');
	var texture6 = THREE.ImageUtils.loadTexture('/assets/photography/phtography6.png');
	// texture.anisotropy = this.renderer.getMaxAnisotropy();
	// texture.offset.x = 0.5; // 0.0 - 1.0
	// texture.offset.y = 0.5; // 0.0 - 1.0
	texture1.repeat.set(1, 1);
	texture2.repeat.set(1, 1);
	texture3.repeat.set(1, 1);
	texture4.repeat.set(1, 1);
	texture5.repeat.set(1, 1);
	texture6.repeat.set(1, 1);


	var imageMaterial = 	    new THREE.MeshBasicMaterial({
	        map: texture1 //left
	    });

	// LRTBFB
	var groundMaterial = [
		new THREE.MeshBasicMaterial({
			map: texture1 //left
		}),
		new THREE.MeshBasicMaterial({
			map: texture2 //left
		}),
		new THREE.MeshBasicMaterial({
			map: texture3 //left
		}),
		new THREE.MeshBasicMaterial({
			map: texture4 //left
		}),
		new THREE.MeshBasicMaterial({
			map: texture5 //left
		}),
		new THREE.MeshBasicMaterial({
			map: texture6 //left
		})
	];



	var mesh = new THREE.Mesh( geometry, groundMaterial );

    // this.object = this.mesh;

    // mesh.rotation.set(this.xRot,this.yRot,this.zRot);
    // mesh.scale.set(this.xScale,this.yScale,this.zScale);

    var middle = new THREE.Vector3();
    // var geometry = this.geometry;

    geometry.computeBoundingBox();

    middle.x = (geometry.boundingBox.max.x + geometry.boundingBox.min.x) / 2;
    middle.y = (geometry.boundingBox.max.y + geometry.boundingBox.min.y) / 2;
    middle.z = (geometry.boundingBox.max.z + geometry.boundingBox.min.z) / 2;
    // console.log(middle);
    mesh.translateX(-1*middle.x*xScale);
    mesh.translateY(-1*middle.y*yScale);
    mesh.translateZ(-1*middle.z*zScale);


    var myScene = new THREE.Scene();

    myScene.add(mesh);

    var myAnimation = function(self: any) {
	  try{
		self.orbitControls.update();
		self.camera.lookAt( self.scene.position );	  	
		self.renderer.render( self.scene, self.camera );
	  }
	  catch(e){
	  	console.log(e);
	  }
	  

	}


    return [ myScene , myAnimation  ]
}
