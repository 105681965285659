import { Component, OnInit } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MasterService } from '../../services/master.service'
import { GoogleMapsAPIWrapper } from '@agm/core';
import { MapsAPILoader, AgmMap } from '@agm/core';
import { PopoverController } from '@ionic/angular';

import { MyActionsPopover } from './my-actions.component';

import { LocationInterface,Marker } from '../../interfaces';
import { ToastController } from '@ionic/angular';

import { giveMissionStatusMessage,giveMyMissionActionMessage } from '../../services/status';


import { parseMission } from '../../services/status';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

import { PilotMissionShare } from './share-mission.component';


interface LocationProps {
  name: string;
  lat: number;
  lng: number;
  label?: any;
}




@Component({
  selector: 'app-pilot-single-mission',
  templateUrl: './pilot-single-mission.component.html',
  styleUrls: ['./pilot-single-mission.component.scss'],
})
export class PilotSingleMissionComponent implements OnInit {

  mission_id: any;
  mission: any;
  status: any;
  my_actions: any;
  mission_cats: any;
  cat_dict = {
  };
  status_actions: any;

  crumbs: any;
  crumb_files: any;


  map_loaded = false;

  current_status = 'Open';
  sub_status = 'Open';
  starred = false;

  my_status = 'Open';

  circleRadius = 1900000;


  noninteractive_message = 'REGESTRATION CLOSED'

  latest_mission_action: any;
  latest_mission_star: any;

  interactive = true;

  public location:LocationInterface = {
    lat: 23.152790,
    lng: 79.947327,
    marker: {
      lat: 23.152790,
      lng: 79.947327,
      draggable: false
    },
    zoom: 9
  };




  center = {
    lat: 59.339025,
    lng: 18.065818
  };
  zoom = 4;
  locations: LocationProps[] = [
    {
      name: "Oslo",
      lat: 59.923043,
      lng: 10.752839
    },
    {
      name: "Stockholm",
      lat: 59.339025,
      lng: 18.065818
    },
    {
      name: "Copenhagen",
      lat: 55.675507,
      lng: 12.574227
    },
    {
      name: "Berlin",
      lat: 52.521248,
      lng: 13.399038
    },
    {
      name: "Paris",
      lat: 48.856127,
      lng: 2.346525
    }
  ];
  icon = {
    labelOrigin: { x: 16, y: 48 ,
        color: "green",
        fontWeight: "bold",
        fontSize: "16px"},

    url: "https://maps.google.com/mapfiles/kml/paddle/red-blank.png",
    scaledSize: {
      width: 32,
      height: 32
    }
  };



  mission_accepted = false;
  mission_standby = false;
  mission_cancellled = false;
  mission_star = false;

  mission_open = true;
  mission_stby = false;
  mission_noop = false;
  mission_ctrt = false;


  ro_date: any = false;

  cm_1 : any = '..';
  cm_2 : any = '..';



  makeCardMessage(){

    //OVVERRIDES
    //ACTIONS
    //MESSAGES

    var msg = parseMission(this.mission,this.status,this.status_actions);
    console.log(msg);


    var last_msg = (x: any, b: any) => {
      x = x.filter((y: any) => {
        return y != '';
      })
      return x[x.length - b];
    }

 

    // var sm_res = giveMissionStatusMessage(this.status);

    // this.current_status = sm_res[0];
    // this.sub_status = sm_res[1];






    // console.log(this.current_status);
    // console.log(this.sub_status);
    // console.log(this.noninteractive_message);
    // console.log(res2);


    this.mission_open = msg[0][0];
    this.mission_stby = msg[0][1];
    this.mission_noop = msg[0][2];
    this.mission_ctrt = msg[0][3];



    this.mission_accepted = msg[1][0];
    this.mission_standby = msg[1][1];
    this.mission_cancellled = msg[1][2];
    this.mission_star = msg[1][3];

    this.cm_1 = last_msg(msg[2],1);
    this.cm_2 = last_msg(msg[2],2);

    this.ro_date = msg[3][0];


    var startdate = this.ro_date
    var now = Date.now();
    var diff  = startdate.getTime() - now ;

    console.log(diff);
    if(diff < 0){
      this.interactive = false;
      this.noninteractive_message = 'REGISTRATIONS CLOSED'
    }

  }  



  constructor(
    public actionSheetController: ActionSheetController,
    private router: Router,
    private route:ActivatedRoute,
    private master: MasterService,
    public toastController: ToastController,
    public popoverController: PopoverController,
    private socialSharing: SocialSharing,
    private _location: Location
	) { 
    this.mission_id = this.route.snapshot.paramMap.get('id');
    console.log(this.mission_id);

     }



  ngOnInit() {

    this.loadMisison();
    

  }
  ionViewWillEnter() {

    this.loadMisison();

  }
  async loadMisison(){
    var res = await this.master.readPilotOneMission(this.mission_id);
    console.log(res);
    this.mission = JSON.parse(res['payload']['mission'])[0];
    this.status = JSON.parse(res['payload']['status'])[0];
    this.my_actions = JSON.parse(res['payload']['my-actions']);
    this.crumbs = JSON.parse(res['payload']['crumbs']);


    this.crumbs = this.crumbs.filter((x: any) => {
      return x.fields.intent == 'mission';
    })
    this.crumb_files = this.master.mapURLsBucket(this.crumbs.map(x=> x.fields.extern_file));

    this.status_actions = JSON.parse(res['payload']['status-actions'])[0];
    this.status_actions = this.status_actions.map((x: any) => {
      return JSON.parse(x);
    })
    console.log(this.mission);
    console.log(this.status);
    console.log(this.crumbs);
    console.log(this.my_actions);
    console.log(this.status_actions);
    var res2 = await this.master.readMissionCategories();


    this.mission_cats = JSON.parse(res2['payload']);

    for (var i = 0; i < this.mission_cats.length; i++) {
      this.cat_dict[this.mission_cats[i].pk] = this.mission_cats[i].fields.name;
    }

    this.location.lat = parseFloat(this.mission.fields.missionLat);
    this.location.lng = parseFloat(this.mission.fields.missionLon);


    this.location.marker.lat = parseFloat(this.mission.fields.missionLat);
    this.location.marker.lng = parseFloat(this.mission.fields.missionLon);
    
    this.map_loaded = true;

    if(this.mission.fields.is_qualifying){
      this.location.zoom = 4;
    }

    this.makeCardMessage();

  }

  async presentActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Options',
      cssClass: 'my-custom-class',
      buttons: [
      {
        text: 'My Actions',
        role: 'destructive',
        icon: 'bag-add-outline',
        handler: () => {
          console.log('Actions clicked');
          this.presentPopover('actions');
        }
      },
      {
        text: 'Share',
        icon: 'share-social-outline',
        handler: () => {
          this.presentSharePopover('share');
        }
      }, 
      {
        text: 'Star',
        icon: 'star-outline',
        handler: () => {
          console.log('Star clicked');
          this.pokeMission('star');
        }
      }, 
      // {
      //   text: 'Report',
      //   icon: 'megaphone-outline',
      //   handler: () => {
      //     console.log('Favorite clicked');
      //   }
      // }, 
      {
        text: 'Cancel Mission',
        icon: 'close',
        role: 'destructive',
        handler: () => {
          console.log('Cancel clicked');
          this.pokeMission('cancel');
      }
      }]
    });
    await actionSheet.present();

    const { role } = await actionSheet.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  async pokeMission(poke){
    if(this.interactive==false){
      this.presentToast("MISSION REGISTRATION CLOSED!")
      return;
    }

    var res = await this.master.pilotPokeMission(this.mission_id,poke);
    console.log(res);
    if(res['result']=="true" && poke=='accept'){
      this.presentToast("ACCEPTED!")
    }
    if(res['result']=="true" && poke=='stand-by'){
      this.presentToast("ON STANDBY!")
    }
    if(res['result']=="true" ){
      this.presentToast(res['payload']['message']);
      this.loadMisison();
    }
    else{
      this.presentToast(res['error']);
    }

  }

 async presentSharePopover(ev: any){
    const popover = await this.popoverController.create({
      component: PilotMissionShare,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
      componentProps: { link: this.route.snapshot.pathFromRoot.map(o => o.url[0]).join('/') + '/' + this.mission_id }
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);    
  }


  backClicked(){
     console.log(this._location.getState());
     var res = this._location.back();
     console.log(res);
     if(res == undefined){
      this.router.navigate(['/pilot-tablinks/pilot-tools']);
     }
  }


  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: MyActionsPopover,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
      componentProps: { actions: this.my_actions }
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }  


  goToMissionDashboard(){
    this.router.navigate(['/pilot-mission-control-dashboard/',this.mission.fields.unique_id]);

  }

}
