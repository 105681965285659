import { Component, OnInit } from '@angular/core';
import { MasterService } from '../../services/master.service';
import { Router } from '@angular/router';
import { LocationInterface,Marker } from '../../interfaces';

@Component({
  selector: 'app-all-pilot-map',
  templateUrl: './all-pilot-map.component.html',
  styleUrls: ['./all-pilot-map.component.scss'],
})
export class AllPilotMapComponent implements OnInit {

  profile : any;



  icon = {
    labelOrigin: { x: 16, y: 48 ,
        color: "green",
        fontWeight: "bold",
        fontSize: "16px"},

    url: "/assets/icon/map_icon.png",
    scaledSize: {
      width: 35,
      height: 35
    }
  };

  public location:LocationInterface = {
    lat: 23.152790,
    lng: 79.947327,
    marker: {
      lat: 23.152790,
      lng: 79.947327,
      draggable: false
    },
    zoom: 5
  };

  constructor(
    private master: MasterService,
    private router: Router,
    ) { }

  ngOnInit() {
      this.loadPilotMap();
  }

    pilot_coords : any;


    async loadPilotMap(){

    var res = await this.master.loadPilotMap();

    console.log(res);
    this.pilot_coords = JSON.parse(res['payload']['feed']);
    console.log(this.pilot_coords);
  }

  backClicked(){
      this.router.navigate(['/mission-tablinks/mission-dashboard']);

  }


}
