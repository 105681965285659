import { Component, OnInit } from '@angular/core';
import { Router , ActivatedRoute} from '@angular/router';
import { MasterService } from '../../services/master.service'
import { trigger, style, animate, transition } from '@angular/animations';

import { Facebook, FacebookLoginResponse } from '@awesome-cordova-plugins/facebook/ngx';

import { Location } from '@angular/common';





import  { WeatherService } from '../../services/weather.service';
@Component({
  selector: 'app-start-flight-log',
  templateUrl: './start-flight-log.component.html',
  styleUrls: ['./start-flight-log.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateY(100%)', opacity: 0}),
          animate('500ms', style({transform: 'translateY(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateY(0)', opacity: 1}),
          animate('200ms', style({transform: 'translateY(100%)', opacity: 0}))
        ])
      
  ]
  )]


})
export class StartFlightLogComponent implements OnInit {




  public form = [
      { val: 'Weather Check', isChecked: true },
      { val: 'Regulations Check', isChecked: false },
      { val: 'Batteries Charged', isChecked: false },
      { val: 'Visual Inspection Pass', isChecked: false },
      { val: 'Location Check', isChecked: false },
      { val: 'Control Surfaces Check', isChecked: false },
      { val: 'RadioComs Check', isChecked: false }
    ];



  stepIndex = 0;

  categoryIndex = -1;

  cat_selected: any;


  public timeBegan = null
  public timeStopped:any = null
  public stoppedDuration:any = 0
  public started = null
  public running = false

  public blankTime = "00:00.000"
  public time = "00:00.000"


  logMissionName = '';
  logMissionDescription = '';
  logWeather = '';
  logNotes = '';

  categories: any;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private master: MasterService,
    private fb: Facebook,
    private weather: WeatherService,
    private _location: Location
    ) { }

  weather_info: any;
  flying_status: any;


   ionViewWillEnter(){
     this.ngOnInit();
   }

   is_vmn_flight = 'false';

  ngOnInit() {
    this.stepIndex = 0;

    this.loadCategories();

    this.is_vmn_flight = this.route.snapshot.paramMap.get('ivf');

    this.weather_info = this.weather.weather_info.getValue();
    this.flying_status = this.weather.flying_status.getValue();
    console.log(this.weather_info);
  }

  goback(){
     console.log(this._location.getState());
     this._location.back();
  }
  async loadCategories(){
    var res: any = await this.master.readMissionCategories();
    this.categories = JSON.parse(res.payload);
    console.log(this.categories);
  }


  start() {
    if(this.running) return;
    if (this.timeBegan === null) {
        this.reset();
        this.timeBegan = new Date();
    }
    if (this.timeStopped !== null) {
      let newStoppedDuration:any = (+new Date() - this.timeStopped)
      this.stoppedDuration = this.stoppedDuration + newStoppedDuration;
    }
    this.started = setInterval(this.clockRunning.bind(this), 10);
      this.running = true;
    }


    stop() {
      this.running = false;
      this.timeStopped = new Date();
      clearInterval(this.started);
   }
    reset() {
      this.running = false;
      clearInterval(this.started);
      this.stoppedDuration = 0;
      this.timeBegan = null;
      this.timeStopped = null;
      this.time = this.blankTime;
    }

    zeroPrefix(num, digit) {
      let zero = '';
      for(let i = 0; i < digit; i++) {
        zero += '0';
      }
      return (zero + num).slice(-digit);
    }

    seconds = 0;

    clockRunning(){
      let currentTime:any = new Date()
      let timeElapsed:any = new Date(currentTime - this.timeBegan - this.stoppedDuration)
      
      this.seconds = timeElapsed.getTime() / 1000
      console.log(this.seconds);
      let hour = timeElapsed.getUTCHours()
      let min = timeElapsed.getUTCMinutes()
      let sec = timeElapsed.getUTCSeconds()
      let ms = timeElapsed.getUTCMilliseconds();
      this.time =
      this.zeroPrefix(hour, 2) + ":" +
      this.zeroPrefix(min, 2) + ":" +
      this.zeroPrefix(sec, 2) + "." +
      this.zeroPrefix(ms, 3);
    };


    async submitLog(){
        var formData = new FormData();
        formData.append('logName',this.logMissionName);
        formData.append('logDescription',this.logMissionDescription);
        formData.append('logWeather',this.logWeather);
        formData.append('logNotes',this.logNotes);
        formData.append('is_vimana_flight',this.is_vmn_flight);
        formData.append('seconds',String(Math.floor(this.seconds)));
        var res = await this.master.submitFlightLog(formData);
        console.log(res);
        this.fb.logEvent(this.fb.EVENTS.EVENT_NAME_COMPLETED_TUTORIAL);
        this.goback();
    }

    endLog(){
      this.submitLog();
    }

  categorySelected(c){
    console.log(c);
    this.categoryIndex = c;
    this.cat_selected = [this.categories[c].pk];
   }

  nextClicked(){
    this.stepIndex += 1;
    console.log(this.stepIndex);
  }

  backClicked(){
    this.stepIndex += -1;
    console.log(this.stepIndex);
  }

}
