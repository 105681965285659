import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { MasterService } from '../../services/master.service';
import { ActivatedRoute } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { filter } from 'rxjs/operators';



import { LicencesComponent } from '../licences/licences.component';

  




@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {
constructor(
    private master: MasterService,
    private router: Router,
    public toastController: ToastController,
    private route:ActivatedRoute,
    public modalController: ModalController,

  	) { }
       async presentModal(component){
    const modal = await this.modalController.create({
      component: component,
      cssClass: 'modal-fullscreen',
    });

    modal.onDidDismiss().then((data) => {
      console.log(data);
      // this.ref.detectChanges();

      // this.zone.run(() => {
      //   console.log('force update the screen');
      // });

      this.ngOnInit();

    })


    return await modal.present();
  }

    dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true,
    });
  }	
  ngOnInit() {}
    openModal(typee){
      if(typee == 'privacy'){
        this.presentModal(PrivacyPolicyComponent);
        return 
      }
      if(typee == 'licences'){
        this.presentModal(LicencesComponent);
        return 
      }
    }

}
