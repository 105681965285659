import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-client-mission-list',
  templateUrl: './client-mission-list.component.html',
  styleUrls: ['./client-mission-list.component.scss'],
})
export class ClientMissionListComponent implements OnInit {

  constructor(
      private router: Router,

  	) { }


  ngOnInit() {}

    backClicked(){
     this.router.navigate(['/mission-tablinks/mission-discover']);
	}


}
