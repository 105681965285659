import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { PilotCreateGroupComponent } from '../pilot-create-group/pilot-create-group.component';
import { MasterService } from '../../services/master.service';
@Component({
  selector: 'app-pilot-groups',
  templateUrl: './pilot-groups.component.html',
  styleUrls: ['./pilot-groups.component.scss'],
})
export class PilotGroupsComponent implements OnInit {

  constructor(
    private router: Router,
    public actionSheetController: ActionSheetController,
    public modalController: ModalController,
    private master: MasterService) { }

  ngOnInit() {

    this.loadGroups();

  }

    backClicked(){
        this.router.navigate(['/pilot-tablinks/pilot-discover']);

  }

  my_groups : any;
  group_images: any;


  async loadGroups(){
    var res = await this.master.loadGroups();
    console.log(res);

    this.my_groups = JSON.parse(res.payload.groups)
    this.group_images = this.master.mapURLs(this.my_groups.map(x => x.fields.media_fg))
    console.log(this.my_groups);
  }
  goToGroup(group){
    this.router.navigate(['/pilot-one-group/',group.fields.unique_id]);
  }

    async presentActionSheet(event) {
      event.stopPropagation();
    const actionSheet = await this.actionSheetController.create({
      header: 'Options',
      cssClass: 'my-custom-class',
      buttons: [{
        text: 'Report this group',
        role: 'destructive',
        icon: 'alert-circle-outline',
        handler: () => {
          console.log('Report clicked');
        }
      }, {
        text: 'Leave this group',
        icon: 'eye-off-outline',
        handler: () => {
          console.log('Hide clicked');
        }
      },{
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }]
    });
    await actionSheet.present();

    const { role } = await actionSheet.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }


   async presentCreateGroupModal() {
    const modal = await this.modalController.create({
      component: PilotCreateGroupComponent,
      cssClass: 'my-custom-class'
    });
    return await modal.present();
  }

}
