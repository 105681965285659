import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { FormControl,FormGroup,Validators } from '@angular/forms';
import { MasterService } from '../../services/master.service'
import { VimanaProfile } from '../../interfaces'
import { ToastController } from '@ionic/angular';


@Component({
  selector: 'app-client-edit-profile',
  templateUrl: './client-edit-profile.component.html',
  styleUrls: ['./client-edit-profile.component.scss'],
})
export class ClientEditProfileComponent implements OnInit {

	now_editing = false;
  profileEditForm: FormGroup;
 	profile: any;

  toSendProfile: VimanaProfile = {
    };

  img_urls: any
  files_selected: any
  files: any

  image_url : any;

  image_changed = false;

  constructor(
  	  private route: Router,
    	private master: MasterService,
      public toastController: ToastController,
    	public modalController: ModalController,
    ) { }

  ngOnInit() {

      this.profileEditForm = new FormGroup(     {
        'userName': new FormControl("",[Validators.required]),
        'userEmail': new FormControl("",[Validators.required,Validators.email]),
        'userPhone': new FormControl("",[Validators.required,Validators.pattern('^[0-9]{10}$')]),
        'userAddress': new FormControl("",[Validators.required]),
      });

  	this.loadProfile();

  }



    dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true,
    });
  }	

    async loadProfile(){
  	var res = await this.master.getProfile();
  	this.profile = res;
  	console.log(res);

    this.image_url = this.master.mapURL(this.profile.fields.userPhoto);
    this.startEdit();


   }





      startEdit()
	  {  
      // console.log('check');
	    // this.now_editing = true;
	    this.profileEditForm = new FormGroup(     {
	      'userName': new FormControl(this.profile.fields.userName,[Validators.required]),
	      'userEmail': new FormControl(this.profile.fields.userEmail,[Validators.required,Validators.email]),
	      'userPhone': new FormControl(this.profile.fields.userPhone,Validators.compose([Validators.required,Validators.pattern('^[0-9]{10}$')])),
	      'userAddress': new FormControl(this.profile.fields.userAddress,[Validators.required]),
	    });
	  }

      async sendEditProfile(){
        console.log(this.profileEditForm);
        if(this.profileEditForm.status == 'INVALID'){
          this.presentToast("Errors in form");
          return 0;
        } 

          this.toSendProfile.userName  = this.profileEditForm.value.userName;
          this.toSendProfile.userEmail  = this.profileEditForm.value.userEmail;
          this.toSendProfile.userPhone  = this.profileEditForm.value.userPhone;
          this.toSendProfile.userAddress  = this.profileEditForm.value.userAddress;

          console.log(this.toSendProfile);

          var formData = new FormData();
  

          formData.append('userName',this.toSendProfile.userName );
          formData.append('userEmail',this.toSendProfile.userEmail);
          formData.append('userPhone', this.toSendProfile.userPhone);
          formData.append('userAddress',this.toSendProfile.userAddress);


          if(this.image_changed)
          {


          for (const file of this.files) {
                formData.append('userPhoto', file, file.name);
                console.log(formData);
            }

          }

          var result = await this.master.editProfile(formData);


          console.log(result);
          if(result==true)
          { 
            this.dismiss();
            this.loadProfile();
            this.ngOnInit();
          }
    }


  onFileSelected(event){
    let fileList: FileList = event.target.files;
    let fileListLength = fileList.length;
    this.files = fileList;
    this.img_urls = [];
    for (var i = 0; i < fileListLength; i++) {
      var a = fileList[i];
      var reader = new FileReader();
      reader.onload = (event:any) => {
        var url = event.target.result;
        this.img_urls.push(url); 
        this.image_url = url;
      }
      reader.readAsDataURL(a);
    }
    this.files_selected = true;
    this.image_changed = true;


  }

    backClicked(){
      this.route.navigate(['/mission-tablinks/mission-profile']);

  }


  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }  


}
