import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MasterService } from '../../services/master.service';
import { ToastController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-client-payments',
  templateUrl: './client-payments.component.html',
  styleUrls: ['./client-payments.component.scss'],
})
export class ClientPaymentsComponent implements OnInit {

  constructor(
    private router: Router,
    private master: MasterService,
    public toastController: ToastController,
    public modalController: ModalController,
    ) { }

  ngOnInit() {
    this.loadTransactions();

  }

      backClicked(){
      this.router.navigate(['/mission-tablinks/mission-profile']);

  }
  
  trans_ingress: any;
  trans_egress: any;

  async loadTransactions(){
    var res = await this.master.clientMyTransactions();

    console.log(res);
    this.trans_ingress = JSON.parse(res['payload']['vm_egress']);
    this.trans_egress = JSON.parse(res['payload']['vm_ingress']);

    console.log(this.trans_egress);
  }
  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }   
}
