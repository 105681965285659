import { Injectable } from '@angular/core';
import { VimanaUser } from  '../interfaces';
import { VmnService } from './vmn.service';
import { BehaviorSubject,Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http'; 

@Injectable({
  providedIn: 'root'
})
export class WeatherService {

  constructor(

    public httpClient:HttpClient ,
    private vmn: VmnService
      ) {}

 public weather_info = new BehaviorSubject({
    feels_like: "20",
    temp: "20",
    temp_min: "20",
    temp_max: "20",
    humidity: "50",
    wind: {
    	speed: '0',
    	deg: '0',
    	gust: '0'
    },
    weather: {
    	id: 0,
    	main: '',
    	description: '',
    	icon: ''
    },
    visibility: 0

  });

  public flying_status = new BehaviorSubject("BAD")


  KtoC(k){
    return (k - 273.15);
  }
  KtoF(k){
    return this.KtoC(k)*9/5 + 32;
  }
  async logCoords(lat,long){
    console.log("logging coords");
    return new Promise((resolve,reject)=>{
      this.vmn.logCoords(lat,long).subscribe(
        (dat) => {
          console.log(dat);
          resolve(dat);
        },
        (err) => {
          reject(err);
        }
      )
    })    
  }
  GetCurrentWeather(latitude,longitude){

    this.logCoords(latitude,longitude);

  	var url = `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&id=524901&appid=9417e07894b3fc43ab000b05d935771a`;
  	this.httpClient.get(url).subscribe((weather)=>{
      console.log(weather);


      var evel = 0;

      if(weather['wind']['speed'] == 0  ){
      	evel += 0;
      }
      else if(weather['wind']['speed'] <= 3 ){
        evel += 1;
      }
      else if(weather['wind']['speed'] <= 8 ){
        evel += 5;
      }
      else if(weather['wind']['speed'] < 8 ){
        evel += 25;
      }


      if(weather['visibility'] <= 600 ){
      	evel += 30;
      }
      else if(weather['visibility'] <= 1000 ){
        evel += 15;
      }
      else if(weather['visibility'] <= 2000 ){
        evel += 10;
      }
      else {
        evel += 0;
      }

      var date = new Date();
      var current_hour = date.getHours();

      if( current_hour > 7 || current_hour < 17 ){
        evel += 0
      }
      if( current_hour < 19 && current_hour > 17 ){
        evel += 10
      }

      if( current_hour < 7 || current_hour > 19 ){
        evel += 30
      }

      if(weather['weather'][0]['main']['main'] == 'Thunderstorm'){
        evel += 40;
      }
     if(weather['weather'][0]['main']['main'] == 'Drizzle'){
        evel += 25;
      }
     if(weather['weather'][0]['main']['main'] == 'Rain'){
        evel += 50;
      }
     if(weather['weather'][0]['main']['main'] == 'Snow'){
        evel += 50;
      }
     if(weather['weather'][0]['main']['main'] == 'Atmosphere'){
        evel += 15;
      }
     if(weather['weather'][0]['main']['main'] == 'Clear'){
        evel += 0;
      }
     if(weather['weather'][0]['main']['main'] == 'Clouds'){
        evel += 10;
      }

      if(evel < 5){
      	this.flying_status.next("GOOD")
      }
      else if(evel < 15){
      	this.flying_status.next("OK")
      }
      else if(evel < 30){
      	this.flying_status.next("NOT RECOMMENDED")
      }
      else{
      	this.flying_status.next("BAD")
      }

      this.weather_info.next({
        feels_like: this.KtoC(weather['main']['feels_like']).toFixed(1),
        temp: this.KtoC(weather['main']['temp']).toFixed(1),
        temp_min: this.KtoC(weather['main']['temp_min']).toFixed(1),
        temp_max: this.KtoC(weather['main']['temp_max']).toFixed(1),
        humidity: weather['main']['humidity'],
        wind: {
        	speed:  weather['wind']['speed'],
        	deg:  weather['wind']['deg'],
        	gust: weather['wind']['gust'],
        },
        weather: {
        	id: weather['weather'][0]['id'],
        	main: weather['weather'][0]['main'],
        	description: weather['weather'][0]['description'],
        	icon: weather['weather'][0]['icon'],
        },
        visibility: weather['visibility']
      })
  	})
  }


}