 import { Component,OnInit} from '@angular/core';
// import { Auth } from 'aws-amplify';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { FormControl,FormGroup,Validators} from '@angular/forms';
// import { FormControl } from '@angular/forms';
import { take,first } from 'rxjs/operators';
import { MasterService } from '../../services/master.service';

import { ActivatedRoute } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';

import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic";
import { Facebook, FacebookLoginResponse } from '@awesome-cordova-plugins/facebook/ngx';
import { ModalController } from '@ionic/angular';
import { MissionSignupComponent } from '../mission-signup/mission-signup.component';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';



@Component({
  selector: 'app-mission-login',
  templateUrl: './mission-login.component.html',
  styleUrls: ['./mission-login.component.scss'],
})
export class MissionLoginComponent implements OnInit {
  // userName: string = "test1";
  // userPassword: string = "Test1234";
  username: string = '';
  password: string = '';
  redirect: any;

  save_details = true;
  my_loading: any;

  constructor(
    private master: MasterService,
    private router: Router,
    public toastController: ToastController,
    public loadingController: LoadingController,
    private route:ActivatedRoute,
     public modalController: ModalController,
    private fb: Facebook
    ) { }



  ngOnInit() {

    // this.fb.login(['public_profile', 'user_friends', 'email'])
    // .then((res: FacebookLoginResponse) => console.log('Logged into Facebook!', res))
    // .catch(e => console.log('Error logging into Facebook', e));


    this.fb.logEvent(this.fb.EVENTS.EVENT_NAME_ACTIVATED_APP);
    console.log("FB LOGGIN")
    this.master.auth_ping.pipe(first()).subscribe((d)=>{
      console.log(d);
      this.authProceed();
    });
    this.route.queryParams.subscribe(
    (params) => {
      this.redirect = params['redirect'];
      if(this.redirect==undefined)
      {
        this.redirect = '/';
      }
    });
    this.attemptSavedLogin();
  }

  async attemptSavedLogin(){
    this.master.login("","",false);
  }
  async attemptLogin() {   
    var username = this.username;
    var password = this.password;
    this.presentLoading();

    var res = await this.master.login(username,password,this.save_details);
    console.log(res);
    this.my_loading.dismiss();

    if(res==true)
    {
      this.authProceed();
    }
    else{
      this.presentToast('invalid details');
    }
  }
  async authProceed(final = false)
  {
    console.log(this.master.auth_status);
    console.log(this.master.get_loginType());
    if(this.master.auth_status)
    {
      if(this.master.is_app()){
      this.initNotifications();    
        
      }
      if(this.redirect == '/')
      {
        this.fb.logEvent(this.fb.EVENTS.EVENT_NAME_ACTIVATED_APP);
        if(this.master.get_loginType()=='pilot'){
          this.router.navigate(['/pilot-tablinks']);
        }
        else if(this.master.get_loginType()=='client')
        {
          this.router.navigate(['/mission-tablinks/mission-dashboard']);
        }
        else{
          this.router.navigate(['/training-tablinks/training-profile']);          
        }
      }
      else{
        var res = await this.router.navigate([this.redirect]);
        console.log(res);
        if(res == false){
          this.redirect = '/';
          if(!final){
            this.authProceed(true);
          }
        }
      }
    }
    else{
      ;
    }
  }
  async presentLoading() {
    this.my_loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...',
      duration: 20000
    });
    await this.my_loading.present();

    const { role, data } = await this.my_loading.onDidDismiss();
    console.log('Loading dismissed!');
  }
  initNotifications(){

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {
        // alert('Push registration success, token: ' + token.value);

        console.log("TOKEN ")
        this.pushToken(token.value)
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        // alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        // alert('Push received: ' + JSON.stringify(notification));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        // alert('Push action performed: ' + JSON.stringify(notification));
      }
    );
  }
  async signupModal(){

      const modal = await this.modalController.create({
        component: MissionSignupComponent,
        cssClass: 'modal-fullscreen',
        componentProps: {
        }
      });

      modal.onDidDismiss().then((data) => {
        console.log(data);
        // this.ref.detectChanges();

        // this.zone.run(() => {
        //   console.log('force update the screen');
        // });

        this.ngOnInit();

      })


      return await modal.present();

  }

  async pushToken(toke){
    console.log("toke",toke)
    var res = await this.master.addNotificationToken(toke);
    console.log("toketoke",res)
  }
  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }  
}
