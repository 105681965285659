import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { MasterService } from '../../services/master.service'
import { GoogleMapsAPIWrapper } from '@agm/core';
import { MapsAPILoader, AgmMap } from '@agm/core';

import { ToastController } from '@ionic/angular';
import { giveMissionStatusMessage } from '../../services/status'
@Component({
  selector: 'app-client-mission-timeline',
  templateUrl: './client-mission-timeline.component.html',
  styleUrls: ['./client-mission-timeline.component.scss'],
})
export class ClientMissionTimelineComponent implements OnInit {

  loading = true;


  mission_id: any;
  mission_details: any;
  _actions: any;
  _profiles: any;
  accepted_profiles: any;
  standby_profiles: any;
  assigned_profiles: any;

  _pilots: any;
  accepted_pilots: any;
  standby_pilots: any;
  assigned_pilots: any;


  _contracts : any;
  _contract_tasks : any;
  _crumbs : any;
  _crumb_files: any;

  _status: any;


  timeline_tasks = [];


  cm1: any;
  cm2: any;


  constructor(
    private router: Router,
    private route:ActivatedRoute,
    private master: MasterService,
    public toastController: ToastController,
  ) {
    this.mission_id = this.route.snapshot.paramMap.get('id');
    console.log(this.mission_id);   
   }
  ngOnInit() {
    this.loadMisison();
  }

   ionViewWillEnter(){
     this.ngOnInit();
   }

  backClicked(){
     this.goToMission();
  }
  goToMission(){
    this.router.navigate(['/client-single-mission/',this.mission_id]);
  }
  async loadMisison(){
    this.loading = true;
    var res : any = await this.master.readOneClientMissionDetails(this.mission_id);
    console.log(res);
    this.mission_details = JSON.parse(res.payload.mission)[0];
    this._actions = JSON.parse(res.payload['actions']);
    this._profiles = JSON.parse(res.payload['profiles']);
    this._pilots = JSON.parse(res.payload['pilots']);
    this._status = JSON.parse(res.payload['status'])[0];
    this._contracts = JSON.parse(res.payload['contracts']);
    
    this._contract_tasks = JSON.parse(res.payload['tasks']);
    this._crumbs = JSON.parse(res.payload['crumbs']);
    this._crumb_files = this.master.mapURLs(this._crumbs.map(x=> x.fields.crumb_file));

    this.timeline_tasks = [];


    if (this._contract_tasks.length > 0){

      this._contract_tasks = this._contract_tasks.map((x: any) => JSON.parse(x));
      this._contract_tasks = this._contract_tasks[0];
    }

    var msg = giveMissionStatusMessage(this._status);

    this.cm1 = msg[0];
    this.cm2 = msg[1];

    console.log(this.mission_details)
    console.log(this._status)
    console.log(this._actions)
    console.log(this._profiles)
    console.log(this._contracts)
    console.log(this._contract_tasks)
    console.log(this._crumbs)
    console.log(this._crumb_files)

    // for (var i = 0; i < this._actions.length; i++) {
    //   // this._actions[i]
    //   this.timeline_tasks.push(true)
    // }
    for (var i = 0; i < this._contract_tasks.length; i++) {
      // this._actions[i]
      if(this._contract_tasks[i].fields.subintent == 'pilot'){
        continue;
      }
      this.timeline_tasks.push(true)
    }

    for (var i = 0; i < this._crumbs.length; i++) {
      // this._actions[i]
      this.timeline_tasks.push(true)
    }
      this.timeline_tasks.pop()
      // this.timeline_tasks.pop()


    this.accepted_profiles = []
    this.standby_profiles = []
    this.assigned_profiles = []


    this.accepted_pilots = []
    this.standby_pilots = []
    this.assigned_pilots = []

    for (var i = 0 ; i < this._profiles.length; i++) {
      if(this._actions[i].fields.action == 'accept'){
       this.accepted_profiles.push(this._profiles[i])
       this.accepted_pilots.push(this._pilots[i])

      }
      if(this._actions[i].fields.action == 'stand-by'){
       this.standby_profiles.push(this._profiles[i])
       this.standby_pilots.push(this._pilots[i])
      }
      if(this._actions[i].fields.action == 'accept-mission-contract'){
       this.assigned_profiles.push(this._profiles[i])
       this.assigned_pilots.push(this._pilots[i])
      }
    }
    console.log("ACCEPTED PROFILES")
    console.log(this.accepted_profiles)
    console.log(this.accepted_pilots)

    console.log("STANDBY PROFILES")
    console.log(this.standby_profiles)
    console.log(this.standby_pilots)
    
    console.log("ASSIGNED PROFILES")
    console.log(this.assigned_profiles)
    console.log(this.assigned_pilots)



  }


  numeric(percent_string){
    const regex = /\b\d+(?:%|percent\b)/;
    var match_array = percent_string.match(regex);

    if(match_array.length > 0){
      return parseFloat(match_array[0].split('%')[0])/100;
    }
    else{
      return 0.0
    }
  }

  trimStringRev(string, length) {
    if(string){

    return string.length > length
      ?  "..." +string.substring(string.length - length, string.length)
      : string;
    }
    else 
      return ''
  }

}
