import { Component, OnInit,EventEmitter,Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MasterService } from '../../services/master.service'


@Component({
  selector: 'app-skillator',
  templateUrl: './skillator.component.html',
  styleUrls: ['./skillator.component.scss'],
})
export class SkillatorComponent implements OnInit {

  skills: any;
  added_skill: any = [];


  @Input() given_skills: any;


  constructor(
    private master: MasterService,
    public modalController: ModalController,
    ) { }
  ngOnInit() {

    this.loadAllSkills();
  }

  async loadAllSkills(){

    var res = await this.master.readPilotSkills();

    console.log(res);
  
    this.skills = JSON.parse(res['payload']);


    if(this.given_skills){     
      for (var i = 0; i < this.given_skills.length; i++) {
        this.addSkill(this.skills[this.skills.map((x:any)=>{return x.pk}).indexOf(this.given_skills[i].pk)]);
      }
    }


    console.log(this.skills);
  }

  // async presentToast(msg) {
  //   const toast = await this.toastController.create({
  //     message: msg,
  //     duration: 2000
  //   });
  //   toast.present();
  // }  

  addSkill(skill){

    console.log(skill);
    console.log(this.skills);
    console.log(this.added_skill);

    if(this.added_skill.indexOf(skill) < 0){
      this.added_skill.push(skill);
    }
    else{
      this.removeSkill(skill);
    }

  }

  inSkill(skill){
    return this.added_skill.indexOf(skill);
  }
  
  removeSkill(skill){

    this.added_skill.splice(this.added_skill.indexOf(skill),1);

  }
  
  async manageSkills(){
    var sk_array = this.added_skill.map((x: any) => { return x.pk })
    console.log(sk_array)
    console.log(this.added_skill)
    sk_array = JSON.stringify(sk_array);

    // var res = await this.master.manageSkills(sk_array);
    // console.log(res); 
    this.dismiss(sk_array);
  }
  dismiss(sk_array: any) {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true,
      'sk_array': sk_array
    });
  } 
}
