import { Component, OnInit, Input,Output,ChangeDetectorRef,EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MasterService } from '../../services/master.service';
import { Router,NavigationEnd } from '@angular/router';
import { PilotOnePortfolioComponent } from '../pilot-one-portfolio/pilot-one-portfolio.component'

@Component({
  selector: 'app-pilot-portfolio-card',
  templateUrl: './pilot-portfolio-card.component.html',
  styleUrls: ['./pilot-portfolio-card.component.scss'],
})
export class PilotPortfolioCardComponent implements OnInit {

  @Input() portfolio_uuid: any;

  @Input() portfolio_data: any;
  @Input() profile_data: any;
  @Output() reopen_emitted = new EventEmitter<any>();


  @Output() doDisplay = new EventEmitter<boolean>();


  profile: any;
  profile_stats: any;
  statistics: any;
  status: any;
  verification: any;
  ranks: any;
  my_rank :any;
  my_rank_insignia :any;
  display_picture:any;
  skills: any;
  star_array = [];


  crumbs: any;
  media = [];

  constructor(
    private route: Router,
    private master: MasterService,
    public modalController: ModalController,
  ) { }

  ngOnInit() {
    console.log(this.portfolio_data)
    this.loadProject();
  }

  shorty(str: any){
    var res = str.split('/');
    return res[res.length - 1];
  }

  async loadProject(){
      var res = await this.master.getPilotProject(this.portfolio_uuid);
      if(res['result'] != 'true'){
       return 
      }
    this.profile = JSON.parse(res['payload']['profile'])[0];
    this.portfolio_data = JSON.parse(res['payload']['project'])[0];
    this.crumbs = JSON.parse(res['payload']['project_crumbs']);
    // this.statistics = JSON.parse(res['payload']['statistics']);

    console.log(this.profile);
    console.log(this.portfolio_data);

    this.media = [];
    this.display_picture = this.master.mapURL(this.profile.fields.userPhoto);

    if(this.portfolio_data.fields.is_public){
      this.doDisplay.emit(true);
    }else{
      this.doDisplay.emit(false);
    }

    for(var i = 0; i < this.crumbs.length; i++){
      var ciq = this.crumbs[i];
      if(this.isFileImage(ciq.fields.crumb_file)){
        var media_url = this.master.mapURL(ciq.fields.crumb_file)
        this.media.push(media_url)
      }
    }

    console.log(this.display_picture);
    console.log(this.crumbs);
  }
  isFileImage(file_url) {
      return(file_url.match(/\.(jpeg|jpg|JPG|JPEG|gif|png)$/) != null);
  }  

  async presentProjectModal(data){
      console.log(data);
      const modal = await this.modalController.create({
        component: PilotOnePortfolioComponent,
        cssClass: 'image-popup-modal-css',
        componentProps: { portfolio_uuid:  data.fields.unique_id}
      });

      modal.onDidDismiss().then((data) => {
        console.log(data);
        // this.ref.detectChanges();
        if(data.data.restart){
            this.reopen_emitted.emit(this.portfolio_data.fields.unique_id);
        }

        this.ngOnInit();

      })


      return await modal.present();
    }


}
