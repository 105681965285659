export const vertexShaderSurvey = `
uniform float time;
uniform vec2 resolution;
uniform float peak;
uniform sampler2D heightmap;

varying vec2 vUV;
varying vec4 hcolor;

uniform float flight_x;
uniform float flight_y;

float random (in vec2 st) {
    return fract(sin(dot(st.xy,
                         vec2(12.9898,78.233)))
                 * 43758.5453123);
}

// 2D Noise based on Morgan McGuire @morgan3d
// https://www.shadertoy.com/view/4dS3Wd
float noise (in vec2 st) {
    vec2 i = floor(st);
    vec2 f = fract(st);

    // Four corners in 2D of a tile
    float a = random(i);
    float b = random(i + vec2(1.0, 0.0));
    float c = random(i + vec2(0.0, 1.0));
    float d = random(i + vec2(1.0, 1.0));

    // Smooth Interpolation

    // Cubic Hermine Curve.  Same as SmoothStep()
    vec2 u = f*f*(3.0-2.0*f);
    // u = smoothstep(0.,1.,f);

    // Mix 4 coorners percentages
    return mix(a, b, u.x) +
            (c - a)* u.y * (1.0 - u.x) +
            (d - b) * u.x * u.y;
}



void main()    {
    vUV = uv;

    float xc = 0.1;
    float yc = 0.1;
    vec2 scaling = vec2(uv[0]*20.,uv[1]*20.);
    float h = noise(scaling);



    if(h > 0.5){
        hcolor = vec4(h,h,0,1);
    }else{
        hcolor = vec4(0,1.-h,1.-h,1);
    }

    float xerr = sqrt((uv[0]-flight_x)*(uv[0]-flight_x));
    bool x_inr = ( xerr < xc);
    float yerr = sqrt((uv[1]-flight_y)*(uv[1]-flight_y));
    bool y_inr = ( yerr < yc);
    vec3 new_position;
    new_position = vec3(position[0],h*100.,position[2]);
   
    if(x_inr && y_inr)
    {
        float red = mod(xerr*100.,10.)/10.;
        float green = mod(yerr*100.,10.)/10.;        
        hcolor = vec4(red,green,1.-h,1);
    }

    gl_Position = projectionMatrix * modelViewMatrix * vec4( new_position, 1.0 );
    // gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}
`;

export const fragmentShaderSurvey = `
#define PI 3.1415926538

uniform float time;
uniform vec2 resolution;
uniform float peak;
uniform sampler2D heightmap;
uniform float flight_x;
uniform float flight_y;

varying vec2 vUV;
varying vec4 hcolor;


void main()    {

    //float telem = sin(mod(time,PI*2.));
    gl_FragColor = hcolor;
}
`;

export const vertexShaderVideography = `
varying vec2 vUv;

void main() {

    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

}
`;

export const fragmentShaderVideography = `
varying vec2 vUv;
uniform sampler2D tDiffuse;

void main() {

    gl_FragColor = texture2D( tDiffuse, vUv );

}
`;

export const vertexShaderTrack = `
#define PI 3.1415926538
uniform float time;
uniform vec2 resolution;
uniform float peak;
uniform sampler2D heightmap;

varying vec2 vUV;
varying vec4 hcolor;

uniform float flight_x;
uniform float flight_y;


void main()    {
    vUV = uv;

    float xc = 0.1;
    float yc = 0.1;

    float n_x = (uv[0] - 0.5)*2.;
    float n_y = (0.5 - uv[1])*2.;

    float functional_K = 3./5.;

    float r_polar = sqrt(n_x*n_x + n_y*n_y);
    float theta = atan(n_y,n_x);

    float err = 0.4;


    if( abs((r_polar - sin(functional_K*PI * theta)))  < err)
    // if( n_x  < err)
    {  
        hcolor = vec4(0.,0.0,0,1.);
    }else{
        hcolor = vec4(1.,.0,1.,1.);        
    }




    float red = mod(uv[0]*100.,10.);
    float green = mod(uv[1]*100.,10.);

    if(red < 0.01 || green  < 0.01) {
        hcolor = vec4(0.5,0.5,1.,1.);        
    }else{
        hcolor = vec4(0.7,0.7,0.7,1.);                
    }


    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}
`;

export const fragmentShaderTrack = `
#define PI 3.1415926538

uniform float time;
uniform vec2 resolution;
uniform float peak;
uniform sampler2D heightmap;
uniform float flight_x;
uniform float flight_y;

varying vec2 vUV;
varying vec4 hcolor;


void main()    {

    //float telem = sin(mod(time,PI*2.));
    gl_FragColor = hcolor;


}
`;


export const vertexShaderPlains = `
uniform float time;
uniform vec2 resolution;
uniform float peak;
uniform sampler2D heightmap;

varying vec2 vUV;
varying vec4 hcolor;

uniform float flight_x;
uniform float flight_y;

float random (in vec2 st) {
    return fract(sin(dot(st.xy,
                         vec2(12.9898,78.233)))
                 * 43758.5453123);
}

// 2D Noise based on Morgan McGuire @morgan3d
// https://www.shadertoy.com/view/4dS3Wd
float noise (in vec2 st) {
    vec2 i = floor(st);
    vec2 f = fract(st);

    // Four corners in 2D of a tile
    float a = random(i);
    float b = random(i + vec2(1.0, 0.0));
    float c = random(i + vec2(0.0, 1.0));
    float d = random(i + vec2(1.0, 1.0));

    // Smooth Interpolation

    // Cubic Hermine Curve.  Same as SmoothStep()
    vec2 u = f*f*(3.0-2.0*f);
    // u = smoothstep(0.,1.,f);

    // Mix 4 coorners percentages
    return mix(a, b, u.x) +
            (c - a)* u.y * (1.0 - u.x) +
            (d - b) * u.x * u.y;
}



void main()    {
    vUV = uv;

    float xc = 0.1;
    float yc = 0.1;
    vec2 scaling = vec2(uv[0]*2.,uv[1]*2.);
    float h = noise(scaling);



    if(h > 0.5){
        hcolor = vec4(h,h,0,1);
    }else{
        hcolor = vec4(0,1.-h,1.-h,1);
    }

    float xerr = sqrt((uv[0]-flight_x)*(uv[0]-flight_x));
    bool x_inr = ( xerr < xc);
    float yerr = sqrt((uv[1]-flight_y)*(uv[1]-flight_y));
    bool y_inr = ( yerr < yc);
    vec3 new_position;
    new_position = vec3(position[0],position[1],position[2]);
   
    if(x_inr && y_inr)
    {
        float red = mod(xerr*100.,10.)/10.;
        float green = mod(yerr*100.,10.)/10.;        
        hcolor = vec4(red,green,1.-h,1);
    }

    gl_Position = projectionMatrix * modelViewMatrix * vec4( new_position, 1.0 );
    // gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}
`;

export const fragmentShaderPlains = `
#define PI 3.1415926538

uniform float time;
uniform vec2 resolution;
uniform float peak;
uniform sampler2D heightmap;
uniform float flight_x;
uniform float flight_y;

varying vec2 vUV;
varying vec4 hcolor;


void main()    {

    //float telem = sin(mod(time,PI*2.));
    gl_FragColor = hcolor;
}
`;
export const vertexShaderMountains = `
uniform float time;
uniform vec2 resolution;
uniform float peak;
uniform sampler2D heightmap;

varying vec2 vUV;
varying vec4 hcolor;

uniform float flight_x;
uniform float flight_y;

float random (in vec2 st) {
    return fract(sin(dot(st.xy,
                         vec2(12.9898,78.233)))
                 * 43758.5453123);
}

// 2D Noise based on Morgan McGuire @morgan3d
// https://www.shadertoy.com/view/4dS3Wd
float noise (in vec2 st) {
    vec2 i = floor(st);
    vec2 f = fract(st);

    // Four corners in 2D of a tile
    float a = random(i);
    float b = random(i + vec2(1.0, 0.0));
    float c = random(i + vec2(0.0, 1.0));
    float d = random(i + vec2(1.0, 1.0));

    // Smooth Interpolation

    // Cubic Hermine Curve.  Same as SmoothStep()
    vec2 u = f*f*(3.0-2.0*f);
    // u = smoothstep(0.,1.,f);

    // Mix 4 coorners percentages
    return mix(a, b, u.x) +
            (c - a)* u.y * (1.0 - u.x) +
            (d - b) * u.x * u.y;
}



void main()    {
    vUV = uv;

    float xc = 0.1;
    float yc = 0.1;
    vec2 scaling = vec2(uv[0]*10.,uv[1]*10.);
    float h = noise(scaling);



    if(h > 0.5){
        hcolor = vec4(h,h,0,1);
    }else{
        hcolor = vec4(0,1.-h,1.-h,1);
    }

    float xerr = sqrt((uv[0]-flight_x)*(uv[0]-flight_x));
    bool x_inr = ( xerr < xc);
    float yerr = sqrt((uv[1]-flight_y)*(uv[1]-flight_y));
    bool y_inr = ( yerr < yc);
    vec3 new_position;
    new_position = vec3(position[0],-40. + h*100.,position[2]);
   
    if(x_inr && y_inr)
    {
        float red = mod(xerr*100.,10.)/10.;
        float green = mod(yerr*100.,10.)/10.;        
        hcolor = vec4(red,green,1.-h,1);
    }

    gl_Position = projectionMatrix * modelViewMatrix * vec4( new_position, 1.0 );
    // gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}
`;

export const fragmentShaderMountains = `
#define PI 3.1415926538

uniform float time;
uniform vec2 resolution;
uniform float peak;
uniform sampler2D heightmap;
uniform float flight_x;
uniform float flight_y;

varying vec2 vUV;
varying vec4 hcolor;


void main()    {

    //float telem = sin(mod(time,PI*2.));
    gl_FragColor = hcolor;
}
`;


export const vertexShaderNoop = `
uniform float time;
uniform vec2 resolution;
uniform float peak;
uniform sampler2D heightmap;

varying vec2 vUV;
varying vec4 hcolor;

uniform float flight_x;
uniform float flight_y;

void main()    {

    gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
}
`;
export const fragmentShaderNoop = `
#define PI 3.1415926538

uniform float time;
uniform vec2 resolution;
uniform float peak;
uniform sampler2D heightmap;
uniform float flight_x;
uniform float flight_y;

varying vec2 vUV;
varying vec4 hcolor;


void main()    {
    gl_FragColor = vec4(1.,1.,1.,1);
}
`;