import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ActivatedRoute } from '@angular/router';
import { MasterService } from '../../services/master.service'



import { ModalController } from '@ionic/angular';

import { EnrollModal } from './enroll/enroll.component';

import { Facebook, FacebookLoginResponse } from '@awesome-cordova-plugins/facebook/ngx';



@Component({
  selector: 'app-training-course',
  templateUrl: './training-course.component.html',
  styleUrls: ['./training-course.component.scss'],
})
export class TrainingCourseComponent implements OnInit {




  constructor(
    private route:ActivatedRoute,
    private router:Router,
    public modalController: ModalController,
  	private master: MasterService,
    private fb: Facebook

  	) { }

  batch_id: any;



  batch: any;
  school: any;
  course: any;

  badges: any;
  skills:any;

  badge_imgs: any;
  skill_imgs: any;

  badge_dict : any;
  skill_dict : any;

  COURSEID = '#'

  ngOnInit() {
    this.fb.logEvent(this.fb.EVENTS.EVENT_NAME_VIEWED_CONTENT);

    this.batch_id = this.route.snapshot.paramMap.get('id');
    console.log(this.batch_id);
    this.loadBatchDetails();
  	this.loadBatches();
  	this.loadSkills();
    this.loadProfile();
    this.loadEligibilities();
   }

   profile: any;


   el_dict: any;

  async loadEligibilities(){
    
    var res = await this.master.readEligibilities();
  
    this.el_dict = {

    }

    var eligi = JSON.parse(res['payload']);
    console.log(eligi);
    for (var i = 0; i < eligi.length; ++i) {
      var eli = eligi[i]
      this.el_dict[eli.pk] = eli;
    }
  }
  async loadProfile(){

    console.log("PROFILE")
    var res = await this.master.getProfile();

    console.log(res);

    this.profile = res;
  }
  async loadBatches(){

  	var res = await this.master.readPilotBadges();
  	console.log(res);
  	this.badges = JSON.parse(res['payload'])
  	console.log(this.badges)

  	this.badge_dict = {};
  	this.badge_imgs = this.master.mapURLs(this.badges.map((x:any ) =>{
  		return x.fields.imagefile
  	}))

  	console.log(this.badge_imgs)

  	for (var i = this.badges.length - 1; i >= 0; i--) {
  		this.badge_dict[this.badges[i].pk] = this.badge_imgs[i]
  	}

  	console.log(this.badge_dict)
  }
  async loadSkills(){
  	var res = await this.master.readPilotSkills();
  	console.log(res);
  	this.skills = JSON.parse(res['payload'])
  	console.log(this.skills)

  	this.skill_dict = {};
  	this.skill_imgs = this.master.mapURLs(this.skills.map((x:any ) =>{
  		return x.fields.imagefile
  	}))

  	console.log(this.skill_imgs)
  	for (var i = this.skills.length - 1; i >= 0; i--) {
  		this.skill_dict[this.skills[i].pk] = this.skill_imgs[i]
  	}
  	console.log(this.skill_dict)


  }
  DISC = 0;
  TOTAL_PRICE = 0;

  async loadBatchDetails(){
  	var res = await this.master.readOneBatch(this.batch_id);

  	console.log(res);

  	this.batch = JSON.parse(res['payload']['batch'])[0];
  	this.school = JSON.parse(res['payload']['school'])[0];
  	this.course = JSON.parse(res['payload']['course'])[0];

  	console.log(this.batch);
  	console.log(this.school);
  	console.log(this.course);

    var fees = this.batch.fields.batchFees;
    var discount = this.batch.fields.batchDiscount;

    this.DISC = Math.floor(100*discount/fees);
    this.TOTAL_PRICE = fees - discount;

    this.COURSEID = '#' + this.batch.fields.unique_id.slice(0,8)

  }
  


  async enrollModal(){

  

      const modal = await this.modalController.create({
        component: EnrollModal,
        cssClass: 'my-custom-class',
        componentProps: {
          batch_id: this.batch_id,
          profile: this.profile
        }
      });

      modal.onDidDismiss().then((data) => {
        console.log(data);
        // this.ref.detectChanges();

        // this.zone.run(() => {
        //   console.log('force update the screen');
        // });

        this.ngOnInit();

      })


      return await modal.present();

  }

  backClicked(){
    this.gotoSchool();
  }
  gotoSchool(){
    this.router.navigate(['/training-school',this.school.fields.unique_id]);
  }

}
