import { Component, OnInit,NgZone } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { MasterService } from '../../services/master.service'

import { ActivatedRoute } from '@angular/router';


import { ModalController } from '@ionic/angular';


import  { RazorPayment } from '../../interfaces';



import { WindowRefService } from '../../services/window-ref.service'

import { ToastController } from '@ionic/angular';
import { Facebook, FacebookLoginResponse } from '@awesome-cordova-plugins/facebook/ngx';

import { ContactComponent } from '../../common/contact/contact.component';



@Component({
  selector: 'app-pilot-trainings',
  templateUrl: './pilot-trainings.component.html',
  styleUrls: ['./pilot-trainings.component.scss'],
})
export class PilotTrainingsComponent implements OnInit {

  constructor(

    private route:ActivatedRoute,
    private router:Router,
    private master: MasterService,
    private zone: NgZone,
    private winRef: WindowRefService,
    public modalController: ModalController,
    public toastController: ToastController,
      private fb: Facebook
	) { }

  selectTabs = "shools";
  schools : any;
  school_imgs : any;


  actions: any;
  action_preferences: any;
  action_preferences_index: any;
  action_batches: any;
  action_courses: any;
  action_schools: any;




  trainings: any;
  training_batches: any;
  training_courses: any;
  training_schools: any;

  ngOnInit() {
    this.doRefresh();
    this.fb.logEvent(this.fb.EVENTS.EVENT_NAME_SEARCHED);
  }
  async doRefresh(){
    this.loadSchools();
    this.loadActions();
    this.loadTrainings();
    this.loadProfile();    
  }
  async refresher(event){
    this.doRefresh();
    event.target.complete();
  }

   profile: any;

  async loadProfile(){

    console.log("PROFILE")
    var res = await this.master.getProfile();

    console.log(res);

    this.profile = res;
  }

  async loadSchools(){
    var res = await this.master.readTrainingSchools();
    console.log(res);

    this.schools = JSON.parse(res['payload']);
    this.school_imgs = this.master.mapURLs(this.schools.map((x:any)=> {
      return x.fields.imagefile
    }));


    console.log(this.schools);
  }

  async loadTrainings(){
    var res = await this.master.loadPilotTrainings();

    console.log(res);

    this.trainings = JSON.parse(res['payload']['trainings']);
    this.training_batches = JSON.parse(res['payload']['batches']);
    this.training_courses = JSON.parse(res['payload']['courses']);
    this.training_schools = JSON.parse(res['payload']['schools']);

    console.log(this.trainings);
  

  }
  async loadActions(){
    var res = await this.master.loadPilotTrainingActions();

    console.log(res);

    this.actions = JSON.parse(res['payload']['actions']);
    this.action_preferences = this.actions.map((x: any) =>{
      return JSON.parse(x.fields.details);
    })
    this.action_preferences_index =  [];
    for (var i = 0; i < this.action_preferences.length; ++i) {
      var pi = []
      for (var j = 0; j < this.action_preferences[i].length; ++j) {
        if (j%2 ==0 ) {
          pi.push(j)
        }
      }
      console.log(pi);
      this.action_preferences_index.push(pi);
    }


    this.action_batches = JSON.parse(res['payload']['batches']);
    this.action_courses = JSON.parse(res['payload']['courses']);
    this.action_schools = JSON.parse(res['payload']['schools']);

    console.log(this.actions);
    console.log(this.action_preferences_index);


    

  }


   Pay_options = {
    "key": this.master.pay_key(), // Enter the Key ID generated from the Dashboard
    "amount": "", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    "currency": "INR",
    "name": "",
    "description": "",
    "image": "/assets/logo.png",
    "order_id": "", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    "handler": async (response) => {
      var payment_info = <RazorPayment> {};
      payment_info.razorpay_order_id = response.razorpay_order_id;
      payment_info.razorpay_payment_id = response.razorpay_payment_id;
      payment_info.razorpay_signature = response.razorpay_signature;
      var res = await this.master.submitPaymentInfo(payment_info);
      console.log(res);
      if(res['result']=='true'){
        this.zone.run(()=>{
        this.presentToast('order made!');
      // this.dismiss();
        });
      }
      else{
        this.zone.run(()=>{
        this.presentToast('error! please try in some time!');
        })
      }
    } ,
    "redirect": false,
    "prefill": {
        "name": "",
        "email": "",
        "contact": ""
    },
    "notes": {
        "none": "none"
    },
    "theme": {
        "color": "#073060"
    }
  };

  payWithRazor(order)
  {
        this.Pay_options.amount = order.amount_due;
        this.Pay_options.name = "VIMANA AeroTech Pvt. Ltd.";
        this.Pay_options.description = "Pay for your training";
        this.Pay_options.order_id = order.id;
        this.Pay_options.prefill.name = this.profile.userName;
        this.Pay_options.prefill.contact = this.profile.userPhone;
        console.log(this.Pay_options);
        const rzp = new this.winRef.nativeWindow.Razorpay(this.Pay_options);
        rzp.open();
  }

 



  async payNow(act){
    console.log(act);
    var res = await this.master.pilotIngressQuery(act.fields.unique_id.slice(0,8));
  
    var ingress = JSON.parse(res['payload']['ingress'])[0];



    console.log(ingress);

    var amt = parseFloat(ingress.fields.invoiceTotal)*100;

    var order = {
      amount_due: amt,
      id: ingress.fields.order_id
    }


    this.payWithRazor(order);
  }

 


  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }  



  backClicked(){
    console.log("hello")
    this.router.navigate(['/pilot-tablinks/pilot-profile']);

  }

   // gotoCourse(){
    // this.router.navigate(['/single-course']);
  // }

  gotoSchool(id){
    this.router.navigate(['/training-school',id]);
  }
  gotoCourse(bid){
    this.router.navigate(['/training-course',bid]);
  }

      async presentModal() {
    const modal = await this.modalController.create({
      component: ContactComponent,
      cssClass: 'my-custom-class'
    });
    return await modal.present();
  }

}
