import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { MasterService } from '../../services/master.service';
import { ActivatedRoute } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { SkillsFormComponent } from '../skills-form/skills-form.component';
import { SinglePortfolioProjectComponent } from '../single-portfolio-project/single-portfolio-project.component';
import { PilotPortfolioHelpComponent } from '../pilot-portfolio-help/pilot-portfolio-help.component';
import { ModalController } from '@ionic/angular';
import { filter } from 'rxjs/operators';
import { Facebook, FacebookLoginResponse } from '@awesome-cordova-plugins/facebook/ngx';



@Component({
  selector: 'app-pilot-portfolio',
  templateUrl: './pilot-portfolio.component.html',
  styleUrls: ['./pilot-portfolio.component.scss'],
})
export class PilotPortfolioComponent implements OnInit {
 
  selectTabs = "info";

  profile: any;
  profile_stats: any;
  statistics: any;
  status: any;
  verification: any;
  ranks: any;
  my_rank :any;
  my_rank_insignia :any;
  display_picture:any;

  star_array = [];

  modal: any;


  skills: any;


  public savedFEED: any;
  public postFEED: any;
  public activityFEED: any;
  static profile_subcriber: any;

  projects: any;

  ionViewWillEnter() {

    this.loadProjects();

  }
  
  constructor(        
  	 private cdr: ChangeDetectorRef,
  	 private route: Router,
  	 private master: MasterService,
     public modalController: ModalController,
      private fb: Facebook
     ) { }

  ngOnInit() {

    this.loadProfile();
    this.loadProjects();

  }
    async loadProjects(){
    var res = await this.master.loadPilotProjects();


    console.log(res);

    this.projects = JSON.parse(res['payload']['projects']);

    console.log(this.projects);

    }
    async loadProfile(){
  	var res = await this.master.getPilotProfile();


    console.log(res);

    this.profile = JSON.parse(res['payload']['profile'])[0];


    this.profile_stats = JSON.parse(res['payload']['profile_stats'])[0];
    this.statistics = JSON.parse(res['payload']['statistics']);
    this.status = res['payload']['status'];
    this.verification = JSON.parse(res['payload']['verification'])[0];
    this.ranks = JSON.parse(res['payload']['ranks']);
    this.skills = JSON.parse(res['payload']['skills']);

    this.my_rank = this.ranks[this.ranks.length -1]

    for (var i = this.ranks.length - 1; i >= 0; i--) {
      var this_rank = parseInt(this.ranks[i].fields.xp_cutoff);
      var my_rank = parseInt(this.statistics.xp)
      if (this_rank <= my_rank || i == 0){
        this.my_rank = this.ranks[i];
        break;
      }
      else{
        console.log(this_rank);
        console.log(my_rank);

      }
    }




    this.my_rank_insignia = this.master.mapURL(this.my_rank.fields.imagefile);

    this.display_picture = this.master.mapURL(this.profile.fields.userPhoto);

    console.log(res);
    console.log(this.my_rank);
    console.log(this.my_rank_insignia);
    console.log(this.display_picture)
    console.log(this.ranks);
    console.log(this.profile);
    // console.log(this.profile_stats);
    // console.log(this.statistics);
    console.log(this.status);
    // console.log(this.verification);
    // console.log(this.statistics["rating"]);

    this.star_array.length = this.statistics["rating"];
    this.star_array.fill(1);

    console.log(this.star_array);

    this.cdr.markForCheck();
   }

  backClicked(){
  	this.route.navigate(['/pilot-tablinks/pilot-profile']);

  }

  addProject(){
    this.route.navigate(['/create-portfolio-project']);

  }


 

  

    async presentSkillsModal(){
      const modal = await this.modalController.create({
        component: SkillsFormComponent,
        cssClass: 'my-custom-class',
      });

      modal.onDidDismiss().then((data) => {
        console.log(data);
        // this.ref.detectChanges();

        // this.zone.run(() => {
        //   console.log('force update the screen');
        // });
        this.fb.logEvent(this.fb.EVENTS.EVENT_NAME_UNLOCKED_ACHIEVEMENT);

        this.ngOnInit();

      })


      return await modal.present();
    }

    async PortfolioHelpComponent(){
      const modal = await this.modalController.create({
        component: PilotPortfolioHelpComponent,
        cssClass: 'portfolio-help-modal-css',
      });

      modal.onDidDismiss().then((data) => {
        console.log(data);
        // this.ref.detectChanges();

        // this.zone.run(() => {
        //   console.log('force update the screen');
        // });
        this.fb.logEvent(this.fb.EVENTS.EVENT_NAME_UNLOCKED_ACHIEVEMENT);

        this.ngOnInit();

      })


      return await modal.present();
    }


    async presentProjectModal(data){
      const modal = await this.modalController.create({
        component: SinglePortfolioProjectComponent,
        cssClass: 'image-popup-modal-css',
        componentProps: { data:  data}
      });

      modal.onDidDismiss().then((ret) => {
        console.log(ret);
        if(ret.data.restart){
          this.reopenProjectModal(data.fields.unique_id)
        }
        // this.ref.detectChanges();

        // this.zone.run(() => {
        //   console.log('force update the screen');
        // });
        this.fb.logEvent(this.fb.EVENTS.EVENT_NAME_ADDED_TO_WISHLIST);

        this.ngOnInit();

      })


      return await modal.present();
    }
    // uuid: any
    async reopenProjectModal(uuid){
      console.log("REPONEN",uuid)
      await this.loadProjects();

      for(var i = 0 ; i < this.projects.length; i ++){
        var piq = this.projects[i];
        if(piq.fields.unique_id == uuid){
          this.presentProjectModal(piq);
        }
      }
    }

}
