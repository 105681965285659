
import { Injectable } from '@angular/core';
import { CanActivate,Router,
		 ActivatedRouteSnapshot,
		 RouterStateSnapshot,
		 NavigationExtras } from '@angular/router';

import { Observable,of } from 'rxjs';
import { VmnService } from './vmn.service'


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private authService: VmnService, private router:Router) { }


	canActivate(route: ActivatedRouteSnapshot,
			state: RouterStateSnapshot):boolean{
	let url: string = state.url;
	return this.checkLogin(url);



	}
	checkLogin(url: string):boolean {
		if(this.authService.loginApproved) { return true;}

		this.authService.redirectUrl = url;

    // Set our navigation extras object
    // that contains our global query params and fragment
    let navigationExtras: NavigationExtras = {
      queryParams: { 'redirect':url },
      fragment: 'anchor'
    };

    // Navigate to the login page with extras
    this.router.navigate(['/login'], navigationExtras);
    return false;
	}
}

@Injectable({
  providedIn: 'root'
})
export class AntiAuthGuardService {

  constructor(private authService: VmnService, private router:Router) { }


	canActivate(route: ActivatedRouteSnapshot,
			state: RouterStateSnapshot):boolean{
	let url: string = state.url;
	return this.checkLogin(url);



	}
	checkLogin(url: string):boolean {
		if(this.authService.loginApproved)
		{
			return false;
		}
    	return true;
	}
}

@Injectable({
  providedIn: 'root'
})
export class PilotGuardService {

  constructor(private authService: VmnService, private router:Router) { }


	canActivate(route: ActivatedRouteSnapshot,
			state: RouterStateSnapshot):boolean{
	let url: string = state.url;
	return this.checkPilot(url);



	}
	checkPilot(url: string):boolean {
		console.log("ckcecking");
		if(this.authService.loginApproved && this.authService.loginType=="pilot" )
		  { return true;}

		this.authService.redirectUrl = url;

    // Set our navigation extras object
    // that contains our global query params and fragment
    let navigationExtras: NavigationExtras = {
      queryParams: { 'redirect':url },
      fragment: 'anchor'
    };

    // Navigate to the login page with extras
    this.router.navigate(['/login'], navigationExtras);
    return false;
	}

}

@Injectable({
  providedIn: 'root'
})
export class ClientGuardService {

  constructor(private authService: VmnService, private router:Router) { }


	canActivate(route: ActivatedRouteSnapshot,
			state: RouterStateSnapshot):boolean{
	let url: string = state.url;
	return this.checkClient(url);



	}
	checkClient(url: string):boolean {
		if(this.authService.loginApproved && this.authService.loginType=="client") 
			{ return true;}

		this.authService.redirectUrl = url;

    // Set our navigation extras object
    // that contains our global query params and fragment
    let navigationExtras: NavigationExtras = {
      queryParams: { 'redirect':url },
      fragment: 'anchor'
    };

    // Navigate to the login page with extras
    this.router.navigate(['/login'], navigationExtras);
    return false;
	}

}

@Injectable({
  providedIn: 'root'
})
export class RPTOGuardService {

  constructor(private authService: VmnService, private router:Router) { }


	canActivate(route: ActivatedRouteSnapshot,
			state: RouterStateSnapshot):boolean{
	let url: string = state.url;
	return this.checkClient(url);



	}
	checkClient(url: string):boolean {
		if(this.authService.loginApproved && this.authService.loginType=="rpto") 
			{ return true;}

		this.authService.redirectUrl = url;

    // Set our navigation extras object
    // that contains our global query params and fragment
    let navigationExtras: NavigationExtras = {
      queryParams: { 'redirect':url },
      fragment: 'anchor'
    };

    // Navigate to the login page with extras
    this.router.navigate(['/login'], navigationExtras);
    return false;
	}

}