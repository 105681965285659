import * as THREE from 'three';
import { Water } from 'three/examples/jsm/objects/Water.js';
import { Sky } from 'three/examples/jsm/objects/Sky.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { fragmentShaderMountains,vertexShaderMountains } from '../shaders';
import { fragmentShaderPlains,vertexShaderPlains } from '../shaders';
import { fragmentShaderNoop,vertexShaderNoop } from '../shaders';

export function get_monitoring_scene(renderer,n_turbines = 4,terrain = 'water'){
	   console.log("><MONITORING SCENE><");

	var xScale = 1.0;
	var yScale = 1.0;
	var zScale = 1.0;

    // ground planes

	var geometry = new THREE.BoxGeometry( 10, 1, 10 );
	var material = new THREE.MeshNormalMaterial();

	var texture = THREE.ImageUtils.loadTexture('/assets/eco.png');
	// texture.anisotropy = this.renderer.getMaxAnisotropy();
	// texture.offset.x = 0.5; // 0.0 - 1.0
	// texture.offset.y = 0.5; // 0.0 - 1.0
	texture.repeat.set(1, 1);


	var imageMaterial = 	    new THREE.MeshBasicMaterial({
	        map: texture //left
	    });

	// LRTBFB
	var groundMaterial = [
	    new THREE.MeshNormalMaterial({
	    }),
	    new THREE.MeshNormalMaterial({
	    }),
	    new THREE.MeshNormalMaterial({
	    }),
	    imageMaterial,
	    new THREE.MeshNormalMaterial({
	    }),
	    new THREE.MeshNormalMaterial({
	    }),
	    new THREE.MeshNormalMaterial({
	    })
	];



	var mesh = new THREE.Mesh( geometry, groundMaterial );

    // this.object = this.mesh;

    // mesh.rotation.set(this.xRot,this.yRot,this.zRot);
    // mesh.scale.set(this.xScale,this.yScale,this.zScale);

    var middle = new THREE.Vector3();
    // var geometry = this.geometry;

    geometry.computeBoundingBox();

    middle.x = (geometry.boundingBox.max.x + geometry.boundingBox.min.x) / 2;
    middle.y = (geometry.boundingBox.max.y + geometry.boundingBox.min.y) / 2;
    middle.z = (geometry.boundingBox.max.z + geometry.boundingBox.min.z) / 2;
    // console.log(middle);
    mesh.translateX(-1*middle.x*xScale);
    mesh.translateY(-1*middle.y*yScale);
    mesh.translateZ(-1*middle.z*zScale);


    var myScene = new THREE.Scene();






    myScene.add( mesh );


    var myAnimation = function(self: any) {
	  try{
		self.orbitControls.update();
		self.camera.lookAt( self.scene.position );
		self.renderer.render( self.scene, self.camera );
	  }
	  catch{
	  	;
	  }
	}

    return [ myScene , myAnimation  ]
}