import { Component, OnInit } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { MasterService } from '../../services/master.service';
import { GoogleMapsAPIWrapper } from '@agm/core';
import { MapsAPILoader, AgmMap } from '@agm/core';
import { PopoverController } from '@ionic/angular';


import { LocationInterface,Marker } from '../../interfaces';
import { ToastController } from '@ionic/angular';


import { giveMissionStatusMessage } from '../../services/status';

@Component({
  selector: 'app-client-single-mission',
  templateUrl: './client-single-mission.component.html',
  styleUrls: ['./client-single-mission.component.scss'],
})
export class ClientSingleMissionComponent implements OnInit {

  mission_id: any;

  mission: any;
  status: any;


  mission_breif_1 = '';
  mission_breif_2 = '';

    public location:LocationInterface = {
    lat: 23.152790,
    lng: 79.947327,
    marker: {
      lat: 23.152790,
      lng: 79.947327,
      draggable: false
    },
    zoom: 9
  };

  map_loaded =false;

  loading = true;

  constructor(
    public actionSheetController: ActionSheetController,
    private router: Router,
    private route:ActivatedRoute,
    private master: MasterService,
    public toastController: ToastController,
    public popoverController: PopoverController  
  ) { }

  ngOnInit() {
    this.mission_id = this.route.snapshot.paramMap.get('id');
    console.log(this.mission_id);   

  }
   ionViewWillEnter(){
     this.ngOnInit();
   }

  backClicked(){
     this.router.navigate(['/mission-tablinks/mission-activities']);
  }

  ngAfterViewInit(){
    this.loadMisison();    
  }
  toggle_status(event){
    console.log(event.detail.checked);
    if(event.detail.checked){
      this.activate_mission();
    }
    else{
      this.cancel_mission();
    }
  }
  async activate_mission(){
    this.loading = true;
    var res = await this.master.activateMission(this.mission_id,'activate');

    await this.loadMisison();
    this.loading = false;

    console.log(res)
  }
  async cancel_mission(){
    this.loading = true;
    var res = await this.master.activateMission(this.mission_id,'cancel');

    await this.loadMisison();
    this.loading = false;

    console.log(res)
  }
  async loadMisison(){
    this.loading = true;
    var res = await this.master.readOneClientMission(this.mission_id);
    console.log(res);
    this.mission = JSON.parse(res['payload']['mission'])[0];
    this.status = JSON.parse(res['payload']['status'])[0];
    console.log(this.mission);
    console.log(this.status);
   this.location.lat = parseFloat(this.mission.fields.missionLat);
    this.location.lng = parseFloat(this.mission.fields.missionLon);


    this.location.marker.lat = parseFloat(this.mission.fields.missionLat);
    this.location.marker.lng = parseFloat(this.mission.fields.missionLon);
 
    this.map_loaded = true;
    this.loading = false;
    this.makeMissionBreif();
  }
  makeMissionBreif(){
    // var is_accepted = this.mission.fields.is_accepted;
    // var is_assigned = this.mission.fields.is_assigned;
    // var is_finished = this.mission.fields.is_finished;
    // var is_ongoing = this.mission.fields.is_ongoing;
    // var is_remitted = this.mission.fields.is_remitted;
    // var is_started = this.mission.fields.is_started;
    console.log(this.status);

    var res = giveMissionStatusMessage(this.status);

    this.mission_breif_1 = res[0];
    this.mission_breif_2 = res[1];

    
  }
  goToMissionTimeline(){
    this.router.navigate(['/client-mission-timeline/',this.mission_id]);
  }
  
}
