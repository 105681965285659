import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { FormControl,FormGroup,Validators } from '@angular/forms';
import { MasterService } from '../../services/master.service'
import { VimanaProfile } from '../../interfaces'
import { ToastController } from '@ionic/angular';




@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit {

	now_editing = false;
  profileEditForm: FormGroup;
 	profile: any;

  toSendProfile: VimanaProfile = {
    };

  img_urls: any
  files_selected: any
  files: any

  image_url : any;

  image_changed = false;


  pilot_profile: any;

  my_languages: any;

  constructor(
  	  private route: Router,
    	private master: MasterService,
      public toastController: ToastController,
    	public modalController: ModalController,
    ) { }

  ngOnInit() {

      this.profileEditForm = new FormGroup(     {
        'userName': new FormControl("",[Validators.required]),
        'userEmail': new FormControl("",[Validators.required,Validators.email]),
        'userPhone': new FormControl("",[Validators.required,Validators.pattern('^[0-9]{10}$')]),
        'userAddress': new FormControl("",[Validators.required]),
      });

  	this.load();

  }




  async load(){
    await this.loadProfile();
    await this.loadLanguages();
    await this.loadPilotProfile();
  }
  all_languages: any;
  added_language: any = [];

  async loadLanguages(){
    var res = await this.master.readLanguages();
    this.all_languages = JSON.parse(res['payload']);
    console.log(this.all_languages)
  }
  async loadProfile(){
  var res = await this.master.getProfile();
  this.profile = res;
  console.log(res);

  this.image_url = this.master.mapURL(this.profile.fields.userPhoto);
  this.startEdit();
 }
  async loadPilotProfile(){
  var res = await this.master.getPilotProfile();
  this.pilot_profile = JSON.parse(res['payload']['profile_stats'])[0];
  console.log(this.pilot_profile);

  this.added_language = [];

  this.my_languages = this.pilot_profile.fields.language;
  console.log(this.my_languages);

  for (var i = 0; i < this.my_languages.length; i++) {
    this.addLanguage(this.all_languages[this.all_languages.map((x:any)=>{return x.pk}).indexOf(this.my_languages[i])]);
  }


  // this.image_url = this.master.mapURL(this.profile.fields.userPhoto);
  // this.startEdit();
 }



  addLanguage(language){

    console.log(language);
    console.log(this.all_languages);
    console.log(this.added_language);

    if(this.added_language.indexOf(language) < 0){
      this.added_language.push(language);
    }
    else{
      this.removeLang(language);
    }

  }


  inLang(lan){
    return this.added_language.indexOf(lan);
  }
  
  removeLang(lan){

    this.added_language.splice(this.added_language.indexOf(lan),1);

  }

  async manageLaunguages(){
    var lang_array = this.added_language.map((x: any) => { return x.pk })
    console.log(lang_array)
    console.log(this.added_language)
    lang_array = JSON.stringify(lang_array);

    var res = await this.master.manageLanguage(lang_array);
    console.log(res); 
    this.loadPilotProfile();
    this.presentmsg();
  }

      startEdit()
	  {  
      // console.log('check');
	    // this.now_editing = true;
	    this.profileEditForm = new FormGroup(     {
	      'userName': new FormControl(this.profile.fields.userName,[Validators.required]),
	      'userEmail': new FormControl(this.profile.fields.userEmail,[Validators.required,Validators.email]),
	      'userPhone': new FormControl(this.profile.fields.userPhone,Validators.compose([Validators.required,Validators.pattern('^[0-9]{10}$')])),
	      'userAddress': new FormControl(this.profile.fields.userAddress,[Validators.required]),
	    });
	  }

      async sendEditProfile(){
        console.log(this.profileEditForm);
        if(this.profileEditForm.status == 'INVALID'){
          this.presentToast("Errors in form");
          return 0;
        } 

          this.toSendProfile.userName  = this.profileEditForm.value.userName;
          this.toSendProfile.userEmail  = this.profileEditForm.value.userEmail;
          this.toSendProfile.userPhone  = this.profileEditForm.value.userPhone;
          this.toSendProfile.userAddress  = this.profileEditForm.value.userAddress;

          console.log(this.toSendProfile);

          var formData = new FormData();
  

          formData.append('userName',this.toSendProfile.userName );
          formData.append('userEmail',this.toSendProfile.userEmail);
          formData.append('userPhone', this.toSendProfile.userPhone);
          formData.append('userAddress',this.toSendProfile.userAddress);


          if(this.image_changed)
          {


          for (const file of this.files) {
                formData.append('userPhoto', file, file.name);
                console.log(formData);
            }

          }

          var result = await this.master.editProfile(formData);


          console.log(result);
          if(result==true)
          { 
            this.dismiss();
            this.loadProfile();
            this.ngOnInit();
          }

          this.presentmsg();
    }


  onFileSelected(event){
    let fileList: FileList = event.target.files;
    let fileListLength = fileList.length;
    this.files = fileList;
    this.img_urls = [];
    for (var i = 0; i < fileListLength; i++) {
      var a = fileList[i];
      var reader = new FileReader();
      reader.onload = (event:any) => {
        var url = event.target.result;
        this.img_urls.push(url); 
        this.image_url = url;
      }
      reader.readAsDataURL(a);
    }
    this.files_selected = true;
    this.image_changed = true;


  }

    async presentmsg() {
    const toast = await this.toastController.create({
      message: 'Saved',
      duration: 1500,
    });

    await toast.present();
  }


  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }  

    dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true,
    });
  }

}
