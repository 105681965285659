import { Component, OnInit } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { MasterService } from '../../services/master.service'
import { GoogleMapsAPIWrapper } from '@agm/core';
import { MapsAPILoader, AgmMap } from '@agm/core';
import { PopoverController } from '@ionic/angular';

import { LocationInterface,Marker } from '../../interfaces';
import { ToastController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';

import { giveMissionStatusMessage,giveMyMissionActionMessage } from '../../services/status';
import { parseMission } from '../../services/status';
import { UploadJob } from '../../interfaces'

@Component({
  selector: 'app-pilot-mission-control-dashboard',
  templateUrl: './pilot-mission-control-dashboard.component.html',
  styleUrls: ['./pilot-mission-control-dashboard.component.scss'],
})
export class PilotMissionControlDashboardComponent implements OnInit {


  mission_id: any;
  mission: any;
  super_status: any;
  my_actions: any;
  mission_cats: any;
  status_actions: any;

  cat_dict = {
  };

  map_loaded = false;

  current_status = 'Open';
  starred = false;

  contract: any;

  latest_mission_action: any;
  latest_mission_star: any;

  interactive = true;
  contract_found = false;
  contract_accepted = false;

  mission_flight_over = false;
  mission_contract: any;
  mission_tasks: any;

  mission_tasks_intents: any;
  mission_intent_tasks: any;
  mission_tasks_subintents: any;
  offered_contract = false;


  my_loading: any;


  crumbs: any;
  crumb_files: any;



  public location:LocationInterface = {
    lat: 23.152790,
    lng: 79.947327,
    marker: {
      lat: 23.152790,
      lng: 79.947327,
      draggable: false
    },
    zoom: 9
  };


  mission_accepted = false;
  mission_standby = false;
  mission_cancellled = false;
  mission_star = false;

  mission_open = true;
  mission_stby = false;
  mission_noop = false;
  mission_ctrt = false;


  ro_date: any = false;

  cm_1 : any = '..';
  cm_2 : any = '..';



  makeCardMessage(){

    //OVVERRIDES
    //ACTIONS
    //MESSAGES

    var msg = parseMission(this.mission,this.super_status,this.status_actions);
    console.log(msg);


    var last_msg = (x: any, b: any) => {
      x = x.filter((y: any) => {
        return y != '';
      })
      return x[x.length - b];
    }

 

    // var sm_res = giveMissionStatusMessage(this.status);

    // this.current_status = sm_res[0];
    // this.sub_status = sm_res[1];






    // console.log(this.current_status);
    // console.log(this.sub_status);
    // console.log(this.noninteractive_message);
    // console.log(res2);


    this.mission_open = msg[0][0];
    this.mission_stby = msg[0][1];
    this.mission_noop = msg[0][2];
    this.mission_ctrt = msg[0][3];



    this.mission_accepted = msg[1][0];
    this.mission_standby = msg[1][1];
    this.mission_cancellled = msg[1][2];
    this.mission_star = msg[1][3];

    this.cm_1 = last_msg(msg[2],1);
    this.cm_2 = last_msg(msg[2],2);

    this.ro_date = msg[3][0];


  }  


  
  constructor(
    public actionSheetController: ActionSheetController,
    private router: Router,
    private route:ActivatedRoute,
    private master: MasterService,
    public toastController: ToastController,
    public popoverController: PopoverController,
    public loadingController: LoadingController
  	) {
    this.mission_id = this.route.snapshot.paramMap.get('id');
    console.log(this.mission_id);
  	 }
  shorty(str: any){
    var res = str.split('/');

    return res[res.length - 1];
  }
  ngOnInit() {
    this.loadMisison();
    this.loadJobs();
  }
  async loadMisison(){
    var res = await this.master.readPilotOneMission(this.mission_id);
    console.log(res);
    this.mission = JSON.parse(res['payload']['mission'])[0];
    this.my_actions = JSON.parse(res['payload']['my-actions']);
    this.super_status =  JSON.parse(res['payload']['status'])[0];
    this.status_actions = JSON.parse(res['payload']['status-actions'])[0];
    this.status_actions = this.status_actions.map((x: any) => {
      return JSON.parse(x);
    })

    this.contract = JSON.parse(res['payload']['contract']);
    this.crumbs = JSON.parse(res['payload']['crumbs']);
    // this.crumb_files = this.master.mapURLsBucket(this.crumbs.map(x=> x.fields.extern_file));
    this.crumb_files = this.master.mapURLs(this.crumbs.map(x=> x.fields.crumb_file));

    console.log(this.mission);
    console.log(this.super_status);
    console.log(this.my_actions);
    console.log(this.contract);
    console.log(this.crumbs);

    var res2 = await this.master.readMissionCategories();

    this.makeCardMessage();
    this.loadMisisonContract();

    // if(this.contract.length != 0 ){
    // 	this.contract_found = true;
    // }

    if(this.contract.length == 1 && this.contract[0].fields.is_accepted){
    	this.contract_accepted = true;
    }

    console.log(this.contract_found);
    console.log(this.contract_accepted);

    var startdate = new Date(this.mission.fields.missionStartDate)
    var now = Date.now();
    var diff  = startdate.getTime() - now ;

    console.log(diff);

    if(diff < 0){
      this.interactive = false;
    }

    console.log(res2);

    this.mission_cats = JSON.parse(res2['payload']);

    for (var i = 0; i < this.mission_cats.length; i++) {
      this.cat_dict[this.mission_cats[i].pk] = this.mission_cats[i].fields.name;
    }

    this.location.lat = parseFloat(this.mission.fields.missionLat);
    this.location.lng = parseFloat(this.mission.fields.missionLon);


    this.location.marker.lat = parseFloat(this.mission.fields.missionLat);
    this.location.marker.lng = parseFloat(this.mission.fields.missionLon);
    
    this.map_loaded = true;

    this.latest_mission_action = this.my_actions.filter((x) => x.fields.action!='star' && x.fields.action!='unstar' )[0]
    this.latest_mission_star= this.my_actions.filter((x) => x.fields.action=='star' || x.fields.action=='unstar' )[0]

    console.log("latest_mission_action");
    console.log(this.latest_mission_action);
    console.log(this.latest_mission_star);



    if(this.latest_mission_star && this.latest_mission_star.fields.action == 'star'){
      this.latest_mission_star = true;
    }
    else{
      this.latest_mission_star = false;
    }

    var status_res = giveMyMissionActionMessage(this.latest_mission_action);
    this.current_status = status_res
  }

  async deleteCrumb(c_index: any){
    var crumb = this.crumbs[c_index]

    var res = await this.master.pilotDeleteMissionCrumbFile(crumb.fields.unique_id)

    if(res['result']=='true'){
      this.ngOnInit();
    }
  }
     backClicked(){
        this.router.navigate(['/pilot-accepted-mission-list']);
  }
  gotoLogging(){
        this.router.navigate(['/start-flight-logs', {ivf : 'true'}]);    
  }

  async loadMisisonContract(){
    console.log(this.mission)
  	var res = await this.master.pilotReadMissionContract(this.mission.fields.unique_id);
  	console.log(res);

  	this.mission_contract = JSON.parse(res['payload']['contract']);
  	this.mission_tasks = JSON.parse(res['payload']['tasks']);
  	this.mission_tasks_intents = JSON.parse(res['payload']['intents']);

  	console.log(this.mission_contract);
  	console.log(this.contract);
  	console.log(this.mission_tasks);
  	console.log(this.mission_tasks_intents);
  	this.mission_intent_tasks = {};
  	for (var i = 0; i < this.mission_tasks_intents.length ; i++) {
  		this.mission_intent_tasks[this.mission_tasks_intents[i]] = [];
  	}
  	for (var i = 0 ; i <  this.mission_tasks.length; i++) {
  		this.mission_intent_tasks[this.mission_tasks[i].fields.intent].push(this.mission_tasks[i]);
  	}

  	console.log(this.mission_intent_tasks);

  	if(this.mission_contract[0].fields.is_landed == true){
  		this.mission_flight_over = true;
  	}

  }
  async doneTaskItem(task_id){


  	var res = await this.master.pilotCheckMissionTask(task_id)

  	console.log(res);
  	if(res['result']=='true'){
  		this.loadMisisonContract();
  	}
  }

  async acceptMissionContract(CID){
  	console.log('dsdsd');
    console.log(CID)
  	var res = await this.master.pilotAcceptMissionContract(CID);
  	console.log(res);
  	if(res['result']=='true'){
  		this.ngOnInit();
  	}
  }
  async pokeMission(poke){
    // if(this.interactive==false){
    //   this.presentToast("MISSION REGISTRATION CLOSED!")
    //   return;
    // }

    var res = await this.master.pilotPokeMission(this.mission_id,poke);
    console.log(res);
    if(res['result']=="true" && poke=='mission-complete'){
      this.presentToast("COMPLETED! +1000 XP")
    }
    if(res['result']=="true" && poke=='accept'){
      this.presentToast("ACCEPTED! +10 XP")
    }
    if(res['result']=="true" && poke=='stand-by'){
      this.presentToast("ON STANDBY! +5 XP")
    }
    if(res['result']=="true" ){
      this.loadMisison();
    } else{
      this.presentToast(res['error']);

    }
  }

  getFileFormData()
  {
  var formData = new FormData();
  if( this.file_list.length <= 0)
    return formData
    formData.append('file_count', this.file_list.length);

    for (var i = 0; i < this.file_list.length; i++) {
      var upload_image = this.file_list[i]
      formData.append('file#' + i.toString() , upload_image, upload_image.name);
    }

  return formData;
  }

  indicateSuccess(){
    this.pokeMission('mission-complete');

  }


  caption = '';
  img_urls: any
  files_selected: any
  files: any
  file_list: any = [];

  onFileSelected(event){
    console.log("FILE SELECTED")
    let fileList: FileList = event.target.files;
    let fileListLength = fileList.length;
    this.files = fileList;
    this.file_list = fileList;
    console.log("FILELIST",fileList)
    this.img_urls = [];
    for (var i = 0; i < fileListLength; i++) {
      console.log("FILE DATA",i)
      var a = fileList[i];
      var reader = new FileReader();
      reader.onload = (event:any) => {
        var url = event.target.result;
        this.img_urls.push(url); 
      }
      reader.readAsDataURL(a);
    }
    this.files_selected = true;

  }

  loading_jobs: any;


  async uploadCrumb(){

      this.presentLoading();
      var fileData = this.getFileFormData();
      fileData.append('mission_uuid',this.mission.fields.unique_id);
      // this.presentLoading();


      var res_upload =  this.master.pilotCreateCrumb(fileData);
      // console.log(res_upload);
      // if(res_upload=='true')
      // {
      //   this.my_loading.dismiss();
      //   console.log("FILE UPLOAD SUCCESS")
      // }

      // console.log(res_upload); 
      this.loadJobs();   
  }



  async presentLoading() {
    this.my_loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...',
      duration: 1000
    });
    await this.my_loading.present();

    const { role, data } = await this.my_loading.onDidDismiss();
    console.log('Loading dismissed!');
  }



  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }  
  job_count = 0;
  upload_jobs: UploadJob[];

  prev_done = 0;


  async loadJobs(){

    this.upload_jobs = [];
    // this.job_count = 0;

    // this.done_refresh = true;

    var res = this.master.getCurrentJobs();
    this.upload_jobs = res[0];

    this.job_count = this.upload_jobs.length;


    

    var jobs_done = this.upload_jobs.filter((x: any) => {
      return x.jobDone;
    }).length

    console.log(jobs_done,this.prev_done);
    // if(jobs_done != this.prev_done){
    //     this.scroller.refresh();
    // }
    // else{
    //   this.prev_done = jobs_done;
    // }


    // setTimeout( this.loadJobs , 1000);
  }
  printJobs(){
    console.log(this.upload_jobs)
    console.log(this.job_count)
  }
  finishedJobs(){
    var jobs_done = this.upload_jobs.filter((x: any) => {
      return x.jobDone;
    }).length
    return jobs_done    
  }

}
