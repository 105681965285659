import { Injectable } from '@angular/core';
import { VimanaUser } from  '../interfaces';
import { VmnService,VPaymentService } from './vmn.service';
import { BehaviorSubject,Subject } from 'rxjs';
import { WeatherService } from './weather.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Contact} from '../interfaces';

import { HttpEventType } from '@angular/common/http';



import { UploadJob } from '../interfaces'

import { HeartbeatService } from './heartbeat.service';
import { of } from 'rxjs'


@Injectable({
  providedIn: 'root'
})
export class MasterService {

	public auth_ping = new Subject();
	public auth_status = false;



	otp_sent_subj = new BehaviorSubject(false);
	otp_verified_subj = new BehaviorSubject(false);

	otp_session_detail = new BehaviorSubject('');
	load_feed_page_subj = new BehaviorSubject(1);

	location_subj = new BehaviorSubject({
		lat: 0,
		lon: 0
	});



	upload_jobs: UploadJob[];
	// upload_percents = [];

	// download_jobs = [];
	// download_percents = [];




  constructor(
  	private vmn :VmnService,
		private payment: VPaymentService,
  	private weather: WeatherService,
    public geolocation:Geolocation ,
    public heartbeat:HeartbeatService ,
  	) { 
  	this.vmn.auth_subject.subscribe((d)=>{
			console.log(d);
			if(d=="approved")
			{
			this.auth_status = true;
			}
			this.auth_ping.next(d);
		});

  	this.captureLocation();

  	this.upload_jobs = [];
  	// this.upload_percents = [];

  	// this.download_jobs = [];
  	// this.download_percents = [];
  	
  	}

  	getCurrentJobs(){
  		return [ this.upload_jobs ]
  	}
  	captureLocation(){
    var loc_options = {
       timeout: 20000
    }
  	 this.geolocation.getCurrentPosition(loc_options).then((position)=>{
  		var latitude = position.coords.latitude;
  		var longitude = position.coords.longitude;


      this.location_subj.next({
        lat: latitude,
        lon: longitude
      })


  	})

  	this.location_subj.subscribe(
  		(dat) =>{
  			this.weather.GetCurrentWeather(dat.lat,dat.lon);
  		}
  		)  		
  	}
	get_loginType(){
		return this.vmn.loginType;
	}
	pay_key(){
		return this.vmn.pay_key();
	}
	is_app(){
		return this.vmn.isApp;
	}

    save_preferences(preferences){
    	this.vmn.preferences = preferences;
    	this.vmn.save_preferences_cookie();
    }
    get_preferences(){
    	this.vmn.load_preferences_cookie();
    	return this.vmn.preferences;
    }

    // trySavedLogin(){
    // 	this.vmn.Login();
    // }
	async login(name = '',pass = '',save_details=false)
	{
		var testu:VimanaUser = 
		{
		username: name,
		password: pass
		};
		return new Promise(async (resolve,reject)=>{
			var res = await this.vmn.Login(testu,save_details);
			resolve(res);
		});
	}

	async logout(){
		console.log("attempting logout")
		this.vmn.Logout();
		this.auth_status = false;
		this.auth_ping.next('fail');
	}

	saved_profile: any;

	async getId(){
		if(!this.saved_profile){
			await this.getProfile();
		}
		return this.saved_profile.fields.unique_id;
	}

	async getProfile(){
		console.log("getting profile");
		return new Promise((resolve,reject)=>{
			this.vmn.getProfile().subscribe(
						(dat) => {
							console.log(dat);
							if(dat)
							{
							var profile = JSON.parse(dat["profile"])[0];
							this.saved_profile = profile;
							resolve(profile);
							}
							else{
								resolve(dat);
							}
						},
						(err) => {
							reject(err);
						}
					)
		})
	}

	async getPilotProfile(){
		console.log("getting profile");
		return new Promise((resolve,reject)=>{
			this.vmn.getPilotProfile().subscribe(
						(dat) => {
							resolve(dat);
						},
						(err) => {
							reject(err);
						}
					)
		})
	}
	async getPilotProject(uuid: any){
		console.log("getting project");
		return new Promise((resolve,reject)=>{
			this.vmn.getPilotProject(uuid).subscribe(
						(dat) => {
							resolve(dat);
						},
						(err) => {
							reject(err);
						}
					)
		})
	}
	async pilotMyTransactions(){
		console.log("getting transactions");
		return new Promise((resolve,reject)=>{
			this.vmn.pilotMyTransactions().subscribe(
						(dat) => {
							resolve(dat);
						},
						(err) => {
							reject(err);
						}
					)
		})
	}

	async clientMyTransactions(){
		console.log("getting transactions");
		return new Promise((resolve,reject)=>{
			this.vmn.clientMyTransactions().subscribe(
						(dat) => {
							resolve(dat);
						},
						(err) => {
							reject(err);
						}
					)
		})
	}

		async editProfile(profile_data){
		console.log("getting profile");
		return new Promise((resolve,reject)=>{
			this.vmn.editProfile(profile_data).subscribe(
						(dat) => {
							if(dat['result']=='true')
							{
							resolve(true);
							}
							else{
								resolve(false);
							}
						},
						(err) => {
							reject(err);
						}
					)
		})
	}
	
	async makeOTP(mobile): Promise<any>
	{
		console.log("making otp");
		return new Promise((resolve,reject)=>{
				this.vmn.sendOTP(mobile).subscribe(
					(dat)=>{
						if(dat['result']=='true'){
						this.otp_session_detail.next(dat['otp-session']);
						}
						resolve(dat);
					},
					(err) => {
						resolve(err);
					}
				)	
		});	
	}

	async verifyOTP(otp): Promise<any>
	{
		console.log("veryfying otp");
		return new Promise((resolve,reject)=>{
				var otp_session = this.otp_session_detail.value;
				this.vmn.verifyOTP(otp,otp_session).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});	
	}
	async verifyREFCODE(code): Promise<any>
	{
		console.log("veryfying otp");
		return new Promise((resolve,reject)=>{
				var otp_session = this.otp_session_detail.value;
				this.vmn.verifyREFCODE(code).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});	
	}

	async signUp(user)
	{
		console.log("signup user");
		return new Promise((resolve,reject)=>{
				this.vmn.newUser(user).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						resolve(err);
					}
				)	
		});			
	}

	async clientGetEstimate(cats,suppli)
	{
		console.log("getting estimate");
		return new Promise((resolve,reject)=>{
				this.vmn.clientGetEstimate(cats,suppli).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}
	async createMisison(mission)
	{
		console.log("creating mission");
		return new Promise((resolve,reject)=>{
				this.vmn.createMission(mission).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}

	async readClientMissions()
	{
		console.log("reading mission");
		return new Promise((resolve,reject)=>{
				this.vmn.readClientMissions().subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}
	async activateMission(uuid,action='activate'){
		console.log("activate mission");
		return new Promise((resolve,reject)=>{
				this.vmn.activateMission(uuid,action).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});		

	}
	async readOneClientMission(mission_id)
	{
		console.log("reading mission");
		return new Promise((resolve,reject)=>{
				this.vmn.readOneClientMission(mission_id).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}
	async readOneClientMissionDetails(mission_id)
	{
		console.log("reading mission details");
		return new Promise((resolve,reject)=>{
				this.vmn.readOneClientMissionDetails(mission_id).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}
	async editPilotProfile(preferences){
		console.log("editing pilot profile");
		return new Promise((resolve,reject)=>{
			this.vmn.editPilotProfile(preferences).subscribe(
						(dat) => {
							resolve(dat);
						},
						(err) => {
							reject(err);
						}
					)
		})
	}
	async readPilotMissionFeed()
	{
		console.log("reading mission");
		return new Promise((resolve,reject)=>{
				this.vmn.readPilotMissionFeed().subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}
	async readPilotMissions()
	{
		console.log("reading mission");
		return new Promise((resolve,reject)=>{
				this.vmn.readPilotMissions().subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}
	async readPilotOneMission(misison_id)
	{
		console.log("reading mission");
		return new Promise((resolve,reject)=>{
				this.vmn.readOneMission(misison_id).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}
	async pilotPokeMission(misison_id,poke)
	{
		console.log("poking mission");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotPokeMission(misison_id,poke).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}
	async pilotPokeTraining(uuid,type_of,poke,context)
	{
		console.log("poking training");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotPokeTraining(uuid,type_of,poke,context).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}
	async pilotAcceptMissionContract(contract_id)
	{
		console.log("accepting mission contract");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotAcceptMissionContract(contract_id).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}
	async pilotReadMissionContract(contract_id)
	{
		console.log("reading mission contract");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotReadMissionContract(contract_id).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}
	async pilotCheckMissionTask(task_id)
	{
		console.log("check mission task");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotCheckMissionTask(task_id).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}
	async pilotUploadVerificationImage(v_id,description,formdata)
	{
		console.log("uploading verifiacation image");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotUploadImageFile(v_id,description,formdata).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}
	async pilotUploadDroneImage(formdata)
	{
		console.log("uploading dorne image via formdata");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotUploadDroneImage(formdata).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}
	async pilotDeleteVerificationImage(image_id)
	{
		console.log("deleting verifiacation image");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotDeleteImageFile(image_id).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}


	saved_categories: any = undefined;

	async readMissionCategories(cache = true)
	{
		console.log("reading misison categories");
		if(cache){

		return new Promise((resolve,reject)=>{
				this.vmn.readCategories().subscribe(
					(dat)=>{
						resolve(dat);
						this.saved_categories = dat;
					},
					(err) => {
						reject(err);
					}
				)	
		});			
		}else{
			return of(this.saved_categories).toPromise();
		}
	}
	async readVIMANAProjects(){
		
		console.log("reading pilot skills");
		return new Promise((resolve,reject)=>{
				this.vmn.readAllProjects().subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}
	async readPilotSkills()
	{
		console.log("reading pilot skills");
		return new Promise((resolve,reject)=>{
				this.vmn.readSkills().subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}
	async readLanguages()
	{
		console.log("reading pilot languages");
		return new Promise((resolve,reject)=>{
				this.vmn.readLanguages().subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}
	async readPilotBadges()
	{
		console.log("reading pilot badges");
		return new Promise((resolve,reject)=>{
				this.vmn.readBadges().subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}
	async readRptoBatches(uuid)
	{
		console.log("reading rpto batches");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotReadRptoBatches(uuid).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}
	async readOneBatch(uuid)
	{
		console.log("reading one batch");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotReadOneBatch(uuid).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}
	async readOneTraining(uuid)
	{
		console.log("reading one trainings");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotReadOneTraining(uuid).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}
	async readTrainingSchools()
	{
		console.log("reading training schools");
		return new Promise((resolve,reject)=>{
				this.vmn.readSchools().subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}
	async readDroneCategories()
	{
		console.log("reading drone categories");
		return new Promise((resolve,reject)=>{
				this.vmn.readDroneCategories().subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}
	async readDroneModels()
	{
		console.log("reading drone models");
		return new Promise((resolve,reject)=>{
				this.vmn.readDroneModels().subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}
	async readMyDrones()
	{
		console.log("reading my drones");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotReadMyDrones().subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}
	async readUAVModels()
	{
		console.log("reading uav models");
		return new Promise((resolve,reject)=>{
				this.vmn.readUAVModels().subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}
	async readEligibilities()
	{
		console.log("reading training eligibilities");
		return new Promise((resolve,reject)=>{
				this.vmn.readEligibilities().subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}
	async readLeaderboard()
	{
		console.log("read leaderboard");
		return new Promise((resolve,reject)=>{
				this.vmn.readLeaderboard().subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}
	async readInsignia()
	{
		console.log("read insignia");
		return new Promise((resolve,reject)=>{
				this.vmn.readInsignia().subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}
    async ResetPasswordOTP(mobile){
		return new Promise(async (resolve,reject)=>{
		this.vmn.resetPasswordOTP(mobile).subscribe(
			(dat)=>{
				if(dat['result']=='true')
				{
					resolve(dat);
				}
				else{
					resolve(dat);
				}
			}
			)
		});
    }
   async ResetPasswordVerify(otp,session){
		return new Promise(async (resolve,reject)=>{
		this.vmn.resetPasswordVerify(otp,session).subscribe(
			(dat)=>{
				if(dat['result']=='true')
				{
					resolve(dat);
				}
				else{
					resolve(dat);
				}
			}
			)
		});
    }
   async ResetPasswordSet(otp,session,uname,pass){
		return new Promise(async (resolve,reject)=>{
		this.vmn.resetPasswordSet(otp,session,uname,pass).subscribe(
			(dat)=>{
				if(dat['result']=='true')
				{
					resolve(dat);
				}
				else{
					resolve(dat);
				}
			}
			)
		});
    }

  

  progress = 0;


	async makePost(post_data):Promise<any>
	{
		console.log("making post");
		var now = new Date();
		var new_job = <UploadJob>{
			startTime : now.toISOString(),
			endTime: 'pending',
			jobName: 'Post Upload',
			jobSize: '0.0',
			jobDone: false,
			jobProgress: '0.0'
		}
		this.upload_jobs.push(new_job)
		var index_this_job = this.upload_jobs.length - 1;

		return new Promise((resolve,reject)=>{
			this.vmn.pilotCreatePost(post_data).subscribe(
				(event : any) => {
					if (event.type === HttpEventType.UploadProgress) {
					console.log("upload progress");
					var percent_done = String((event.loaded / event.total * 100.00).toFixed(2));			        		
					this.upload_jobs[index_this_job].jobProgress = percent_done;
					// if(percent_done == '100.00'){
					// }
					console.log(percent_done);
					}
					if (event.type === HttpEventType.DownloadProgress) {
					console.log("download progress");
					console.log(event);
					}
					if (event.type === HttpEventType.Response) {
					console.log("donwload completed");
					this.upload_jobs[index_this_job].jobDone = true;
					this.heartbeat.newDiscoveryPost();
					resolve(event);
					}
			});	
		});
		
	}
	async makeGroupPost(post_data,group_id):Promise<any>
	{
		console.log("making group post");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotCreateGroupPost(post_data,group_id).subscribe(
					(dat)=>{
						var res = dat["result"];
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async updatePost(post_data):Promise<any>
	{
		console.log("updating post");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotUpdatePost(post_data).subscribe(
					(dat)=>{
						var res = dat["result"];
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}

	async deletePost(post_id):Promise<any>
	{
		console.log("deleting post");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotDeletePost(post_id).subscribe(
					(dat)=>{
						var res = dat["result"];
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async deletePostMedia(post_id,media_id):Promise<any>
	{
		console.log("deleting post media");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotDeletePostMedia(post_id,media_id).subscribe(
					(dat)=>{
						var res = dat["result"];
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async makeComment(post_id,comment):Promise<any>
	{
		console.log("updating post");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotCreateComment(post_id,comment).subscribe(
					(dat)=>{
						var res = dat["result"];
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async editComment(comment_id,comment):Promise<any>
	{
		console.log("updating post");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotEditComment(comment_id,comment).subscribe(
					(dat)=>{
						var res = dat["result"];
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async deleteComment(comment_id):Promise<any>
	{
		console.log("updating post");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotDeleteComment(comment_id).subscribe(
					(dat)=>{
						var res = dat["result"];
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}

	async likeItem(uuid,item_type):Promise<any>
	{
		console.log("updating post");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotLikeItem(uuid,item_type).subscribe(
					(dat)=>{
						var res = dat["result"];
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async socialMiscActivity(uuid: any, item_type: any, activity: any):Promise<any>
	{
		console.log("misc social activity");
		return new Promise((resolve,reject)=>{
				this.vmn.socialMiscActivity(uuid,item_type,activity).subscribe(
					(dat)=>{
						var res = dat["result"];
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}


	async unlikeItem(uuid,item_type):Promise<any>
	{
		console.log("updating post");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotUnlikeItem(uuid,item_type).subscribe(
					(dat)=>{
						var res = dat["result"];
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async bookmarkItem(uuid,item_type):Promise<any>
	{
		console.log("updating post");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotBookmarkItem(uuid,item_type).subscribe(
					(dat)=>{
						var res = dat["result"];
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}


	async unbookmarkItem(uuid,item_type):Promise<any>
	{
		console.log("updating post");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotUnbookmarkItem(uuid,item_type).subscribe(
					(dat)=>{
						var res = dat["result"];
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}

	async loadSocialActivity(uuid):Promise<any>
	{
		console.log("loading social activity");
		return new Promise((resolve,reject)=>{
				this.vmn.loadSocialActivity(uuid).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
 

	async pilotFeed():Promise<any>
	{
		console.log("pilot feed");

		var page_val = this.load_feed_page_subj.getValue();
		return new Promise((resolve,reject)=>{
				this.vmn.pilotSocialFeed(page_val).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async userBookmarked():Promise<any>
	{
		console.log("user bookmarked");

		var page_val = this.load_feed_page_subj.getValue();
		return new Promise((resolve,reject)=>{
				this.vmn.loadMySavedPosts(page_val).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		})
	}
	async userPosted(): Promise<any>
	{
		console.log("user posts");

		var page_val = this.load_feed_page_subj.getValue();
		return new Promise((resolve,reject)=>{
				this.vmn.loadMySocialPosts(page_val).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});
	}
	async userActivity():Promise<any>
	{
		console.log("user activity feed");

		var page_val = this.load_feed_page_subj.getValue();
		return new Promise((resolve,reject)=>{
				this.vmn.loadMySocialActivity(page_val).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});
	}
	async pilotNotifications():Promise<any>
	{
		console.log("loading notifications");

		var page_val = this.load_feed_page_subj.getValue();
		return new Promise((resolve,reject)=>{
				this.vmn.pilotSocialNotifications().subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async pilotXPEvents():Promise<any>
	{
		console.log("loading xp events");

		return new Promise((resolve,reject)=>{
				this.vmn.pilotXPEvents().subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async pilotPokeXPEvent(poke,id):Promise<any>
	{
		console.log("poking xp event");

		return new Promise((resolve,reject)=>{
				this.vmn.pilotPokeXPEvent(poke,id).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async pilotNotificationSeen(uuid):Promise<any>
	{
		console.log("loading notifications");

		var page_val = this.load_feed_page_subj.getValue();
		return new Promise((resolve,reject)=>{
				this.vmn.pilotSeenNotification(uuid).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async loadGroups():Promise<any>
	{
		console.log("making post");
		return new Promise((resolve,reject)=>{
				this.vmn.groupList().subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async loadGroupPosts(uuid):Promise<any>
	{
		console.log("loading group posts");
		return new Promise((resolve,reject)=>{
				this.vmn.loadGroupPosts(uuid).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async pilotSearch(query):Promise<any>
	{
		console.log("making post");
		return new Promise((resolve,reject)=>{
				this.vmn.doPilotMissionSearch(query).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}

	async loadSocialComments(uuid):Promise<any>
	{
		console.log("getting post comments");
		return new Promise((resolve,reject)=>{
				this.vmn.loadSocialComments(uuid).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}



	async loadPilotTrainings():Promise<any>
	{
		console.log("loading pilot trainings");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotLoadTrainings().subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}

	async loadPilotTrainingActions():Promise<any>
	{
		console.log("loading pilot training actions");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotLoadTrainingActions().subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async loadPilotLogs():Promise<any>
	{
		console.log("loading logs");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotLoadLogs().subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async loadPilotProjects():Promise<any>
	{
		console.log("loading projects");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotLoadProjects().subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async loadPilotOneProject(uuid: any):Promise<any>
	{
		console.log("loading one project");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotOneProject(uuid).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});
	}
	async loadPilotProjectsCrumbs(uuid):Promise<any>
	{
		console.log("loading project crumbs");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotLoadProjectCrumbs(uuid).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async rptoIncreaseBatchSlots(uuid):Promise<any>
	{
		console.log("increaseing batch slots");
		return new Promise((resolve,reject)=>{
				this.vmn.rptoIncreaseBatchSlots(uuid).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async rptoDecreaseBatchSlots(uuid):Promise<any>
	{
		console.log("decreasing batch slots");
		return new Promise((resolve,reject)=>{
				this.vmn.rptoDecreaseBatchSlots(uuid).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}


	async submitFlightLog(post_data):Promise<any>
	{
		console.log("uploading logs");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotUploadLog(post_data).subscribe(
					(dat)=>{
						var res = dat["result"];
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async clientCreateCrumb(crumb_data):Promise<any>
	{
		console.log("uploading crumb via formdata");
		return new Promise((resolve,reject)=>{
				this.vmn.clientCreateCrumb(crumb_data).subscribe(
					(dat)=>{
						var res = dat["result"];
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				)	
		});
	}


	async makeClientCrumb(post_data):Promise<any>
	{
		console.log("uploading crumb via formdata logging");
		var now = new Date();
		var new_job = <UploadJob>{
			startTime : now.toISOString(),
			endTime: 'pending',
			jobName: 'Uploading',
			jobSize: '0.0',
			jobDone: false,
			jobProgress: '0.0'
		}

		this.upload_jobs.push(new_job);

		var index_this_job = this.upload_jobs.length - 1;

		return new Promise((resolve,reject)=>{
			this.vmn.clientCreateCrumb(post_data).subscribe(
				(event : any) => {
					if (event.type === HttpEventType.UploadProgress) {
					console.log("upload progress");
					var percent_done = String((event.loaded / event.total * 100.00).toFixed(2));			        		
					this.upload_jobs[index_this_job].jobProgress = percent_done;
					// if(percent_done == '100.00'){
					// }
					console.log(percent_done);
					}
					if (event.type === HttpEventType.DownloadProgress) {
					console.log("download progress");
					console.log(event);
					}
					if (event.type === HttpEventType.Response) {
					console.log("donwload completed");
					this.upload_jobs[index_this_job].jobDone = true;
					// this.heartbeat.newDiscoveryPost();
					resolve(event);
					}
			});	
		});
		
	}




	async clientCreateOrder(order: any):Promise<any>
	{
		console.log("creating customer order");
		return new Promise((resolve,reject)=>{
				this.vmn.clientCreateOrder(order).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});
	}



	async pilotCreateCrumb(crumb_data):Promise<any>
	{
		console.log("uploading crumb via formdata logging");

		var now = new Date();
		var new_job = <UploadJob>{
			startTime : now.toISOString(),
			endTime: 'pending',
			jobName: 'Uploading',
			jobSize: '0.0',
			jobDone: false,
			jobProgress: '0.0'
		}


		this.upload_jobs.push(new_job);


		var index_this_job = this.upload_jobs.length - 1;

		return new Promise((resolve,reject)=>{
			this.vmn.pilotCreateCrumb(crumb_data).subscribe(
				(event : any) => {
					if (event.type === HttpEventType.UploadProgress) {
					console.log("upload progress");
					var percent_done = String((event.loaded / event.total * 100.00).toFixed(2));			        		
					this.upload_jobs[index_this_job].jobProgress = percent_done;
					// if(percent_done == '100.00'){
					// }
					console.log(percent_done);
					}
					if (event.type === HttpEventType.DownloadProgress) {
					console.log("download progress");
					console.log(event);
					}
					if (event.type === HttpEventType.Response) {
					console.log("donwload completed");
					this.upload_jobs[index_this_job].jobDone = true;
					// this.heartbeat.newDiscoveryPost();
					resolve(event);
					}
			});	
		});
		// return new Promise((resolve,reject)=>{
		// 		this.vmn.pilotCreateCrumb(crumb_data).subscribe(
		// 			(dat)=>{
		// 				var res = dat["result"];
		// 				resolve(res);
		// 			},
		// 			(err) => {
		// 				reject(err);
		// 			}
		// 		)	
		// });
	}
	async pilotDeleteMissionCrumbFile(image_id)
	{
		console.log("deleting mission crumb file");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotDeleteCrumbFile(image_id).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}	
	async pilotDeleteProjectCrumbFile(image_id)
	{
		console.log("deleting project crumb file");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotDeleteCrumbFile(image_id).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});			
	}	
	async pilotCreateUAV(drone_data):Promise<any>
	{
		console.log("creating drone data");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotCreateUAV(drone_data).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async pilotCreateProjectCrumb(crumb_data):Promise<any>
	{
		console.log("uploading project crumb via formdata logging");

		var now = new Date();
		var new_job = <UploadJob>{
			startTime : now.toISOString(),
			endTime: 'pending',
			jobName: 'Uploading',
			jobSize: '0.0',
			jobDone: false,
			jobProgress: '0.0'
		}

		this.upload_jobs.push(new_job);

		var index_this_job = this.upload_jobs.length - 1;

		
		return new Promise((resolve,reject)=>{
			this.vmn.pilotCreateProjectCrumb(crumb_data).subscribe(
				(event : any) => {
					if (event.type === HttpEventType.UploadProgress) {
					console.log("upload progress");
					var percent_done = String((event.loaded / event.total * 100.00).toFixed(2));			        		
					this.upload_jobs[index_this_job].jobProgress = percent_done;
					// if(percent_done == '100.00'){
					// }
					console.log(percent_done);
					}
					if (event.type === HttpEventType.DownloadProgress) {
					console.log("download progress");
					console.log(event);
					}
					if (event.type === HttpEventType.Response) {
					console.log("donwload completed");
					this.upload_jobs[index_this_job].jobDone = true;
					// this.heartbeat.newDiscoveryPost();
					resolve(event);
					}
			});	
		});

		// return new Promise((resolve,reject)=>{
		// 		this.vmn.pilotCreateProjectCrumb(crumb_data).subscribe(
		// 			(dat)=>{
		// 				var res = dat["result"];
		// 				resolve(res);
		// 			},
		// 			(err) => {
		// 				reject(err);
		// 			}
		// 		)	
		// });
		
	}

	async manageSkills(skills):Promise<any>
	{
		console.log("managing skills");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotManageSkills(skills).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}

	async manageLanguage(lang):Promise<any>
	{
		console.log("managing languages");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotManageLanguage(lang).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}

	async pilotAddProject(project):Promise<any>
	{
		console.log("adding project");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotAddProject(project).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async pilotEditProject(uuid,project):Promise<any>
	{
		console.log("editing project");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotEditProject(uuid,project).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async pilotEditProjectSkills(uuid,skills):Promise<any>
	{
		console.log("editing project skills");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotEditProjectSkills(uuid,skills).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async pilotIngressQuery(query):Promise<any>
	{
		console.log("finding ingress");
		return new Promise((resolve,reject)=>{
				this.vmn.pilotFindIngress(query).subscribe(
					(dat)=>{
						resolve(dat);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}

	async loadPilotMap():Promise<any>
	{
		console.log("loading pilot map");
		return new Promise((resolve,reject)=>{
				this.vmn.readClientPilotMap().subscribe(
					(dat)=>{
						var res = dat;
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}


	async rptoMyProfile():Promise<any>
	{
		console.log("reading rpto profile");
		return new Promise((resolve,reject)=>{
				this.vmn.rptoMyProfile().subscribe(
					(dat)=>{
						var res = dat;
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}

	async rptoMyCourses():Promise<any>
	{
		console.log("reading rpto courses");
		return new Promise((resolve,reject)=>{
				this.vmn.rptoMyCourses().subscribe(
					(dat)=>{
						var res = dat;
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}

	async rptoMyBatches():Promise<any>
	{
		console.log("reading rpto batches");
		return new Promise((resolve,reject)=>{
				this.vmn.rptoMyBatches().subscribe(
					(dat)=>{
						var res = dat;
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}

	async rptoMyTransactions():Promise<any>
	{
		console.log("reading rpto transactions");
		return new Promise((resolve,reject)=>{
				this.vmn.rptoMyTransactions().subscribe(
					(dat)=>{
						var res = dat;
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}

	async rptoMyEnrollments():Promise<any>
	{
		console.log("reading rpto enrollments");
		return new Promise((resolve,reject)=>{
				this.vmn.rptoMyEnrollments().subscribe(
					(dat)=>{
						var res = dat;
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async rptoMyTrainings():Promise<any>
	{
		console.log("reading rpto trainings");
		return new Promise((resolve,reject)=>{
				this.vmn.rptoMyTrainings().subscribe(
					(dat)=>{
						var res = dat;
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async rptoBatchEnrollments(uuid):Promise<any>
	{
		console.log("reading rpto batch enrollments");
		return new Promise((resolve,reject)=>{
				this.vmn.rptoBatchEnrollments(uuid).subscribe(
					(dat)=>{
						var res = dat;
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async rptoOneEnrollment(uuid):Promise<any>
	{
		console.log("reading rpto one enrollment");
		return new Promise((resolve,reject)=>{
				this.vmn.rptoOneEnrollment(uuid).subscribe(
					(dat)=>{
						var res = dat;
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async rptoOneTraining(uuid):Promise<any>
	{
		console.log("reading rpto one training");
		return new Promise((resolve,reject)=>{
				this.vmn.rptoOneTraining(uuid).subscribe(
					(dat)=>{
						var res = dat;
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async rptoAssignPilot(uuid,fb_uuid):Promise<any>
	{
		console.log("assign pilot enrollment");
		return new Promise((resolve,reject)=>{
				this.vmn.rptoAssignPilot(uuid,fb_uuid).subscribe(
					(dat)=>{
						var res = dat;
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async rptoAcceptPilot(uuid):Promise<any>
	{
		console.log("accepting pilot enrollment");
		return new Promise((resolve,reject)=>{
				this.vmn.rptoAcceptPilot(uuid).subscribe(
					(dat)=>{
						var res = dat;
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async rptoRejectPilot(uuid,msg):Promise<any>
	{
		console.log("rejecting pilot enrollment");
		return new Promise((resolve,reject)=>{
				this.vmn.rptoRejectPilot(uuid,msg).subscribe(
					(dat)=>{
						var res = dat;
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}
	async rptoCreateBatch(course_id,batch):Promise<any>
	{
		console.log("creating rpto batch");
		return new Promise((resolve,reject)=>{
				this.vmn.rptoCreateBatch(course_id,batch).subscribe(
					(dat)=>{
						var res = dat;
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}

	async rptoCreateSubBatch(batch_id,batch,enrolls):Promise<any>
	{
		console.log("creating rpto batch");
		return new Promise((resolve,reject)=>{
				this.vmn.rptoCreateSubBatch(batch_id,batch,enrolls).subscribe(
					(dat)=>{
						var res = dat;
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				)	
		});
		
	}




	mapURLs(src){
		return this.vmn.mapURLs(src);
	}
	mapURL(src){
		return this.vmn.mapURL(src);
	}

	mapURLsBucket(src){
		return this.vmn.mapURLsBucket(src);
	}
	mapURLBucket(src){
		return this.vmn.mapURLBucket(src);
	}



	async addNotificationToken(token_id){
		console.log("adding notification token");
		return new Promise((resolve,reject)=>{
			this.vmn.addToken(token_id).subscribe(
						(dat) => {
							resolve(dat);
						},
						(err) => {
							reject(err);
						}
					)
		})
	}





	///////// PAYMENT GATEWAY //////////////////
	async submitPaymentInfo(info){
		console.log("getting payment info");
		return new Promise((resolve,reject)=>{
			this.payment.submitPayment(info).subscribe(
						(dat) => {
							resolve(dat);
						},
						(err) => {
							reject(err);
						}
					)
		})
	}


	////////CONTACT FORM ///////////

	  async addContact(contact:Contact)
  	{
  	console.log("sending contact");
  	return new Promise((resolve,reject)=>{
  		this.vmn.newContact(contact).subscribe(
  			(dat)=>{
  				resolve(dat);
  			},
  			(err) => {
  				reject(err);
  			}
  			)

  	})

  }
}
