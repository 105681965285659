import { NgModule } from '@angular/core';
import { FeedScrollerComponent } from './feed-scroller/feed-scroller.component';
import { CommonModule } from '@angular/common';
import { TimeagoModule,TimeagoPipe } from 'ngx-timeago';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PilotPostCardComponent } from '../social/pilot-post-card/pilot-post-card.component';

import {ScrollingModule} from '@angular/cdk/scrolling';
import { PilotPortfolioCardComponent } from '../social/pilot-portfolio-card/pilot-portfolio-card.component'


import { LocatorComponent } from './locator/locator.component'; 

export {LocatorComponent};

import { AgmCoreModule } from '@agm/core';
import { GoogleMapsAPIWrapper } from '@agm/core';

import { VidiaMaterialModule } from './material.module';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';


@NgModule({
  imports: [
    CommonModule,
    FormsModule, 
  	TimeagoModule.forRoot(),
    IonicModule,
    VidiaMaterialModule,
    ScrollingModule,
    NgbModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAChTFNbiwIMNd9s0Fjv3odZJRkTRexgS4'
    }),
  ],
  declarations: [
    FeedScrollerComponent,
    PilotPostCardComponent,
    PilotPortfolioCardComponent,
    LocatorComponent
  ],
  exports: [
  FeedScrollerComponent,
  PilotPostCardComponent,
  PilotPortfolioCardComponent,
  LocatorComponent
  ],
  providers: [
    GoogleMapsAPIWrapper,    
    TimeagoPipe,
  ]
})
export  class VmnCommonModule {}
