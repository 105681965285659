import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MasterService } from '../../services/master.service';
import { Router } from '@angular/router';
import { take,first } from 'rxjs/operators';
import { ToastController } from '@ionic/angular';
import { FormControl,FormGroup,Validators} from '@angular/forms';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent implements OnInit {

 redirect: any;

  name:any;
  pass:any;

  newUserForm:any;
  otpUserForm:any;


  otp_sent_control = false;
  otp_verified_control = false;

  timeLeft: number = 60;
  interval;


  forgot_otp_sent_control = false;
  forgot_otp_verified_control = false;
  forgot_otp_session = '';

  // timeLeft: number = 60;
  // interval;

  new_pass = '';
  confirm_new_pass = '';


    constructor(
    private master: MasterService,
  	private router: Router,
    public toastController: ToastController,
  	private route:ActivatedRoute,
	) { }

  ngOnInit() {

  	   this.otpUserForm = new FormGroup(
    {
    'mobile': new FormControl('',[Validators.required,Validators.pattern("[0-9]{10}")]),
    'otp': new FormControl('',[Validators.required,Validators.pattern("[0-9]*")]),
    });

  }


  async makeNewOTPForgetPassword(){
    var mobile = this.otpUserForm.value.mobile;
    var res = await this.master.ResetPasswordOTP(mobile);
    console.log(res);
    if(res['result'] == 'true')
    {
    this.forgot_otp_sent_control = res['result'] == 'true';
    this.forgot_otp_session = res['otp-session'];
    this.restartTimer(); 
    }
    else{
       this.presentToast('check mobile number');
    }
  }
  async verifyOTPForgetPassword(){
    var otp = this.otpUserForm.value.otp;
    var res = await this.master.ResetPasswordVerify(otp,this.forgot_otp_session); 
    console.log(res);
    if(res['result'] == 'true')
    {
      this.forgot_otp_verified_control = true;
    }
    else{
       this.presentToast('error in OTP');
    }

  }

  async tryResetPasword() {
    var otp = this.otpUserForm.value.otp;
    var new_pass = this.new_pass;
    var mobile = this.otpUserForm.value.mobile;
    var res = await this.master.ResetPasswordSet(otp,this.forgot_otp_session,mobile,new_pass);
    console.log(res);
    if(res['result']=='true'){
      console.log('password changed');
      this.presentToast('password changed');
      this.forgot_otp_session = "";
      this.new_pass = '';
      this.confirm_new_pass = '';
      this.forgot_otp_verified_control = false;
      this.forgot_otp_sent_control = false;
      this.router.navigate(['/login']);
    }    
  }

  // password_confirmer(formGroup: FormGroup){
  // const { value: password } = formGroup.get('password');
  //   const { value: confirmPassword } = formGroup.get('passwordconf');
  //   return password === confirmPassword ? null : { passwordNotMatch: true };

  // }

    async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  } 

  restartTimer() {
    this.timeLeft = 60;
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 0;
      }
    },1000)
  }

}
