import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MasterService } from '../../services/master.service';
import { LoadingController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-stories-contest',
  templateUrl: './stories-contest.component.html',
  styleUrls: ['./stories-contest.component.scss'],
})
export class StoriesContestComponent implements OnInit {

  img_urls: any
  files_selected: any
  files: any
  file_list: any = [];

  constructor(
  	public modalController: ModalController,
    private master: MasterService,
    public loadingController: LoadingController,
    public toastController: ToastController
  	) { }

  ngOnInit() {}

    closeModal() {
      this.modalController.dismiss();
  }

    onFileSelected(event){
    let fileList: FileList = event.target.files;
    let fileListLength = fileList.length;
    this.files = fileList;
    this.file_list = fileList;
    this.img_urls = [];


    for (var i = 0; i < fileListLength; i++) {
       if(fileList[i].size > 1048576*50){

         this.img_urls = undefined;

        this.presentToast('FILE TOO BIG');
         return ;
       }
    }

    for (var i = 0; i < fileListLength; i++) {
      var a = fileList[i];
      var reader = new FileReader();
      reader.onload = (event:any) => {
        var url = event.target.result;
        this.img_urls.push(url); 
      }
      reader.readAsDataURL(a);
    }
    this.files_selected = true;

  }



    isFileImage(file_index) {
      // console.log(file_index);
      // console.log();
      var img_type = this.file_list[file_index].type;
      // return true;
      return(img_type.match(/(jpeg|jpg|JPG|JPEG|gif|png)$/) != null);
  }


    async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }

}
