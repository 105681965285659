import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-air-sign-up',
  templateUrl: './air-sign-up.component.html',
  styleUrls: ['./air-sign-up.component.scss'],
})
export class AirSignUpComponent implements OnInit {

  stepIndex = 0;

  constructor() { }

  ngOnInit() {}


  nextClicked(){
    this.stepIndex += 1;
    console.log(this.stepIndex);
  }

  backClicked(){
  	this.stepIndex -= 1;
  	console.log(this.stepIndex);
  }

}
