import { Component, OnInit,Input } from '@angular/core';
import { Router } from '@angular/router';
import { MasterService } from '../../services/master.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { LoadingController } from '@ionic/angular';



import { Facebook, FacebookLoginResponse } from '@awesome-cordova-plugins/facebook/ngx';

import { ContactComponent } from '../../common/contact/contact.component';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss'],
})
export class CertificateComponent implements OnInit {

    @Input() verifications_pending: any;
    
    constructor(
    private master: MasterService,
    public loadingController: LoadingController,
    private router: Router,
    private _location: Location,
    private fb: Facebook,
    private route:ActivatedRoute,
        public modalController: ModalController,

  	) { }




  my_loading: any;
  profile: any;
  profile_stats: any;
  statistics: any;
  status: any;
  verification: any;
  ms_verification: any;
  tr_verification: any;

  img_urls: any
  files_selected: any
  files: any

  selected_index: any;

  star_array = [];


  selectTabs = 'mission';

  ngOnInit() {
    this.loadProfile()
      this.fb.logEvent(this.fb.EVENTS.EVENT_NAME_ACHIEVED_LEVEL);
  }
  ionViewWillEnter(){
    this.loadProfile()
  }
  async loadProfile(){
    var res = await this.master.getPilotProfile();
    this.profile = JSON.parse(res['payload']['profile'])[0];
    this.profile_stats = JSON.parse(res['payload']['profile_stats'])[0];
    this.statistics = JSON.parse(res['payload']['statistics']);
    this.status = res['payload']['status'];
    this.verification = JSON.parse(res['payload']['verification']);

    this.ms_verification = this.verification.filter((x:any) =>{
      return x.fields.intent == 'profile';
    })
    this.tr_verification = this.verification.filter((x:any) =>{
      return x.fields.intent == 'training';
    })
    console.log(res);
    console.log(this.profile);
    console.log(this.profile_stats);
    console.log(this.statistics);
    console.log(this.status);
    console.log(this.verification);
    console.log(this.ms_verification);
    console.log(this.tr_verification);
    console.log(this.statistics["rating"]);

    this.star_array.length = this.statistics["rating"];
    this.star_array.fill(1);

    console.log(this.star_array);
   }


   backClicked(){
      this.router.navigate(['/pilot-tablinks/pilot-profile']);
  }
  index_selected_to_upload = 0;
  setSelected(i){
    this.img_urls = [];
    this.selected_index = i;
  }

  onFileSelected(event,index){
    this.index_selected_to_upload = index 
    let fileList: FileList = event.target.files;
    let fileListLength = fileList.length;
    this.files = fileList;
    this.img_urls = [];
    for (var i = 0; i < fileListLength; i++) {
      var a = fileList[i];
      var reader = new FileReader();
      reader.onload = (event:any) => {
        var url = event.target.result;
        this.img_urls.push(url); 
      }
      reader.readAsDataURL(a);
    }
    this.files_selected = true;

  }
 async uploadImages(id) {
    this.presentLoading();
  var formData = new FormData();
  for (const file of this.files) {
        formData.append('imagefile', file, file.name);
        console.log(formData);
        var res = await this.master.pilotUploadVerificationImage(id,'ok',formData);
        console.log(res);
        this.my_loading.dismiss()
    }
    this.ngOnInit();
    this.img_urls = [];
    
  }
  async presentLoading() {
    this.my_loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...'
    });
    await this.my_loading.present();

    const { role, data } = await this.my_loading.onDidDismiss();
    console.log('Loading dismissed!');
  }

  async presentModal() {
    const modal = await this.modalController.create({
      component: ContactComponent,
      cssClass: 'my-custom-class'
    });
    return await modal.present();
  }

}
