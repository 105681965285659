import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-about-pilot',
  templateUrl: './about-pilot.component.html',
  styleUrls: ['./about-pilot.component.scss'],
})
export class AboutPilotComponent implements OnInit {




  profile: any;
  profile_stats: any;
  statistics: any;
  status: any;
  verification: any;
  ranks: any;
  my_rank :any;
  my_rank_insignia :any;
  display_picture:any;
  my_skills: any;
  star_array = [];

  skills: any;
  skills_dict: any;

  my_languages: any;
  all_languages: any;
  lang_dict: any;



  @Input() about_pilot_data: any;


  constructor() { }

  ngOnInit() { 
    this.profile = this.about_pilot_data.profile;
    this.profile_stats = this.about_pilot_data.profile_stats;
    this.statistics = this.about_pilot_data.statistics;
    this.status = this.about_pilot_data.status;
    this.verification = this.about_pilot_data.verification;
    this.ranks = this.about_pilot_data.ranks;
    this.my_rank = this.about_pilot_data.my_rank;
    this.my_rank_insignia = this.about_pilot_data.my_rank_insignia;
    this.display_picture = this.about_pilot_data.display_picture;
    this.my_skills = this.about_pilot_data.my_skills;
    this.star_array = this.about_pilot_data.star_array;
    this.skills = this.about_pilot_data.skills;
    this.skills_dict = this.about_pilot_data.skills_dict;
    this.my_languages = this.about_pilot_data.my_languages;
    this.all_languages = this.about_pilot_data.all_languages;
    this.lang_dict = this.about_pilot_data.lang_dict;
  }

}
