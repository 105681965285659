import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ContactComponent } from '../../common/contact/contact.component';

@Component({
  selector: 'app-pilot-portfolio-help',
  templateUrl: './pilot-portfolio-help.component.html',
  styleUrls: ['./pilot-portfolio-help.component.scss'],
})
export class PilotPortfolioHelpComponent implements OnInit {
   
    slideOpts = {
      initialSlide: 0,
      speed: 400
    };

  constructor(public modalController: ModalController,) { }

  ngOnInit() {}

        dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true,
    });
  }	



  async contactUs() {
    const modal = await this.modalController.create({
      component: ContactComponent,
      cssClass: 'my-custom-class'
    });
    return await modal.present();
  }
  
}
