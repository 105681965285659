import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { MasterService } from '../../services/master.service';
import { ActivatedRoute } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { EditProfileComponent } from '../edit-profile/edit-profile.component';
import { ModalController } from '@ionic/angular';
import { filter } from 'rxjs/operators';



import { AboutUsComponent } from '../../common/about-us/about-us.component';
import { PrivacyPolicyComponent } from '../../common/privacy-policy/privacy-policy.component';
import { LicencesComponent } from '../../common/licences/licences.component';
import { PilotPaymentsComponent } from '../pilot-payments/pilot-payments.component';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {

  constructor(
    private master: MasterService,
    private router: Router,
    public toastController: ToastController,
    private route:ActivatedRoute,
    public modalController: ModalController,
  	) { }

  profile: any;


  ngOnInit() {
  	this.loadProfile();

  }

  async loadProfile(){
  	var res = await this.master.getProfile();
  	this.profile = res;
  	console.log(res);

   }


     doLogout(){
    this.master.logout();
    // this.route.navigate(['/login']);
  }
    backClicked(){
      this.router.navigate(['/pilot-tablinks/pilot-profile']);

  }

       async presentModal(component){
    const modal = await this.modalController.create({
      component: component,
      cssClass: 'modal-fullscreen',
    });

    modal.onDidDismiss().then((data) => {
      console.log(data);
      // this.ref.detectChanges();

      // this.zone.run(() => {
      //   console.log('force update the screen');
      // });

      this.ngOnInit();

    })


    return await modal.present();
  }
    openModal(typee){
      if(typee == 'edit'){
        this.presentModal(EditProfileComponent);
        return 
      }
      if(typee == 'about'){
        this.presentModal(AboutUsComponent);
        return 
      }
      if(typee == 'txns'){
        this.presentModal(PilotPaymentsComponent);
        return 
      }
    }

}


