import { Renderer2,Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MasterService } from '../../services/master.service';
import { ActivatedRoute } from '@angular/router';
import { Animation, AnimationController } from '@ionic/angular';

// import {  startConfettiInner,startConfetti,stopConfetti,toggleConfetti,removeConfetti } from './confetti.animation';

// import type {
//   FireworksDirective,
//   FireworksOptions
// } from '@fireworks-js/angular'

// import { confetti } from 'canvas-confetti';
// const confetti = require('canvas-confetti');

import * as confetti from 'canvas-confetti';

var maxParticleCount = 150; //set max confetti count
var particleSpeed = 2; //set the particle animation speed


var colors = ["DodgerBlue", "OliveDrab", "Gold", "Pink", "SlateBlue", "LightBlue", "Violet", "PaleGreen", "SteelBlue", "SandyBrown", "Chocolate", "Crimson"]
var streamingConfetti = false;
var animationTimer = null;
var waveAngle = 0;



@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss'],
})
export class LeaderboardComponent implements OnInit {

  // @ViewChild('fireworks') fireworks?: FireworksDirective

  fireworks_hot = true
  // fireworks_options: FireworksOptions = {
  //   opacity: 0.5
  // }

  selectTabs = "rewards";
  @ViewChild('confetti', {static: false}) myCanvas: ElementRef;
    constructor(
    private master: MasterService,
    private router: Router,
    private route:ActivatedRoute,
    private animationCtrl: AnimationController,
    private renderer: Renderer2
  	) {
      
     }
  particles = [];

  ngOnInit() {
    this.load();
    this.loadProfile();
    this.loadEvents();




  }

  leaderboard: any;
  insignia: any;
  urls_insignia:any;
  profile: any;
  profile_stats: any;
  statistics: any;
  status: any;
  verification: any;
  ranks: any;
  my_rank :any;
  my_rank_insignia :any;
  display_picture:any;


  my_xp: any = 0;


  conf_width = 1000;
  conf_height = 1000;

  async loadProfile(){
    var res = await this.master.getPilotProfile();
    this.profile = JSON.parse(res['payload']['profile'])[0];


    this.profile_stats = JSON.parse(res['payload']['profile_stats'])[0];
    this.statistics = JSON.parse(res['payload']['statistics']);
    this.status = res['payload']['status'];
    this.verification = JSON.parse(res['payload']['verification'])[0];
    this.ranks = JSON.parse(res['payload']['ranks']);

    this.my_rank = this.ranks[this.ranks.length -1]


    this.my_xp = parseInt(this.statistics.xp);

    for (var i = this.ranks.length - 1; i >= 0; i--) {
      var this_rank = parseInt(this.ranks[i].fields.xp_cutoff);
      var my_rank = parseInt(this.statistics.xp)
      if (this_rank <= my_rank || i == 0){
        this.my_rank = this.ranks[i];
        break;
      }
      else{
        console.log(this_rank);
        console.log(my_rank);

      }
    }
  }


  events: any;

  async loadEvents(){
    var res = await this.master.pilotXPEvents();
    console.log(res);

    var events = JSON.parse(res['payload']['events'])

    this.events = events;

    console.log(events)
  }


  async claim_event(toclaim_event){
    console.log(toclaim_event);

    var res = await this.master.pilotPokeXPEvent('claim',toclaim_event.fields.unique_id);
    console.log(res);


    if(res['result']=='true'){
      this.startConfetti2();
      // this.fireworks_hot = true;
      // setTimeout(()=>{                           // <<<---using ()=> syntax
      //     this.fireworks_hot = false;
      // }, 2000);
    }

    // window.settim

    this.load()
    this.loadProfile()
    this.loadEvents()
  }
  myConfetti: any;
  startConfetti2(){
    var myCanvas = document.createElement('canvas');
    document.body.appendChild(myCanvas);


    this.myConfetti = confetti.create(myCanvas, {
    resize: true,
    useWorker: true
    });
    this.myConfetti({
    particleCount: 100,
    spread: 160
    // any other options from the global
    // confetti function
    });
    setTimeout(() => {
      this.myConfetti.reset();
    }, 1000);
  }
  async load(){
    var res = await this.master.readLeaderboard();
    var res2 = await this.master.readInsignia();
    console.log(res);
    console.log(res2);
    this.leaderboard = JSON.parse(res['payload']);
    this.insignia = JSON.parse(res2['payload']);
    console.log(this.leaderboard);
    console.log(this.insignia);

    this.urls_insignia = this.master.mapURLs(this.insignia.map(x=> x.fields.imagefile));
    console.log(this.urls_insignia);

  }
      backClicked(){
      this.router.navigate(['/pilot-tablinks/pilot-profile']);

  }
  rankIndex(xp){
    var index = 0;
    for (var i = 0; i < this.insignia.length; i++) {
      if (this.insignia[i].fields.xp_cutoff < xp){
        index = i;
      }
      else{
        break;
      }
    }
    // console.log(index)
    return index;
  }


  resetParticle(particle, width, height) {
    particle.color = colors[(Math.random() * colors.length) | 0];
    particle.x = Math.random() * width;
    particle.y = Math.random() * height - height;
    particle.diameter = Math.random() * 10 + 5;
    particle.tilt = Math.random() * 10 - 10;
    particle.tiltAngleIncrement = Math.random() * 0.07 + 0.05;
    particle.tiltAngle = 0;
    return particle;
  }
  ngAfterViewInit(){
  }
}
