import * as moment from 'moment';


export function  giveMissionStatusMessage(super_status){

  var sm_1 = '';
  var sm_2 = '';

  if(!super_status){
  return [sm_1,sm_2];
  }

  if(super_status.fields.status_draft){
  sm_1 = 'Draft Mission'
  sm_2 = '';
  }


  if(super_status.fields.status_active_search){
  sm_1 = 'Looking For Best Pilot'
  if(super_status.fields.status_backup_search){
  sm_2 = 'Looking For Backup Pilot'
  }
  }

  else if(!super_status.fields.status_draft && super_status.fields.status_pending_contract_pilot){
  sm_1 = 'Waiting For Pilot to accept Contract'
  if(super_status.fields.status_pending_contract_pilot){
  sm_2 = 'Waiting For You to accept Contract'
  }
  }
  else if(!super_status.fields.status_draft && super_status.fields.status_mission_fixed){
  sm_1 = 'Mission Finalized.'
  sm_2 = '';
  }

  console.log(sm_1);
  console.log(sm_2);


  return [sm_1,sm_2]
}
export function  giveMyMissionActionMessage(latest_mission_action){

    var action_status = '';
    if(!latest_mission_action){
      latest_mission_action = {fields : { action: ''}};
      action_status = 'Open';
    }
    if(latest_mission_action.fields.action == 'accept'){
      action_status = "Accepted";
    }
    if(latest_mission_action.fields.action == 'mission-complete'){
      action_status = "COMPLETED!";
    }
    if(latest_mission_action.fields.action == 'accept-mission-contract'){
      action_status = "Offered";
    }
    if(latest_mission_action.fields.action == 'stand-by'){
      action_status = "On StandBy";
    }
    if(latest_mission_action.fields.action == 'waitlist'){
      action_status = "On Waitlist";
    }
    if(latest_mission_action.fields.action == 'cancel'){
      action_status = "Cancelled";
    }
    return action_status;
}
export function parseMission(mission,super_status,my_actions){
  var is_starred = false;
  var view_count = 23;

  var mission_header = 'OPEN';

  var sm_1,sm_2;
  var cmsgs = [];
  var pmsgs = [];
  var pm_1,pm_2;



  console.log(mission)
  console.log(super_status)
  console.log(my_actions)

  if(my_actions.length == 1){
    my_actions = my_actions[0]
  }


  var make_state = function(a: any, b: any){
    cmsgs.push(a);
    pmsgs.push(b);
  }

  if(super_status.fields.status_draft){
    make_state('Draft Mission','')
  }
  else{
    make_state('','')
  }


  //<-MISSION CREATE->
  var mission_open  = false;
  var mission_standby_only  = false;
  var misison_closed  = false;


  // MISSION ACTIVE
  if(super_status.fields.status_active_search){
    make_state('Looking For Best Pilot','OPEN');
    mission_open  = true;
  }
  else{
    make_state('','')
  }

  if(super_status.fields.status_backup_search){
  make_state('Looking For Backup Pilot','OPEN-Backup');
    if(!mission_open){
      mission_header = "OPEN-Backup"
      mission_standby_only = true;
    }
  }
  else{
    make_state('','')
  }


  // if(!mission_open && !mission_standby_only)

  var mission_accepted  = false;
  var mission_standby  = false;
  var mission_cancelled  = false;
  var mission_starred =  false;


  var pma = my_actions[1].map((x) => { 
    console.log(x);
    return x.fields.action;
  });

  console.log(pma)
  if(pma.indexOf('accept') > -1 ){
    make_state('','You Have Accepted the Mission')
      mission_header = "Accepted"
    mission_accepted = true;
  }
  else{
    make_state('','')
  }

  if(pma.indexOf('stand-by') > -1 ){
    make_state('','You Have Accepted to be Stand-by Pilot')
    mission_header = "On Standby"
    mission_standby = true;
  }
  else{
    make_state('','')
  }

  if(pma.indexOf('cancel') > -1 ){
    sm_2 = 'You Have Cancelled the Mission'
    mission_header = "Cancelled"
    make_state('',sm_2);
    var mission_cancelled  = true;
  }
  else{
    make_state('','')
  }


  if(my_actions[0].slice(-1)[0].fields.action == 'star'){
    var mission_starred  = true;
  }


  if(super_status.fields.status_mission_fixed){
    misison_closed = true;
  }
  // var mission_contract_await_pilot  = false;
  // var mission_contract_await_client  = false;

  // if(super_status.status_pending_contract_pilot){
  //   pmsgs.push('AWAITING your contract acceptance')
  //   mission_contract_await_pilot  = true;
  // }
  // if(super_status.status_pending_contract_customer){
  //   cmsgs.push('AWAITING your contract acceptance')
  //   mission_contract_await_client  = true;
  // }

  var completed = false;




  var mission_contract_await_pilot  = false;
  var mission_contract_await_client  = false;
  
  var mymission_ongoing  = false;

  if(super_status.status_pending_contract_pilot){
    pm_1 = 'AWAITING your contract acceptance'
    mission_contract_await_pilot  = true;
    mission_header = "Accept Contract"
    make_state('',pm_1);

  }
  else{
    make_state('','')
  }


  var contracted = pma.indexOf('accept-mission-contract') > -1;

  if( contracted&& super_status.fields.status_mission_fixed){
    mission_contract_await_pilot  = false;
    mymission_ongoing  = true;
    mission_header = "Upcoming"
    make_state('MISSION GO!','MISSION UPCOMING')
  }
  else{
    make_state('','')
  }

  if(contracted && super_status.fields.status_travel_start){
    pm_1 = 'Travel Logging Started.'
    make_state('Pilot has Left for the destination!',pm_1)
  }
  else{
    make_state('','')
  }


  if(contracted && super_status.fields.status_travel_end){
    pm_1 = 'Reached Destination.Do Pre-mission checks!'
    make_state('Pilot has reached destination!',pm_1)
  }
  else{
    make_state('','')
  }


  if(contracted&& super_status.fields.status_flight_start){
    pm_1 = 'Remember to do pre-flight checklist! Flight Logging Started.'
    make_state('Pilot has started the engines!',pm_1)
  }
  else{
    make_state('','')
  }

  if(contracted && super_status.fields.status_flight_end){
    pm_1 = 'Flight over. Do postflight checklist. Upload artifacts'
    make_state('Pilot has landed the vimana!',pm_1)

  }
  else{
    make_state('','')
  }


  if(contracted && super_status.fields.status_mission_completed){
    pm_1 = 'Mission Complete. Waiting for settlement.'
    mission_header = "Waiting For Settlement"
    make_state('Complete Payment to recieve Payload.',pm_1)

  }
  else{
    make_state('','')
  }

  if(contracted && super_status.fields.status_mission_remitted){
    pm_1 = 'Flight has been settled'
    mission_header = "Complete"
    make_state('Mission Success!',pm_1)
    completed = true

  }
  else{
    make_state('','')
  }

  if(contracted && super_status.fields.status_mission_on_hold){
    pm_1 = 'On Hold. Contact Support.'
    mission_header = "Disputed"
    make_state('On Hold. Contact Support.',pm_1)

  }
  else{
    make_state('','')
  }

  var startdate = new Date(mission.fields.missionStartDate)
  var enddate = new Date(mission.fields.missionEndDate)
  // var now = Date.now();
  // var diff  = startdate.getTime() - now ;
  var startmoment = moment(startdate);
  var endmoment = moment(enddate);

  if(!mission.fields.is_qualifying){
    var rol_moment = startmoment.subtract(0.5,'hours');
  }
  else{
    var rol_moment = endmoment.subtract(24,'hours');    
  }


   // var now = moment();

  var is_time_left =  moment().isBefore(rol_moment);

  if(!is_time_left){
    misison_closed = true;
  }



  var rol_date = rol_moment.toDate();
  console.log(cmsgs)
  console.log(pmsgs);
  var mission_overrides = [ mission_open, mission_standby_only, misison_closed, contracted , completed]
  var mission_actions = [ mission_accepted , mission_standby ,mission_cancelled, mission_starred ]
  var mission_info = [ rol_date , mission_header ]
  return [ mission_overrides,mission_actions, pmsgs, mission_info ]
}