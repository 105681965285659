import { Component, OnInit,Input, } from '@angular/core';

import { NavController, NavParams } from '@ionic/angular';

import { SocialSharing } from '@ionic-native/social-sharing/ngx';

@Component({
  selector: 'app-pilot-mission-share',
  styleUrls: ['./pilot-single-mission.component.scss'],
  template: `
        <ion-header [translucent]="true">
          <ion-toolbar>
            <ion-title>
              Social Share
            </ion-title>
          </ion-toolbar>
        </ion-header>

        <ion-content [fullscreen]="true">
          <ion-header collapse="condense">
            <ion-toolbar>
              <ion-title size="large">Social Share</ion-title>
            </ion-toolbar>
          </ion-header>
          <ion-grid class="gridding">
            <div class="ion-align-items-center gridding">
              <div size="8" style="height: 200px;">
                <ion-img style="height: 200px;" [src]="imgurl"></ion-img>
              </div>        
            </div>
            <h2 class="gridding">
             VIMANA MISSION
            </h2>

            <div class="ion-align-items-center flexbox">
              <ion-col size="2">
                <ion-button color="light" (click)="ShareLink('text')"><ion-icon name="share-social-outline"></ion-icon></ion-button>
              </ion-col>
              <ion-col size="2">
                  <ion-button color="warning" (click)="SendEmail()"><ion-icon name="mail-outline"></ion-icon></ion-button>
              </ion-col>
              <ion-col size="2">
                <ion-button color="primary" (click)="ShareFacebook()"><ion-icon name="logo-facebook"></ion-icon></ion-button>
              </ion-col>
              <ion-col size="2">
                  <ion-button color="success" (click)="ShareWhatsapp()"><ion-icon name="logo-whatsapp"></ion-icon></ion-button>
              </ion-col>
              <ion-col size="2">
                <ion-button  color="secondary" (click)="SendTwitter()"><ion-icon name="logo-twitter"></ion-icon></ion-button>
              </ion-col>   
              <ion-col size="2">
                <ion-button color="tertiary" (click)="SendInstagram()"><ion-icon name="logo-instagram"></ion-icon></ion-button>
              </ion-col>    
            </div>
          </ion-grid>  
         </ion-content>
          `
})
export class PilotMissionShare implements OnInit {


    // actions: any;
    text: string='Checkout this VIMANA Mission'
    imgurl:string= 'https://www.vimana.net.in/assets/new-logo-black.png'
    @Input() link: any;
    home: string='https://console.vimana.net.in'

    constructor(
    private navParams: NavParams,
    private socialSharing: SocialSharing,
    ) {

    }

    ngOnInit(){
       // this.actions = this.navParams.get('actions');
        this.link = this.home + this.link;
       console.log(this.link)
    }   

  ShareGeneric(parameter){
    const url = this.link
    const text = parameter+'\n'
    this.socialSharing.share(text, 'MEDIUM', null, url)
  }
  
  ShareWhatsapp(){
    this.socialSharing.shareViaWhatsApp(this.text, this.imgurl, this.link)
  }

  ShareFacebook(){
    this.socialSharing.shareViaFacebookWithPasteMessageHint(this.text, this.imgurl, null /* url */, 'Copia Pega!')
  }

  SendEmail(){
    this.socialSharing.shareViaEmail('text', 'subject', ['email@address.com'])
  }

  SendTwitter(){
    this.socialSharing.shareViaTwitter(this.text, this.imgurl, this.link)
  }

  SendInstagram(){
    this.socialSharing.shareViaInstagram(this.text, this.imgurl)
  }
  

}