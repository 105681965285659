import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-flight-checklist',
  templateUrl: './new-flight-checklist.component.html',
  styleUrls: ['./new-flight-checklist.component.scss'],
})
export class NewFlightChecklistComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
