import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { ActivatedRoute } from '@angular/router';
import { MasterService } from '../../services/master.service'



@Component({
  selector: 'app-training-school',
  templateUrl: './training-school.component.html',
  styleUrls: ['./training-school.component.scss'],
})
export class TrainingSchoolComponent implements OnInit {


  constructor(
    private route:ActivatedRoute,
    private router:Router,
    private cdr: ChangeDetectorRef,
	private master: MasterService,
  	) { }

  school_id: any;
  batches: any;
  courses: any;
  school: any;

  selectTabs = "open";
  
  batches_o: any;
  batches_c: any;

  courses_o: any;
  courses_c: any;

  ngOnInit() {
    this.school_id = this.route.snapshot.paramMap.get('id');
    console.log(this.school_id);
    this.loadSchool();
  }
  school_loaded = true;
  course_dict: any;

  async loadSchool(){

    this.school_loaded = false;
  	var res = await this.master.readRptoBatches(this.school_id);

  	console.log(res);
  


  	this.school = JSON.parse(res['payload']['profile'])[0];
  	this.batches = JSON.parse(res['payload']['batches']);
  	this.courses = JSON.parse(res['payload']['courses']);

    this.course_dict = {};

    for (var i = 0; i < this.courses.length; ++i) {
      this.course_dict[this.courses[i].pk] = this.courses[i];
    }
    // this.courses_o = this.courses.filter(( x: any, i: any)  => {
    //                                  // console.log(i)
    //                                  // console.log(x)
    //                                  // console.log(this.batches[i])
    //                                 return this.batches[i].fields.is_open
    //                               })
    // this.courses_c = this.courses.filter((x: any, i : any)  => {
    //                                 return !this.batches[i].fields.is_open
    //                               })
    this.batches_o = this.batches.filter((x:any)  => {
                                    return x.fields.is_open
                                  })
    this.batches_c = this.batches.filter((x:any)  => {
                                    return !x.fields.is_open
                                  })


    this.batches_o.reverse();
    this.batches_c.reverse();
  	console.log(this.batches);
    this.school_loaded = true;

    this.cdr.markForCheck()
    this.cdr.detectChanges();

  }
  // async loadProfile(){

  // 	var res = await this.master.rptoMyProfile()

  // 	console.log(res);


  // 	var profile = JSON.parse(res['payload']['profile'])[0];

  // 	console.log(profile);

  // 	this.profile = profile;


  // }

  backClicked(){
    this.router.navigate(['/pilot-trainings']);
  }

}
