import { Directive } from '@angular/core';

@Directive({
  selector: '[appHideHeader]'
})
export class HideHeaderDirective {

  constructor() { }




}
