import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PilotTablinksPage } from './pilot-tablinks.page';
import { AuthGuardService,PilotGuardService,ClientGuardService } from '../../services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    canActivate: [PilotGuardService],
    component: PilotTablinksPage,
    children : [
    	{
    		path: 'pilot-missioncontrol',
    		loadChildren: () => import('../pilot-missioncontrol/pilot-missioncontrol.module').then(m => m.PilotMissioncontrolPageModule)

    	},
    	{
    		path: 'pilot-profile',
    		loadChildren: () => import('../pilot-profile/pilot-profile.module').then(m => m.PilotProfilePageModule)

    	},
    	{
    		path: 'pilot-discover',
    		loadChildren: () => import('../../social/pilot-discover/pilot-discover.module').then(m => m.PilotDiscoverPageModule)

    	},
      {
        path: 'pilot-tools',
        loadChildren: () => import('../pilot-tools/pilot-tools.module').then(m => m.PilotToolsPageModule)

      },
    	{
    		path:'',
    		redirectTo:'/pilot-tablinks/pilot-missioncontrol',
    		pathMatch: 'full'
    	}
    ]
  },
  {
  	path: '',
  	redirectTo: 'pilot-tablinks/pilot-missioncontrol',
  	pathMatch:'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PilotTablinksPageRoutingModule {}
