import { Component, OnInit,Input,ChangeDetectorRef  } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { FormControl,FormGroup,Validators } from '@angular/forms';
import { MasterService } from '../../services/master.service'
import { ToastController } from '@ionic/angular';
import { Facebook, FacebookLoginResponse } from '@awesome-cordova-plugins/facebook/ngx';

@Component({
  selector: 'app-single-portfolio-project',
  templateUrl: './single-portfolio-project.component.html',
  styleUrls: ['./single-portfolio-project.component.scss'],
})
export class SinglePortfolioProjectComponent implements OnInit {

  @Input() data: any;


  
  constructor( private route: Router,
		private master: MasterService,
		public toastController: ToastController,
		public modalController: ModalController,
    private fb: Facebook,
    private cdr: ChangeDetectorRef,
    	) { }


  ngOnInit() {
    console.log(this.data)
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true,
    });
  }	
}
