import { Component, OnInit,Input } from '@angular/core';
import { MasterService } from '../../services/master.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogSkill,DialogBadge } from '../../common/info-dialogs/info-dialog.dialog';



@Component({
  selector: 'app-training-card',
  templateUrl: './training-card.component.html',
  styleUrls: ['./training-card.component.scss'],
})
export class TrainingCardComponent implements OnInit {





	@Input() batch_data: any;
	@Input() school_data: any;
	@Input() course_data: any;

  DISC = 0;
  TOTAL_PRICE = 0;

  constructor(
    private router:Router,
    public dialog: MatDialog,
  	private master: MasterService
  	) { }

  ngOnInit() {



    console.log(this.batch_data)
    console.log(this.school_data)
    console.log(this.course_data)
  	this.loadBadges();
  	this.loadSkills();

    var fees = this.batch_data.fields.batchFees;
    var discount = this.batch_data.fields.batchDiscount;

    this.DISC = Math.floor(100*discount/fees);
    this.TOTAL_PRICE = fees - discount;

  }  

  badges: any;
  skills:any;

  badge_imgs: any;
  skill_imgs: any;

  badge_dict : any;
  skill_dict : any;

  async loadBadges(){
    this.badge_dict = {};

    var res = await this.master.readPilotBadges();
    console.log(res);
    this.badges = JSON.parse(res['payload'])
    console.log(this.badges)

    this.badge_imgs = this.master.mapURLs(this.badges.map((x:any ) =>{
      return x.fields.imagefile
    }))

    console.log(this.badge_imgs)

    for (var i = this.badges.length - 1; i >= 0; i--) {
      this.badge_dict[this.badges[i].pk] = { 
                                              'badge' : this.badges[i],
                                              'image': this.badge_imgs[i]
                                            }
    }

    console.log(this.badge_dict)
  }
  async loadSkills(){
    this.skill_dict = {};
    var res = await this.master.readPilotSkills();
    console.log(res);
    this.skills = JSON.parse(res['payload'])
    console.log(this.skills)

    this.skill_imgs = this.master.mapURLs(this.skills.map((x:any ) =>{
      return x.fields.imagefile
    }))

    console.log(this.skill_imgs)
    for (var i = this.skills.length - 1; i >= 0; i--) {
      this.skill_dict[this.skills[i].pk] = { 
                                              'skill' : this.skills[i],
                                              'image': this.skill_imgs[i]
                                            }
    }
    console.log(this.skill_dict)



  }
  gotoCourse(id){
    this.router.navigate(['/training-course',id]);
  }

  openDialogSkill(skill: any): void {
    const dialogRef = this.dialog.open(DialogSkill, {
      width: '250px',
      data: {skill: skill},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  openDialogBadge(badge: any): void {
    const dialogRef = this.dialog.open(DialogBadge, {
      width: '250px',
      data: {badge: badge},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}
