import { Component, OnInit,Input } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { MasterService } from '../../services/master.service';
import { ActivatedRoute } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { filter } from 'rxjs/operators';
import { Contact} from '../../interfaces';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {

	  toSendContact: Contact = {

    
     };


	   @Input() title: string='';
     @Input() email: string='';
     @Input() phone: string='';
     message: string= '';

  constructor(
    private master: MasterService,
    private router: Router,
    public toastController: ToastController,
    private route:ActivatedRoute,
    public modalController: ModalController,
  	) { }


  ngOnInit() {}	

    async sendContact(){
        console.log(this.title)
        console.log(this.email)
        console.log(this.phone)
        console.log(this.message)

        this.toSendContact.contactTitle = this.title;
        this.toSendContact.contactEmail = this.email;
        this.toSendContact.contactPhone = this.phone;
        this.toSendContact.contactMessage = this.message;

        var result = await this.master.addContact(this.toSendContact);
        
        console.log(result);
        this.title="";
        this.email="";
        this.phone="";
        this.message="";



    }    


      dismiss() {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        this.modalController.dismiss({
          'dismissed': true,
    });
  }	
}
