import { Component, OnInit } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MasterService } from '../../services/master.service'
import { GoogleMapsAPIWrapper } from '@agm/core';
import { MapsAPILoader, AgmMap } from '@agm/core';
import { PopoverController } from '@ionic/angular';


import { LocationInterface,Marker,VimanaProject } from '../../interfaces';
import { ToastController } from '@ionic/angular';
import { FormControl,FormGroup,Validators } from '@angular/forms';

import { LoadingController } from '@ionic/angular';

// import { ToastController } from '@ionic/angular';




interface LocationProps {
  name: string;
  lat: number;
  lng: number;
  label?: any;
}

declare var google: any;


@Component({
  selector: 'app-create-portfolio-project',
  templateUrl: './create-portfolio-project.component.html',
  styleUrls: ['./create-portfolio-project.component.scss'],
})
export class CreatePortfolioProjectComponent implements OnInit {


  my_loading: any;


  constructor(
    private route: Router,
    private master: MasterService,
    public toastController: ToastController,
    public loadingController: LoadingController
    ) {
     }

  ngOnInit() {


  }

  async presentLoading() {
    this.my_loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...',
      duration: 6000
    });
    await this.my_loading.present();

    const { role, data } = await this.my_loading.onDidDismiss();
    console.log('Loading dismissed!');
  }



  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }  



    goBack(){
  	this.route.navigate(['/pilot-portfolio']);

  }

}
