import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { FormControl,FormGroup,Validators } from '@angular/forms';
import { MasterService } from '../../services/master.service'
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-skills-form',
  templateUrl: './skills-form.component.html',
  styleUrls: ['./skills-form.component.scss'],
})
export class SkillsFormComponent implements OnInit {

  skills: any;


  constructor( private route: Router,
		private master: MasterService,
		public toastController: ToastController,
		public modalController: ModalController,
    	) { }

  ngOnInit() {

    this.loadAllSkills();
  }

  async loadAllSkills(){

    var res2 = await this.master.getPilotProfile();

    var my_skills = JSON.parse(res2['payload']['skills']);

    var res = await this.master.readPilotSkills();

    console.log(res);
  
    this.skills = JSON.parse(res['payload']);



    for (var i = 0; i < my_skills.length; i++) {
      this.addSkill(this.skills[this.skills.map((x:any)=>{return x.pk}).indexOf(my_skills[i].pk)]);
    }


    console.log(this.skills);
  }

  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }  

  added_skill: any = [];

  addSkill(skill){

    console.log(skill);
    console.log(this.skills);
    console.log(this.added_skill);

    if(this.added_skill.indexOf(skill) < 0){
      this.added_skill.push(skill);
    }
    else{
      this.removeSkill(skill);
    }

  }

  inSkill(skill){
    return this.added_skill.indexOf(skill);
  }
  
  removeSkill(skill){

    this.added_skill.splice(this.added_skill.indexOf(skill),1);

  }
  
  async manageSkills(){
    var sk_array = this.added_skill.map((x: any) => { return x.pk })
    console.log(sk_array)
    console.log(this.added_skill)
    sk_array = JSON.stringify(sk_array);

    var res = await this.master.manageSkills(sk_array);
    console.log(res); 
    this.dismiss();
  }

    dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true,
    });
  }	



}
