import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from "@angular/animations";
import { MasterService } from '../../services/master.service'

import { giveMissionStatusMessage } from '../../services/status';
import { parseMission } from '../../services/status';

export interface CardData {
  imageId: string;
  state: "default" | "flipped" | "matched";
}


@Component({
  selector: 'app-pilot-accepted-mission-list',
  templateUrl: './pilot-accepted-mission-list.component.html',
  styleUrls: ['./pilot-accepted-mission-list.component.scss'],
  animations: [
    trigger("cardFlip", [
      state(
        "default",
        style({
          transform: "none"
        })
      ),
      state(
        "flipped",
        style({
          transform: "rotateY(180deg)"
        })
      ),
      state(
        "matched",
        style({
          visibility: "false",
          transform: "scale(0.05)",
          opacity: 0
        })
      ),
      transition("default => flipped", [animate("400ms")]),
      transition("flipped => default", [animate("400ms")]),
      transition("* => matched", [animate("400ms")])
    ])
  ]
})
export class PilotAcceptedMissionListComponent implements OnInit {
 

   data: CardData = {
    imageId: "pDGNBK9A0sk",
    state: "default"
  }; 


  missions: any;
  mission_actions: any;
  missions_status: any;

  mission_bin: any;


  mission_cats: any;
  cat_dict: any;



  constructor(
    private route: Router,
    private master: MasterService,
    ) { }
   ionViewWillEnter(){
     this.ngOnInit();
   }

   selectTabs = "upcomming";
  

  ngOnInit() {
    this.loadMyMissions();
  }
  async loadMyMissions(){
    var res = await this.master.readPilotMissions();
    var res2 = await this.master.readMissionCategories();
    console.log(res);
    this.missions = JSON.parse(res['payload']['feed']);
    this.missions_status = JSON.parse(res['payload']['status']);
    var mission_actions = JSON.parse(res['payload']['actions']);
    console.log(mission_actions);

    this.mission_actions = mission_actions.map((x: any) => {
      return x.map((y : any) =>  { return JSON.parse(y) });
    })
    console.log(this.mission_actions);

    var sm_res = giveMissionStatusMessage(this.missions_status[0]);


    this.mission_cats = JSON.parse(res2['payload']);
    this.cat_dict = {};
    for (var i = 0; i < this.mission_cats.length; i++) {
      this.cat_dict[this.mission_cats[i].pk] = this.mission_cats[i].fields.name;
    }


    console.log(this.missions);
    console.log(this.mission_actions);


    this.mission_bin = this.missions.map( ( x : any, i: any) => {
      console.log(this.mission_actions)
      console.log(this.mission_actions[i])
      console.log(this.mission_actions[i][0])
      var srats = parseMission(x , this.missions_status[i] , this.mission_actions[i])

      if(srats[0][3]){
        return 0; //Upcoming
      }
      if(srats[1][0]){
        return 1; //Accepted
      }
      if(srats[1][1]){
        return 2; //Standby
      }
      if(srats[1][2]){
        return 3; //Complete  
      }
      if(srats[0][4]){
        return 4; //Cancelled
      }
      else{
        return -1;
      }
    })
    console.log(this.mission_bin);
  }
  getCategory(mission: any){
    return this.cat_dict[mission.fields.category[0]];
  }
  backClicked(){
        this.route.navigate(['/pilot-tablinks/pilot-tools']);

  }


      cardClicked() {
    if (this.data.state === "default") {
      this.data.state = "flipped";
    } else {
      this.data.state = "default";
    }
  }

}
