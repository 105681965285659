import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http'; 
// import { MissionProfilePage } from './mission-profile/mission-profile.page';

import { LandingComponent } from './passages/landing/landing.component';
// import { PilotLoginComponent } from './pilot-login/pilot-login.component';
import { MissionLoginComponent } from './passages/mission-login/mission-login.component';
import { AirSignUpComponent } from './passages/air-sign-up/air-sign-up.component';

import { MissionSignupComponent } from './passages/mission-signup/mission-signup.component';
// import { PilotSignupComponent } from './pilot-signup/pilot-signup.component';
import { ForgetPasswordComponent } from './passages/forget-password/forget-password.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HideHeaderDirective } from './directives/hide-header.directive';

import {Geolocation} from '@ionic-native/geolocation/ngx';

import { AgmCoreModule } from '@agm/core';
import { GoogleMapsAPIWrapper } from '@agm/core';
import { TimeagoModule,TimeagoPipe } from 'ngx-timeago';


import { MatStepperModule} from '@angular/material/stepper';
import { MatExpansionModule } from '@angular/material/expansion';


import {MatDatepickerModule} from '@angular/material/datepicker';



import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';

import { MatNativeDateModule } from '@angular/material/core';



import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { PmCardFlipComponent } from './pm-card-flip/pm-card-flip.component';
// import { PmCardMiniComponent } from './pm-card-mini/pm-card-mini.component';

// import { PilotOneGroupComponent } from './pilot-one-group/pilot-one-group.component';
import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';
import { FCM } from '@ionic-native/fcm/ngx';




// import { MissionTablinksPageModule } from './client/mission-tablinks/mission-tablinks.module';
import { PilotTablinksPageModule } from './partner/pilot-tablinks/pilot-tablinks.module';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { WindowRefService } from './services/window-ref.service';
import { Facebook, FacebookLoginResponse } from '@awesome-cordova-plugins/facebook/ngx';
import { DialogSkill,DialogBadge } from './common/info-dialogs/info-dialog.dialog';

import { ContactComponent } from './common/contact/contact.component';
import { StoriesContestComponent } from './contest/stories-contest/stories-contest.component';
import { ChooseStorieComponent } from './contest/choose-storie/choose-storie.component';
import { AllPilotMapComponent } from './client/all-pilot-map/all-pilot-map.component';


export function momentAdapterFactory() {
  return adapterFactory(moment);
};

// import { LoginPopComponent } from '../login-pop/login-pop.component';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

import { MarkdownModule } from 'ngx-markdown';



// import { AirCreateMissionComponent } from './client/air-create-mission/air-create-mission.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    MissionLoginComponent,
    AirSignUpComponent,
    MissionSignupComponent,
    ForgetPasswordComponent,
    HideHeaderDirective,
    DialogSkill,
    DialogBadge,
    ContactComponent,
    StoriesContestComponent,
    ChooseStorieComponent,
    AllPilotMapComponent
    // AirCreateMissionComponent
    // LoginPopComponent,       
    ],
  entryComponents: [
    DialogSkill,
    DialogBadge
  ],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    PilotTablinksPageModule,
    // MissionTablinksPageModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatStepperModule,
    MatInputModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAChTFNbiwIMNd9s0Fjv3odZJRkTRexgS4'
    }),
    TimeagoModule.forRoot(),
    NgbModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
    MarkdownModule.forRoot()
    ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Geolocation,
    GoogleMapsAPIWrapper,
    TimeagoPipe,
    MatDatepickerModule,
    Push,
    FCM,
    WindowRefService,
    Facebook,
    SocialSharing
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class AppModule {}
