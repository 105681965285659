import { Component, OnInit, Input  } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MasterService } from '../../services/master.service';
@Component({
  selector: 'app-pilot-post-comments',
  templateUrl: './pilot-post-comments.component.html',
  styleUrls: ['./pilot-post-comments.component.scss'],
})
export class PilotPostCommentsComponent implements OnInit {

	@Input() post: any

	comments_loaded = false;

	comment = '';



	comment_feed: any;
	comment_feed_likes: any;
	comment_feed_ops: any;
	comment_feed_media: any;
	comment_feed_my_actions: any;

	my_like_array: any;
	my_like_list: any;

  constructor(
  	public modalController: ModalController,
  	private master: MasterService
  	) { }

  ngOnInit() {

  	console.log(this.post);
  	this.loadComments();

  }


  async loadComments(){
  	var res = await this.master.loadSocialComments(this.post.fields.unique_id);
  	console.log(res);


    if(res['result']=='true'){
      this.comment_feed = JSON.parse(res['payload']['all_comments'])
      this.comment_feed_ops = JSON.parse(res['payload']['users']).map(x => JSON.parse(x)[0])
      this.comment_feed_my_actions = JSON.parse(res['payload']['my_activity'])
      this.comment_feed_likes = JSON.parse(res['payload']['likes'])
      this.my_like_array = Array(this.comment_feed.length).fill(false);
      this.my_like_list = Array(this.comment_feed.length).fill(undefined);
    }
  
  	var pk_dict = {}

  	for (var i = 0; i < this.comment_feed.length; i++) {
  		pk_dict[this.comment_feed[i].pk] = i;
  	}


    for (var i = 0; i < this.comment_feed_my_actions.length; i++) {
    	this.my_like_array[pk_dict[this.comment_feed_my_actions[i].fields.comment]] = true;
    	this.my_like_list[pk_dict[this.comment_feed_my_actions[i].fields.comment]] = this.comment_feed_my_actions[i];
    }

    console.log(res)
    console.log(this.comment_feed)
    console.log(this.comment_feed_ops)
    console.log(this.comment_feed_my_actions)
    console.log(this.comment_feed_likes)



  }

     closeModal() {
      this.modalController.dismiss(    {
    'dismissed': true
    });
  }

  async makeComment(){
  	var res = await this.master.makeComment(this.post.fields.unique_id,this.comment);
  	console.log(res);
  	this.loadComments();
  	this.comment = '';
  }

  async toggleLiked(i){

  	if ( !this.my_like_array[i]){
  		this.my_like_array[i] = true;
  		var res = await this.master.likeItem(this.comment_feed[i].fields.unique_id,'comment')
  		console.log(res);
  	}
  	else{
  		this.my_like_array[i] = false;
  		var res = await this.master.unlikeItem(this.my_like_list[i].fields.unique_id,'comment')
  		console.log(res);
  	}

  	this.ngOnInit();
  }
}
