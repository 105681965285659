import { Component, OnInit,NgZone, Renderer2,ViewChild,ViewContainerRef,AfterViewInit,ComponentFactoryResolver } from '@angular/core';
import { ChangeDetectorRef ,ElementRef} from '@angular/core';
import { Location } from '@angular/common';
import { VimanaMission } from '../../interfaces';
import { MasterService } from '../../services/master.service';
import { FormControl,FormGroup,Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { GoogleMapsAPIWrapper } from '@agm/core';
import { MapsAPILoader, AgmMap } from '@agm/core';
import { LocationInterface,Marker } from '../../interfaces';
import { trigger, style, animate, transition } from '@angular/animations';

declare var google: any;

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

import { LoadingController } from '@ionic/angular';
import { Water } from 'three/examples/jsm/objects/Water.js';
import { Sky } from 'three/examples/jsm/objects/Sky.js';

import { get_dune_scene } from '../../common/scenes/dune-scene';
import { get_monitoring_scene } from '../../common/scenes/monitoring-scene';
import { get_inspection_scene } from '../../common/scenes/inspection-scene';
import { get_photography_scene } from '../../common/scenes/photography-scene';
import { get_videography_scene } from '../../common/scenes/videography-scene';
import { get_survey_scene } from '../../common/scenes/survey-scene';




@Component({
  selector: 'app-air-create-mission',
  templateUrl: './air-create-mission.component.html',
  styleUrls: ['./air-create-mission.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateY(100%)', opacity: 0}),
          animate('500ms', style({transform: 'translateY(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateY(0)', opacity: 1}),
          animate('200ms', style({transform: 'translateY(100%)', opacity: 0}))
        ])
      
  ]
  )],
  host: {
  '(window:resize)': 'onResize($event)'
  }    
})
export class AirCreateMissionComponent implements OnInit {


  @ViewChild('dunes', {static: false}) dunes: ElementRef;


  locationSelected = false;

  stepIndex = 0;

  categoryIndex = -1;

  toSendMission: VimanaMission = {};

  missionForm : FormGroup;

  categories: any;

  startDate: any;
  endDate: any;

  cat_selected: any;



  coords_lat: any;
  coords_long: any;


  title = 'My first AGM project';
  lat =  23.152790;
  lng = 79.947327;
  circleRadius:number = 5000;


  minDate: Date;
  maxDate: Date;


  manager: any;
  loader: any;
  object: any;
  camera:any;
  orbitControls: any;

  scene: any;

  renderer: any;
  geometry: any;
  material: any;
  mesh: any;

  xRot = 0;
  yRot = 0;
  zRot = 0;

  xScale = 1;
  yScale = 1;
  zScale = 1;

  selection_description: any;
  selection_uid: any;
  master_cat_selected: any;

  geocoder:any;
  to_send_address : any;

    public location:LocationInterface = {
    lat: 23.152790,
    lng: 79.947327,
    marker: {
      lat: 23.152790,
      lng: 79.947327,
      draggable: true
    },
    zoom: 5
  };


  @ViewChild(AgmMap) map: AgmMap;



  my_loading: any;

   public videography = [
      { val: 'Wedding Videography/Photography', isChecked: false },
      { val: 'Event Videography/Photography', isChecked: false },
      { val: 'Tourism Promotions', isChecked: false },
      { val: 'Film Making/Movie Making', isChecked: false },
      { val: 'Institute promotions', isChecked: false },
      { val: 'Architechture Videography/Photography', isChecked: false },
      { val: 'Hotels and Resorts Promotions', isChecked: false },
   ];



  async presentLoading() {
    this.my_loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...',
    });
    await this.my_loading.present();

    const { role, data } = await this.my_loading.onDidDismiss();
    console.log('Loading dismissed!');
  }

    constructor(
    public mapsApiLoader: MapsAPILoader,
    private zone: NgZone,
    private wrapper: GoogleMapsAPIWrapper,
    private _location: Location,
    private router: Router,
    private master: MasterService,
    private ngrenderer: Renderer2,
    public loadingController: LoadingController

    ) {
    this.mapsApiLoader = mapsApiLoader;
    this.zone = zone;
    this.wrapper = wrapper;
    this.mapsApiLoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
    });
  }


  @ViewChild('MAP_CONTENT', { read: ViewContainerRef , static: false}) public map_: ViewContainerRef;


  ngOnInit() {

    var now_date = new Date();
    this.minDate = now_date;
    this.maxDate = new Date(now_date.getFullYear() + 1, now_date.getMonth(), now_date.getDate());    

    this.missionForm = new FormGroup(
    {
      'missionTitle': new FormControl('',[Validators.required]),
      'missionDescription': new FormControl('',[Validators.required]),
      'misisonLocation': new FormControl('',[]),
      'custom_id': new FormControl('',[]),
      'missionPriceHigh': new FormControl('',[]),
      'misisonStartDate': new FormControl('',[Validators.required]),
      'misisonEndDate': new FormControl('',[Validators.required]),
  	})

    this.loadCategories();
    this.location.marker.draggable = true;

    console.log(this.missionForm)


  }
  ngAfterViewInit(){
    this.teenInit();
    this.loadScene(0);
  }


  sub_cat_dict: any;
  sub_cats: any;

  cat_dict : any;

  async loadCategories(){
    var res: any = await this.master.readMissionCategories();
    this.categories = JSON.parse(res.payload);

    this.sub_cat_dict  = {};
    this.cat_dict = {};

    var sub_cats = [];
    var master_cats = [];
    for (var i = 0; i < this.categories.length; i++) {
      var ciq = this.categories[i];
      this.cat_dict[ciq.pk] = ciq;
      if(ciq.fields.parent == null){
        master_cats.push(ciq);
      }
      else{
        sub_cats.push(ciq);
        if(this.sub_cat_dict[ciq.fields.parent] == undefined){
          this.sub_cat_dict[ciq.fields.parent] = [ ciq ];
        }
        else{
          this.sub_cat_dict[ciq.fields.parent].push(ciq);
        }
      }
    }

    // for (var i = 0; i < sub_cats.length; i++) {
    //   var ciq = sub_cats[i];

    // }



    this.categories = this.categories.filter(x => x.fields.parent == null);

    console.log(this.sub_cat_dict);
    console.log(master_cats);
    console.log(sub_cats);
  }

  async sendMission(){

    this.toSendMission.missionTitle = this.missionForm.value.missionTitle;
    this.toSendMission.missionDescription =  this.missionForm.value.missionDescription;
    this.toSendMission.missionPriceLow =  this.estimate_amount;
    this.toSendMission.missionPriceHigh =  this.estimate_amount;
    this.toSendMission.missionStartDate = this.missionForm.value.misisonStartDate ;
    this.toSendMission.missionEndDate =  this.missionForm.value.misisonEndDate;
    this.toSendMission.missionLocation =  this.to_send_address;
    this.toSendMission.missionLat =  this.location.marker.lat;
    this.toSendMission.missionLon =  this.location.marker.lng;
    this.toSendMission.custom_id =  this.missionForm.value.custom_id;
    this.toSendMission.category = JSON.stringify(this.cat_selected);

    console.log(this.startDate);
    console.log(this.endDate);
    console.log(this.missionForm);
    console.log(this.toSendMission);

    this.presentLoading();


  	var res = await this.master.createMisison(this.toSendMission);

    if(res['result']=='true')
    {
      // this.router.navigate(['/mission-tablinks/mission-dashboard']);


      var fileData = this.getFileFormData();
      fileData.append('mission_uuid',res['uuid']);
      // this.presentLoading();


      var res_upload = await this.master.clientCreateCrumb(fileData);
      console.log(res_upload);
      if(res_upload=='true')
      {
        this.my_loading.dismiss();
        this.router.navigate(['/mission-tablinks/mission-dashboard']);
        console.log("FILE UPLOAD SUCCESS")
      }
    }

    console.log(res);




  }
  getFileFormData()
  {
  var formData = new FormData();
  if( this.file_list.length <= 0)
    return formData
    formData.append('file_count', this.file_list.length);

    for (var i = 0; i < this.file_list.length; i++) {
      var upload_image = this.file_list[i]
      formData.append('file#' + i.toString() , upload_image, upload_image.name);
    }

  return formData;
  }
updateOnMap() {
    let full_address:string = this.location.address_level_1 || ""
    if (this.location.address_level_2) full_address = full_address + " " + this.location.address_level_2
    if (this.location.address_state) full_address = full_address + " " + this.location.address_state
    if (this.location.address_country) full_address = full_address + " " + this.location.address_country
    if (this.location.address_zip) full_address = full_address + " " + this.location.address_zip

    this.findLocation(full_address);
  }

  findLocation(address) {
    if (!this.geocoder) this.geocoder = new google.maps.Geocoder()
    this.geocoder.geocode({
      'address': address
    }, (results, status) => {
      console.log(results);
      if (status == google.maps.GeocoderStatus.OK) {
        this.to_send_address = results[0].formatted_address;
        for (var i = 0; i < results[0].address_components.length; i++) {
          let types = results[0].address_components[i].types

          if (types.indexOf('locality') != -1) {
            this.location.address_level_2 = results[0].address_components[i].long_name
          }
          if (types.indexOf('country') != -1) {
            this.location.address_country = results[0].address_components[i].long_name
          }
          if (types.indexOf('postal_code') != -1) {
            this.location.address_zip = results[0].address_components[i].long_name
          }
          if (types.indexOf('administrative_area_level_1') != -1) {
            this.location.address_state = results[0].address_components[i].long_name
          }
        }

        if (results[0].geometry.location) {
          this.location.lat = results[0].geometry.location.lat();
          this.location.lng = results[0].geometry.location.lng();
          this.location.marker.lat = results[0].geometry.location.lat();
          this.location.marker.lng = results[0].geometry.location.lng();
          this.location.marker.draggable = true;
          this.location.viewport = results[0].geometry.viewport;
        }
        this.locationSelected = true;

        this.map.triggerResize()
      } else {
        console.log("Sorry, this search produced no results.");
      }
    })
  }
 markerDragEnd(m: any, $event: any) {
   console.log(m);
   console.log(event);
   this.location.marker.lat = m.latLng.lat();
   this.location.marker.lng = m.latLng.lng();
   this.findAddressByCoordinates();
  }
 findAddressByCoordinates() {
    this.geocoder.geocode({
      'location': {
        lat: this.location.marker.lat,
        lng: this.location.marker.lng
      }
    }, (results, status) => {
      this.decomposeAddressComponents(results);
    })
  }
 decomposeAddressComponents(addressArray) {
   console.log(addressArray)

   this.location['address_level_1'] = '';
   this.location['address_level_2'] = '';
   this.location['address_country'] = 'India';
   this.location['address_zip'] = '';
   this.location['address_state'] = '';

   this.to_send_address = addressArray[0].formatted_address;

    if (addressArray.length == 0) return false;
    let address = addressArray[0].address_components;

    for(let element of address) {
      if (element.length == 0 && !element['types']) continue

      if (element['types'].indexOf('premise') > -1) {
        this.location.address_level_1 = element['long_name'];
        continue;
      }
      if (element['types'].indexOf('street_number') > -1) {
        this.location.address_level_1 += ' ' + element['long_name'];
        continue;
      }
      if (element['types'].indexOf('sublocality_level_1') > -1) {
        this.location.address_level_1 += ' ' + element['long_name'];
        continue;
      }
      if (element['types'].indexOf('sublocality') > -1 || element['types'].indexOf('sublocality_level_3') > -1) {
        this.location.address_level_2 = element['long_name'];
        continue;
      }
      if (element['types'].indexOf('sublocality') > -1 || element['types'].indexOf('sublocality_level_2') > -1) {
        this.location.address_level_2 += ' ' + element['long_name'];
        continue;
      }
      if (element['types'].indexOf('sublocality') > -1 || element['types'].indexOf('sublocality_level_1') > -1) {
        this.location.address_level_2 += ' ' + element['long_name'];
        continue;
      }
      if (element['types'].indexOf('locality') > -1 || element['types'].indexOf('locality') > -1) {
        this.location.address_level_2 += ' ' + element['long_name'];
        continue;
      }
      if (element['types'].indexOf('administrative_area_level_1') > -1) {
        this.location.address_state = element['long_name'];
        continue;
      }
      if (element['types'].indexOf('country') > -1) {
        this.location.address_country = element['long_name'];
        continue;
      }
      if (element['types'].indexOf('postal_code') > -1) {
        this.location.address_zip = element['long_name'];
        continue;
      }
    }
  }    
  milesToRadius(value) {
     this.circleRadius = value / 0.00062137;
  }

  circleRadiusInMiles() {
    return this.circleRadius * 0.00062137;
  }


  categorySelected(c,cat){
    console.log(c);
    console.log(cat);
    this.categoryIndex = c;
    this.cat_selected = [this.categories[c].pk];

    this.master_cat_selected = cat;
    // ***********18 MAR 2022***********
    // VIMANA MISSION CATEGORY ORDERLIST
    // 0/1 Areial Photography/Videography
    // 2 Drone Survey
    // 3 Inspection
    // 4 Surveillance/Monitoring
    // *************************t:viz-n*

    var toLoadScene = 0;
    
    if(cat.fields.name.toLowerCase().includes('inspection')){
      toLoadScene = 3;
    }
    if(cat.fields.name.toLowerCase().includes('mapping') || cat.fields.name.toLowerCase().includes('survey') ){
      toLoadScene = 2;
    }
    if(cat.fields.name.toLowerCase().includes('videography')||cat.fields.name.toLowerCase().includes('photography')){
      toLoadScene = 1;
    }
    if(cat.fields.name.toLowerCase().includes('monitoring')||cat.fields.name.toLowerCase().includes('surveillance')){
      toLoadScene = 4;
    }

    this.sub_cats = this.sub_cat_dict[cat.pk];

    console.log(this.sub_cats);
    this.loadScene(toLoadScene);
   }

  nextClicked(){
    this.stepIndex += 1;
    console.log(this.stepIndex);
  }
  loadSubCats(){

  }
  // ESTIMATIONS

  // name - { name }
  // clause - { oneofmany, many of many}
  // parent - pk
  // alpha - base *= alpha (many of many)
  // beta - base += beta ( )
  // gamma - base 

  one_of_many_slection : any;
  many_of_many_array : any;


  is_selected(sc){
    if(this.many_of_many_array){
      if(this.many_of_many_array.indexOf(sc.pk) > -1){
        return true;
      }
    }
    if(this.one_of_many_slection){     
      if(this.one_of_many_slection == sc.pk){
        return true;
      }
    }
    return false;
  }
  chipClicked(sc){
    console.log(sc);
    if(sc.fields.clause == 'manyofmany'){
      if(this.many_of_many_array == undefined){
        this.many_of_many_array = []
      }
      var x_ind  =this.many_of_many_array.indexOf(sc.pk);
      if(x_ind == -1){
      this.many_of_many_array.push(sc.pk);
      }
      else{
        this.many_of_many_array.splice(x_ind,1);
      }
      // this.one_of_many_slection = undefined;
    }
    if(sc.fields.clause == 'oneofmany'){
      // this.many_of_many_array = [];
      this.one_of_many_slection = sc.pk;
    }
    console.log(this.many_of_many_array);
    console.log(this.one_of_many_slection);


    this.getEstimate();


  }

  getCatFromPk(pk){

  }

  estimate: any;


  estimate_amount : any;
  tax_amount : any;
  total_amount : any;

  async getEstimate(){
    var cats = [];
    var suppli = {};


    suppli['acerage'] = this.calc_p1;
    suppli['terrain'] = 'simple';
    suppli['hours'] = this.calc_p3;


    cats.push(this.master_cat_selected.fields.unique_id);

    if(this.one_of_many_slection){
        var ciq = this.cat_dict[this.one_of_many_slection];
        cats.push(ciq.fields.unique_id);
    }

    if(this.many_of_many_array){
      for (var i = 0; i < this.many_of_many_array.length; i++) {
        var miq = this.many_of_many_array[i];
        var ciq = this.cat_dict[miq];
        cats.push(ciq.fields.unique_id);
      }
    }

    var res = await this.master.clientGetEstimate(cats, suppli);
    console.log(res);

    if(res['result']=='true'){
      this.estimate = JSON.parse(res['payload']['estimate'])[0];
    }
    console.log(this.estimate);
    var estimate_amount = parseFloat(this.estimate.fields.estimate_amount);
    var tax_amount = estimate_amount * 0.18;
    var total_amount = estimate_amount + tax_amount;
    this.estimate_amount = estimate_amount.toFixed(2);
    this.tax_amount = tax_amount.toFixed(2);
    this.total_amount = total_amount.toFixed(2);

  }

  caption = '';
  img_urls: any
  files_selected: any
  files: any
  file_list: any = [];

  onFileSelected(event){
    console.log("FILE SELECTED")
    let fileList: FileList = event.target.files;
    let fileListLength = fileList.length;
    this.files = fileList;
    this.file_list = fileList;
    console.log("FILELIST",fileList)
    this.img_urls = [];
    for (var i = 0; i < fileListLength; i++) {
      console.log("FILE DATA",i)
      var a = fileList[i];
      var reader = new FileReader();
      reader.onload = (event:any) => {
        var url = event.target.result;
        this.img_urls.push(url); 
      }
      reader.readAsDataURL(a);
    }
    this.files_selected = true;

  }




  //     backClicked(){
  //     this.router.navigate(['/mission-tablinks/mission-dashboard']);

  // }

  backClicked(){

    if(this.stepIndex == 0 ){
       this.router.navigate(['/mission-tablinks/mission-dashboard']);
    }
    else{
      this.stepIndex -=1;
    }

  }

  headingTouched(){
    console.log("heading touched")
  }





// THREEJS SCENES FOR VIZOR 
  teenClear(){
    const placeholders = this.dunes.nativeElement.childNodes;
    for (var i = 0; i < placeholders.length; i++) {
        this.ngrenderer.removeChild(this.dunes.nativeElement, placeholders[i]);
    }
  }
  teenInit(){
    var width = this.dunes.nativeElement.offsetWidth;
    var height = this.dunes.nativeElement.offsetHeight;
    
    console.log("renderer init");

    this.camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 5000 );
    var hElement: HTMLElement = this.dunes.nativeElement;
    this.orbitControls = new OrbitControls(this.camera,hElement);
    // this.orbitControls.target.set(0, 0, 0);

    this.camera.position.x = 500;
    this.camera.position.y = 500;
    this.camera.position.z = 500;
    this.orbitControls.update();
    this.scene = new THREE.Scene();
    this.camera.aspect = width/height;
    this.camera.updateProjectionMatrix();

    var pointLightC = new THREE.PointLight( 0xaaaaaa, 0.1, 1000 );
    var pointLightD = new THREE.PointLight( 0xffffff, 0.1, 1000 );
    pointLightC.position.set( 0, 100, 0 );
    pointLightD.position.set( 10, 100, -10 );

    var ambientLight = new THREE.AmbientLight( 0xcccccc, 0.4 );

    // this.scene.add( ambientLight );
    // this.scene.add( pointLightC );
    // this.scene.add( pointLightD );
    var pointLight = new THREE.PointLight( 0xffffff, 0.8 );
    // this.camera.add( pointLight );
    this.scene.add( this.camera );
 
    this.renderer = new THREE.WebGLRenderer( { antialias: true } );
    this.renderer.setSize( 400, 400);
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setClearColor( 0xffffff, 1 );
    this.ngrenderer.setStyle(this.renderer.domElement,'border-radius','10px');
    this.ngrenderer.appendChild(this.dunes.nativeElement,this.renderer.domElement);
    this.renderer.render(this.scene,this.camera);

    var self = this;
    var noopAnimFunc = function(){
      self.animationUpdate();
    }

    this.renderer.setAnimationLoop( noopAnimFunc );

  }

  // animationUpdate(){
  //   // console.log('animation update');
  //   var self = this;
  //   self.animFunction(self);
  //   // self.orbitControls.position0.set(10,10,10);
  //   // console.log(self.orbitControls.position0);

  //   self.orbitControls.update();
  //   self.camera.lookAt( self.scene.position );
  //   self.renderer.render( self.scene, self.camera );
  // }
  animFunction: any;
  dunesUpdate(self: any , time){
    console.log("dunes update");
  }
  animationUpdate(){
    // console.log('animation update');
    var self = this;
    self.animFunction(self);
    // self.orbitControls.position0.set(10,10,10);
    // console.log(self.orbitControls.position0);

    self.orbitControls.update();
    self.camera.lookAt( self.scene.position );
    self.renderer.render( self.scene, self.camera );
  }
  selected_scene: any;

  loadScene(scene_number : number){
    if(scene_number != undefined){
      this.selected_scene = scene_number;
    }
    this.teenClear();
    this.teenInit();


    var self = this;
    var noopFunction = function(time){
      self.dunesUpdate(self,time);
    }

    var sceneElements: any;
    var smallScene: any;
    var smallAnimation: any;

    // ***********18 MAR 2022***********
    // VIMANA MISSION CATEGORY ORDERLIST
    // 0/1 Areial Photography/Videography
    // 2 Drone Survey
    // 3 Inspection
    // 4 Surveillance/Monitoring
    // *************************t:viz-n*



    if(this.selected_scene == 3){
      this.selection_uid = 'inspection';
      this.selection_description = "Use VIMANA to inspect your investments and properties cheaply and safely!"
      sceneElements = get_inspection_scene(this.renderer,this.calc_p1,this.calc_p2)
      smallScene = sceneElements[0];
      smallAnimation = sceneElements[1];
      this.startVizor(smallScene,smallAnimation);
    }
    else if(this.selected_scene == 2){
      this.selection_uid = 'survey';
      this.selection_description = "Use VIMANA to survey faster, more accurately and with full 3D support!"
      sceneElements = get_survey_scene(this.renderer)
      smallScene = sceneElements[0];
      smallAnimation = sceneElements[1];
      this.startVizor(smallScene,smallAnimation);
     }
    else if(this.selected_scene == 0){
      this.selection_uid = 'photography';
      this.selection_description = "Use VIMANA to capture moments in an affordable never thought possible before!"
      sceneElements = get_photography_scene(this.renderer)
      smallScene = sceneElements[0];
      smallAnimation = sceneElements[1];
      this.startVizor(smallScene,smallAnimation);
    }
    else if(this.selected_scene == 1){
      this.selection_uid = 'videography';
      this.selection_description = "Use VIMANA to take your videography portfolio skills to the stratosphere!"
      sceneElements = get_videography_scene(this.renderer)
      smallScene = sceneElements[0];
      smallAnimation = sceneElements[1];
      this.startVizor(smallScene,smallAnimation);
    }
    else if(this.selected_scene == 4){
      this.selection_uid = 'monitoring';
      this.selection_description = "Use VIMANA to monitor in realtime your plants, sites and events!"
      sceneElements = get_monitoring_scene(this.renderer)
      smallScene = sceneElements[0];
      smallAnimation = sceneElements[1];
      this.startVizor(smallScene,smallAnimation);
    }
    const myTimeout = setTimeout(window.dispatchEvent, 3000,new Event('resize'));  
  }
  startVizor(given_scene,animation_function){


    this.scene.add( given_scene );

    var self = this;
    
    this.animFunction = animation_function;


    this.renderer.render(this.scene,this.camera);
  }
   onResize(event) {
      this.resizeCanvasToDisplaySize();
  }
  changeScene(){
    this.loadScene(this.selected_scene);
    this.getEstimate();
  }

    resizeCanvasToDisplaySize(){
    console.log("resizing");

    var width = this.dunes.nativeElement.offsetWidth;
    var height = this.dunes.nativeElement.offsetHeight;
    if(this.renderer.width !== width||this.renderer.height !==height)
    {
    this.camera.aspect = width/height;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize( width, height);
    }
    }
    calc_p1: any = 10;
    calc_p2: any = 'water';
    calc_p3: any = 10;

}