import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pilot-tablinks',
  templateUrl: './pilot-tablinks.page.html',
  styleUrls: ['./pilot-tablinks.page.scss'],
})
export class PilotTablinksPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
