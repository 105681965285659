import { Component, OnInit } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { Router } from '@angular/router';
import { MasterService } from '../../services/master.service';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { PilotCreatePostComponent } from '../pilot-create-post/pilot-create-post.component';





@Component({
  selector: 'app-pilot-one-group',
  templateUrl: './pilot-one-group.component.html',
  styleUrls: ['./pilot-one-group.component.scss'],
})
export class PilotOneGroupComponent implements OnInit {

  group_id: any;


  social_feed: any;
  social_feed_ops: any;
  social_feed_media: any;
  social_feed_my_actions: any;


  constructor(
    public actionSheetController: ActionSheetController,
    public modalController: ModalController,
    private router: Router,
    private route:ActivatedRoute,
    private master: MasterService,) {
    this.group_id = this.route.snapshot.paramMap.get('id');
     }
    

  ngOnInit() {

    this.loadGroup();
  }

  group: any;
  group_bg: any;
  group_fg: any;

    async loadGroup(){

      var res = await this.master.loadGroupPosts(this.group_id);

      console.log(res);

    if(res['result']=='true'){
      this.social_feed = JSON.parse(res['payload']['feed'])
      this.social_feed_media = JSON.parse(res['payload']['media'])
      this.social_feed_ops = JSON.parse(res['payload']['users']).map(x => JSON.parse(x)[0])
      this.social_feed_my_actions = JSON.parse(res['payload']['actions'])
      this.social_feed_media = this.social_feed_media.map(x => this.master.mapURLs(JSON.parse(x).map(x => x? x['fields']['media'] : '' )));
      this.group = JSON.parse(res['group'])[0];

      this.group_bg = this.master.mapURL(this.group.fields.media_bg);
      this.group_fg = this.master.mapURL(this.group.fields.media_fg);


    }
 
    console.log(this.group) 
    console.log(this.social_feed)
    console.log(this.social_feed_ops)
    console.log(this.social_feed_media)
    console.log(this.social_feed_my_actions)

    }



  async presentCreatePostModal() {
    const modal = await this.modalController.create({
      component: PilotCreatePostComponent,
      cssClass: 'my-custom-class',
      componentProps: { group: this.group }      
    });



    var preres = await modal.present();

    console.log(preres)

    var  res  = await  modal.onWillDismiss()

    console.log(res);
    this.ngOnInit();
  }




  async presentActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Options',
      cssClass: 'my-custom-class',
      buttons: [{
        text: 'Report',
        role: 'destructive',
        icon: 'alert-circle-outline',
        handler: () => {
          console.log('Report clicked');
        }
      },{
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }]
    });
    await actionSheet.present();

    const { role } = await actionSheet.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

   backClicked(){
        this.router.navigate(['/pilot-groups']);

  }

}
