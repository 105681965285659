import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { PilotPostCommentsComponent } from '../pilot-post-comments/pilot-post-comments.component';
import { PilotEditPostComponent } from '../pilot-edit-post/pilot-edit-post.component';
import { MasterService } from '../../services/master.service';
import { ToastController } from '@ionic/angular';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-pilot-post-card',
  templateUrl: './pilot-post-card.component.html',
  styleUrls: ['./pilot-post-card.component.scss'],
})
export class PilotPostCardComponent implements OnInit {


  @Input() media: any;
  @Input() media_raw: any;
  @Input() user: any;
  @Input() post: any;
  @Input() intent: any;

  @Output() trig = new EventEmitter<string>();

   showMore: false

   is_liked = false;
   is_bookmarked = false;

   activity : any;

   my_activity: any;

   my_like: any;
   my_bookmark: any;


   display_picture : any;

   is_reported = false;

   showNavigationArrows = false;
   showNavigationIndicators = false;

  constructor(
  	public actionSheetController: ActionSheetController,
  	public modalController: ModalController,
    private master: MasterService,
    public toastController: ToastController,
    config: NgbCarouselConfig
  	) {

      config.showNavigationArrows = true;
      config.showNavigationIndicators = true;

   }

  is_me = false;


  ngOnInit() {

    console.log(this.user);
    console.log(this.media);
    console.log(this.media_raw);
    console.log(this.post);

    this.display_picture = this.master.mapURL(this.user.fields.userPhoto);

    this.loadActivity();

    this.isItMe()
  }

  async isItMe(){
    var mast_id = await this.master.getId();
    console.log(this.user.fields.unique_id)
    console.log(mast_id)
    if(this.user.fields.unique_id == mast_id){
      this.is_me = true;
    }    
  }

  trimString(string, length) {
    if(string){

    return string.length > length
      ? string.substring(0, length) + "..."
      : string;
    }
    else 
      return ''
  }
  do_display:any;

  doDisplay(display: any){
    console.log(display)
    this.do_display = display;
  }
  async presentActionSheet(){
    if(this.is_me){
      this.presentActionSheetMine()
    }else{
      this.presentActionSheetGeneric()
    }
  }
  async presentActionSheetMine() {
      const actionSheet = await this.actionSheetController.create({
      header: 'Options',
      cssClass: 'my-custom-class',
      buttons: [{
        text: 'Report',
        role: 'destructive',
        icon: 'alert-circle-outline',
        handler: () => {
          this.is_reported = true;
          this.fileReport();
        }
      },
      {
        text: 'Delete',
        icon: 'eye-off-outline',
        handler: () => {
          console.log('Hide clicked');
          this.deletePost()
        }
      },
      {
        text: 'Edit',
        icon: 'create-outline',
        handler: () => {
          this.presentEditModal()
        }
      },
       {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }]
    }); 
    await actionSheet.present();

    const { role } = await actionSheet.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);

  }


  async presentActionSheetGeneric() {
        const actionSheet = await this.actionSheetController.create({
      header: 'Options',
      cssClass: 'my-custom-class',
      buttons: [{
        text: 'Report',
        role: 'destructive',
        icon: 'alert-circle-outline',
        handler: () => {
          this.is_reported = true;
          this.fileReport();
        }
      },
       {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }]
    });  
    await actionSheet.present();

    const { role } = await actionSheet.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);

  }


  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }  



  async loadActivity(){
    var res = await this.master.loadSocialActivity(this.post.fields.unique_id);
    this.activity = res['payload'];
    this.my_activity = JSON.parse(this.activity['my_activity']);
    console.log(this.activity);
    console.log(this.my_activity);

    for (var i = 0; i < this.my_activity.length; i++) {
      if(this.my_activity[i].fields.intent == 'like')
      {
        this.is_liked = true;
        this.my_like = this.my_activity[i];
      }
      if(this.my_activity[i].fields.intent == 'bookmark')
      {
        this.is_bookmarked = true;
        this.my_bookmark = this.my_activity[i];
      }
    }

  }

   async presentCommentModal() {
    const modal = await this.modalController.create({
      component: PilotPostCommentsComponent,
      cssClass: 'portfolio-help-modal-css',
      componentProps: { post: this.post }      
    });
    await modal.present();
    var  res  = await  modal.onWillDismiss()
    this.loadActivity();     

    return 0; 
  }
   async presentEditModal() {
    const modal = await this.modalController.create({
      component: PilotEditPostComponent,
      cssClass: 'portfolio-help-modal-css',
      componentProps: 
      { 
        post: this.post,
        media: this.media,
        media_raw: this.media_raw
      }      
    });
    await modal.present();
    var  res  = await  modal.onWillDismiss()
    this.trig.emit('1');

    return 0; 
  }

  isFileImage(file_url) {
      return(file_url.match(/\.(jpeg|jpg|JPG|JPEG|gif|png)$/) != null);
  }
  isFileVideo(file_url) {
      return(file_url.match(/\.(mp4|avi|webm)$/) != null);
  }

  async toggleLike(){
    console.log('toggline like');
    console.log(this.post)
    if(!this.is_liked){
      this.is_liked = true;
      var res = await this.master.likeItem(this.post.fields.unique_id,'post')
      console.log(res)
    }
    else{
      this.is_liked = false;
      var res = await this.master.unlikeItem(this.my_like.fields.unique_id,'post')
      console.log(res)
    }
    await this.loadActivity();     
    console.log('toggline like');
  }

  async fileReport(){
    console.log('filing report');
    console.log(this.post)
    var res = await this.master.socialMiscActivity(this.post.fields.unique_id,'post','report')
    console.log(res)
    await this.loadActivity();     
    console.log('report filed');
    this.presentToast("This post has been reported");
  }

  async deletePost(){
    var res = await this.master.deletePost(this.post.fields.unique_id);
    console.log(res);
    this.trig.emit('1');
  }
  async toggleBookmark(){
    console.log('toggline bookamrk');
    console.log(this.post)
    if(!this.is_bookmarked){
      this.is_bookmarked = true;
      var res = await this.master.bookmarkItem(this.post.fields.unique_id,'post')
      console.log(res)
    }
    else{
      this.is_bookmarked = false;
      var res = await this.master.unbookmarkItem(this.my_bookmark.fields.unique_id,'post')
      console.log(res)
    }
    await this.loadActivity();     
    console.log('toggline bookmark');
  }

}
