import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MasterService } from '../../services/master.service';
import { ActivatedRoute } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-client-account',
  templateUrl: './client-account.component.html',
  styleUrls: ['./client-account.component.scss'],
})
export class ClientAccountComponent implements OnInit {

    constructor(
    private master: MasterService,
    private router: Router,
    public toastController: ToastController,
    private route:ActivatedRoute,
    public modalController: ModalController,
  	) { }

  profile : any;

  ngOnInit() {
  	this.loadProfile()
  }

  async loadProfile(){
  	var res = await this.master.getProfile();
  	this.profile = res;
  	console.log(res);

   }

   
    doLogout(){
    this.master.logout();
    // this.route.navigate(['/login']);
  }

      backClicked(){
      this.router.navigate(['/mission-tablinks/mission-discover']);

  }
}
