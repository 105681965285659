import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-client-portfolio',
  templateUrl: './client-portfolio.component.html',
  styleUrls: ['./client-portfolio.component.scss'],
})
export class ClientPortfolioComponent implements OnInit {

  slideOpts = {
    initialSlide: 1,
    speed: 400
  };


  constructor(private router: Router,) { }

  ngOnInit() {}


  goBack(){
      this.router.navigate(['/mission-tablinks/mission-dashboard']);
  }

}
