import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MasterService } from '../../services/master.service';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-pilot-refer',
  templateUrl: './pilot-refer.component.html',
  styleUrls: ['./pilot-refer.component.scss'],
})
export class PilotReferComponent implements OnInit {
   
    selectTabs = "learn";

    // selectTimeline = "activity"

    profile: any;
    profile_stats: any;
    statistics: any;
    status: any;
    verification: any;
    ranks: any;
    my_rank :any;
    my_rank_insignia :any;

    star_array = [];

    modal: any;

    refer_code : any;

    constructor(
    public formBuilder: FormBuilder,
    private master: MasterService,
    private router: Router,
    public toastController: ToastController,

    ) { }

  ngOnInit() {
    this.loadProfile();
  }
   ionViewWillEnter() {

    this.ngOnInit();

  } 

  referred_pilots: any;

  async loadProfile(){
    var res = await this.master.getPilotProfile();
    this.profile = JSON.parse(res['payload']['profile'])[0];
    this.profile_stats = JSON.parse(res['payload']['profile_stats'])[0];
    this.statistics = JSON.parse(res['payload']['statistics']);
    this.status = res['payload']['status'];
    this.verification = JSON.parse(res['payload']['verification'])[0];
    
    this.ranks = JSON.parse(res['payload']['ranks']);


    this.my_rank = this.ranks[this.ranks.length -1]

    for (var i = this.ranks.length - 1; i >= 0; i--) {
      if (this.ranks[i].fields.xp_cutoff <= this.statistics.xp){
        this.my_rank = this.ranks[i];
        break;
      }
    }

    this.refer_code = this.profile.fields.unique_id.slice(0,8);
    this.referred_pilots = JSON.parse(res['payload']['referrals']);
    this.my_rank_insignia = this.master.mapURL(this.my_rank.fields.imagefile);
    console.log(res);
    console.log(this.my_rank);
    console.log(this.my_rank_insignia);
    console.log(this.ranks);
    console.log(this.profile);
    console.log(this.profile_stats);
    console.log(this.statistics);
    console.log(this.status);
    console.log(this.verification);
    console.log(this.statistics["rating"]);

    this.star_array.length = this.statistics["rating"];
    this.star_array.fill(1);

    console.log(this.star_array);
   }
   goBack(){
  	this.router.navigate(['/pilot-tablinks/pilot-profile']);
  }
copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = "Download VIMANA Today! https://play.google.com/store/apps/details?id=in.net.vimana ! Earn Rewards using my Pilot Signup Code : "
    selBox.value += val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.presentToast('copied to clipboard!');
  }

  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }  



}

