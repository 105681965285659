import { Component, OnInit,Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-xp-contest-mini',
  templateUrl: './xp-contest-mini.component.html',
  styleUrls: ['./xp-contest-mini.component.scss'],
})
export class XpContestMiniComponent implements OnInit {


	@Input() event: any;
	@Input() name: any;
	@Input() val: any;

	@Output() claimed = new EventEmitter<any>();
  
  	constructor() { }

	ngOnInit() {}

	claim(){
		this.claimed.emit(this.event);
	}
}
