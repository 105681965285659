import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonInfiniteScroll } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { MasterService } from '../../services/master.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-feed-scroller',
  templateUrl: './feed-scroller.component.html',
  styleUrls: ['./feed-scroller.component.scss'],
})
export class FeedScrollerComponent implements OnInit {



  social_feed: any;
  social_feed_ops: any;
  social_feed_media: any;
  social_feed_media_raw: any;
  social_feed_intents: any;
  social_feed_my_actions: any;

  page_range: any;


  show_skeleton = true;

  scroll_feed = [];
  scroll_feed_ops = [];
  scroll_feed_intents = [];
  scroll_feed_media = [];
  scroll_feed_media_raw = [];
  scroll_feed_my_actions = [];
  scroll_bump = 3;
  scroll_limit = 3;

  my_activity= [];
  scroll_activity = [];

  @Input() activity_scroll = false;
  @Input() infinite_scroll = false;

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  @Input() FEED_FUNCTION: any;
  @Input() SEED_VAL: any;

  constructor(
    private master: MasterService,
    private router: Router,
    public modalController: ModalController
  	) { }


   ionViewWillEnter(){
     // this.ngOnInit();


   }

  ngOnInit() {
  	console.log(this.FEED_FUNCTION);
  	console.log(this.SEED_VAL);
     console.log(this.activity_scroll);
    this.master.load_feed_page_subj.next(5);

    this.loadFeed();


  }

  eos = false;


  loadData(event) {

    console.log("loading next page");


    var curr_page = this.master.load_feed_page_subj.getValue();
    this.master.load_feed_page_subj.next(curr_page + 1);
    if(this.page_range && this.page_range[this.page_range.length - 1] == curr_page){
      this.eos = true;
      event.target.complete();
      return 0;
    }
    var curr_page = this.master.load_feed_page_subj.getValue();
    console.log("LOADING PAGE: ",curr_page);
    // console.log()

    this.loadFeed();


    // this.scroll_feed = this.social_feed.slice(0, this.scroll_limit + this.scroll_bump)
    // this.scroll_limit += this.scroll_bump;
    

    event.target.complete();
  }





  async loadFeed(){

    console.log(this.FEED_FUNCTION);

  	if(this.SEED_VAL == undefined){
	  	var res = await this.master[this.FEED_FUNCTION]();
  	}
  	else{
  		var res =  await this.master[this.FEED_FUNCTION](this.SEED_VAL);
  	}

    if(res['result']=='true'){
      this.social_feed = JSON.parse(res['payload']['feed'])
      this.social_feed_media = JSON.parse(res['payload']['media'])
      this.social_feed_media_raw = JSON.parse(res['payload']['media'])
      this.social_feed_intents = JSON.parse(res['payload']['intents']).map(x => JSON.parse(x)[0])
      this.social_feed_ops = JSON.parse(res['payload']['users']).map(x => JSON.parse(x)[0])
      this.social_feed_my_actions = JSON.parse(res['payload']['actions'])
      this.social_feed_media = this.social_feed_media.map(x => this.master.mapURLs(JSON.parse(x).map(x => x? x['fields']['media'] : '' )));
      this.social_feed_media_raw = this.social_feed_media_raw.map(x => JSON.parse(x).map(x => x? x : '' ));
      // this.social_feed_media_raw = this.social_feed_media_raw.map((x: any) => { JSON.parse(x) } );
      this.page_range = JSON.parse(res['payload']['page-range'])
      this.show_skeleton = false;
    }
  


    console.log(res)
    console.log(this.social_feed)
    console.log(this.social_feed_ops)
    console.log(this.social_feed_media)
    console.log(this.social_feed_media_raw)
    console.log(this.social_feed_intents)
    console.log(this.social_feed_my_actions)
    console.log(this.my_activity)

    this.scroll_feed.push(...this.social_feed);
    this.scroll_feed_ops.push(...this.social_feed_ops);
    this.scroll_feed_media.push(...this.social_feed_media);
    this.scroll_feed_media_raw.push(...this.social_feed_media_raw);
    this.scroll_feed_intents.push(...this.social_feed_intents);
    this.scroll_feed_my_actions.push(...this.social_feed_my_actions);
   	if(this.activity_scroll == true){
     	this.my_activity =JSON.parse(res['payload']['my_activity'])
    	this.scroll_activity.push(...this.my_activity);

   	}

    // this.scroll_limit = this.scroll_bump;
  }


  refresh(){
    this.scroll_feed = [];
    this.scroll_feed_ops = [];
    this.scroll_feed_media = [];
    this.scroll_feed_media_raw = [];
    this.scroll_feed_intents = [];
    this.scroll_feed_my_actions = [];
    this.master.load_feed_page_subj.next(1);
    this.loadFeed();
  }



}
