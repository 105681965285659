import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { of } from 'rxjs'


import { environment } from '../../environments/environment';


import { CookieService } from 'ngx-cookie-service';

import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { VimanaProfile,VimanaUser, VimanaMission,RazorPayment } from '../interfaces';
// import { NavController, } from 'ionic-angular';
import { Platform } from '@ionic/angular';
import { Contact} from '../interfaces';


// var  backend_url_prod_alpha = 'https:\/\/18.236.148.214\/api\/';
// var  media_url_prod_alpha = 'https:\/\/18.236.148.214\/media\/';
 

// var  backend_url_prod_alpha = 'https:\/\/testing.vimana.net.in\/api\/';
// var  media_url_prod_alpha = 'https:\/\/testing.vimana.net.in\/media\/';

var  backend_url_prod_alpha = 'https:\/\/api.vimana.net.in\/api\/';
var  media_url_prod_alpha = 'https:\/\/api.vimana.net.in\/media\/';

// var  backend_url_prod_alpha = 'http:\/\/localhost:8000\/api\/';
// var  media_url_prod_alpha = 'http:\/\/localhost:8000\/media\/';

// var  backend_url_prod_alpha = 'https:\/\/b74f1e1c2cf5c2.lhr.life\/api\/';
// var  media_url_prod_alpha = 'https:\/\/b74f1e1c2cf5c2.lhr.life\/media\/';

// var  bucket_url_prod_alpha = 'http://bcaptures-001.s3.ap-south-1.amazonaws.com/';
var  bucket_url_prod_alpha = 'https://ecosystem-breadbasket-001.s3.us-west-2.amazonaws.com/';


var pay_key = 'rzp_live_fnpf59YxdMqrlh'
// var pay_key = 'rzp_test_Zr6CBMw7nUl4dj'


var  contact_backend_url_prod_alpha = `https://64bbmpfadl.execute-api.ap-south-1.amazonaws.com/dev`;




// if(environment.production)
// {
//   backend_url_prod_alpha = 'https:\/\/api.vimana.net.in\/api\/';
//   media_url_prod_alpha = 'https:\/\/api.vimana.net.in\/media\/';
//   pay_key = 'rzp_live_fnpf59YxdMqrlh'
//   bucket_url_prod_alpha = 'https://ecosystem-breadbasket-001.s3.us-west-2.amazonaws.com/';
//   contact_backend_url_prod_alpha = `https://64bbmpfadl.execute-api.ap-south-1.amazonaws.com/dev`;
// }



@Injectable({
  providedIn: 'root'
})
export class VPaymentService {

  	constructor(
  		private http: HttpClient,
  		private cookie: CookieService,
  		private router: Router,

  		) { 
  		;
  	}

  	submitPayment(payment_info: RazorPayment){
	const body = new HttpParams()
	.set('razorpay_order_id', payment_info.razorpay_order_id)
	.set('razorpay_payment_id', payment_info.razorpay_payment_id)
	.set('razorpay_signature', payment_info.razorpay_signature)

	return this.http.post(backend_url_prod_alpha +'submit-payment',
  				body.toString(),
		{
			headers: new HttpHeaders()
			.set('Content-Type','application/x-www-form-urlencoded')
		})  		
  	}

}




@Injectable({
  providedIn: 'root'
})
export class VmnService {
	public auth_subject= new BehaviorSubject<any>(0);

	  platformList: string = '';
  isApp: boolean = true;

	myUser :VimanaUser = 
	{
	username: 'testpc',
	password: 'oplkioplki'
	};

	redirectUrl = '';
	user_location = ''; 
    loginApproved = false;

	rma_cookie_name = 'lmvm-rma-cookie';
	rmb_cookie_name = 'lmvm-rmb-cookie';
	prf_cookie_name = 'lmvm-prf-cookie';

	preferences = {} ;
    loginType = 'unknown';

	constructor(
		private http: HttpClient,
		private cookie: CookieService,
		private router: Router,
	    public platform: Platform	) { 

      let platforms = this.platform.platforms();

      this.platformList = platforms.join(', ');


      console.log(platforms);
      var is_web = platforms.indexOf('mobileweb');

      // if (is_web  > 0){
      //   this.isApp = false;
      // }

	}
	pay_key(){
		return pay_key;
	}
	save_user_cookie(){
		var encoded_A = btoa(unescape(encodeURIComponent(this.myUser.username)));
		var encoded_B = btoa(unescape(encodeURIComponent(this.myUser.password)));
		this.cookie.set(this.rma_cookie_name,encoded_A);
		this.cookie.set(this.rmb_cookie_name,encoded_B);
	}
	remove_user_cookie(){
		this.cookie.set(this.rma_cookie_name,'');
		this.cookie.set(this.rmb_cookie_name,'');
	}
	load_user()
	{
		var encoded_A = this.cookie.get(this.rma_cookie_name);
		var encoded_B = this.cookie.get(this.rmb_cookie_name);
		var decoded_A = decodeURIComponent(escape(window.atob(encoded_A)));
		var decoded_B = decodeURIComponent(escape(window.atob(encoded_B)));
		this.myUser.username = decoded_A;
		this.myUser.password = decoded_B;
	}
	save_preferences_cookie(){
		var encoded = btoa(unescape(encodeURIComponent(JSON.stringify(this.preferences))));
		this.cookie.set(this.prf_cookie_name,encoded);
	}
	load_preferences_cookie()
	{
		var encoded = this.cookie.get(this.prf_cookie_name);
		console.log(encoded);
		if(encoded == '')
		{
			this.preferences = {};
			return
		}
		var decoded = JSON.parse(decodeURIComponent(escape(window.atob(encoded))));
		console.log(decoded);
		this.preferences = decoded;
	}

  	tryLogin(user: VimanaUser)
  	{
		const body = new HttpParams()
		.set('vmusername', user.username)
		.set('vmpassword', user.password);

		this.myUser = user;

		return this.http.post(backend_url_prod_alpha+'login',
  				body.toString(),
		{
			headers: new HttpHeaders()
			.set('Content-Type','application/x-www-form-urlencoded')
		})
  	}
  	async TryGivenUser(user: VimanaUser,save_details = false){
  		return new Promise((resolve,reject)=>{
  			this.tryLogin(user).subscribe(
  				(dat) => {
  					if(dat['result']=='true')
  					{

						if(dat['scope']=="pilot"){
  							this.loginType = 'pilot';
  						}
  						if(dat['scope']=="client"){
  							this.loginType = 'client';
  						}

  						if(dat['scope']=="rpto"){
  							this.loginType = 'rpto';
  						}


  						this.loginApproved = true;
  						this.myUser = user;
  						this.auth_subject.next('approved');
  						
  						console.log("approved given");

						if(save_details)
						{
							this.save_user_cookie();
						}


						if(!save_details)
						{
							this.remove_user_cookie();
						}


  						resolve(true);
  					}
  					else{
  						resolve(false);
  					}
  				},
  				(err) => {
  					resolve(err);
  				}
  			)
  		})
  	}
  	async Login(user: VimanaUser,save_details = false){

  		if(user.password == '' && user.username == '')
  		{
  			this.load_user();
	  		return new Promise((resolve,reject)=>{
	  			this.tryLogin(this.myUser).subscribe(
	  				(dat) => {
	  					if(dat['result']=='true')
	  					{

							if(dat['scope']=="pilot"){
	  							this.loginType = 'pilot';
	  						}
	  						if(dat['scope']=="client"){
	  							this.loginType = 'client';
	  						}
	  						if(dat['scope']=="rpto"){
	  							this.loginType = 'rpto';
	  						}



	  						this.loginApproved = true;
	  						this.auth_subject.next('approved');
	  						console.log("approved saved");
	  						resolve(true);
	  					}
	  					else{
	  						resolve(false);
	  					}
	  				},
	  				(err) => {
	  					resolve(err);
	  				}
	  			)
	  		});  			
  		}
  		else
  		{
  		return this.TryGivenUser(user,save_details);
  		}
  	}
  	Logout()
  	{
  		console.log("logout");
  		this.remove_user_cookie();
  		this.loginApproved = false;
	  	this.auth_subject.next("false");
  		this.router.navigate(['/login']);
  	}
  	logCoords(lat,long){
  		const body = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('intent', 'logging')
		.set('coordLon', long)
		.set('coordLat', lat);

		return this.http.post(backend_url_prod_alpha+'pilot-location-capture',
  				body.toString(),
		{
			headers: new HttpHeaders()
			.set('Content-Type','application/x-www-form-urlencoded')
		})


  	}
  	getProfile()
  	{
  		if(this.auth_subject.getValue()=='approved')
  		{
  		const body = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password);

		return this.http.post(backend_url_prod_alpha+'profile',
  				body.toString(),
		{
			headers: new HttpHeaders()
			.set('Content-Type','application/x-www-form-urlencoded')
		})
  		}
  		else{
  			return of(false);
  		}
  	}
  	getPilotProfile()
  	{
  		if(this.auth_subject.getValue()=='approved')
  		{
  		const body = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password);

		return this.http.post(backend_url_prod_alpha+'pilot-read-profile',
  				body.toString(),
		{
			headers: new HttpHeaders()
			.set('Content-Type','application/x-www-form-urlencoded')
		})
  		}
  		else{
  			return of(false);
  		}
  	}
 	getPilotProject(uuid: any)
  	{
			const body = new HttpParams()
			.set('uuid', uuid)
			.set('vmusername', this.myUser.username)
			.set('vmpassword', this.myUser.password);

			return this.http.post(backend_url_prod_alpha+'read-pilot-project',
	  				body.toString(),
			{
				headers: new HttpHeaders()
				.set('Content-Type','application/x-www-form-urlencoded')
			})
	}
	editProfile(formdata)
	{
		// const mbody = new HttpParams()
		// .set('vmusername', this.myUser.username)
		// .set('vmpassword', this.myUser.password)
		// .set('userName', profile.userName)
		// .set('userEmail', profile.userEmail)
		// .set('userPhone', profile.userPhone)
		// .set('userAddress', profile.userAddress)
		// return this.http.post(backend_url_prod_alpha + "update-profile",
		// mbody.toString(),
		// {
		// headers: new HttpHeaders()
		// .set('Content-Type','application/x-www-form-urlencoded')
		// });		


		formdata.append('vmusername', this.myUser.username);
		formdata.append('vmpassword', this.myUser.password);

		console.log(formdata)
		const options = {} as any;

		return this.http.post(backend_url_prod_alpha + "update-profile",
		formdata,
		options);	



	}
	editPilotProfile(preferences)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('user_afk', preferences.user_afk)

		return this.http.post(backend_url_prod_alpha + "pilot-update-profile",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
		// formdata.append('vmusername', this.myUser.username);
		// formdata.append('vmpassword', this.myUser.password);

		// console.log(formdata)
		// const options = {} as any;

		// return this.http.post(backend_url_prod_alpha + "update-profile",
		// formdata,
		// options);	
	}

	sendOTP(mobile)
	{
		const mbody = new HttpParams()
		.set('otp_mobile', mobile)

		return this.http.post(backend_url_prod_alpha + "make-otp",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});
	}


	verifyOTP(otp,otp_session)
	{
		const mbody = new HttpParams()
		.set('otp', otp)
		.set('otp_session', otp_session)

		return this.http.post(backend_url_prod_alpha + "verify-otp",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});
	}
	verifyREFCODE(code)
	{
		const mbody = new HttpParams()
		.set('refer_code', code)

		return this.http.post(backend_url_prod_alpha + "verify-ref-code",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});
	}
	
  	newUser(user){
		const mbody = new HttpParams()
		.set('userName', user.userName)
		.set('userPhone',user.userPhone)
		.set('userEmail', user.userEmail)
		.set('userAddress', user.userAddress)
		.set('gvmusername', user.gusername)
		.set('gvmpassword', user.gpassword)
		.set('profile_type', user.profile_type)
		.set('refer_code', user.refer_code)

		return this.http.post(backend_url_prod_alpha + "signup",
			mbody.toString(),
			{
				headers: new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded')
			});
	}

	createMission(mission: VimanaMission)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('missionTitle', mission.missionTitle)
		.set('missionDescription', mission.missionDescription)
		.set('missionPriceLow', mission.missionPriceLow)
		.set('missionPriceHigh', mission.missionPriceHigh)
		.set('missionStartDate', new Date(mission.missionStartDate).toISOString())
		.set('missionEndDate', new Date(mission.missionEndDate).toISOString())
		.set('missionLocation', mission.missionLocation)
		.set('missionLat', mission.missionLat)
		.set('missionLon', mission.missionLon)
		.set('custom_id', mission.custom_id)
		.set('category', mission.category)
		return this.http.post(backend_url_prod_alpha + "client-create-mission",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}

	activateMission(mission_id: any,action: any)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid', mission_id)
		.set('action', action)


		return this.http.post(backend_url_prod_alpha + "client-activate-mission",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}

	readClientPilotMap()
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)

		return this.http.post(backend_url_prod_alpha + "client-pilot-map",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}
	readClientMissions()
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)

		return this.http.post(backend_url_prod_alpha + "client-read-my-missions",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}
	readOneClientMission(mission_id)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid', mission_id)

		return this.http.post(backend_url_prod_alpha + "client-read-one-mission",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}
	readOneClientMissionDetails(mission_id)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid', mission_id)

		return this.http.post(backend_url_prod_alpha + "client-read-one-mission-details",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}
	readPilotMissions()
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)

		return this.http.post(backend_url_prod_alpha + "pilot-read-mission-active",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}
	readPilotMissionFeed()
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)

		return this.http.post(backend_url_prod_alpha + "pilot-read-mission-feed",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}
	doPilotMissionSearch(qparams)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('query', qparams)

		return this.http.post(backend_url_prod_alpha + "pilot-mission-search",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}
	readOneMission(mission_id)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid', mission_id)

		return this.http.post(backend_url_prod_alpha + "pilot-read-one-mission",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}
	pilotPokeMission(mission_id,poke)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid', mission_id)
		.set('poke', poke)

		return this.http.post(backend_url_prod_alpha + "pilot-poke-mission",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}
	pilotPokeTraining(uuid,type_of,poke,context)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid', uuid)
		.set('poke', poke)
		.set('poke_context', context)
		.set('typeof', type_of)

		return this.http.post(backend_url_prod_alpha + "pilot-poke-training",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}
	pilotAcceptMissionContract(contract_id)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid', contract_id)

		return this.http.post(backend_url_prod_alpha + "pilot-accept-mission-contract",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}
	pilotReadMissionContract(contract_id)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid', contract_id)

		return this.http.post(backend_url_prod_alpha + "pilot-read-mission-contract",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}
	pilotCheckMissionTask(task_id)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid', task_id);

		return this.http.post(backend_url_prod_alpha + "pilot-check-mission-task",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}

	readCategories()
	{
		const mbody = new HttpParams()

		return this.http.post(backend_url_prod_alpha + "read-mission-categories",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}
	readLanguages()
	{
		const mbody = new HttpParams()

		return this.http.post(backend_url_prod_alpha + "read-all-languages",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}
	readSkills()
	{
		const mbody = new HttpParams()

		return this.http.post(backend_url_prod_alpha + "read-all-skills",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}
	readBadges()
	{
		const mbody = new HttpParams()

		return this.http.post(backend_url_prod_alpha + "read-all-badges",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}
	readSchools()
	{
		const mbody = new HttpParams()

		return this.http.post(backend_url_prod_alpha + "read-all-schools",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}
	readDroneModels()
	{
		const mbody = new HttpParams()

		return this.http.post(backend_url_prod_alpha + "read-drone-models",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}
	readEligibilities()
	{
		const mbody = new HttpParams()

		return this.http.post(backend_url_prod_alpha + "pilot-all-eligibility",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}
	readDroneCategories()
	{
		const mbody = new HttpParams()

		return this.http.post(backend_url_prod_alpha + "read-drone-categories",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}
	readLeaderboard()
	{
		const mbody = new HttpParams()

		return this.http.post(backend_url_prod_alpha + "pilot-leaderboard",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}
	readInsignia()
	{
		const mbody = new HttpParams()

		return this.http.post(backend_url_prod_alpha + "pilot-insignia",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}

  	resetPasswordOTP(mobile)
  	{
		const body = new HttpParams()
		.set('reset_mob', mobile);

		return this.http.post(backend_url_prod_alpha+'reset-password-otp',
  				body.toString(),
		{
			headers: new HttpHeaders()
			.set('Content-Type','application/x-www-form-urlencoded')
		})
  	}
  	resetPasswordVerify(otp,session)
  	{
		const body = new HttpParams()
		.set('reset_otp', otp)
		.set('reset_session', session);

		return this.http.post(backend_url_prod_alpha+'reset-password-verify',
  				body.toString(),
		{
			headers: new HttpHeaders()
			.set('Content-Type','application/x-www-form-urlencoded')
		})
  	}
  	resetPasswordSet(otp,session,name,pass)
  	{
		const body = new HttpParams()
		.set('reset_otp', otp)
		.set('reset_session', session)
		.set('reset_username', name)
		.set('reset_pass', pass);

		return this.http.post(backend_url_prod_alpha+'reset-password-set',
  				body.toString(),
		{
			headers: new HttpHeaders()
			.set('Content-Type','application/x-www-form-urlencoded')
		})
  	}
	pilotUploadDroneImage(formdata: FormData)
	{
		formdata.append('vmusername', this.myUser.username);
		formdata.append('vmpassword', this.myUser.password);

		console.log(formdata)
		const options = {} as any;

		return this.http.post(backend_url_prod_alpha + "pilot-add-drone-image",
		formdata,
		options);	
	}

	pilotUploadImageFile(v_id,desciption,formdata: FormData)
	{
		formdata.append('vmusername', this.myUser.username);
		formdata.append('vmpassword', this.myUser.password);
		formdata.append('desciption', desciption);
		formdata.append('uuid', v_id);

		console.log(formdata)
		const options = {} as any;

		return this.http.post(backend_url_prod_alpha + "pilot-upload-verification-image",
		formdata,
		options);	
	}
	readAllProjects()
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		// .set('uuid', image_id)

		return this.http.post(backend_url_prod_alpha + "read-all-projects",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}

	pilotDeleteImageFile(image_id)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid', image_id)

		return this.http.post(backend_url_prod_alpha + "pilot-delete-verification-image",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}


 	pilotSocialFeed(page = 1)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('page', page)

		return this.http.post(backend_url_prod_alpha + "social/pilot-feed-g",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}
 	loadMySavedPosts(page = 1)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('page', page)

		return this.http.post(backend_url_prod_alpha + "social/pilot-saved-g",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}
	loadMySocialPosts(page = 1)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('page', page)

		return this.http.post(backend_url_prod_alpha + "social/pilot-posted-g",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}

	loadMySocialActivity(page = 1)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('page', page)

		return this.http.post(backend_url_prod_alpha + "social/pilot-activity-g",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}

	pilotSocialNotifications()
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)

		return this.http.post(backend_url_prod_alpha + "social/load-social-notifications",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}
	pilotSeenNotification(uuid)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid', uuid)

		return this.http.post(backend_url_prod_alpha + "social/notification-seen",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}
 	groupList()
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)

		return this.http.post(backend_url_prod_alpha + "social/load-groups",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}
 	loadSocialActivity(uuid)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('post_uuid',uuid)

		return this.http.post(backend_url_prod_alpha + "social/load-social-activity",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}
 	loadGroupPosts(uuid)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid',uuid)

		return this.http.post(backend_url_prod_alpha + "social/load-group-posts",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}
 	loadSocialComments(uuid)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('post_uuid',uuid)

		return this.http.post(backend_url_prod_alpha + "social/load-social-comments",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}
  pilotCreatePost(formdata: FormData)
	{
		formdata.append('vmusername', this.myUser.username);
		formdata.append('vmpassword', this.myUser.password);
		console.log(formdata)
		const options = {
			reportProgress: true,
			observe: 'events'
		} as any;

		return this.http.post(backend_url_prod_alpha + "social/create-post",
		formdata,
		options);	

	}
  pilotCreateGroupPost(formdata: FormData,group_id)
	{
		formdata.append('vmusername', this.myUser.username);
		formdata.append('vmpassword', this.myUser.password);
		formdata.append('uuid', group_id);


		console.log(formdata)
		const options = {} as any;

		return this.http.post(backend_url_prod_alpha + "social/create-group-post",
		formdata,
		options);	

	}
  pilotUpdatePost(formdata: FormData)
	{
		formdata.append('vmusername', this.myUser.username);
		formdata.append('vmpassword', this.myUser.password);
		console.log(formdata)
		const options = {} as any;

		return this.http.post(backend_url_prod_alpha + "social/update-post",
		formdata,
		options);	

	}
  pilotDeletePost(post_id)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('post_uuid', post_id);

		return this.http.post(backend_url_prod_alpha + "social/delete-post",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		

	}
  pilotDeletePostMedia(post_id,media_id)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('media_uuid', media_id)
		.set('post_uuid', post_id);

		return this.http.post(backend_url_prod_alpha + "social/delete-post-media",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		

	}
  	pilotXPEvents()
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)

		return this.http.post(backend_url_prod_alpha + "pilot-my-xpevents",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		

	}
  	pilotPokeXPEvent(poke: any,id: any)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid', id)
		.set('poke', poke)

		return this.http.post(backend_url_prod_alpha + "pilot-poke-xpevent",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		

	}
  	pilotCreateComment(post_id,comment)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('post_uuid', post_id)
		.set('comment', comment);

		return this.http.post(backend_url_prod_alpha + "social/create-comment",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}

  	pilotEditComment(comment_id,comment)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('comment_uuid', comment_id)
		.set('comment', comment);

		return this.http.post(backend_url_prod_alpha + "social/edit-comment",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}

  	pilotDeleteComment(comment_id)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('comment_uuid', comment_id);
		
		return this.http.post(backend_url_prod_alpha + "social/remove-comment",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}

  socialMiscActivity(uuid: any, item_type: any, activity: any)
	{
		const mbody = new HttpParams()
		.set('uuid', uuid)
		.set('item_type', item_type)
		.set('activity', activity)
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)

		return this.http.post(backend_url_prod_alpha + "social/misc-activity",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}

 	pilotLikeItem(uuid,item_type)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid',uuid)
		.set('item_type',item_type);
		
		return this.http.post(backend_url_prod_alpha + "social/like",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}

 	pilotUnlikeItem(uuid,item_type)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid',uuid)
		.set('item_type',item_type);
		
		return this.http.post(backend_url_prod_alpha + "social/unlike",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}

 	pilotBookmarkItem(uuid,item_type)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid',uuid)
		.set('item_type',item_type);
		
		return this.http.post(backend_url_prod_alpha + "social/bookmark",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}
 	pilotManageSkills(skills)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('skills', skills)

		return this.http.post(backend_url_prod_alpha + "pilot-manage-skills",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}
 	pilotManageLanguage(langs)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('language', langs)

		return this.http.post(backend_url_prod_alpha + "pilot-manage-language",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}
 	pilotUnbookmarkItem(uuid,item_type)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid',uuid)
		.set('item_type',item_type);
		
		return this.http.post(backend_url_prod_alpha + "social/remove-bookmark",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}
 	pilotMyTransactions()
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)

		
		return this.http.post(backend_url_prod_alpha + "pilot-my-transactions",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	} 	
	clientMyTransactions()
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)

		
		return this.http.post(backend_url_prod_alpha + "client-my-transactions",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}
 	pilotAddProject(project)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('projectName',project.projectName)
		.set('projectDesciption',project.projectDesciption)
		.set('projectLocation',project.projectLocation)
		.set('projectDate',new Date( project.projectDate).toISOString())
		.set('projectEstimateLow',project.projectEstimateLow)
		.set('projectEstimateHigh',project.projectEstimateHigh)
		.set('projectDeliveryTime',project.projectDeliveryTime)

		return this.http.post(backend_url_prod_alpha + "pilot-add-project",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}

 	pilotEditProject(uuid,project)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid',uuid)
		.set('projectName',project.projectName)
		.set('projectDesciption',project.projectDesciption)
		.set('projectLocation',project.projectLocation)
		.set('projectDate',new Date( project.projectDate).toISOString())
		.set('projectEstimateLow',project.projectEstimateLow)
		.set('projectEstimateHigh',project.projectEstimateHigh)
		.set('projectDeliveryTime',project.projectDeliveryTime)
		.set('is_public',project.is_public)

		return this.http.post(backend_url_prod_alpha + "pilot-edit-project",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}

 	pilotOneProject(uuid)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid',uuid)

		return this.http.post(backend_url_prod_alpha + "pilot-one-project",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}


 	pilotEditProjectSkills(uuid,skills)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid',uuid)
		.set('skills',skills)


		return this.http.post(backend_url_prod_alpha + "pilot-edit-project-skills",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}

 	pilotLoadTrainingActions()
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		
		return this.http.post(backend_url_prod_alpha + "pilot-my-training-actions",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}
 	pilotLoadTrainings()
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		
		return this.http.post(backend_url_prod_alpha + "pilot-my-trainings",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}
 	pilotLoadLogs()
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		
		return this.http.post(backend_url_prod_alpha + "pilot-my-logs",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}
 	pilotLoadProjects()
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		
		return this.http.post(backend_url_prod_alpha + "pilot-my-projects",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}
 	pilotReadMyDrones()
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		
		return this.http.post(backend_url_prod_alpha + "pilot-my-drones",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}
 	pilotFindIngress(query)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('query', query)
		
		return this.http.post(backend_url_prod_alpha + "pilot-find-ingress",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}

 	pilotLoadProjectCrumbs(uuid)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid', uuid)
		
		return this.http.post(backend_url_prod_alpha + "pilot-my-project-crumbs",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}
  	pilotUploadLog(formdata: FormData)
	{
		formdata.append('vmusername', this.myUser.username);
		formdata.append('vmpassword', this.myUser.password);
		console.log(formdata)
		const options = {} as any;

		return this.http.post(backend_url_prod_alpha + "pilot-submit-log",
		formdata,
		options);	
	}
 	clientGetEstimate(cats,suppli)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('suppli', JSON.stringify(suppli))
		.set('categories', JSON.stringify(cats))
		
		return this.http.post(backend_url_prod_alpha + "client-mission-estimate",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}


 	clientCreateOrder(order: any)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('customerContact', order.customerContact)
		.set('customerName', order.customerName)
		.set('customerAddress', order.customerAddress)
		.set('invoiceTotal', order.invoiceTotal)
		.set('customerGST', order.customerGST)
		
		return this.http.post(backend_url_prod_alpha + "client-create-order",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}


  	clientCreateCrumb(formdata: FormData)
	{
		formdata.append('vmusername', this.myUser.username);
		formdata.append('vmpassword', this.myUser.password);
		console.log(formdata)
		const options = {
			reportProgress: true,
			observe: 'events'
		} as any;

		return this.http.post(backend_url_prod_alpha + "client-upload-crumb",
		formdata,
		options);	
	}
  	pilotCreateCrumb(formdata: FormData)
	{
		formdata.append('vmusername', this.myUser.username);
		formdata.append('vmpassword', this.myUser.password);
		console.log(formdata)
		const options = {
			reportProgress: true,
			observe: 'events'
		} as any;

		return this.http.post(backend_url_prod_alpha + "pilot-upload-crumb",
		formdata,
		options);	
	}

	pilotDeleteCrumbFile(image_id)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid', image_id)

		return this.http.post(backend_url_prod_alpha + "pilot-delete-project-crumb",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}
	


	readUAVModels()
	{
		const mbody = new HttpParams()

		return this.http.post(backend_url_prod_alpha + "read-uav-models",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}

  pilotCreateProjectCrumb(formdata: FormData)
	{
		formdata.append('vmusername', this.myUser.username);
		formdata.append('vmpassword', this.myUser.password);
		
		console.log(formdata)

		const options = {
			reportProgress: true,
			observe: 'events'
		} as any;


		return this.http.post(backend_url_prod_alpha + "pilot-upload-project-crumb",
		formdata,
		options);	
	}
 	pilotReadRptoBatches(rpto_id)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid', rpto_id)

		return this.http.post(backend_url_prod_alpha + "pilot-rpto-batches",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	
	}
  	pilotCreateUAV(drone_data: any)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('model_uuid', drone_data.model_uuid)
		.set('purchaseDate', drone_data.purchaseDate)
		.set('estimate_amount', drone_data.estimate_amount)
		.set('code_name', drone_data.code_name)
		.set('description', drone_data.description)
		.set('DAN', drone_data.DAN)
		.set('OAN', drone_data.OAN)
		.set('UIN', drone_data.UIN)
		.set('serial_number', drone_data.serial_number)

		return this.http.post(backend_url_prod_alpha + "pilot-add-drone",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	

	}
 	pilotReadOneBatch(batch_id)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid', batch_id)

		return this.http.post(backend_url_prod_alpha + "pilot-one-batch",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	
	}

 	pilotReadOneTraining(training_id)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid', training_id)

		return this.http.post(backend_url_prod_alpha + "pilot-one-training",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	
	}


 	rptoMyProfile()
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)

		return this.http.post(backend_url_prod_alpha + "rpto-read-profile",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	
	}

 	rptoMyCourses()
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)

		return this.http.post(backend_url_prod_alpha + "rpto-my-courses",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	
	}

 	rptoMyBatches()
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)

		return this.http.post(backend_url_prod_alpha + "rpto-my-batches",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	
	}

 	rptoMyTransactions()
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)

		return this.http.post(backend_url_prod_alpha + "rpto-my-transactions",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	
	}

 	rptoMyEnrollments()
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)

		return this.http.post(backend_url_prod_alpha + "rpto-my-enrollments",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	
	}
 	rptoMyTrainings()
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)

		return this.http.post(backend_url_prod_alpha + "rpto-my-trainings",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	
	}

 	rptoBatchEnrollments(uuid)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid', uuid)

		return this.http.post(backend_url_prod_alpha + "rpto-batch-enrollments",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	
	}

 	rptoOneEnrollment(uuid)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid', uuid)

		return this.http.post(backend_url_prod_alpha + "rpto-one-enrollment",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	
	}

 	rptoOneTraining(uuid)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid', uuid)

		return this.http.post(backend_url_prod_alpha + "rpto-one-training",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	
	}


 	rptoAcceptPilot(uuid)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid', uuid)

		return this.http.post(backend_url_prod_alpha + "rpto-accept-pilot",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	
	}
 	rptoRejectPilot(uuid,msg)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('msg', msg)
		.set('uuid', uuid)

		return this.http.post(backend_url_prod_alpha + "rpto-reject-pilot",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	
	}
 	rptoAssignPilot(uuid,fb_uuid)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid', uuid)
		.set('fb_uuid', fb_uuid)

		return this.http.post(backend_url_prod_alpha + "rpto-assign-pilot",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	
	}

 	rptoIncreaseBatchSlots(uuid)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid', uuid)

		return this.http.post(backend_url_prod_alpha + "rpto-increase-batch-slots",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	
	}

 	rptoDecreaseBatchSlots(uuid)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid', uuid)

		return this.http.post(backend_url_prod_alpha + "rpto-decrease-batch-slots",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	
	}

 	rptoCreateBatch(course_id: any,batch: any)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('uuid', course_id )
		.set('startDate', new Date( batch.startDate).toISOString() )
		.set('endDate', new Date( batch.endDate).toISOString())
		.set('batchName', batch.batchName)
		.set('batchFees', batch.batchFees)
		.set('batchDiscount', batch.batchDiscount)
		.set('batchSize', batch.batchSize)
		.set('skills', batch.skills)
		.set('badges', batch.badges)

		return this.http.post(backend_url_prod_alpha + "rpto-new-batch",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	
	}

 	rptoCreateSubBatch(batch_id: any,batch: any,enrolls: any)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('ob_uuid', batch_id )
		.set('start_date', new Date( batch.startDate).toISOString() )
		.set('end_date', new Date( batch.endDate).toISOString())
		.set('batch_name', batch.batchName)
		.set('batch_size', batch.batchSize)
		.set('enrolls', enrolls)


		return this.http.post(backend_url_prod_alpha + "rpto-new-sub-batch",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});	
	}



	addToken(token_id: string)
	{
		const mbody = new HttpParams()
		.set('vmusername', this.myUser.username)
		.set('vmpassword', this.myUser.password)
		.set('token_id', token_id)

		return this.http.post(backend_url_prod_alpha + "notifs/new-notification-token",
		mbody.toString(),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/x-www-form-urlencoded')
		});		
	}


  	mapURL(src)
  	{
  		return media_url_prod_alpha + src;
  	}

  	mapURLs(src)
  	{
  		return src.map(x=>media_url_prod_alpha + x )
  	}
  	mapURLBucket(src)
  	{
  		return bucket_url_prod_alpha + src;
  	}

  	mapURLsBucket(src)
  	{
  		return src.map(x=>bucket_url_prod_alpha + x )
  	}





  	newContact(contact:Contact)
	{	
		console.log(contact.contactTitle)
		// const mbody = new HttpParams()

		// .set('title', contact.contactTitle)
		// .set('phoneno', contact.contactPhoneno)
		// .set('email', contact.contactEmail)
		// .set('companyname', contact.contactCompanyname)
		// .set('message', contact.contactMessage)

		return this.http.post(contact_backend_url_prod_alpha,
		JSON.stringify(
			{"name":contact.contactTitle,
			"email":contact.contactEmail,
			"phone":contact.contactPhone,
			"message":contact.contactMessage
		}),
		{
		headers: new HttpHeaders()
		.set('Content-Type','application/json')
		});		
	}
}
