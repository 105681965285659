import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { PilotPostCommentsComponent } from '../pilot-post-comments/pilot-post-comments.component';
import { MasterService } from '../../services/master.service';
import { ToastController } from '@ionic/angular';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';
import { LoadingController } from '@ionic/angular';

@Component({
  selector: 'app-pilot-edit-post',
  templateUrl: './pilot-edit-post.component.html',
  styleUrls: ['./pilot-edit-post.component.scss'],
})
export class PilotEditPostComponent implements OnInit {


  @Input() post: any;
  @Input() media: any;
  @Input() media_raw: any;

  caption = '';
  img_urls: any
  files_selected: any
  files: any
  file_list: any = [];


  constructor(
    public modalController: ModalController,
    private master: MasterService,
    public loadingController: LoadingController,
    public toastController: ToastController
    ) { }



  ngOnInit() {
    this.caption = this.post.fields.postBody;

    console.log(this.post)
    console.log(this.media)
    console.log(this.media_raw)
  }


  closeModal() {
    this.modalController.dismiss(
    {
    'dismissed': true
    });
  }


  onFileSelected(event){
    let fileList: FileList = event.target.files;
    let fileListLength = fileList.length;
    this.files = fileList;
    this.file_list = fileList;
    this.img_urls = [];


    for (var i = 0; i < fileListLength; i++) {
       if(fileList[i].size > 1048576*50){

         this.img_urls = undefined;

        this.presentToast('FILE TOO BIG');
         return ;
       }
    }

    for (var i = 0; i < fileListLength; i++) {
      var a = fileList[i];
      var reader = new FileReader();
      reader.onload = (event:any) => {
        var url = event.target.result;
        this.img_urls.push(url); 
      }
      reader.readAsDataURL(a);
    }
    this.files_selected = true;

  }
  async deleteMedia(i: any){
    var muidiq = this.media_raw[i].fields.unique_id;
    var puidiq = this.post.fields.unique_id;
    var res = await this.master.deletePostMedia(puidiq,muidiq);
    if(res=='true'){
      this.closeModal()
    }
  }
  send(){
    this.sendEdit();

  }
  async sendEdit(){
    console.log("posting");
    var fileData = this.getFileFormData();
    fileData.append('post_uuid',this.post.fields.unique_id);
    fileData.append('postBody',this.caption);
    

    this.present();
    var res = await this.master.updatePost(fileData);
    this.dismiss();
    this.closeModal();
  }

  getFileFormData()
  {
  var formData = new FormData();
  if( this.file_list.length <= 0)
    return formData
    formData.append('file_count', this.file_list.length);

    for (var i = 0; i < this.file_list.length; i++) {
      var upload_image = this.file_list[i]
      formData.append('file#' + i.toString() , upload_image, upload_image.name);
    }

  return formData;
  }

  my_loading: any;

  // async presentLoading() {
  //   const loading = await this.loadingController.create({
  //     cssClass: 'my-custom-class',
  //     message: 'Please wait...',
  //     duration: 2000
  //   });
  //   await loading.present();

  //   const { role, data } = await loading.onDidDismiss();
  //   console.log('Loading dismissed!');
  // }
  isLoading = false;
  async present() {
    this.isLoading = true;
    return await this.loadingController.create({
      // duration: 5000,
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }

  async dismiss() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => console.log('dismissed'));
  }
  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }
  isFileImage(file_url) {
    console.log(file_url)
      return(file_url.match(/\.(jpeg|jpg|JPG|JPEG|gif|png)$/) != null);
  }

}
