import { Component, OnInit, Input,Output,ChangeDetectorRef,EventEmitter } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MasterService } from '../../services/master.service'
import { GoogleMapsAPIWrapper } from '@agm/core';
import { MapsAPILoader, AgmMap } from '@agm/core';
import { PopoverController } from '@ionic/angular';


import { LocationInterface,Marker,VimanaProject } from '../../interfaces';
import { ToastController } from '@ionic/angular';
import { FormControl,FormGroup,Validators } from '@angular/forms';

import { LoadingController } from '@ionic/angular';

import { ModalController } from '@ionic/angular';
// import { Router } from '@angular/router';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';

//     private router: Router,


@Component({
  selector: 'app-pilot-one-portfolio',
  templateUrl: './pilot-one-portfolio.component.html',
  styleUrls: ['./pilot-one-portfolio.component.scss'],
})
export class PilotOnePortfolioComponent implements OnInit {

@Input() portfolio_uuid: any;

  @Input() portfolio_data: any;
  @Input() profile_data: any;
  @Output() reopen_emitted = new EventEmitter<any>();


  @Output() doDisplay = new EventEmitter<boolean>();

  @Input() curr_cat: any;
  @Output() cat_emit = new EventEmitter<Number>();
  
  skills_dict: any;


  profile: any;
  profile_stats: any;
  statistics: any;
  status: any;
  verification: any;
  ranks: any;
  my_rank :any;
  my_rank_insignia :any;
  display_picture:any;
  skills: any;
  star_array = [];


  crumbs: any;
  media = [];


  
   showNavigationArrows = false;
   showNavigationIndicators = false;

  categories: any = [];
  cat_dict: any;

  constructor(
    private route: Router,
    private master: MasterService,
    public modalController: ModalController,
    config: NgbCarouselConfig
  ) { 
       config.showNavigationArrows = true;
        config.showNavigationIndicators = true;

  }

  login_type : any;
  ngOnInit() {
    console.log(this.portfolio_uuid);
    // this.bag_data = portfolio_data;
    this.login_type = this.master.get_loginType();
    this.loadProject();
    this.loadCategories();
    this.loadAllSkills();
  }
  shorty(str: any){
    var res = str.split('/');
    return res[res.length - 1];
  }

  async loadAllSkills(){

    var res = await this.master.readPilotSkills();

    console.log(res);
  
    this.skills = JSON.parse(res['payload']);

    this.skills_dict = {};

    for(var i = 0; i < this.skills.length; i ++) {
      var siq = this.skills[i];
      this.skills_dict[siq.pk] = siq;
    }
    console.log(this.skills);
  }

  async loadProject(){
      var res = await this.master.getPilotProject(this.portfolio_uuid);
    this.profile = JSON.parse(res['payload']['profile'])[0];
    this.portfolio_data = JSON.parse(res['payload']['project'])[0];
    this.crumbs = JSON.parse(res['payload']['project_crumbs']);
    this.statistics = JSON.parse(res['payload']['statistics']);

    console.log(this.profile);
    console.log(this.portfolio_data);

    this.media = [];
    this.display_picture = this.master.mapURL(this.profile.fields.userPhoto);

    if(this.portfolio_data.fields.is_public){
      this.doDisplay.emit(true);
    }else{
      this.doDisplay.emit(false);
    }

    for(var i = 0; i < this.crumbs.length; i++){
      var ciq = this.crumbs[i];
      if(this.isFileImage(ciq.fields.crumb_file)||this.isFileVideo(ciq.fields.crumb_file)){
        var media_url = this.master.mapURL(ciq.fields.crumb_file)
        this.media.push(media_url)
      }
    }


    this.star_array.length = this.statistics["rating"];
    this.star_array.fill(1);

    console.log(this.display_picture);
    console.log(this.crumbs);
  }
  isFileImage(file_url) {
      return(file_url.match(/\.(jpeg|jpg|JPG|JPEG|gif|png)$/) != null);
  }  
  isFileVideo(file_url) {
      return(file_url.match(/\.(mp4|avi|webm)$/) != null);
  }  

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true,
    });
  }
  gotoSanctionMission(){
    this.route.navigate(['/create-sanction-mission/',this.portfolio_uuid]);
    this.dismiss();    
  } 


  async loadCategories(){
    var res: any = await this.master.readMissionCategories();
    var categories = JSON.parse(res['payload']);
    console.log(categories);
    this.categories = categories;

    this.cat_dict = {
    };

    for (var i = 0; i < this.categories.length; i++) {
      this.cat_dict[this.categories[i].pk] = this.categories[i].fields.name;
    }
    // this.categories = categories.map((x: any) => {
    //   return x.f
    // })
  }
  catEmit(cat: any){
    if(this.curr_cat == cat){
      this.cat_emit.emit(0)
    }else{
      this.cat_emit.emit(cat)
    }
  }
}
