import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MasterService } from '../../services/master.service'
import { Facebook, FacebookLoginResponse } from '@awesome-cordova-plugins/facebook/ngx';


@Component({
  selector: 'app-pilot-flightlogs',
  templateUrl: './pilot-flightlogs.component.html',
  styleUrls: ['./pilot-flightlogs.component.scss'],
})
export class PilotFlightlogsComponent implements OnInit {
  selectTabs = "personal";

  constructor(
  private route: Router,
  private master: MasterService,
    private fb: Facebook

  ) { }



  ionViewWillEnter() {

    this.ngOnInit();

  }

  ngOnInit() {

    this.loadLogs();

  }

  logs: any;

  plogs: any;
  vlogs: any;


  async loadLogs(){
    var res = await this.master.loadPilotLogs();

    console.log(res);

    this.logs = JSON.parse(res['payload']['logs']);

    this.vlogs = this.logs.filter((x: any) => {
      return x.fields.is_vimana_flight;
    })

    this.plogs = this.logs.filter((x: any) => {
      return !x.fields.is_vimana_flight;
    })
    console.log(this.logs);
  }

  backClicked(){
        this.route.navigate(['/pilot-tablinks/pilot-tools']);

  }


  startFlight(){
    // params
    this.fb.logEvent(this.fb.EVENTS.EVENT_NAME_INITIATED_CHECKOUT);
    this.route.navigate(['/start-flight-logs']);

  }

}
