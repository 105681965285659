import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { MasterService } from '../../services/master.service';
import { ActivatedRoute } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { EditProfileComponent } from '../edit-profile/edit-profile.component';
import { ModalController } from '@ionic/angular';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-pilot-badges',
  templateUrl: './pilot-badges.component.html',
  styleUrls: ['./pilot-badges.component.scss'],
})
export class PilotBadgesComponent implements OnInit {

  constructor(
    private master: MasterService,
    private router: Router,
    public toastController: ToastController,
    private route:ActivatedRoute,
    private cdr: ChangeDetectorRef,
    public modalController: ModalController,
  	) { }

  star_array = [];

  modal: any;

	profile: any;
	profile_stats: any;
	statistics: any;
	status: any;
	verification: any;
  ranks: any;
  ranks_badges: any;
	my_rank :any;
	my_rank_insignia :any;
	display_picture:any;

 ngOnInit() {

    // this.savedFEED = 'userBookmarked';
    // this.postFEED = 'userPosted';
    // this.activityFEED = 'userActivity';
  	this.loadProfile();


  }

    async loadProfile(){
  	var res = await this.master.getPilotProfile();
    this.profile = JSON.parse(res['payload']['profile'])[0];


    this.profile_stats = JSON.parse(res['payload']['profile_stats'])[0];
    this.statistics = JSON.parse(res['payload']['statistics']);
    this.status = res['payload']['status'];
    this.verification = JSON.parse(res['payload']['verification'])[0];
    this.ranks = JSON.parse(res['payload']['ranks']);
    this.ranks_badges = this.master.mapURLs(this.ranks.map((x: any) => { return x.fields.imagefile}))
    this.my_rank = this.ranks[this.ranks.length -1]
    for (var i = this.ranks.length - 1; i >= 0; i--) {
      var this_rank = parseInt(this.ranks[i].fields.xp_cutoff);
      var my_rank = parseInt(this.statistics.xp)
      if (this_rank <= my_rank || i == 0){
        this.my_rank = this.ranks[i];
        break;
      }
      else{
        console.log(this_rank);
        console.log(my_rank);

      }
    }


    this.my_rank_insignia = this.master.mapURL(this.my_rank.fields.imagefile);

    this.display_picture = this.master.mapURL(this.profile.fields.userPhoto);

    console.log(res);
    console.log(this.my_rank);
    console.log(this.my_rank_insignia);
    console.log(this.display_picture)
    console.log(this.ranks);
    console.log(this.profile);
    console.log(this.profile_stats);
    console.log(this.statistics);
    console.log(this.status);
    console.log(this.verification);
    console.log(this.statistics["rating"]);

    this.star_array.length = this.statistics["rating"];
    this.star_array.fill(1);

    console.log(this.star_array);

    this.cdr.markForCheck();
   }

  closeModal() {
      if(this.modal){

      this.modalController.dismiss();
      }
  }

}
