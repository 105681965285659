import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { MasterService } from '../../services/master.service';
import { ActivatedRoute } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { EditProfileComponent } from '../edit-profile/edit-profile.component';
import { PilotBadgesComponent } from '../pilot-badges/pilot-badges.component';

import { ModalController } from '@ionic/angular';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-pilot-profile',
  templateUrl: './pilot-profile.page.html',
  styleUrls: ['./pilot-profile.page.scss'],
})
export class PilotProfilePage implements OnInit {

  constructor(
    private master: MasterService,
    private router: Router,
    public toastController: ToastController,
    private route:ActivatedRoute,
    private cdr: ChangeDetectorRef,
    public modalController: ModalController,
  	) { }

    selectTabs = "stats";

    selectTimeline = "activity"

  profile: any;
  profile_stats: any;
  statistics: any;
  status: any;
  verification: any;
  ranks: any;
  my_rank :any;
  my_rank_insignia :any;
  display_picture:any;

  star_array = [];

  modal: any;
  public savedFEED: any;
  public postFEED: any;
  public activityFEED: any;
  static profile_subcriber: any;


  ngOnInit() {

    this.savedFEED = 'userBookmarked';
    this.postFEED = 'userPosted';
    this.activityFEED = 'userActivity';
  	this.loadProfile();
    if(PilotProfilePage.profile_subcriber == undefined){
    PilotProfilePage.profile_subcriber = this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((dat: any) => {
      console.log(dat);
      if(dat.url == '/pilot-tablinks/pilot-profile'){
        console.log("ROUTER CALL");
        this.loadProfile();
      }
    });
    }

  }
  ionViewWillEnter() {

    this.ngOnInit();

  }

  next_rank: any;
  
  async loadProfile(){
  	var res = await this.master.getPilotProfile();
    this.profile = JSON.parse(res['payload']['profile'])[0];


    this.profile_stats = JSON.parse(res['payload']['profile_stats'])[0];
    this.statistics = JSON.parse(res['payload']['statistics']);
    this.status = res['payload']['status'];
    this.verification = JSON.parse(res['payload']['verification'])[0];
    this.ranks = JSON.parse(res['payload']['ranks']);

    this.my_rank = this.ranks[this.ranks.length -1]

    this.next_rank = this.ranks[this.ranks.length -1]
    for (var i = this.ranks.length - 1; i >= 0; i--) {
      var this_rank = parseInt(this.ranks[i].fields.xp_cutoff);
      var my_rank = parseInt(this.statistics.xp)
      if (this_rank <= my_rank || i == 0){
        this.my_rank = this.ranks[i];
        this.next_rank = this.ranks[i + 1]
        break;
      }
      else{
        console.log(this_rank);
        console.log(my_rank);

      }
    }



    this.my_rank_insignia = this.master.mapURL(this.my_rank.fields.imagefile);



    this.display_picture = this.master.mapURL(this.profile.fields.userPhoto);



    this.statistics.flight_hours = String(this.statistics.flight_hours).slice(0,4);
    console.log(res);
    console.log(this.my_rank);
    console.log(this.my_rank_insignia);
    console.log(this.display_picture)
    console.log(this.ranks);
    console.log(this.profile);
    console.log(this.profile_stats);
    console.log(this.statistics);
    console.log(this.status);
    console.log(this.verification);
    console.log(this.statistics["rating"]);

    this.star_array.length = parseInt(this.statistics["rating"]);
    this.star_array.fill(1);

    console.log(this.star_array);

    this.cdr.markForCheck();
   }

     doLogout(){
    this.master.logout();
    // this.route.navigate(['/login']);
  }

     async presentModal(){
    this.modal = await this.modalController.create({
      component: EditProfileComponent,
      cssClass: 'my-custom-class',
    });

    this.modal.onDidDismiss().then((data) => {
      console.log(data);
      // this.ref.detectChanges();

      // this.zone.run(() => {
      //   console.log('force update the screen');
      // });

      this.ngOnInit();

    })


    return await this.modal.present();
  }

  async presentEditProfileModal(){
      const modal = await this.modalController.create({
        component: EditProfileComponent,
        cssClass: 'image-popup-modal-css',
      });

      modal.onDidDismiss().then((data) => {
        console.log(data);
        // this.ref.detectChanges();

        // this.zone.run(() => {
        //   console.log('force update the screen');
        // });

        this.ngOnInit();

      })


      return await modal.present();
    }


    async goToBadges(){
      const modal = await this.modalController.create({
        component: PilotBadgesComponent,
        cssClass: 'image-popup-modal-css',
      });

      modal.onDidDismiss().then((data) => {
        console.log(data);
        // this.ref.detectChanges();

        // this.zone.run(() => {
        //   console.log('force update the screen');
        // });

        this.ngOnInit();

      })


      return await modal.present();
    }

    goToAccount(){
      this.closeModal()
      this.router.navigate(['/account']);

  }
    goToCertificate(){
      this.closeModal()
      this.router.navigate(['/verifications']);

  }

     goToPayments(){
      this.closeModal()
      this.router.navigate(['/pilot-payments']);

  }
    goToLeaderboard(){
      this.closeModal()
      this.router.navigate(['/leaderboard']);

  }  

    goToRefer(){
      this.closeModal()
      this.router.navigate(['/pilot-refer']);

  }  
     goToMyDrones(){
      this.closeModal()
      this.router.navigate(['/pilot-drones']);

  }  
     goToPortfolio(){
      this.closeModal()
      this.router.navigate(['/pilot-portfolio']);

  }  

    goToTrainings(){
      this.closeModal()
      this.router.navigate(['/pilot-trainings']);
    }

  //    goToBadges(){
  //     this.closeModal()
  //     this.router.navigate(['/pilot-badges']);

  // } 


    closeModal() {
      if(this.modal){

      this.modalController.dismiss();
      }
  }

  goHome(){
    this.closeModal();
    this.router.navigate(['/pilot-tablinks/pilot-missioncontrol']);
  }
}
