import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'skill-dialog',
  templateUrl: 'skill-dialog.html',
})
export class DialogSkill {
  constructor(
    public dialogRef: MatDialogRef<DialogSkill>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  	console.log(data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}


@Component({
  selector: 'badge-dialog',
  templateUrl: 'badge-dialog.html',
})
export class DialogBadge {
  constructor(
    public dialogRef: MatDialogRef<DialogBadge>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  	console.log(data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}