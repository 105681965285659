import { Component, OnInit,Input } from '@angular/core';
import * as THREE from 'three';
import { ChangeDetectorRef ,ViewChild,ViewContainerRef,Renderer2,ElementRef} from '@angular/core';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';



import { LoadingController } from '@ionic/angular';
import { Water } from 'three/examples/jsm/objects/Water.js';
import { Sky } from 'three/examples/jsm/objects/Sky.js';

import { get_dune_scene } from '../../common/scenes/dune-scene';
import { get_monitoring_scene } from '../../common/scenes/monitoring-scene';
import { get_inspection_scene } from '../../common/scenes/inspection-scene';
import { get_photography_scene } from '../../common/scenes/photography-scene';
import { get_videography_scene } from '../../common/scenes/videography-scene';
import { get_survey_scene } from '../../common/scenes/survey-scene';

// function buildSky() {
//   const sky = new Sky();
//   sky.scale.setScalar(10000);
//   // scene.add(sky);
//   return sky;
// }

// function buildSun() {
//   const pmremGenerator = new THREE.PMREMGenerator(renderer);
//   const sun = new THREE.Vector3();

// 	// Defining the x, y and z value for our 3D Vector
//   const theta = Math.PI * (0.49 - 0.5);
//   const phi = 2 * Math.PI * (0.205 - 0.5);
//   sun.x = Math.cos(phi);
//   sun.y = Math.sin(phi) * Math.sin(theta);
//   sun.z = Math.sin(phi) * Math.cos(theta);

//   sky.material.uniforms['sunPosition'].value.copy(sun);
//   scene.environment = pmremGenerator.fromScene(sky).texture;
//   return sun;
// }

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  host: {
  '(window:resize)': 'onResize($event)'
  }
})
export class LandingComponent implements OnInit {

  @ViewChild('dunes', {static: false}) dunes: ElementRef;
  @ViewChild('bg_screen', {static: false}) bg_screen: ElementRef;



  manager: any;
  loader: any;
  object: any;
  camera:any;
  orbitControls: any;

  scene: any;

  renderer: any;
  geometry: any;
  material: any;
  mesh: any;


  @Input()
  xRot = 0;
  @Input()
  yRot = 0;
  @Input()
  zRot = 0;

  @Input()
  xScale = 1;
  @Input()
  yScale = 1 ;
  @Input()
  zScale = 1;

  selection_description: any;

  constructor(
    private ngrenderer: Renderer2,
    public loadingController: LoadingController
  	) { }
  async presentLoading() {
    const loading = await this.loadingController.create({
      message: 'Welcome to Vimana...',
      duration: 4000
    });
    await loading.present();

    const { role, data } = await loading.onDidDismiss();

    console.log('Loading dismissed!');
  }

  ngOnInit() {
  	// this.loadpage();
  }
  ngAfterViewInit(){

  	this.teenInit();
  	this.loadScene(0);
  }
  teenInit(){
    var width = this.dunes.nativeElement.offsetWidth;
    var height = this.dunes.nativeElement.offsetHeight;
    
    console.log("renderer init");

    this.camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 5000 );
    var hElement: HTMLElement = this.dunes.nativeElement;
    this.orbitControls = new OrbitControls(this.camera,hElement);
    // this.orbitControls.target.set(0, 0, 0);

    this.camera.position.x = 500;
    this.camera.position.y = 500;
    this.camera.position.z = 500;
    this.orbitControls.update();
    this.scene = new THREE.Scene();
    this.camera.aspect = width/height;
    this.camera.updateProjectionMatrix();

    var pointLightC = new THREE.PointLight( 0xaaaaaa, 0.1, 1000 );
    var pointLightD = new THREE.PointLight( 0xffffff, 0.1, 1000 );
    pointLightC.position.set( 0, 100, 0 );
    pointLightD.position.set( 10, 100, -10 );

    var ambientLight = new THREE.AmbientLight( 0xcccccc, 0.4 );

    this.scene.add( ambientLight );
    this.scene.add( pointLightC );
    this.scene.add( pointLightD );
    var pointLight = new THREE.PointLight( 0xffffff, 0.8 );
    this.camera.add( pointLight );
    this.scene.add( this.camera );
 
    this.renderer = new THREE.WebGLRenderer( { antialias: true } );
    this.renderer.setSize( 400, 400);
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setClearColor( 0xffffff, 1 );
    this.ngrenderer.setStyle(this.renderer.domElement,'border-radius','10px');
    this.ngrenderer.appendChild(this.dunes.nativeElement,this.renderer.domElement);
    this.renderer.render(this.scene,this.camera);
    this.animFunction = this.dunesUpdate;

    var self = this;
    var noopAnimFunc = function(){
      self.animationUpdate();
    }

    this.renderer.setAnimationLoop( noopAnimFunc );

  }

  startVizor(given_scene,animation_function){


    console.log(given_scene)
    console.log(animation_function)

    this.scene.add( given_scene );

    var self = this;
    
    this.animFunction = animation_function;


    this.renderer.render(this.scene,this.camera);
  }
  animFunction: any;

  animationUpdate(){
    // console.log('animation update');
    var self = this;
    self.animFunction(self);
    // self.orbitControls.position0.set(10,10,10);
    // console.log(self.orbitControls.position0);

    self.orbitControls.update();
    self.camera.lookAt( self.scene.position );
    self.renderer.render( self.scene, self.camera );
  }

  dunesUpdate(self: any , time){
  	// console.log("dunes update");
  }

  teenClear(){
  	const placeholders = this.dunes.nativeElement.childNodes;
    for (var i = 0; i < placeholders.length; i++) {
        this.ngrenderer.removeChild(this.dunes.nativeElement, placeholders[i]);
    }
  }
   onResize(event) {
      this.resizeCanvasToDisplaySize();
  }

  resizeCanvasToDisplaySize(){
  	console.log("resizing");
  	console.log(this.bg_screen);
    this.ngrenderer.setStyle(this.bg_screen.nativeElement,'background','black');
    var width = this.dunes.nativeElement.offsetWidth;
    var height = this.dunes.nativeElement.offsetHeight;
    if(this.renderer.width !== width||this.renderer.height !==height)
    {
      this.camera.aspect = width/height;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize( width, height);
    }
  }

  async loadScene(scene_number : number){
	this.teenClear();
	this.teenInit();
	this.presentLoading();


    var self = this;
    var noopFunction = function(time){
    	self.dunesUpdate(self,time);
    }

    var sceneElements: any;
    var smallScene: any;
    var smallAnimation: any;


    const myTimeout = setTimeout(window.dispatchEvent, 3000,new Event('resize'));  

  	if(scene_number == 0){
      this.selection_description = "Use VIMANA to inspect your investments and properties cheaply and safely!"
    	sceneElements = get_inspection_scene(this.renderer)
    	smallScene = sceneElements[0];
    	smallAnimation = sceneElements[1];
		  this.startVizor(smallScene,smallAnimation);
  	}
  	else if(scene_number == 1){
      this.selection_description = "Use VIMANA to survey faster, more accurately and with full 3D support!"
      sceneElements = get_survey_scene(this.renderer)
      smallScene = sceneElements[0];
      smallAnimation = sceneElements[1];
      this.startVizor(smallScene,smallAnimation);
   	}
  	else if(scene_number == 2){
      this.selection_description = "Use VIMANA to capture moments in an affordable never thought possible before!"
      sceneElements = get_photography_scene(this.renderer)
      smallScene = sceneElements[0];
      smallAnimation = sceneElements[1];
      this.startVizor(smallScene,smallAnimation);
  	}
  	else if(scene_number == 3){
      this.selection_description = "Use VIMANA to take your videography portfolio skills to the stratosphere!"
      sceneElements = get_videography_scene(this.renderer)
      smallScene = sceneElements[0];
      smallAnimation = sceneElements[1];
      this.startVizor(smallScene,smallAnimation);
  	}
  	else if(scene_number == 4){
      this.selection_description = "Use VIMANA to monitor in realtime your plants, sites and events!"
      sceneElements = get_monitoring_scene(this.renderer)
      smallScene = sceneElements[0];
      smallAnimation = sceneElements[1];
      this.startVizor(smallScene,smallAnimation);
    }
  }

}
