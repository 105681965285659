import { Component, OnInit } from '@angular/core';
import { VimanaMission } from '../../interfaces';
import { MasterService } from '../../services/master.service';
import { FormControl,FormGroup,Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { trigger, style, animate, transition } from '@angular/animations';
import { LoadingController } from '@ionic/angular';



import * as moment from 'moment';



const arr = x => Array.from(x);
const num = x => Number(x) || 0;
const str = x => String(x);
const isEmpty = xs => xs.length === 0;
const take = n => xs => xs.slice(0,n);
const drop = n => xs => xs.slice(n);
const reverse = xs => xs.slice(0).reverse();
const comp = f => g => x => f (g (x));
const not = x => !x;
const chunk = n => xs =>
  isEmpty(xs) ? [] : [take(n)(xs), ...chunk (n) (drop (n) (xs))];

let numToWords = n => {
  let a = [
    '', 'one', 'two', 'three', 'four',
    'five', 'six', 'seven', 'eight', 'nine',
    'ten', 'eleven', 'twelve', 'thirteen', 'fourteen',
    'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'
  ];
  let b = [
    '', '', 'twenty', 'thirty', 'forty',
    'fifty', 'sixty', 'seventy', 'eighty', 'ninety'
  ];
  let g = [
    '', 'thousand', 'million', 'billion', 'trillion', 'quadrillion',
    'quintillion', 'sextillion', 'septillion', 'octillion', 'nonillion'
  ];
  // this part is really nasty still
  // it might edit this again later to show how Monoids could fix this up
  let makeGroup = ([ones,tens,huns]) => {
    return [
      num(huns) === 0 ? '' : a[huns] + ' hundred ',
      num(ones) === 0 ? b[tens] : b[tens] && b[tens] + '-' || '',
      a[tens+ones] || a[ones]
    ].join('');
  };
  // "thousands" constructor; no real good names for this, i guess
  let thousand = (group,i) => group === '' ? group : `${group} ${g[i]}`;
  // execute !
  if (typeof n === 'number') return numToWords(String(n));
  if (n === '0')             return 'zero';
  return comp (chunk(3)) (reverse) (arr(n))
    .map(makeGroup)
    .map(thousand)
    .filter(comp(not)(isEmpty))
    .reverse()
    .join(' ');
};

@Component({
  selector: 'app-create-mission',
  templateUrl: './create-mission.component.html',
  styleUrls: ['./create-mission.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateY(100%)', opacity: 0}),
          animate('500ms', style({transform: 'translateY(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateY(0)', opacity: 1}),
          animate('200ms', style({transform: 'translateY(100%)', opacity: 0}))
        ])
      
  ]
  )],
})
export class CreateMissionComponent implements OnInit {

  toSendMission: VimanaMission = {};
  missionForm : FormGroup;

  categories: any;

  startDate: any;
  endDate: any;

  cat_selected: any = [ 0 ];


  to_send_address: any;
  location: any;
  
  my_loading: any;

  customer_budget: any = 0;

  constructor(
    private router: Router,
    public loadingController: LoadingController,
    private master: MasterService
    ) { }

  ngOnInit() {
    this.missionForm = new FormGroup(
    {
      'missionTitle': new FormControl('Draft Mission',[Validators.required]),
      'missionDescription': new FormControl('',[Validators.required]),
      'misisonLocation': new FormControl('',[Validators.required]),
      'custom_id': new FormControl('',[Validators.required]),
      'missionPriceHigh': new FormControl('',[Validators.required]),
      'missionStartDate': new FormControl('',[Validators.required]),
      'missionEndDate': new FormControl('',[Validators.required]),
    })
    this.missionForm.setValue({

      missionTitle: 'Videography',
      missionDescription: 'Vimana Premier Videography - epic shots and unforgettable memories.',
      misisonLocation: 'Unknown',
      custom_id: 'PAX',
      missionPriceHigh: '20000',
      missionStartDate: new Date(moment().add('d',15).toDate()),
      missionEndDate: new Date(moment().add('d',15).toDate())
    });

    console.log(this.missionForm.value)
    this.loadCategories();

  }
  // async loadCategories(){
  //   var res: any = await this.master.readMissionCategories();
  //   this.categories = JSON.parse(res.payload);
  //   console.log(this.categories);
  // }
  //new functions



  stepIndex = 0;
   nextClicked(){
    this.stepIndex += 1;
    console.log(this.stepIndex);
  }


    backClicked(){

    if(this.stepIndex == 0 ){
       this.router.navigate(['/mission-tablinks/mission-dashboard']);
    }
    else{
      this.stepIndex -=1;
    }

  }

  currDiv :any;

  ShowDiv(divVal: string) {
    this.currDiv = divVal;
  }


  currBud :any;

    ShowBud(budVal: string) {
      this.currBud = budVal;
  }
  
  location_capture = false;

  locationEmitted(location){
    console.log(location);
    this.location = location;
    this.location_capture = true;
    this.to_send_address = location.address_level_1 + ' ' + location.address_level_2 + ' ' + location.address_state;
    // this.nextClicked
  }

  num_in_words: any = '';

  keuUpBudget(event: any){
    console.log(event);
    this.num_in_words = numToWords(event.target.value);

  }

  async presentLoading() {
    this.my_loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...',
      duration: 1000,
    });
    await this.my_loading.present();

    const { role, data } = await this.my_loading.onDidDismiss();
    console.log('Loading dismissed!');
  }



  sub_cat_dict: any;
  sub_cats: any;

  cat_dict : any;

  async loadCategories(){
    var res: any = await this.master.readMissionCategories();
    this.categories = JSON.parse(res.payload);

    this.sub_cat_dict  = {};
    this.cat_dict = {};

    var sub_cats = [];
    var master_cats = [];
    for (var i = 0; i < this.categories.length; i++) {
      var ciq = this.categories[i];
      this.cat_dict[ciq.pk] = ciq;
      if(ciq.fields.parent == null){
        master_cats.push(ciq);
      }
      else{
        sub_cats.push(ciq);
        if(this.sub_cat_dict[ciq.fields.parent] == undefined){
          this.sub_cat_dict[ciq.fields.parent] = [ ciq ];
        }
        else{
          this.sub_cat_dict[ciq.fields.parent].push(ciq);
        }
      }
    }

    // for (var i = 0; i < sub_cats.length; i++) {
    //   var ciq = sub_cats[i];

    // }



    this.categories = this.categories.filter(x => x.fields.parent == null);

    console.log(this.sub_cat_dict);
    console.log(master_cats);
    console.log(sub_cats);
  }

  getFileFormData()
  {
  var formData = new FormData();
  if( this.file_list.length <= 0)
    return formData
    formData.append('file_count', this.file_list.length);

    for (var i = 0; i < this.file_list.length; i++) {
      var upload_image = this.file_list[i]
      formData.append('file#' + i.toString() , upload_image, upload_image.name);
    }

  return formData;
  }
  caption = '';
  img_urls: any
  files_selected: any
  files: any
  file_list: any = [];

  onFileSelected(event){
    console.log("FILE SELECTED")
    let fileList: FileList = event.target.files;
    let fileListLength = fileList.length;
    this.files = fileList;
    this.file_list = fileList;
    console.log("FILELIST",fileList)
    this.img_urls = [];
    for (var i = 0; i < fileListLength; i++) {
      console.log("FILE DATA",i)
      var a = fileList[i];
      var reader = new FileReader();
      reader.onload = (event:any) => {
        var url = event.target.result;
        this.img_urls.push(url); 
      }
      reader.readAsDataURL(a);
    }
    this.files_selected = true;

  }
  async sendMission(){

    this.toSendMission.missionTitle = this.missionForm.value.missionTitle;
    this.toSendMission.missionDescription =  this.missionForm.value.missionDescription;
    this.toSendMission.missionPriceLow = this.missionForm.value.missionPriceHigh;
    this.toSendMission.missionPriceHigh = this.missionForm.value.missionPriceHigh;
    this.toSendMission.missionStartDate = this.missionForm.value.missionStartDate ;
    this.toSendMission.missionEndDate =  this.missionForm.value.missionEndDate;
    this.toSendMission.missionLocation =  this.to_send_address;
    this.toSendMission.missionLat =  this.location.marker.lat;
    this.toSendMission.missionLon =  this.location.marker.lng;
    this.toSendMission.custom_id =  this.missionForm.value.custom_id;
    this.toSendMission.category = JSON.stringify(this.cat_selected);

    console.log(this.startDate);
    console.log(this.endDate);
    console.log(this.missionForm);
    console.log(this.toSendMission);

    this.presentLoading();


    var res = await this.master.createMisison(this.toSendMission);

    if(res['result']=='true')
    {
      // this.router.navigate(['/mission-tablinks/mission-dashboard']);


      var fileData = this.getFileFormData();
      fileData.append('mission_uuid',res['uuid']);
      // this.presentLoading();
      var res_upload = this.master.makeClientCrumb(fileData);
      this.router.navigate(['/mission-tablinks/mission-activities']);
      console.log("FILE UPLOAD ONGOING")
    }
    console.log(res);
  }
  categoryIndex = -1;
  master_cat_selected: any;

  categorySelected(c,cat){
    console.log(c);
    console.log(cat);
    this.categoryIndex = c;
    this.cat_selected = [this.categories[c].pk];

    this.master_cat_selected = cat;
    // ***********23 MAR 2023***********
    // VIMANA MISSION CATEGORY ORDERLIST
    // 0/1 Areial Photography/Videography
    // 2 Drone Survey
    // 3 Inspection
    // 4 Surveillance/Monitoring
    // 5 Agri
    // 6 High Altitude
    // *************************t:viz-n*

    this.sub_cats = this.sub_cat_dict[cat.pk];

    console.log(this.sub_cats);
   }

  one_of_many_slection : any;
  many_of_many_array : any;


  is_selected(sc){
    if(this.many_of_many_array){
      if(this.many_of_many_array.indexOf(sc.pk) > -1){
        return true;
      }
    }
    if(this.one_of_many_slection){     
      if(this.one_of_many_slection == sc.pk){
        return true;
      }
    }
    return false;
  }
  chipClicked(sc: any){
    console.log(sc);
    if(sc.fields.clause == 'manyofmany'){
      if(this.many_of_many_array == undefined){
        this.many_of_many_array = []
      }
      var x_ind  =this.many_of_many_array.indexOf(sc.pk);
      if(x_ind == -1){
      this.many_of_many_array.push(sc.pk);
      }
      else{
        this.many_of_many_array.splice(x_ind,1);
      }
      // this.one_of_many_slection = undefined;
    }
    if(sc.fields.clause == 'oneofmany'){
      // this.many_of_many_array = [];
      this.one_of_many_slection = sc.pk;
    }
    console.log(this.many_of_many_array);
    console.log(this.one_of_many_slection);
    // this.getEstimate();
  }
}

