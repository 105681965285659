import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './passages/landing/landing.component';
// import { PilotLoginComponent } from './pilot-login/pilot-login.component';
import { MissionLoginComponent } from './passages/mission-login/mission-login.component';
import { AirSignUpComponent } from './passages/air-sign-up/air-sign-up.component';

import { MissionSignupComponent } from './passages/mission-signup/mission-signup.component';
// import { PilotSignupComponent } from './pilot-signup/pilot-signup.component';
import { ForgetPasswordComponent } from './passages/forget-password/forget-password.component';


//Client ------******
import { CreateMissionComponent } from './client/create-mission/create-mission.component';
import { CreateSanctionMissionComponent } from './client/create-sanction-mission/create-sanction-mission.component';
import { AirCreateMissionComponent } from './client/air-create-mission/air-create-mission.component';
import { ClientMissionListComponent } from './client/client-mission-list/client-mission-list.component';
import { ClientSingleMissionComponent } from './client/client-single-mission/client-single-mission.component';
import { ClientAccountComponent } from './client/client-account/client-account.component';
import { ClientPaymentsComponent } from './client/client-payments/client-payments.component';
import { ClientMissionTimelineComponent } from './client/client-mission-timeline/client-mission-timeline.component';
import { ClientEditProfileComponent } from './client/client-edit-profile/client-edit-profile.component';
import { AllPilotMapComponent } from './client/all-pilot-map/all-pilot-map.component';
import { ClientPortfolioComponent } from './client/client-portfolio/client-portfolio.component';



//Partner ------*******


import { CertificateComponent } from './partner/certificate/certificate.component';
import { LeaderboardComponent } from './partner/leaderboard/leaderboard.component';
import { PilotSingleMissionComponent } from './partner/pilot-single-mission/pilot-single-mission.component';
import { PilotAcceptedMissionListComponent } from './partner/pilot-accepted-mission-list/pilot-accepted-mission-list.component';
import { PilotMissionControlDashboardComponent } from './partner/pilot-mission-control-dashboard/pilot-mission-control-dashboard.component';
import { AccountComponent } from './partner/account/account.component';
import { PilotFlightlogsComponent } from './partner/pilot-flightlogs/pilot-flightlogs.component';
import { StartFlightLogComponent } from './partner/start-flight-log/start-flight-log.component';
import { PilotFlightChecklistComponent } from './partner/pilot-flight-checklist/pilot-flight-checklist.component';
import { NewFlightChecklistComponent } from './partner/new-flight-checklist/new-flight-checklist.component';
import { PilotGroupsComponent } from './social/pilot-groups/pilot-groups.component';
import { PilotOneGroupComponent } from './social/pilot-one-group/pilot-one-group.component';
import { PilotFlightRegulationComponent } from './partner/pilot-flight-regulation/pilot-flight-regulation.component';
import { PilotPaymentsComponent } from './partner/pilot-payments/pilot-payments.component';
import { PilotReferComponent } from './partner/pilot-refer/pilot-refer.component';
import { PilotDronesComponent } from './partner/pilot-drones/pilot-drones.component';
import { PilotPortfolioComponent } from './partner/pilot-portfolio/pilot-portfolio.component';
import { SkillsFormComponent } from './partner/skills-form/skills-form.component';
import { SinglePortfolioProjectComponent } from './partner/single-portfolio-project/single-portfolio-project.component';
import { CreatePortfolioProjectComponent } from './partner/create-portfolio-project/create-portfolio-project.component';
import { PilotAddDroneComponent } from './partner/pilot-add-drone/pilot-add-drone.component';
import { PilotBadgesComponent } from './partner/pilot-badges/pilot-badges.component';
import { PilotTrainingsComponent } from './partner/pilot-trainings/pilot-trainings.component';

import { TrainingSchoolComponent  } from './partner/training-school/training-school.component';
import { TrainingCourseComponent } from './partner/training-course/training-course.component';
import { SingleCourseComponent } from './partner/single-course/single-course.component';

import { AuthGuardService,AntiAuthGuardService,PilotGuardService,ClientGuardService,RPTOGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: 'pilot-tablinks',
    canActivate: [PilotGuardService],
    loadChildren: () => import('./partner/pilot-tablinks/pilot-tablinks.module').then(m => m.PilotTablinksPageModule)
  },
  {
    path: 'mission-tablinks',
    canActivate: [ClientGuardService],
    loadChildren: () => import('./client/mission-tablinks/mission-tablinks.module').then(m => m.MissionTablinksPageModule)
  },
  {
    path: 'training-tablinks',
    canActivate: [RPTOGuardService],
    loadChildren: () => import('./training/training-tablinks/training-tablinks.module').then(m => m.TrainingTablinksPageModule)
  },
  {
    path: 'forget-password',
    component : ForgetPasswordComponent  
  },

  {
    path: 'login',
    canActivate: [AntiAuthGuardService],
    component : MissionLoginComponent  
  },
  {
    path: 'signup',
    component : MissionSignupComponent  
  },
 {
    path: 'air-sign-up',
    component : AirSignUpComponent  
  },
  {
    path: 'landing',
    component : LandingComponent  
  },

  //Client Routs
  {
    path: 'create-mission',
    canActivate: [ClientGuardService],
    component : CreateMissionComponent  
  },
  {
    path: 'create-sanction-mission/:id',
    canActivate: [ClientGuardService],
    component : CreateSanctionMissionComponent  
  },
  {
    path: 'all-pilot-map',
    canActivate: [ClientGuardService],
    component : AllPilotMapComponent  
  },
  {
    path: 'air-create-mission',
    canActivate: [ClientGuardService],
    component : AirCreateMissionComponent  
  },
  {
    path: 'client-portfolio',
    canActivate: [ClientGuardService],
    component : ClientPortfolioComponent  
  },
  {
    path: 'client-mission-list',
    canActivate: [ClientGuardService],
    component : ClientMissionListComponent  
  },
  {
    path: 'client-single-mission/:id',
    canActivate: [ClientGuardService],
    component : ClientSingleMissionComponent  
  },
  {
    path: 'client-account',
    canActivate: [ClientGuardService],
    component : ClientAccountComponent  
  },
  {
    path: 'client-payments',
    canActivate: [ClientGuardService],
    component : ClientPaymentsComponent  
  },

  {
    path: 'client-edit-profile',
    canActivate: [ClientGuardService],
    component : ClientEditProfileComponent  
  },

  {
    path: 'client-mission-timeline/:id',
    canActivate: [ClientGuardService],
    component : ClientMissionTimelineComponent  
  },



  //Pilot Routs
  {
    path: 'verifications',
    canActivate: [PilotGuardService],
    component : CertificateComponent  
  },
  {
    path: 'leaderboard',
    canActivate: [PilotGuardService],
    component : LeaderboardComponent  
  },
  {
    path: 'pilot-groups',
    canActivate: [PilotGuardService],
    component : PilotGroupsComponent  
  },
  {
    path: 'pilot-accepted-mission-list',
    canActivate: [AuthGuardService],
    component : PilotAcceptedMissionListComponent  
  },

  {
    path: 'pilot-single-mission/:id',
    canActivate: [PilotGuardService],
    component : PilotSingleMissionComponent  
  },
  {
    path: 'pilot-mission-control-dashboard/:id',
    canActivate: [PilotGuardService],
    component : PilotMissionControlDashboardComponent  
  },
  {
    path: 'pilot-payments',
    canActivate: [PilotGuardService],
    component : PilotPaymentsComponent  
  },

  {
    path: 'pilot-drones',
    canActivate: [PilotGuardService],
    component : PilotDronesComponent  
  },

  {
    path: 'pilot-portfolio',
    canActivate: [PilotGuardService],
    component : PilotPortfolioComponent  
  },

  {
    path: 'account',
    canActivate: [PilotGuardService],
    component : AccountComponent  
  },
  {
    path: 'flight-logs',
    canActivate: [PilotGuardService],
    component : PilotFlightlogsComponent  
  },
  {
    path: 'pilot-flight-regulation',
    component : PilotFlightRegulationComponent  
  },
  {
    path: 'pilot-one-group/:id',
    canActivate: [PilotGuardService],
    component : PilotOneGroupComponent  
  },
  {
    path: 'start-flight-logs',
    canActivate: [PilotGuardService],
    component : StartFlightLogComponent  
  },
  {
    path: 'flight-checklist',
    canActivate: [PilotGuardService],
    component : PilotFlightChecklistComponent  
  },
  {
    path: 'pilot-refer',
    canActivate: [PilotGuardService],
    component : PilotReferComponent  
  },
   {
    path: 'new-flight-checklist',
    canActivate: [PilotGuardService],
    component : NewFlightChecklistComponent  
  },
  {
    path: 'pilot-skills',
    canActivate: [PilotGuardService],
    component : SkillsFormComponent  
  },
  {
    path: 'create-portfolio-project',
    canActivate: [PilotGuardService],
    component : CreatePortfolioProjectComponent  
  },
  {
    path: 'portfolio-project',
    canActivate: [PilotGuardService],
    component : SinglePortfolioProjectComponent  
  },
   {
    path: 'add-drone',
    canActivate: [PilotGuardService],
    component : PilotAddDroneComponent  
  },
   {
    path: 'pilot-badges',
    canActivate: [PilotGuardService],
    component : PilotBadgesComponent  
  },
   {
    path: 'pilot-trainings',
    canActivate: [PilotGuardService],
    component : PilotTrainingsComponent  
  },
  {
    path: 'training-school/:id',
    canActivate: [PilotGuardService],
    component : TrainingSchoolComponent  
  },
  {
    path: 'training-course/:id',
    canActivate: [PilotGuardService],
    component : TrainingCourseComponent  
  },
   {
    path: 'single-course/:id',
    canActivate: [PilotGuardService],
    component : SingleCourseComponent  
  },
  {
    path: '',
    pathMatch : 'full',
    redirectTo: 'login'  
  },
  // {
  //   path: 'pilot-login',
  //   component : PilotLoginComponent  
  // },
  
  // {
  //   path: 'pilot-tools',
  //   loadChildren: () => import('./partner/pilot-tools/pilot-tools.module').then( m => m.PilotToolsPageModule)
  // },
  {
    path: 'activities',
    loadChildren: () => import('./client/activities/activities.module').then( m => m.ActivitiesPageModule)
  },
  {
    path: 'training-tablinks',
    loadChildren: () => import('./training/training-tablinks/training-tablinks.module').then( m => m.TrainingTablinksPageModule)
  },
  // {
  //   path: 'training-cources',
  //   loadChildren: () => import('./training/training-cources/training-cources.module').then( m => m.TrainingCourcesPageModule)
  // },
  // {
  //   path: 'training-profile',
  //   loadChildren: () => import('./training/training-profile/training-profile.module').then( m => m.TrainingProfilePageModule)
  // },
  // {
  //   path: 'training-transactions',
  //   loadChildren: () => import('./training/training-transactions/training-transactions.module').then( m => m.TrainingTransactionsPageModule)
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
