import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PilotProfilePageRoutingModule } from './pilot-profile-routing.module';

import { PilotProfilePage } from './pilot-profile.page';
import { CertificateComponent } from '../certificate/certificate.component';
import { VmnCommonModule } from '../../common/vmncommon.module';
import { PilotDronesComponent } from '../pilot-drones/pilot-drones.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgbModule,
    VmnCommonModule,
    PilotProfilePageRoutingModule
  ],
  declarations: [
  	PilotProfilePage,
  	CertificateComponent,
    PilotDronesComponent,
  	]
})
export class PilotProfilePageModule {}
