import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { MasterService } from '../../services/master.service';
import { ActivatedRoute } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { filter } from 'rxjs/operators';



import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';

@Component({
  selector: 'app-licences',
  templateUrl: './licences.component.html',
  styleUrls: ['./licences.component.scss'],
})
export class LicencesComponent implements OnInit {


  constructor(
    private master: MasterService,
    private router: Router,
    public toastController: ToastController,
    private route:ActivatedRoute,
    public modalController: ModalController,

  	) { }
       async presentModal(component){
    const modal = await this.modalController.create({
      component: component,
      cssClass: 'my-custom-class',
    });

    modal.onDidDismiss().then((data) => {
      console.log(data);
      // this.ref.detectChanges();

      // this.zone.run(() => {
      //   console.log('force update the screen');
      // });

      this.ngOnInit();

    })


    return await modal.present();
  }
    dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true,
    });
  }	
  ngOnInit() {}
    openModal(typee){
      if(typee == 'privacy'){
        this.presentModal(PrivacyPolicyComponent);
        return 
      }
      if(typee == 'licences'){
        this.presentModal(LicencesComponent);
        return 
      }
    }
}
