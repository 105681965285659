import { Component, OnInit,Input, } from '@angular/core';

import { NavController, NavParams } from '@ionic/angular';


@Component({
  selector: 'app-pilot-mission-actions',
  template: `
  	<ion-list>

    <div *ngIf="actions.length == 0" style="padding: 10px;">
      NO ACTIONS YET
    </div>
  <ion-item *ngFor="let action of actions">
  		<ion-label>{{action.fields.action}} | {{action.fields.timestamp | timeago}}</ion-label>
  </ion-item>
	</ion-list>

  `
})
export class MyActionsPopover implements OnInit {


	actions: any;

	constructor(
    private navParams: NavParams
    ) {


	}

	ngOnInit(){
       this.actions = this.navParams.get('actions');
       console.log(this.actions)
	}	
}